import React, { useEffect, useState, useContext } from "react";
import Loading from "./Loading";
import { UserInfoContext } from "./UserInfo";
import { useNavigate } from "react-router-dom";

function NewsletterSubscribers() {
  const [subscribers, setSubscribers] = useState(null);
  const { isLogedIn } = useContext(UserInfoContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("logedIn")) {
      navigate("/");
    }
  }, [isLogedIn]);

  const removeSubcriber = (event) => {
    const { id } = event.target;
    const indexToRemove = parseInt(id);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: subscribers[indexToRemove].email,
      }),
    };
    setSubscribers((prevState) =>
      prevState.filter((_, index) => index !== indexToRemove)
    );

    fetch("/api/user/deleteNewsletterSubscriber", requestOptions);
  };

  useEffect(() => {
    fetch("/api/user/getAllNewsletterSubscibers")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSubscribers(data.users);
        }
      })
      .catch((error) => console.error("Error fetching subscribers:", error));
  }, []);

  return (
    <div className="flex justify-content-center" style={{ minHeight: "80vh" }}>
      {!subscribers ? (
        <Loading />
      ) : (
        <div style={{ width: "80%" }}>
          {subscribers.length > 0 ? (
            <div>
              <div className="mt-5 mb-3" style={{ fontWeight: "bold" }}>
                Zoznam odoberateľov newslettra
              </div>
              {subscribers.map((follower, index) => (
                <div
                  key={index}
                  className="mb-2 mt-1"
                  style={{
                    borderBottom:
                      index + 1 !== subscribers.length && "1px solid #3a3432",
                  }}
                >
                  <div className="flex justify-content-between pt-1 pb-2">
                    <div className="flex ">
                      <div className="mr-2">{index + 1}.</div>
                      <div>{follower.email}</div>
                    </div>
                    <i
                      className="pi pi-trash pointer justify-content-end align-items-end"
                      id={index}
                      onClick={removeSubcriber}
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              className="flex justify-content-center align-items-center"
              style={{ height: "90vh" }}
            >
              Zatiaľ sa nikto neprihlásil na odber newslettra
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default NewsletterSubscribers;
