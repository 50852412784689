import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import NewProductSpecification from "./NewProductSpecification";
import { Dialog } from "primereact/dialog";
import { UserInfoContext } from "../../UserInfo";
function UpdateProductSpecification() {
  const loaderData = useLoaderData();
  const [submitBtnClicked, setSubmitBtnClicked] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const navigate = useNavigate();
  const updateSubmitBtnState = (state) => {
    setSubmitBtnClicked(state);
  };
  const { isLogedIn } = useContext(UserInfoContext);

  useEffect(() => {
    if (!isLogedIn && !localStorage.getItem("logedIn")) {
      window.location.reload();
    }
  }, [isLogedIn]);

  const updateProducts = (formData) => {
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch("/api/products/updateProduct", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          setDisplayDialog(true);
          setWarningMessage(data.message);
        } else {
          navigate(`/product/detail/${loaderData.product.id}`);
        }
      });
  };
  return (
    <div className="add-product-form">
      <Dialog
        visible={displayDialog}
        header={<div style={{ color: "#df4930" }}>Error</div>}
        className="dialog-added-to-cart"
        onHide={() => setDisplayDialog(false)}
        dismissableMask
      >
        {warningMessage}
      </Dialog>
      <div className="add-product-card" style={{ maxWidth: "600px" }}>
        <NewProductSpecification
          serverRequest={updateProducts}
          updateSubmitBtnState={updateSubmitBtnState}
          submitBtnClicked={submitBtnClicked}
        />
      </div>
    </div>
  );
}

export default UpdateProductSpecification;
