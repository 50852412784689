import React, { useEffect, useState, useRef, useContext } from "react";
import Loading from "../Loading";
import { Toast } from "primereact/toast";
import { UserInfoContext } from "../UserInfo";

function OrdersOverview() {
  const toast = useRef(null);
  const [orders, setOrders] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersToDisplay, setOrdersToDisplay] = useState(null);
  const { loading, updateLoadingState, isAdmin } = useContext(UserInfoContext);
  const updateOrdersToDisplay = (ordersPar) => {
    if (ordersPar) {
      const ordersPerPage = 12;
      const startIndex = (currentPage - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      const ordersPerPageContent = ordersPar.slice(0, endIndex);
      setOrdersToDisplay(ordersPerPageContent);
    }
  };

  useEffect(() => {
    updateOrdersToDisplay(orders);
  }, [orders]);

  useEffect(() => {
    updateLoadingState(true);
    fetch("/api/orders/getAllCreatedOrders")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          updateOrdersToDisplay(data.orders);
          setOrders(data.orders);
        }
        updateLoadingState(false);
      })
      .catch(() => {
        updateLoadingState(false);
      });
  }, []);

  const getCurrentDateClient = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dayFormatted = day < 10 ? `0${day}` : day;
    const monthFormatted = month < 10 ? `0${month}` : month;
    const formattedDate = `${dayFormatted}.${monthFormatted}.${year}`;
    return formattedDate;
  };

  const getCurrentDateDB = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    mm = mm.toString().length === 1 ? `0${mm}` : mm;
    dd = dd.toString().length === 1 ? `0${dd}` : dd;

    return `${yyyy}-${mm}-${dd}`;
  };

  const getRequestOptions = (index) => {
    const currentDate = getCurrentDateDB();

    return {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        date: currentDate,
        orderId: orders[index].id,
      }),
    };
  };

  const showError = (summary, message) => {
    toast.current.show({
      severity: "error",
      summary: summary,
      detail: message,
      life: 5000,
    });
  };

  const updateDeliveryPaiddDate = (index) => {
    const currentDate = getCurrentDateClient();
    const requestOptions = getRequestOptions(index);
    fetch("/api/orders/updatePaidDate", requestOptions)
      .then((resonse) => resonse.json())
      .then((data) => {
        if (data.success) {
          setOrders((prevState) => {
            return prevState.map((order, i) => {
              if (i === index) {
                return { ...order, datePaid: currentDate };
              }
              return order;
            });
          });
        } else {
          showError(
            "Chyba aktualizácie objednávky",
            "V dôsledku dočasnej chyby servera nebolo možné aktualizovať objednávku, vyskúšajte to prosím neskôr"
          );
        }
      });
  };

  const updateDeliveryPreparedDate = (index) => {
    const currentDate = getCurrentDateClient();
    const requestOptions = getRequestOptions(index);
    fetch("/api/orders/updatePreparedDate", requestOptions)
      .then((resonse) => resonse.json())
      .then((data) => {
        if (data.success) {
          setOrders((prevState) => {
            return prevState.map((order, i) => {
              if (i === index) {
                return { ...order, datePrepared: currentDate };
              }
              return order;
            });
          });
        } else {
          showError(
            "Chyba aktualizácie objednávky",
            "V dôsledku dočasnej chyby servera nebolo možné aktualizovať objednávku, vyskúšajte to prosím neskôr"
          );
        }
      });
  };

  const sendReviewRequestEmail = (index) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderId: orders[index].id,
      }),
    };

    fetch("/api/reviews/sendReviewRequestEmail", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOrders((prevState) => {
            return prevState.map((order, i) => {
              if (i === index) {
                return { ...order, reviewRequestSend: true };
              }
              return order;
            });
          });
        }
      });
  };

  const updateDeliverySendDate = (index) => {
    const currentDate = getCurrentDateClient();
    const requestOptions = getRequestOptions(index);
    fetch("/api/orders/updateSendDate", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOrders((prevState) => {
            return prevState.map((order, i) => {
              if (i === index) {
                return { ...order, dateSend: currentDate };
              }
              return order;
            });
          });
        } else {
          showError(
            "Chyba aktualizácie objednávky",
            "V dôsledku dočasnej chyby servera nebolo možné aktualizovať objednávku, vyskúšajte to prosím neskôr"
          );
        }
      });
  };

  const downloadInvoice = (invoiceId) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        invoiceId: invoiceId,
      }),
    };
    fetch("/api/orders/downloadInvoice", requestOptions)
      .then((response) => {
        // Check if response is successful
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Extract filename from response headers
        const filename = response.headers
          .get("Content-Disposition")
          .split(";")[1]
          .trim()
          .split("=")[1]
          .replace(/"/g, "");
        // Convert response to blob
        return response.blob().then((blob) => {
          // Create object URL from blob
          const url = window.URL.createObjectURL(blob);
          // Create a link element
          const a = document.createElement("a");
          a.href = url;
          a.download = filename; // Set the download attribute with the filename
          document.body.appendChild(a);
          a.click(); // Trigger the click event to initiate download
          window.URL.revokeObjectURL(url); // Clean up object URL
        });
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  const deleteOrder = (orderId) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderId: orderId,
      }),
    };
    fetch("/api/orders/deleteOrder", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOrders((prevState) => {
            return prevState.filter((order) => order.id !== orderId);
          });
        } else {
          showError(
            "Nebolo možné odstrániť objednávku",
            "V dôsledku chyby servera nebolo možné odstrániť objednávku, vyskúšajte to opäť neskôr."
          );
        }
      });
  };

  useEffect(() => {
    updateOrdersToDisplay(orders);
  }, [currentPage]);

  const displayMoreProducts = () => {
    setCurrentPage((prevState) => prevState + 1);
  };
  return (
    <div
      className="flex justify-content-center mt-3"
      style={{ minHeight: "80vh" }}
    >
      <Toast ref={toast} />
      {!isAdmin || loading || !orders || !ordersToDisplay ? (
        <Loading />
      ) : orders &&
        orders.length > 0 &&
        ordersToDisplay &&
        ordersToDisplay.length > 0 ? (
        <div className="mt-3">
          {ordersToDisplay.map((order, index) => (
            <div key={`order_${index}`} style={{ position: "relative" }}>
              <i
                style={{
                  position: "absolute",
                  right: "0px",
                  fontSize: "18px",
                }}
                className="pi pi-times pointer"
                onClick={() => deleteOrder(order.id)}
              ></i>
              <div
                key={order.id}
                className=" flex justify-content-between flex-wrap pt-3 pb-3 mb-1"
                style={{
                  width: "80vw",
                  borderBottom:
                    index !== ordersToDisplay.length - 1 && "1px solid gray",
                }}
              >
                <div style={{ fontSize: "16px" }} className="mb-2 mt-2 ">
                  <div style={{ fontWeight: "bold" }}>
                    Objednávka číslo: {order.id}
                  </div>
                  <div>Užívateľ: {order.userName}</div>
                  <div>Email: {order.userEmail}</div>
                  <div>Telefónne číslo: {order.userPhoneNumber}</div>
                  <div>Spôsob platby: {order.paymentMethod}</div>
                  <div>Spôsob doručenia: {order.deliveryMethod}</div>
                </div>
                <div
                  className="flex flex-column align-buttons gap-1"
                  style={{ width: "308px" }}
                >
                  {order.dateCreated && (
                    <div>Dátum vytvorenia: {order.dateCreated}</div>
                  )}
                  {order.datePaid && (
                    <div>Dátum zaplatenia: {order.datePaid}</div>
                  )}
                  {order.datePrepared && (
                    <div>Dátum pripravenia: {order.datePrepared}</div>
                  )}
                  {order.dateSend && (
                    <div>Dátum odoslania: {order.dateSend}</div>
                  )}

                  {order.datePaid === null ? (
                    <button
                      className="btn light pt-2 pb-2"
                      style={{ fontWeight: "bold" }}
                      onClick={() => updateDeliveryPaiddDate(index)}
                    >
                      Označiť objednávku za zaplatenú
                    </button>
                  ) : (
                    ""
                  )}
                  {order.deliveryMethod !== "Email" &&
                    order.datePaid !== null &&
                    order.datePrepared === null && (
                      <button
                        className="btn light pt-2 pb-2"
                        style={{ fontWeight: "bold" }}
                        onClick={() => updateDeliveryPreparedDate(index)}
                      >
                        Označiť objednávku za pripravenú
                      </button>
                    )}
                  {order.deliveryMethod !== "Email" &&
                    order.datePaid !== null &&
                    order.datePrepared !== null &&
                    order.dateSend === null && (
                      <button
                        className="btn light pt-2 pb-2"
                        style={{ fontWeight: "bold", width: "308px" }}
                        onClick={() => updateDeliverySendDate(index)}
                      >
                        Označiť objednávku za odoslanú
                      </button>
                    )}
                  {(order.datePaid &&
                    order.deliveryMethod === "Email" &&
                    !order.reviewRequestSend) ||
                  (order.deliveryMethod !== "Email" &&
                    order.datePaid &&
                    order.datePrepared &&
                    order.dateSend &&
                    !order.reviewRequestSend) ? (
                    <button
                      className="btn light pt-2 pb-2"
                      style={{ fontWeight: "bold", width: "308px" }}
                      onClick={() => sendReviewRequestEmail(index)}
                    >
                      Odoslať email so žiadosťou o recenziu
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    className="btn dark"
                    style={{ width: "190px" }}
                    onClick={() => downloadInvoice(order.invoiceId)}
                  >
                    Stiahnuť faktúru
                  </button>
                </div>
              </div>
            </div>
          ))}
          {ordersToDisplay.length !== orders.length && (
            <div className="flex justify-content-center mt-2 mb-2">
              <button
                className="btn dark"
                style={{ maxWidth: "200px" }}
                onClick={displayMoreProducts}
              >
                Načítať ďalšie
              </button>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{ height: "80vh", fontSize: "19px" }}
          className="flex align-items-center"
        >
          Žiadne dostupné údaje o objednávkach
        </div>
      )}
    </div>
  );
}

export default OrdersOverview;
