import React, { useState } from "react";
const ImageSwitcher = ({ defaultImage, alternateImage, width }) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };
  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };
  return (
    <div
      className="pointer"
      style={{
        position: "relative",
        zIndex: "0",
        height: `calc(${width}px + 60px)`,
      }}
    >
      <img
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`card-image-default card-image ${
          isMouseOver ? "alter" : "default"
        }`}
        src={defaultImage}
        alt={"default-image"}
        style={{
          width: `${width}px`,
          height: `calc(${width}px + 60px)`,
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: "2",
        }}
      />
      <img
        className="card-image"
        src={alternateImage}
        alt={"alter-image"}
        style={{
          width: `${width}px`,
          height: `calc(${width}px + 60px)`,
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: "1",
        }}
      />
    </div>
  );
};

export default ImageSwitcher;
