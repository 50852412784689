import React from "react";
import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Slider } from "primereact/slider";
import { Sidebar } from "primereact/sidebar";
import "../../styles/products.css";
import { UserInfoContext } from "../UserInfo";
import Loading from "../Loading";
import ProductsGrid from "./ProductsGrid";

function Products({ allProducts, updateLoadingState, loading }) {
  const params = useParams();
  //variable where all products are stored
  //variable where just products that are displayed will be stored - for admin it is allProduct, for user just those that are available still
  const [products, setProducts] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  //for paginator setting
  const [currentPage, setCurrentPage] = useState(1);
  const [orderStyle, setOrderStyle] = useState("Usporiadať");
  const [isOrderOptionOpen, setIsOrderOptionOpen] = useState(false);
  const dropdownRef = useRef(null);
  const productRef = useRef(null);
  const numberOfProductsPerPage = 12;
  // Calculate the starting and ending indices for the current page
  const startIndex = (currentPage - 1) * numberOfProductsPerPage;
  const endIndex = startIndex + numberOfProductsPerPage;

  const productsPerPage = products ? filteredProducts.slice(0, endIndex) : [];
  // Slice the products array to get the products for the current page

  //variable to manage if i want to add new product, in case that url doesnt contain "all" I want
  const [displayAddProduct, setDisplayAddProduct] = useState(false);
  const [priceRange, setPriceRange] = useState([]);
  const [sliderMaxMin, setSliderMaxMin] = useState(["", ""]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [dispHidenFilterPrice, setDispHidenFilterPrice] = useState(false);
  const [dispHidenFilterColors, setDispHidenFilterColors] = useState(false);
  const [dispHidenFilterSizes, setDispHidenFilterSizes] = useState(false);
  const [hidenMenuTitle, setHidenMenuTitle] = useState("Filtrovať");
  const [clickedSizes, setClickedSizes] = useState([]);
  const [clickedColors, setClickedColors] = useState([]);
  const [clickedFilter, setClickedFilter] = useState(false);
  const [isFilterAplied, setIsFilterAplied] = useState(false);
  const orderOptions = [
    { name: "Od najdrahších" },
    { name: "Od najlacnejších" },
  ];
  const [inputPrice, setInputPrice] = useState({
    priceRangeMin: 0,
    priceRangeMax: 0,
  });
  const [order, setOrder] = useState({});
  const { isLogedIn, updateItemsCount, removeFromWishlist, isAdmin } =
    useContext(UserInfoContext);
  const urlLocation = useLocation();
  const prevLocationRef = useRef(null);
  const [maxProductOverviewWidth, setMaxProductOverviewWidth] = useState(null);
  const [productOrderWrapperWidth, setProductOrderWrapperWidth] = useState();
  const navigate = useNavigate();

  const handleStorageChange = (event) => {
    if (event.key === "shoppingCart") {
      const newCart = JSON.parse(event.newValue);
      updateItemsCount(newCart.length);
    }
  };

  useEffect(() => {
    const includesAll =
      window.location.href.includes("all") ||
      window.location.href.includes("search");
    setDisplayAddProduct(includesAll);

    const handleResize = () => {
      if (!loading && productRef && productRef.current) {
        setMaxProductOverviewWidth(productRef.current.offsetWidth);
      } else {
        setMaxProductOverviewWidth(window.innerWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("storage", handleStorageChange);
    document.addEventListener("click", handleDocumentClick);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    updateLoadingState(true);
    if (allProducts) {
      let visibleProducts = isAdmin
        ? allProducts
        : getAvailableProductsForNotAdmin();
      updateSeenProducts(visibleProducts);
      updateLoadingState(false);
    }
  }, [allProducts]);

  useEffect(() => {
    if (products) {
      const updatedProducts = products.filter((product) => {
        const productPrices = Array.isArray(product.prices)
          ? product.prices
          : [product.prices];

        const isPriceInRange = productPrices.some(
          (price) => price >= priceRange[0] && price <= priceRange[1]
        );

        const isSizeSelected =
          clickedSizes.length === 0 ||
          product.sizes.some((productSize) =>
            clickedSizes.includes(productSize)
          );

        const isColorSelected =
          clickedColors.length === 0 ||
          product.colors.some((productColors) =>
            clickedColors.includes(productColors)
          );

        return isPriceInRange && isSizeSelected && isColorSelected;
      });

      // Create a copy of filteredProducts to avoid mutating the state directly
      const sortedProducts = [...updatedProducts];

      // Sort the products based on the selected order
      if (order.name === "Od najdrahších") {
        sortedProducts.sort((a, b) => {
          const priceA = Array.isArray(a.prices)
            ? Math.min(...a.prices)
            : a.prices;
          const priceB = Array.isArray(b.prices)
            ? Math.min(...b.prices)
            : b.prices;
          return priceB - priceA;
        });
      } else if (order.name === "Od najlacnejších") {
        sortedProducts.sort((a, b) => {
          const priceA = Array.isArray(a.prices)
            ? Math.min(...a.prices)
            : a.prices;
          const priceB = Array.isArray(b.prices)
            ? Math.min(...b.prices)
            : b.prices;
          return priceA - priceB;
        });
      }

      // Update the state with the filtered and sorted products
      setFilteredProducts(sortedProducts);
      setIsFilterAplied(
        clickedSizes.length > 0 ||
          clickedColors.length > 0 ||
          priceRange[0] !== sliderMaxMin[0] ||
          priceRange[1] !== sliderMaxMin[1]
      );
    }
  }, [products, priceRange, clickedSizes, clickedColors, order]);

  useEffect(() => {
    if (
      prevLocationRef.current &&
      prevLocationRef.current !== location.pathname
    ) {
      window.location.reload();
    }
    prevLocationRef.current = location.pathname;
  }, [urlLocation]);

  const extractMinMaxPrices = (prices) => {
    if (Array.isArray(prices) && prices.length > 0) {
      const minPrice = Math.min(...prices);
      const maxPrice = Math.max(...prices);
      return { min: minPrice, max: maxPrice };
    }

    return { min: prices, max: prices };
  };

  //if product variant has just 0 amounts it is not available
  const areAllAmountsZero = (amounts) => {
    let allValuesZero = true;
    amounts.some((amount) => {
      if (amount !== 0) {
        allValuesZero = false;
        return true; // Stop iteration once a non-zero value is found
      }
    });
    return allValuesZero;
  };

  const getAvailableProductsForNotAdmin = () => {
    let newProducts = [];
    allProducts.map((product) => {
      if (!areAllAmountsZero(product.amounts)) {
        newProducts.push(product);
      }
    });
    return newProducts;
  };
  useEffect(() => {
    if (
      productsPerPage &&
      productsPerPage.length > 0 &&
      !loading &&
      productRef &&
      productRef.current
    ) {
      setMaxProductOverviewWidth(productRef.current.offsetWidth);
    } else {
      const newWidth =
        window.innerWidth < 905
          ? window.innerWidth - 50
          : window.innerWidth - 400;
      setProductOrderWrapperWidth(newWidth);
      setMaxProductOverviewWidth(window.innerWidth);
    }
  }, [products]);

  const updateSeenProducts = (visibleProducts) => {
    if (allProducts) {
      setProducts(visibleProducts);
      setFilteredProducts(visibleProducts);
      const { min, max } = visibleProducts.reduce(
        (acc, product) => {
          const { min: productMin, max: productMax } = extractMinMaxPrices(
            product.prices
          );

          return {
            min: Math.min(acc.min, productMin),
            max: Math.max(acc.max, productMax),
          };
        },
        { min: Infinity, max: -Infinity }
      );
      setPriceRange([min, max]);
      setInputPrice({
        priceRangeMin: min,
        priceRangeMax: max,
      });
      setSliderMaxMin([min, max]);
      const allSizes = visibleProducts
        .flatMap(
          (product) => product.sizes?.filter(Boolean) // Use optional chaining and filter(Boolean)
        )
        .filter((size, index, self) => self.indexOf(size) === index); // Remove duplicates

      const desiredOrder = ["XS", "S", "M", "L", "XL", "XXL", "UNI"];
      // Sort the allSizes array based on the desired order or push non-specified sizes to the end
      const sortedSizes = allSizes.sort((a, b) => {
        const indexA = desiredOrder.indexOf(a);
        const indexB = desiredOrder.indexOf(b);

        // Handle the case when a size is not in the desiredOrder array
        if (indexA === -1 && indexB === -1) {
          return 0; // Maintain the current order for non-specified sizes
        } else if (indexA === -1) {
          return 1; // Move non-specified size 'a' to the end
        } else if (indexB === -1) {
          return -1; // Move non-specified size 'b' to the end
        }

        return indexA - indexB;
      });

      // Set the sorted sizes to the state variable
      setSizes(sortedSizes);

      const allColors = Array.from(
        new Set(allProducts.flatMap((product) => product.colors))
      ).filter((color) => color !== null);

      setColors(allColors);
    }
  };
  useEffect(() => {
    if (allProducts) {
      let visibleProducts = isAdmin
        ? allProducts
        : getAvailableProductsForNotAdmin();
      updateSeenProducts(visibleProducts);
    }
  }, [isAdmin]);

  const addNewProduct = () => {
    navigate(`/add-new-product/${params.productKind}`);
  };

  const updateWidthOfProductOrder = (width) => {
    setProductOrderWrapperWidth(width);
  };

  const filterPriceRange = (event) => {
    const { value } = event;

    if (value[0] < value[1]) {
      setPriceRange(value);
      setInputPrice({ priceRangeMin: value[0], priceRangeMax: value[1] });
    }
  };

  const filterColor = (color) => {
    setClickedColors((prevColors) => {
      const isColorClicked = prevColors.includes(color);

      const updatedColors = isColorClicked
        ? prevColors.filter((clickedColor) => clickedColor !== color)
        : [...prevColors, color];

      return updatedColors;
    });
  };

  const filterSize = (size) => {
    setClickedSizes((prevSizes) => {
      // Check if the size is already in the clickedSizes array
      const isSizeClicked = prevSizes.includes(size);

      // If the size is not in the array, add it; otherwise, remove it
      const updatedSizes = isSizeClicked
        ? prevSizes.filter((clickedSize) => clickedSize !== size)
        : [...prevSizes, size];

      // Return the updated array of clicked sizes
      return updatedSizes;
    });
  };

  const orderProducts = (order) => {
    setOrderStyle(order);
    setOrder({ name: order });
  };

  const handlePriceRangeChange = (event) => {
    const { name, value } = event.target;
    const number = parseFloat(value);
    setInputPrice((prevPrices) => ({ ...prevPrices, [name]: value }));
    if (number >= sliderMaxMin[0] && number <= sliderMaxMin[1]) {
      setPriceRange((prevPrices) => {
        if (name === "priceRangeMin") {
          return [number, prevPrices[1]];
        } else {
          return [prevPrices[0], number];
        }
      });
    } else if (value === "" || value === null) {
      setPriceRange((prevPrices) => {
        if (name === "priceRangeMin") {
          return [sliderMaxMin[0], prevPrices[1]];
        } else {
          return [prevPrices[0], sliderMaxMin[1]];
        }
      });
    }
  };

  const handleDocumentClick = (event) => {
    // Check if the clicked element is inside the dropdown
    if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
      return;
    }
    setIsOrderOptionOpen(false);
  };

  const hideAll = () => {
    setClickedFilter(false);
  };

  const toggleFilterSidebar = () => {
    setHidenMenuTitle("Filtrovať");
    setClickedFilter(true);
    setDispHidenFilterColors(false);
    setDispHidenFilterSizes(false);
    setDispHidenFilterPrice(false);
  };

  const toggleHidenPrice = () => {
    !dispHidenFilterPrice && setHidenMenuTitle("Cena");
    setDispHidenFilterPrice((prevState) => !prevState);
  };

  const toggleHidenColors = () => {
    !dispHidenFilterColors && setHidenMenuTitle("Farba");
    setDispHidenFilterColors((prevState) => !prevState);
  };
  const toggleHidenSizes = () => {
    !dispHidenFilterSizes && setHidenMenuTitle("Veľkosť");
    setDispHidenFilterSizes((prevState) => !prevState);
  };

  const priceForm = (
    <div className="input-price-range-wrapper border-input">
      <input
        type="number"
        id="priceRangeMin"
        name="priceRangeMin"
        placeholder={
          sliderMaxMin[0] && sliderMaxMin[0] !== ""
            ? `${parseFloat(sliderMaxMin[0]).toFixed(2).replace(".", ",")}€`
            : ""
        }
        value={inputPrice.priceRangeMin}
        style={{ fontSize: "14px", padding: "0px", paddingLeft: "6px" }}
        className="input-price-range"
        onChange={handlePriceRangeChange}
      />
      <input
        style={{ fontSize: "14px", padding: "0px", paddingLeft: "6px" }}
        type="number"
        id="priceRangeMax"
        name="priceRangeMax"
        placeholder={
          sliderMaxMin[1] && sliderMaxMin[1] !== ""
            ? `${parseFloat(sliderMaxMin[1]).toFixed(2).replace(".", ",")}€`
            : ""
        }
        value={inputPrice.priceRangeMax}
        onChange={handlePriceRangeChange}
        className="input-price-range"
      />
    </div>
  );

  const deleteFilters = () => {
    setFilteredProducts(products);
    setClickedColors([]);
    setClickedSizes([]);
    const min = sliderMaxMin[0];
    const max = sliderMaxMin[1];
    setInputPrice({
      priceRangeMin: min,
      priceRangeMax: max,
    });
    setPriceRange([min, max]);
  };

  const updateProductsAfterDelete = (productIdToDelete) => {
    const originalProductIndex = products.findIndex(
      (product) => product.id == productIdToDelete
    );
    setProducts((prevProducts) => {
      const newProducts = [...prevProducts];
      newProducts.splice(originalProductIndex, 1);
      return newProducts;
    });

    const filteredProductIndex = filteredProducts.findIndex(
      (product) => product.id == productIdToDelete
    );
    setFilteredProducts((prevFilteredProducts) => {
      const newProducts = [...prevFilteredProducts];
      newProducts.splice(filteredProductIndex, 1);
      return newProducts;
    });
  };

  const hidenFilterMenu = (
    <div className="hiden-filter-menu">
      <button
        className="hiden-filter-menu btn flex justify-content-between align-items-center"
        onClick={toggleHidenPrice}
      >
        Cena
        <i
          className="pi pi-arrow-right"
          style={{ fontSize: "0.8rem", color: "#3a3432" }}
        ></i>
      </button>
      {sizes && sizes.length > 0 && (
        <button
          className="hiden-filter-menu btn flex justify-content-between align-items-center"
          onClick={toggleHidenSizes}
        >
          Veľkosť{" "}
          <i
            className="pi pi-arrow-right"
            style={{ fontSize: "0.8rem", color: "#3a3432" }}
          ></i>
        </button>
      )}
      {colors && colors.length > 0 && (
        <button
          className="hiden-filter-menu btn flex justify-content-between align-items-center"
          onClick={toggleHidenColors}
        >
          Farba
          <i
            className="pi pi-arrow-right"
            style={{ fontSize: "0.8rem", color: "#3a3432" }}
          ></i>
        </button>
      )}
      <div className="bottom-items">
        <button
          className="col-5 pointer transparent light"
          style={{ border: "2px solid #3a3432" }}
          onClick={deleteFilters}
        >
          Vymazať filtre
        </button>
        <button
          className="col-5 pointer dark"
          style={{ color: "white" }}
          onClick={hideAll}
        >
          Hotovo
        </button>
      </div>
    </div>
  );

  const displayMoreProducts = () => {
    setCurrentPage((prevState) => prevState + 1);
  };

  const hiddenFilterSidebar = () => {
    return (
      <Sidebar
        visible={clickedFilter}
        position="right"
        onHide={hideAll}
        header={
          <div className="flex  sidebar-title">
            <div className="justify-content-start">
              {hidenMenuTitle !== "Filtrovať" && (
                <i
                  className="pointer pi pi-arrow-left"
                  onClick={toggleFilterSidebar}
                ></i>
              )}
            </div>
            <div className="flex justify-content-center ml-2">
              {hidenMenuTitle}
            </div>
          </div>
        }
      >
        {dispHidenFilterPrice ? (
          <div>
            <div
              className="flex justify-content-between mb-3 mt-3"
              style={{ padding: "0px 15px" }}
            >
              <div>Od</div>
              <div>Do</div>
            </div>
            <div style={{ padding: "0px 15px" }}>{priceForm}</div>
          </div>
        ) : dispHidenFilterColors ? (
          colors.map((color, index) => (
            <div
              className="p-2 hiden-options-checkbox"
              key={`${color}_${index}`}
            >
              <input
                type="checkbox"
                id={`color_${index}`}
                checked={clickedColors.includes(color)}
                onChange={() => filterColor(color)}
              ></input>
              <label htmlFor={`color_${index}`}>{color}</label>
            </div>
          ))
        ) : dispHidenFilterSizes ? (
          sizes.map((size, index) => (
            <div
              className="p-2 hiden-options-checkbox"
              key={`${size}_${index}`}
            >
              <input
                type="checkbox"
                id={`size_${index}`}
                checked={clickedSizes.includes(size)}
                onChange={() => filterSize(size)}
              ></input>
              <label htmlFor={`size_${index}`}>{size}</label>
            </div>
          ))
        ) : (
          hidenFilterMenu
        )}
      </Sidebar>
    );
  };
  return (
    <div>
      {loading || !products ? (
        <Loading />
      ) : (
        <div style={{ height: "100%" }}>
          {hiddenFilterSidebar()}
          <div
            className={`overviews ${
              allProducts.length > 0
                ? "justify-content-between"
                : "justify-content-center"
            }`}
          >
            {!clickedFilter && (
              <div
                style={{
                  display: !products || (products.length === 0 && "none"),
                }}
                className="filter-menu-wrapper"
              >
                <div className="filter-menu">
                  {isFilterAplied && (
                    <button
                      className="pointer dark btn col-11"
                      style={{
                        marginTop: "30px",
                        color: "white",
                        fontWeight: "normal",
                      }}
                      onClick={deleteFilters}
                    >
                      Vymazať filtre
                    </button>
                  )}
                  <div
                    className="scroll-menu"
                    id="scrollable-container"
                    style={{
                      height: isFilterAplied
                        ? window.innerHeight - 170
                        : window.innerHeight - 90,
                    }}
                  >
                    <div className="filter-menu-title">Cena</div>
                    <div className="slider">
                      {priceForm}
                      <div style={{ marginBottom: "30px" }}>
                        <Slider
                          value={priceRange}
                          max={sliderMaxMin[1]}
                          min={sliderMaxMin[0]}
                          onChange={filterPriceRange}
                          range
                        />
                      </div>
                    </div>
                    {sizes && sizes.length > 0 && (
                      <div>
                        <div className="filter-menu-title">Veľkosť</div>

                        <div
                          className="flex flex-wrap"
                          style={{ marginBottom: "20px" }}
                        >
                          {sizes.map((size, index) => (
                            <div
                              key={`${size}_${index}`}
                              className="filter-menu-box"
                              style={{
                                borderColor: clickedSizes.includes(size)
                                  ? "#3a3432"
                                  : "transparent",
                                maxWidth: "100%",
                              }}
                              onClick={() => filterSize(size)}
                            >
                              {size}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {colors && colors.length > 0 && (
                      <div>
                        <div className="filter-menu-title">Farba</div>
                        <div className="grid">
                          {colors.map((color, index) => (
                            <div
                              className="mb-2"
                              style={{ minWidth: "50%", maxWidth: "100%" }}
                              key={`${color}_${index}`}
                            >
                              <input
                                type="checkbox"
                                id={`color_${index}`}
                                checked={clickedColors.includes(color)}
                                onChange={() => filterColor(color)}
                              ></input>
                              <label
                                htmlFor={`color_${index}`}
                                style={{ fontSize: "15px" }}
                              >
                                {color}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {(productsPerPage && filteredProducts) ||
            (productsPerPage && products && products.length > 0) ? (
              <div className="product-overviews" ref={productRef}>
                <div
                  className="flex flex-column justify-content-end align-items-end upper-options-wrapper"
                  style={{
                    width: productOrderWrapperWidth,
                  }}
                >
                  {isAdmin && isLogedIn && !displayAddProduct && (
                    <div className="flex justify-content-end">
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <button
                          className="btn dark mt-3 pt-2 pb-2"
                          style={{ marginRight: "10px" }}
                          onClick={addNewProduct}
                        >
                          + Pridať nový produkt
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="flex alignt-items-end justify-content-end">
                    <div className="order-dropdown-wrapper">
                      {productsPerPage.length > 0 && (
                        <div
                          className="flex justify-content-between block pointer"
                          ref={dropdownRef}
                          onClick={() =>
                            setIsOrderOptionOpen((prevState) => !prevState)
                          }
                        >
                          <div className="mr-2">{orderStyle}</div>
                          <i className="pi pi-angle-down mt-1"></i>
                        </div>
                      )}
                      <div
                        className="order-dropdown-menu"
                        style={{
                          border: isOrderOptionOpen && "1px solid #c7c9ca",
                        }}
                      >
                        {isOrderOptionOpen && (
                          <div
                            className="mt-2 pointer"
                            style={{
                              opacity: "0.7",
                            }}
                          >
                            {orderOptions.map((option, index) => (
                              <button
                                key={`option_${index}`}
                                className="mb-2 order-button"
                                onClick={() => orderProducts(option.name)}
                              >
                                {option.name}
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="filter-btn">
                      <button
                        className="btn transparent filter-btn"
                        style={{ padding: "7.5px 30px" }}
                        onClick={toggleFilterSidebar}
                      >
                        <i className="pi pi-filter"></i>
                        Filtrovať
                      </button>
                    </div>
                  </div>
                </div>

                {productsPerPage &&
                productsPerPage.length > 0 &&
                maxProductOverviewWidth ? (
                  <div className="mt-3">
                    <ProductsGrid
                      maxProductWidth={maxProductOverviewWidth}
                      productsToDisplay={productsPerPage}
                      updateWidthOfProductOrder={updateWidthOfProductOrder}
                      removeFromWishlist={removeFromWishlist}
                      updateProductsAfterDelete={updateProductsAfterDelete}
                    />
                  </div>
                ) : (
                  <div
                    className="flex justify-content-center align-items-center"
                    style={{ height: "80vh" }}
                  >
                    <div>Žiadne produkty nevyhovujú vyhľadávaniu </div>
                  </div>
                )}

                {filteredProducts &&
                  filteredProducts.length > 12 * currentPage && (
                    <button
                      className="mt-3 mb-3 dark btn"
                      onClick={displayMoreProducts}
                      style={{ width: "200px" }}
                    >
                      Načítať ďalšie
                    </button>
                  )}
              </div>
            ) : (
              <div className="felx justify-content-center">
                {isAdmin && isLogedIn && !displayAddProduct && (
                  <div className="flex justify-content-end">
                    <button
                      className="btn dark mt-3 pt-2 pb-2 "
                      style={{ width: "280px" }}
                      onClick={addNewProduct}
                    >
                      + Pridať nový produkt
                    </button>
                  </div>
                )}
                <div
                  className="flex justify-content-center align-items-center"
                  style={{
                    height: "85vh",
                    width:
                      products && products.length > 0
                        ? productOrderWrapperWidth
                        : "95vw",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, 1000%)",
                      }}
                    >
                      Žiadne produkty nevyhovujú vyhľadávaniu
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default Products;
