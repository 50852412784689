import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import Loading from "../Loading";
import ReviewForm from "./ReviewForm";
import { UserInfoContext } from "../UserInfo";

function EditReview() {
  const [reviewInfo, setReviewInfo] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [alertStarsFromServer, setAlertStarsFromServer] = useState(null);
  const [alertCommentFromServer, setAlertCommentFromServer] = useState(null);
  const [alertNameFromServer, setAlertNameFromServer] = useState(null);
  const loaderData = useLoaderData();
  const navigate = useNavigate();
  const { loading } = useContext(UserInfoContext);
  useEffect(() => {
    setReviewInfo(loaderData.review);
    setRedirectUrl(loaderData.redirectUrl);

    const handleStorageChange = (event) => {
      if (event.key === "reviewChanged") {
        const reviewChanged = localStorage.getItem("reviewChanged")
          ? JSON.parse(localStorage.getItem("reviewChanged"))
          : null;
        if (reviewChanged) {
          localStorage.removeItem("reviewChanged");

          window.location.reload();
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const setRestOfReviewInfo = (event) => {
    const { value, name } = event.target;
    setReviewInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setStars = (newRating) => {
    setReviewInfo((prevState) => ({
      ...prevState,
      stars: parseFloat(newRating),
    }));
  };

  const manageReview = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        stars: reviewInfo.stars,
        name: reviewInfo.name,
        comment: reviewInfo.comment,
        reviewId: reviewInfo.reviewId,
      }),
    };
    fetch("/api/reviews/updateReview", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.emptyFields) {
          setAlertCommentFromServer(data.alertComment);
          setAlertNameFromServer(data.alertName);
          setAlertStarsFromServer(data.alertStars);
        } else if (!data.emptyFields && data.success) {
          localStorage.setItem(
            "reviewChanged",
            JSON.stringify({ changed: true })
          );
          navigate(redirectUrl);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {!reviewInfo || !redirectUrl || loading ? (
        <Loading />
      ) : (
        <div className="flex justify-content-center">
          <ReviewForm
            reviewInfo={reviewInfo}
            setStarsOfReview={setStars}
            setRestOfReviewInfo={setRestOfReviewInfo}
            manageReview={manageReview}
            alertStarsFromServer={alertStarsFromServer}
            alertNameFromServer={alertNameFromServer}
            alertCommentFromServer={alertCommentFromServer}
          />
        </div>
      )}
    </div>
  );
}

export default EditReview;
