import React, { useEffect } from "react";

function NotFound() {
  const icons = require("../icons").icons;

  const handleStorageChange = (event) => {
    if (event.key === "logedIn") {
      if (window.location.href.includes("profile")) {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  return (
    <div
      className="flex justify-content-center text-center align-items-center"
      style={{ height: "70vh" }}
    >
      <div>
        <img
          src={icons.notFound}
          alt="not-found-img"
          style={{ height: "200px" }}
        />
        <div
          style={{ fontSize: "18px", fontWeight: "bold" }}
          className="mb-2 mt-5"
        >
          Zadaná stránka neexistuje
        </div>
        <div>Ľutujeme ale zadaná stránka už neexistuje</div>
      </div>
    </div>
  );
}

export default NotFound;
