import React, { useEffect } from "react";
import { FacebookProvider, CustomChat } from "react-facebook";
import CookiesManager from "./CookiesManager";
import Footer from "./Footer";
//import { getConsentState } from "react-native-onetrust-cmp"; // Assuming this retrieves consent data
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { path } from "scriptjs";

export default function Layout({ children }) {
  const appId = "769580365202502"; // Replace with your app ID
  const pageId = "110294775748153"; //"100083228244550";
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <div style={{ position: "relative" }}>
        <CookiesManager />

        {children}

        {/* <div style={{ position: "absolute", bottom: "0", right: "10" }}>
        <FacebookProvider appId={appId} chatSupport>
          <CustomChat pageId={pageId} minimized={false} />
        </FacebookProvider>
      </div> */}
      </div>
    </div>
  );
}
