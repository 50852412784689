import React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/auth.css";
import LogInForm from "./LogInForm";
import SignInForm from "./SignInForm";
import { UserInfoContext } from "../UserInfo";
import { useGoogleLogin } from "@react-oauth/google";
import { LoginSocialFacebook } from "reactjs-social-login";
import GmailExistsForm from "./GmailExistsForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import TermsAndConditionsAgreement from "./TermsAndConditionsAgreement";
import Loading from "../Loading";

function AuthContainter({ facebookId, closeForms = null, title = null }) {
  const [loadingAfterGoogle, setLoadingAfterGoogle] = useState(false);
  const [isLoggingIn, setIsLogingIn] = useState(true);
  const [emailToRegister, setEmailToRegister] = useState(null);
  const [displayAlterLogin, setDisplayAlterLogin] = useState(false);
  const authRef = useRef(null);
  const { wishList, setLogedInUser, updateLoadingState } =
    useContext(UserInfoContext);
  const [existingMail, setExistingMail] = useState(null);

  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate();

  const checkIfRegisteredOtherWay = (email) => {
    let cartItems = [];
    if (localStorage.getItem("googleLogin")) {
      localStorage.removeItem("googleLogin");
    }
    setLoadingAfterGoogle(true);
    if (localStorage.getItem("shoppingCart")) {
      cartItems = JSON.parse(localStorage.getItem("shoppingCart"));
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        wishlist: wishList,
        shoppingCart: cartItems,
      }),
    };
    fetch("/api/auth/checkIfRegisteredOtherWay", requestOptions)
      .then((response) => response.json())
      .then(async (data) => {
        setLoadingAfterGoogle(false);
        if (!data.differentlyRegistered) {
          if (data.newlyRegistered) {
            setEmailToRegister(email);
          } else {
            updateLoadingState(true);
            if (closeForms) {
              closeForms();
              await setLogedInUser();
            } else {
              await setLogedInUser();
              navigate("/shopping-cart");
            }
          }
        } else {
          setExistingMail(email);
        }
      });
  };

  const googleLogin = useGoogleLogin({
    clientId:
      "100743819708-8esngl0kae8mq1sf8o7ajtcs1cs55ash.apps.googleusercontent.com",
    onSuccess: (tokenResponse) => {
      fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`
      )
        .then((response) => response.json())
        .then((data) => checkIfRegisteredOtherWay(data.email));
    },
    onError: (error) => {
      if (localStorage.getItem("googleLogin")) {
        localStorage.removeItem("googleLogin");
      }
      console.error(error);
    }, // Handle login errors
    // Set uxMode to 'redirect' for in-page login
    uxMode: "redirect",
    // Optional: Set redirectUri to current page URL to ensure redirect within the page
    redirectUri: "https://broleit.com",
  });

  const switchDisplayAlterLogin = () => {
    setDisplayAlterLogin((prevState) => !prevState);
  };

  const handleDocumentClick = (event) => {
    if (event.target.classList.contains("form") && closeForms) {
      closeForms();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  function switchForm(event) {
    event.stopPropagation();
    setIsLogingIn((prevState) => !prevState);
  }

  const facebook = () => {
    console.log("Faceboook");
  };

  const isFormFilled = (formData, setAlerMesage) => {
    let isFilled = true;
    if (formData.formEmail.length === 0) {
      setAlerMesage((prevValues) => ({
        ...prevValues,
        formEmail: "Prosím zadajte email",
      }));
      isFilled = false;
    } else {
      setAlerMesage((prevValues) => ({
        ...prevValues,
        formEmail: "",
      }));
    }
    if (formData.formPassword.length === 0) {
      setAlerMesage((prevValues) => ({
        ...prevValues,
        formPassword: "Prosím zadajte heslo",
      }));
      isFilled = false;
    } else if (formData.formPassword.length < 8) {
      setAlerMesage((prevValues) => ({
        ...prevValues,
        formPassword: "Heslo musí obsahovať aspoň 8 znakov",
      }));
      isFilled = false;
    } else {
      setAlerMesage((prevValues) => ({
        ...prevValues,
        formPassword: "",
      }));
    }
    const emailFormat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (
      !emailFormat.test(formData.formEmail) &&
      formData.formEmail.length !== 0
    ) {
      setAlerMesage((prevValues) => ({
        ...prevValues,
        formEmail: "Nesprávne zadaný email",
      }));
      isFilled = false;
    }
    return isFilled;
  };

  const disablePasswordForm = (event) => {
    event.stopPropagation();
    setExistingMail(null);
    setIsLogingIn(true);
  };

  const displayForgotPassword = () => {
    setExistingMail(null);
    setForgotPassword((prevState) => !prevState);
  };

  const hideRegistrationConfirmation = () => {
    setEmailToRegister(null);
    closeForms();
  };

  return (
    <div>
      {existingMail ? (
        <div ref={authRef}>
          <GmailExistsForm
            toggleForgotPassForm={displayForgotPassword}
            closeForms={closeForms}
            formEmail={existingMail}
            disablePasswordForm={disablePasswordForm}
          />
        </div>
      ) : forgotPassword ? (
        <ForgotPasswordForm
          closeForms={closeForms}
          switchToForgotPassword={displayForgotPassword}
        />
      ) : (
        <div
          className={closeForms && "overlay"}
          style={{ boxShadow: !closeForms && "0 2px 10px rgba(0, 0, 0, 0.2)" }}
        >
          <div className={closeForms && "form"}>
            {emailToRegister ? (
              <TermsAndConditionsAgreement
                email={emailToRegister}
                hideDialog={hideRegistrationConfirmation}
              />
            ) : loadingAfterGoogle ? (
              <Loading />
            ) : (
              <div
                className={`form-wrapper ${!title ? "pt-5 pb-5" : "pb-3"}`}
                style={{ height: title ? "630px" : "570px" }}
                ref={authRef}
              >
                {closeForms && (
                  <div>
                    {title && (
                      <div
                        className="flex justify-content-center mt-5 mb-3 ml-5 mr-5 p-2"
                        style={{
                          fontSize: "18px",
                          border: "2px solid #df4930",
                          color: "#df4930",
                        }}
                      >
                        {title}
                      </div>
                    )}
                    <i
                      className="pi pi-times closing-tab"
                      onClick={closeForms}
                    ></i>
                  </div>
                )}
                <div style={{ width: "250px" }} className="login-form">
                  {isLoggingIn ? (
                    <LogInForm
                      closeForms={closeForms}
                      switchForm={switchForm}
                      switchToForgotPassword={displayForgotPassword}
                      isFormFilled={isFormFilled}
                    />
                  ) : (
                    <SignInForm
                      closeForms={closeForms}
                      switchForm={switchForm}
                      switchDisplayAlterLogin={switchDisplayAlterLogin}
                      isFormFilled={isFormFilled}
                    />
                  )}
                  {!displayAlterLogin && (
                    <div className="loginButtons-wrapper">
                      <p>Alebo pokračovať pomocou</p>
                      <div
                        className="loginButton google"
                        onClick={() => {
                          const now = new Date(); // Get the current date and time
                          const afterMinute = now.getTime() + 1 * 60 * 1000;
                          localStorage.setItem(
                            "googleLogin",
                            JSON.stringify({ untill: afterMinute })
                          );
                          googleLogin();
                        }}
                      >
                        <i className="pi pi-google icon"></i>
                        Google
                      </div>

                      {/* <LoginSocialFacebook
                  appId={facebookId}
                  fields="email"
                  onResolve={(response) => {
                    checkIfRegisteredOtherWay(response.data.email);
                  }}
                  onReject={(error) => console.log(error)}
                >
                  <div className="loginButton facebook">
                    <i className="pi pi-facebook icon"></i>
                    Facebook
                  </div>
                </LoginSocialFacebook> */}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AuthContainter;
