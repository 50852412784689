import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ImageSwitcher from "./ImageSwitcher";
import { UserInfoContext } from "../UserInfo";
function ProductOverview({ overviewData, maxWidth, removeFromWishlist }) {
  const icons = require("../../icons.json").icons;
  const { addToWishlist, wishList } = useContext(UserInfoContext);
  const [soldOut, setSoldOut] = useState(false);

  function truncateText(text) {
    const maxLength = maxWidth < 210 ? 17 : 25;
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  }

  const areAllAmountsZero = () => {
    let allValuesZero = true;
    overviewData.amounts.some((amount) => {
      if (amount !== 0) {
        allValuesZero = false;
        return true; // Stop iteration once a non-zero value is found
      }
    });
    return allValuesZero;
  };

  useEffect(() => {
    setSoldOut(areAllAmountsZero());
  }, []);

  function areAllPricesSame() {
    for (let i = 1; i < overviewData.prices.length; i++) {
      if (overviewData.prices[i] !== overviewData.prices[0]) {
        return false;
      }
    }
    return true;
  }
  return (
    <div style={{ width: `${maxWidth}px` }}>
      <NavLink
        to={`/product/detail/${overviewData.id}`}
        style={{ textDecoration: "none" }}
      >
        <ImageSwitcher
          soldOut={soldOut}
          defaultImage={overviewData.images[0]}
          alternateImage={
            overviewData.images[1]
              ? overviewData.images[1]
              : overviewData.images[0]
          }
          width={maxWidth}
        />
      </NavLink>

      <img
        className="pointer"
        src={
          wishList.includes(overviewData.id)
            ? icons.hearthFilled
            : icons.hearthEmpty
        }
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          padding: "5px",
          height: "45px",
        }}
        onClick={
          wishList.includes(overviewData.id)
            ? () => removeFromWishlist(overviewData.id)
            : () => addToWishlist(overviewData.id)
        }
      ></img>

      {soldOut && (
        <div style={{ position: "relative" }}>
          <div
            style={{
              marginTop: "5px",
              border: "2px solid #df4930",
              borderRadius: "10px",
              fontWeight: "bold",
              color: "#df4930",
              width: "100px",
              position: "absolute",
              top: "0",
              right: "0",
            }}
            className="p-1 pl-2 pr-2 flex justify-content-center"
          >
            Vypredané
          </div>
        </div>
      )}

      <div className="flex justify-content-between">
        <NavLink
          className="card-items mt-2"
          to={`/product/detail/${overviewData.id}`}
          style={{
            textDecoration: "none",
            color: "black",
            fontWeight: "bold",
          }}
        >
          {truncateText(overviewData.name)}
        </NavLink>
      </div>

      <div className="card-items">
        <div
          className="mt-1 mb-2"
          style={{
            fontSize: "15px",
          }}
        >
          {!areAllPricesSame()
            ? `od ${Math.min(...overviewData.prices)
                .toFixed(2)
                .replace(".", ",")}`
            : overviewData.prices.length > 1
            ? overviewData.prices[0] &&
              `${overviewData.prices[0].toFixed(2).replace(".", ",")}€`
            : `${overviewData.prices.toFixed(2).replace(".", ",")}€`}
        </div>
      </div>
    </div>
  );
}

export default ProductOverview;
