import React, { useState, useEffect, useContext, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { UserInfoContext } from "../UserInfo";
import Loading from "../Loading";
import * as scriptjs from "scriptjs";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";

import { NavLink, useLoaderData, useNavigate } from "react-router-dom";
import BouncingDots from "../BouncingDots";

function OrderDetail() {
  const navigate = useNavigate();
  const loaderData = useLoaderData();
  const [creatingOrder, setCreatingOrder] = useState(false);
  const [redirectingGoPay, setRedirectingGoPay] = useState(false);
  const icons = require("../../icons.json").icons;
  const toast = useRef(null);
  const [orderDone, setOrderDone] = useState(false);
  const [displayDifferenDeliveryInput, setDisplayDifferentDeliveryInput] =
    useState(false);
  const [choosenDelivery, setChoosenDelivery] = useState({
    method: null,
    price: null,
  });
  const [unavailableProductsInCard, setUnavailableProductsInCard] =
    useState(false);
  const [choosenPaymentType, setChoosenPaymentType] = useState("Karta");
  const [cardPaymentType, setCardPaymentType] = useState("PAYMENT_CARD");
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [displayedDeliveryMethods, setDisplayedDeliveryMethods] = useState([]);
  const states = ["Slovensko", "Česko"];
  const [conditionsAgreed, setConditionsAgreed] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
    email: "",
    state: "",
    street: "",
    psc: "",
    city: "",
  });
  const [invoiceAdress, setInvoiceAdress] = useState({
    street: "",
    state: "",
    city: "",
    psc: "",
  });
  const [displayInvoiceDetail, setDisplayInvoiceDetail] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState({
    ICO: "",
    DIC: "",
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [multipleProductsInCard, setMultipleProductsInCard] = useState(false);
  const [submitBtnClicked, setSubmitBtnClicked] = useState(false);
  const {
    isLogedIn,
    updateItemsCount,
    loading,
    updateLoadingState,
    updateUserDataAfterLogout,
    setLogInExpiration,
  } = useContext(UserInfoContext);
  const [packetaName, setPacketaName] = useState(null);
  const packetaApiKey = "fbd16455be53a907";
  const packetaOptions = {
    valueFormat: '"Packeta",id,carrierId,carrierPickupPointId,name,city,street',
    view: "modal",
  };
  const [discounts, setDiscounts] = useState(null);
  const [onlyEmailDelivery, setOnlyEmailDelivery] = useState(null);
  const handleChange = (event) => {
    const { value, name } = event.target;
    setOrderDetails((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  }

  const sortArrayAlpabheticaly = (array) => {
    return array.sort((a, b) => {
      if (a.method < b.method) return -1;
      if (a.method > b.method) return 1;
      return 0;
    });
  };

  const getMinPriceForDeliveryMethod = (allDeliveryMethods, methodName) => {
    const allMethodsWithName = allDeliveryMethods.filter(
      (method) => method.method === methodName
    );
    const minPrice = allMethodsWithName[0].price;
    allMethodsWithName.map((deliverMethod) => {
      if (minPrice > deliverMethod.price) {
        minPrice = deliverMethod.price;
      }
    });
    return minPrice;
  };

  const deliveryMethodInitialization = (allDeliveryMethods) => {
    const allDeliveryMethodsSorted = sortArrayAlpabheticaly(allDeliveryMethods);
    setDeliveryMethods(allDeliveryMethodsSorted);
    const uniqueMethods = new Set(
      allDeliveryMethodsSorted.map((item) => item.method)
    );
    const uniqueMethodsArray = Array.from(uniqueMethods);
    const prices = uniqueMethodsArray.map((method) => {
      return getMinPriceForDeliveryMethod(allDeliveryMethods, method);
    });
    setDisplayedDeliveryMethods(
      uniqueMethodsArray.map((method, index) => ({
        method: method,
        price: `od ${prices[index].toFixed(2).replace(".", ",")}`,
      }))
    );
  };
  const updateTotalPrice = (discount) => {
    let percentDiscount = 0;
    if (discount) {
      const arrayDiscounts = discount.filter(
        (discount) => discount.type === "%"
      );
      percentDiscount =
        arrayDiscounts && arrayDiscounts[0] ? arrayDiscounts[0].amount : 0;
    }
    let amount = 0;
    let multipleProducts = false;
    JSON.parse(localStorage.getItem("shoppingCart")).map((product) => {
      const proportion = product.name.startsWith("Poukážka")
        ? 1
        : 1 - percentDiscount / 100;
      if (product.amount > 1) {
        multipleProducts = true;
      }
      amount += product.amount * product.price * proportion;
    });
    setProductPrice(amount);
    const eurDiscount = discount.filter((discount) => discount.type === "€");
    if (eurDiscount.length > 0) {
      amount -= eurDiscount[0].amount;
    }
    setMultipleProductsInCard(
      multipleProducts ||
        JSON.parse(localStorage.getItem("shoppingCart").length > 1)
    );

    setTotalPrice(Math.max(amount, 0));
    if (choosenDelivery.price && typeof choosenDelivery.price !== "string") {
      setTotalPrice((prevPrice) => prevPrice + choosenDelivery.price);
    }
  };

  const findOutIfDeliveryNeeded = () => {
    const storedShoppingCart = JSON.parse(localStorage.getItem("shoppingCart"));

    for (const item of storedShoppingCart) {
      if (item.name !== "Poukážka na email") {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (
      !localStorage.getItem("logedIn") ||
      !localStorage.getItem("shoppingCart") ||
      JSON.parse(localStorage.getItem("shoppingCart")).length === 0
    ) {
      navigate("/shopping-cart");
    }
  }, [isLogedIn]);

  function isBeforeToday(date1) {
    const today = new Date(); // Get today's date

    // Convert date1 to a Date object if it's not already one
    date1 = date1 instanceof Date ? date1 : new Date(date1);

    // Compare the dates (considering year, month, and day)
    return (
      date1.getFullYear() < today.getFullYear() ||
      (date1.getFullYear() === today.getFullYear() &&
        date1.getMonth() < today.getMonth()) ||
      (date1.getFullYear() === today.getFullYear() &&
        date1.getMonth() === today.getMonth() &&
        date1.getDate() <= today.getDate())
    );
  }

  const createEmailRequest = (orderData) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tokenInvoice: orderData.tokenInvoice,
      }),
    };
    fetch("/api/orders/createInvoice", requestOptions);
  };

  const handleStorageChange = (event) => {
    if (event.key === "shoppingCart") {
      window.location.reload();
      updateTotalPrice(discounts);
    } else if (event.key === "discounts") {
      setDiscounts(JSON.parse(localStorage.getItem("discounts")));
    }
  };

  const findOutIfUserLogedIn = () => {
    let logedIn = false;
    if (localStorage.getItem("logedIn")) {
      const logedInDate = localStorage.getItem("logedIn");
      const logedInExpiration = new Date(logedInDate);
      const currentDate = new Date();
      const isBefore = isBeforeToday(logedInExpiration);
      if (!isBefore) {
        logedInExpiration.setDate(logedInExpiration.getDate() + 1);
        let refreshTokenOk = true;
        const timeDifferenceInMilliseconds =
          currentDate.getTime() - logedInExpiration.getTime();
        const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
        if (timeDifferenceInMilliseconds < oneDayInMilliseconds) {
          fetch("/api/auth/setNewRefreshToken")
            .then((response) => response.json())
            .then((data) => {
              if (data.error) {
                updateUserDataAfterLogout();
                refreshTokenOk = false;
                updateLoadingState(false);
              } else {
                if (data.success) {
                  setLogInExpiration();
                } else {
                  refreshTokenOk = false;
                }
              }
            })
            .catch(() => {
              refreshTokenOk = false;
            });
        }
        if (refreshTokenOk) {
          logedIn = true;
        } else {
          updateUserDataAfterLogout();
        }
      } else {
        logedIn = false;
        updateUserDataAfterLogout();
      }
    } else {
      logedIn = false;
    }
    return logedIn;
  };
  useEffect(() => {
    updateLoadingState(true);
    if (
      !loaderData.shoppingCarExists ||
      !localStorage.getItem("logedIn") ||
      !findOutIfUserLogedIn()
    ) {
      if (
        !localStorage.getItem("shoppingCart") ||
        JSON.parse(localStorage.getItem("shoppingCart")).length === 0
      ) {
        updateItemsCount(0);
      }
      navigate("/shopping-cart");
    } else {
      if (localStorage.getItem("shoppingCart")) {
        checkIfEmptyImgExists();

        if (localStorage.getItem("discounts")) {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              discounts: JSON.parse(localStorage.getItem("discounts")),
            }),
          };
          fetch("/api/orders/checkValidDiscounts", requestOptions)
            .then((response) => response.json())
            .then((data) => {
              setDiscounts(data.discounts);
              localStorage.setItem("discounts", JSON.stringify(data.discounts));
              updateTotalPrice(data.discounts);
            })
            .catch(() => updateLoadingState(false));
        }

        fetch("/api/orders/getDeliveryMethods")
          .then((response) => response.json())
          .then((data) => {
            deliveryMethodInitialization(data.deliveryMethods);

            fetch("/api/orders/getUserData")
              .then((response) => response.json())
              .then((userData) => {
                if (userData) {
                  const needDelivery = findOutIfDeliveryNeeded();
                  setOnlyEmailDelivery(
                    !needDelivery || userData.onlyDigiProducts
                  );
                  if (userData.orderDetails) {
                    setOrderDetails(userData.orderDetails);
                  }
                  if (userData.orderDetails.state) {
                    setPriceOfDeliveryMethod(
                      data.deliveryMethods,
                      userData.orderDetails.state,
                      "Slovenská pošta"
                    );
                  }
                }

                updateLoadingState(false);
              });
          });
      } else {
        updateLoadingState(false);
      }

      window.addEventListener("storage", handleStorageChange);

      return () => {
        window.removeEventListener("storage", handleStorageChange);
      };
    }
  }, []);

  const changePrice = (state) => {
    if (choosenDelivery.method) {
      const newPrice = deliveryMethods.filter(
        (deliverMethod) =>
          deliverMethod.state === state &&
          deliverMethod.method === choosenDelivery.method
      );
      if (newPrice && newPrice[0]) {
        setChoosenDelivery((prevState) => ({
          ...prevState,
          price: parseFloat(newPrice[0].price),
        }));

        setPriceOfDeliveryMethod(
          deliveryMethods,
          state,
          choosenDelivery.method
        );
      }
    }
  };

  useEffect(() => {
    changePrice(orderDetails.state);
  }, [orderDetails.state]);

  useEffect(() => {
    changePrice(invoiceAdress.state);
  }, [invoiceAdress.state]);

  useEffect(() => {
    if (choosenDelivery.method === "Packeta") {
      setDisplayDifferentDeliveryInput(false);
    }
    if (discounts && discounts.length > 0) {
      updateTotalPrice(discounts);
    } else if (
      choosenDelivery.price &&
      typeof choosenDelivery.price !== "string"
    ) {
      setTotalPrice(productPrice + choosenDelivery.price);
    } else {
      setTotalPrice(productPrice);
    }
  }, [choosenDelivery]);

  useEffect(() => {
    if (orderDetails.email !== "") {
      localStorage.setItem("discounts", JSON.stringify(discounts));
      updateTotalPrice(discounts);
    }
  }, [discounts]);

  const setPriceOfDeliveryMethod = (allMethods, state, selectedMethod) => {
    const foundMethod = allMethods.filter(
      (deliveryMethod) =>
        deliveryMethod.state === state &&
        deliveryMethod.method === selectedMethod
    );

    setDisplayedDeliveryMethods((prevState) => {
      const index = prevState.findIndex(
        (method) => method.method === selectedMethod
      );
      prevState[index].price = foundMethod[0].price;
      return prevState;
    });
  };

  const handleStateChanges = (event, differentDeliveryAdress) => {
    if (differentDeliveryAdress) {
      //id user set invoice adress by himself it will be by default send by slovak poste
      setInvoiceAdress((prevState) => ({
        ...prevState,
        state: event.value,
      }));
      setPriceOfDeliveryMethod(deliveryMethods, event.value, "Slovenská pošta");
    } else {
      //if he doesnt
      setOrderDetails((prevState) => ({
        ...prevState,
        state: event.value,
      }));
      if (!displayDifferenDeliveryInput) {
        //if there is no invoice input set price for slovakia post
        setPriceOfDeliveryMethod(
          deliveryMethods,
          event.value,
          "Slovenská pošta"
        );
      }
    }
  };

  const handleInvoiceChange = (event) => {
    const { value, name } = event.target;
    setInvoiceAdress((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const getLowestPrice = () => {
    if (deliveryMethods.length > 0) {
      let lowest = deliveryMethods[0].price;
      deliveryMethods.map((deliverMethod) => {
        if (deliverMethod.price < lowest) {
          lowest = deliverMethod.price;
        }
      });
      return lowest.toFixed(2);
    }
  };

  function isObjectAllFilled(obj) {
    for (const key in obj) {
      if (key !== "place") {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          return false;
        }
      }
    }
    return true;
  }

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Nebolo možné vykonať objednávku",
      detail: message,
      life: 3000,
    });
  };

  function handlePointSelection(point) {
    if (point) {
      if (point.country === "cz" || point.country === "sk") {
        const country = point.country === "cz" ? "Česko" : "Slovensko";
        setInvoiceAdress({
          place: point.place,
          street: point.nameStreet,
          state: country,
          city: point.city,
          psc: point.zip,
        });
        setPacketaName(`${point.place} - ${point.nameStreet}`);
        setPriceOfDeliveryMethod(deliveryMethods, country, "Packeta");
      } else {
        showError("Objednávky nie je možné zaslať mimo Česka a Slovenska");
      }
    }
  }

  const displayInvoiceAdressInput = () => {
    if (!displayDifferenDeliveryInput) {
      deliveryMethodInitialization(deliveryMethods);
      if (choosenDelivery.method === "Packeta") {
        setChoosenDelivery({
          method: "",
          price: "",
        });
        setDisplayDifferentDeliveryInput(false);
        setPacketaName(null);
      }
    } else {
      if (orderDetails.state) {
        setPriceOfDeliveryMethod(
          deliveryMethods,
          orderDetails.state,
          "Slovenská pošta"
        );
      } else {
        deliveryMethodInitialization(deliveryMethods);
      }
    }
    setInvoiceAdress({
      place: "",
      street: "",
      state: "",
      city: "",
      psc: "",
    });
    setDisplayDifferentDeliveryInput((prevState) => !prevState);
  };

  function displayPacketaOptions(event) {
    event.preventDefault();
    scriptjs("https://widget.packeta.com/v6/www/js/library.js", () => {
      Packeta.Widget.pick(packetaApiKey, handlePointSelection, packetaOptions);
    });
  }

  const checkIfEmptyImgExists = () => {
    const shoppingCart = JSON.parse(localStorage.getItem("shoppingCart"));
    let emptyImg = false;
    for (const item of shoppingCart) {
      // Use for...of loop to iterate over array elements directly
      if (!item.image || item.maxAmount === 0) {
        emptyImg = true;
        break; // Exit the loop once an empty image is found
      }
    }
    return setUnavailableProductsInCard(emptyImg);
  };

  const makeOrder = (event) => {
    event.preventDefault();

    setSubmitBtnClicked(true);
    if (
      isObjectAllFilled(orderDetails) &&
      (onlyEmailDelivery ||
        ((!displayDifferenDeliveryInput ||
          (displayDifferenDeliveryInput && isObjectAllFilled(invoiceAdress))) &&
          (choosenDelivery.method !== "Packeta" ||
            (choosenDelivery.method === "Packeta" && packetaName)))) &&
      (!displayInvoiceDetail ||
        (displayInvoiceDetail && isObjectAllFilled(invoiceDetail)))
    ) {
      if (conditionsAgreed) {
        setCreatingOrder(true);
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderDetails: orderDetails,
            invoiceAdress: invoiceAdress,
            deliveryMethod: choosenDelivery,
            paymentMethod: choosenPaymentType,
            logedIn: isLogedIn,
            shoppingCart: JSON.parse(localStorage.getItem("shoppingCart")),
            invoiceDetail: invoiceDetail,
            invoiceDetailNeeded: displayInvoiceDetail,
            invoiceAdressNeeded: displayDifferenDeliveryInput,
            discounts: discounts,
            onlyEmailDelivery: onlyEmailDelivery,
            cardType: cardPaymentType,
            conditionsAgreed: conditionsAgreed,
          }),
        };
        fetch("/api/orders/makeOrder", requestOptions)
          .then((response) => response.json())
          .then(async (orderData) => {
            setCreatingOrder(false);
            if (orderData.success) {
              if (choosenPaymentType === "Karta") {
                setRedirectingGoPay(true);
              } else {
                setOrderDone(true);
              }
              createEmailRequest(orderData);

              updateItemsCount(0);
              localStorage.setItem("shoppingCart", JSON.stringify([]));
              localStorage.setItem("discounts", JSON.stringify([]));
              if (choosenPaymentType === "Karta") {
                try {
                  const requestOptions = {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      email: orderDetails.email,
                      orderId: orderData.orderId,
                      paymentMethod: cardPaymentType,
                    }),
                  };
                  const response = await fetch(
                    "/api/orders/createGoPayPayment",
                    requestOptions
                  );
                  const result = await response.json();
                  if (response.ok) {
                    window.location.href = result.paymentUrl;
                  } else {
                    window.location.href = orderData.cancelUrl;
                  }
                } catch (error) {
                  window.location.href = orderData.cancelUrl;
                }
              }
            } else {
              if (orderData.unavailableProductsInCart) {
                const newShoppingCart = JSON.parse(
                  localStorage.getItem("shoppingCart")
                );
                newShoppingCart.map((item) => {
                  const unavailable = orderData.unavailableProducts.filter(
                    (product) => product.id_variant === item.id_variant
                  );
                  if (unavailable.length > 0) {
                    item.maxAmount = unavailable[0].maxAmount;
                  }
                });
                localStorage.setItem(
                  "shoppingCart",
                  JSON.stringify(newShoppingCart)
                );
                setUnavailableProductsInCard(true);
              } else {
                showError(orderData.message);
              }
              updateLoadingState(false);
            }
          })
          .catch(() => {
            updateLoadingState(false);
          });
      }
    } else {
      if (!unavailableProductsInCard) {
        showError("Vyplňte prosím všetky potrebné údaje");
      } else {
        setUnavailableProductsInCard(true);
      }
      updateLoadingState(false);
    }
  };

  const percentDiscountExists = () => {
    if (discounts && discounts.length > 0) {
      return discounts.some((discount) => discount.type === "%");
    }
  };

  const getPriceAfterDiscount = (itemPrice) => {
    const discountAmount = discounts.filter(
      (discount) => discount.type === "%"
    );
    if (discountAmount && discountAmount.length > 0) {
      return (1 - discountAmount[0].amount / 100) * itemPrice;
    }
  };

  const toggleInvoiceAdressInput = () => {
    setDisplayInvoiceDetail((prevState) => !prevState);
    setInvoiceDetail({
      ICO: "",
      DIC: "",
    });
  };

  const handleInvoiceInput = (event) => {
    event.preventDefault();
    const { value, name } = event.target;
    setInvoiceDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updatePaymentCardType = (event) => {
    const { id } = event.target;
    setChoosenPaymentType("Karta");
    setCardPaymentType(id);
  };

  const updatePaymentMethod = (event) => {
    const { name } = event.target;
    setChoosenPaymentType(name);
    if (name === "Karta" && !cardPaymentType) {
      setCardPaymentType("PAYMENT_CARD");
    } else {
      setCardPaymentType(null);
    }
  };

  const removeDiscount = (code) => {
    const discountToRemove = discounts.filter(
      (discount) => discount.code === code
    );
    const eurDiscount = discounts.filter((discount) => discount.type === "€");
    if (discountToRemove[0].type === "%") {
      let newTotalPrice = totalPrice;
      if (eurDiscount.length > 0) {
        newTotalPrice += parseFloat(eurDiscount[0].amount);
      }
      newTotalPrice *= 1 / (1 - discountToRemove[0].amount / 100);
      if (eurDiscount.length > 0) {
        newTotalPrice -= eurDiscount[0].amount;
      }
      setTotalPrice(Math.max(newTotalPrice, 0));
    } else {
      let newTotalPrice = totalPrice + discountToRemove[0].amount;
      setTotalPrice(Math.max(newTotalPrice, 0));
    }

    if (choosenDelivery.price && typeof choosenDelivery.price !== "string") {
      setTotalPrice((prevState) => prevState + choosenDelivery.price);
    }

    const newDiscounts = discounts.filter((discount) => discount.code !== code);
    setDiscounts(newDiscounts);
  };

  const hideAlertDialog = () => {
    setUnavailableProductsInCard(false);
    navigate("/shopping-cart");
  };

  const deletedProductDialog = () => {
    return (
      <Dialog
        header="Nebolo možné vykonať objednávku"
        visible={unavailableProductsInCard}
        style={{ fontSize: "16px" }}
        onHide={hideAlertDialog}
        dismissableMask
        resizable={false}
        draggable={false}
      >
        <div style={{ fontSize: "17px" }} className="mb-0 pb-0">
          Ľutujeme, ale niektoré produkty už nie sú k dispozícii.
        </div>
        <button
          className="btn dark mt-4 p-2"
          onClick={() => navigate("/shopping-cart")}
        >
          Upraviť košík
        </button>
      </Dialog>
    );
  };

  const redirectAfterOrder = () => {
    return (
      <div
        className="flex align-items-center justify-content-center"
        style={{ height: "calc(100vh - 90px)" }}
      >
        <div
          style={{
            maxWidth: "600px",
            boxShadow: " 0 2px 10px rgba(0, 0, 0, 0.3)",
            padding: "20px 50px",
            borderRadius: "10px",
          }}
        >
          <div className="flex justify-content-center align-items-center">
            <i
              style={{ color: "green", fontSize: "40px" }}
              className="pi pi-check-circle"
            ></i>
            <div
              className="ml-3"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              Objednávka bola vytvorená
            </div>
          </div>

          <div className="text-center mt-2">
            ďakujeme za objednávku, o jej priebehu Ťa budeme priebežne
            informovať na {orderDetails.email}
          </div>
        </div>
      </div>
    );
  };

  const invoiceDetailForm = () => {
    return (
      <div>
        <div className="flex  flex-wrap order-detail-line align-items-center">
          <div className="order-input">
            <input
              className="mb-2"
              type="text"
              name="ICO"
              value={invoiceDetail.ICO}
              onChange={handleInvoiceInput}
              placeholder="IČO"
              style={{
                borderBottom:
                  submitBtnClicked && displayInvoiceDetail && !invoiceDetail.ICO
                    ? "2px solid #df4930"
                    : "",
              }}
            ></input>
            {submitBtnClicked && displayInvoiceDetail && !invoiceDetail.ICO && (
              <div className="alert mb-2" style={{ fontSize: "15px" }}>
                Prosím zadajte IČO
              </div>
            )}
          </div>
          <div className="order-input">
            <input
              className="mb-2"
              type="text"
              name="DIC"
              value={invoiceDetail.DIC}
              onChange={handleInvoiceInput}
              placeholder="DIČ"
              style={{
                borderBottom:
                  submitBtnClicked && displayInvoiceDetail && !invoiceDetail.DIC
                    ? "2px solid #df4930"
                    : "",
              }}
            ></input>
            {submitBtnClicked && displayInvoiceDetail && !invoiceDetail.DIC && (
              <div className="alert mb-2" style={{ fontSize: "15px" }}>
                Prosím zadajte DIČ
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const adressInputs = (
    object,
    handleInput,
    differentDeliveryAdress = false
  ) => {
    return (
      <div>
        <div className="flex flex-wrap order-detail-line align-items-center">
          <div className="order-input">
            <Dropdown
              value={object.state}
              onChange={(event) =>
                handleStateChanges(event, differentDeliveryAdress)
              }
              options={states}
              placeholder="Krajina"
              className="w-full"
              style={{
                border: "none",
                borderBottom:
                  submitBtnClicked && !object.state
                    ? "2px solid #df4930"
                    : "1px solid #3a3432",
                borderRadius: "0px",
              }}
            />
            {submitBtnClicked && !object.state && (
              <div className="alert mb-2 mt-2" style={{ fontSize: "15px" }}>
                Prosím zadajte štát
              </div>
            )}
          </div>

          <div className="order-input">
            <input
              className="mb-2"
              type="text"
              name="street"
              placeholder="Ulica"
              value={object.street}
              onChange={handleInput}
              style={{
                borderBottom:
                  submitBtnClicked && !object.street ? "2px solid #df4930" : "",
              }}
            ></input>
            {submitBtnClicked && !object.street && (
              <div className="alert mb-2" style={{ fontSize: "15px" }}>
                Prosím zadajte ulicu
              </div>
            )}
          </div>
        </div>
        <div className="flex  flex-wrap order-detail-line align-items-center">
          <div className="order-input">
            <input
              className="mb-2"
              type="text"
              name="psc"
              value={object.psc}
              onChange={handleInput}
              placeholder="PSČ"
              style={{
                borderBottom:
                  submitBtnClicked && !object.psc ? "2px solid #df4930" : "",
              }}
            ></input>
            {submitBtnClicked && !object.psc && (
              <div className="alert mb-2" style={{ fontSize: "15px" }}>
                Prosím zadajte PSČ
              </div>
            )}
          </div>
          <div className="order-input">
            <input
              className="mb-2"
              type="text"
              name="city"
              value={object.city}
              onChange={handleInput}
              placeholder="Mesto"
              style={{
                borderBottom:
                  submitBtnClicked && !object.city ? "2px solid #df4930" : "",
              }}
            ></input>
            {submitBtnClicked && !object.city && (
              <div className="alert mb-2" style={{ fontSize: "15px" }}>
                Prosím zadajte mesto
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Toast ref={toast} />
      {deletedProductDialog()}
      {loading ||
      (localStorage.getItem("logedIn") && !orderDetails.email) ||
      !loaderData.shoppingCarExists ||
      !localStorage.getItem("logedIn") ||
      (localStorage.getItem("logedIn") && !isLogedIn) ? (
        <Loading />
      ) : orderDone ? (
        redirectAfterOrder()
      ) : redirectingGoPay ? (
        <BouncingDots message="Prebieha presmerovanie na platobnú bránu" />
      ) : creatingOrder ? (
        <BouncingDots message="Prebieha vytvorenie objednávky" />
      ) : (
        <div className="flex justify-content-center flex-wrap pt-4 order-details">
          <form className="order-detail-form">
            <div>
              <div>
                <div className="order-detail-title-wrapper">
                  <div
                    className="mb-1 order-detail-title"
                    style={{ fontWeight: "bold" }}
                  >
                    Fakturačné údaje
                  </div>
                </div>

                <div className="flex flex-wrap order-detail-line align-items-center">
                  <div className="order-input">
                    <input
                      className="mb-2"
                      type="text"
                      name="name"
                      value={orderDetails.name}
                      onChange={handleChange}
                      placeholder="Meno"
                      style={{
                        borderBottom:
                          submitBtnClicked && !orderDetails.name
                            ? "2px solid #df4930"
                            : "",
                      }}
                    ></input>
                    {submitBtnClicked && !orderDetails.name && (
                      <div className="alert mb-2" style={{ fontSize: "15px" }}>
                        Prosím zadajte meno
                      </div>
                    )}
                  </div>
                  <div className="order-input">
                    <input
                      className="mb-2"
                      type="text"
                      name="surname"
                      value={orderDetails.surname}
                      onChange={handleChange}
                      placeholder="Priezvisko"
                      style={{
                        borderBottom:
                          submitBtnClicked && !orderDetails.surname
                            ? "2px solid #df4930"
                            : "",
                      }}
                    ></input>
                    {submitBtnClicked && !orderDetails.surname && (
                      <div className="alert mb-2" style={{ fontSize: "15px" }}>
                        Prosím zadajte priezvisko
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="order-detail-title-wrapper mt-2 mb-2"
                  style={{ fontSize: "15px" }}
                >
                  <div className="mb-1 order-detail-title ">
                    <input
                      type="checkbox"
                      id="invoice-detail"
                      checked={displayInvoiceDetail}
                      onChange={toggleInvoiceAdressInput}
                    ></input>
                    <label htmlFor="invoice-detail">Fakturovať na firmu?</label>
                  </div>
                </div>

                <div
                  className={`table-container ${
                    displayInvoiceDetail ? "open" : ""
                  }`}
                >
                  {invoiceDetailForm()}
                </div>

                <div className="flex flex-wrap order-detail-line align-items-center">
                  <div className="order-input">
                    <input
                      className="mb-2"
                      type="text"
                      name="phoneNumber"
                      value={orderDetails.phoneNumber}
                      onChange={handleChange}
                      placeholder="Tel. číslo"
                      style={{
                        borderBottom:
                          submitBtnClicked && !orderDetails.phoneNumber
                            ? "2px solid #df4930"
                            : "",
                      }}
                    ></input>

                    {submitBtnClicked && !orderDetails.phoneNumber && (
                      <div className="alert mb-2" style={{ fontSize: "15px" }}>
                        Prosím zadajte tel. číslo
                      </div>
                    )}
                  </div>
                  <div className="order-input">
                    {localStorage.getItem("logedIn") ? (
                      <div
                        className="mb-2"
                        style={{
                          opacity: "0.7",
                          borderBottom: "1px solid black",
                          paddingLeft: "10px",
                          fontSize: "15px",
                          height: "30px",
                          marginTop: "10px",
                        }}
                      >
                        {orderDetails.email}
                      </div>
                    ) : (
                      <div>
                        <input
                          className="mb-2"
                          type="text"
                          name="email"
                          value={orderDetails.email}
                          onChange={handleChange}
                          placeholder="Email"
                          style={{
                            borderBottom:
                              submitBtnClicked && !orderDetails.email
                                ? "2px solid #df4930"
                                : "",
                          }}
                        ></input>
                        {submitBtnClicked && !orderDetails.email && (
                          <div
                            className="alert mb-2"
                            style={{ fontSize: "15px" }}
                          >
                            Prosím zadajte email
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {adressInputs(orderDetails, handleChange)}
              {!onlyEmailDelivery && (
                <div
                  className="order-detail-title-wrapper mt-2 mb-2"
                  style={{ fontSize: "15px" }}
                >
                  <div className="mb-1 order-detail-title ">
                    <input
                      type="checkbox"
                      id="delivery-address"
                      checked={displayDifferenDeliveryInput}
                      onChange={displayInvoiceAdressInput}
                    ></input>
                    <label htmlFor="delivery-address">
                      Odoslať na inú adresu?
                    </label>
                  </div>
                </div>
              )}

              <div
                className={`table-container ${
                  displayDifferenDeliveryInput ? "open" : ""
                }`}
              >
                {adressInputs(invoiceAdress, handleInvoiceChange, true)}
              </div>
            </div>
            <div className="order-detail-title-wrapper">
              {!onlyEmailDelivery && (
                <div className="order-detail-title">
                  <div
                    className="mt-5 mb-2 order-detail-title"
                    style={{ fontWeight: "bold" }}
                  >
                    Doprava
                  </div>
                  <div className="mt-2 mb-2" style={{ fontSize: "15px" }}>
                    {displayedDeliveryMethods &&
                      displayedDeliveryMethods.map((deliveryMethod, index) => (
                        <div
                          className="flex justify-content-between flex-wrap"
                          key={`${deliveryMethod.method}-${index}`}
                        >
                          <div className="flex align-items-center mr-2 mb-2">
                            <input
                              type="checkbox"
                              id={`${deliveryMethod.method}-${index}`}
                              style={{ borderRadius: "10px" }}
                              checked={
                                choosenDelivery.method === deliveryMethod.method
                              }
                              onChange={() =>
                                setChoosenDelivery({
                                  method: deliveryMethod.method,
                                  price: parseFloat(deliveryMethod.price),
                                })
                              }
                            ></input>
                            <label
                              htmlFor={`${deliveryMethod.method}-${index}`}
                              className="flex align-items-center mt-2"
                            >
                              <div
                                className="flex justify-content-center"
                                style={{ fontSize: "15px" }}
                              >
                                {deliveryMethod.method}

                                {deliveryMethod.price && (
                                  <div className="flex pl-1">
                                    -
                                    <div
                                      className="pl-1"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {typeof deliveryMethod.price === "string"
                                        ? deliveryMethod.price
                                        : deliveryMethod.price
                                            .toFixed(2)
                                            .replace(".", ",")}
                                      €
                                    </div>
                                  </div>
                                )}
                              </div>
                            </label>
                          </div>
                          {deliveryMethod.method === "Packeta" &&
                            choosenDelivery.method ===
                              deliveryMethod.method && (
                              <div className="mt-1 mb-1 align-packeta-bnt packeta-wraper-width">
                                <div className="flex align-packeta-bnt mb-1">
                                  <button
                                    style={{
                                      fontSize: "14px",
                                      backgroundColor: "#3a3432",
                                      color: "white",
                                      borderRadius: "5px",
                                      padding: "5px 0px",
                                      border: "none",
                                      cursor: "pointer",
                                      width: "200px",
                                    }}
                                    onClick={displayPacketaOptions}
                                  >
                                    Vybrať výdajné miesto
                                  </button>
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                  {packetaName && packetaName}
                                </div>
                                {submitBtnClicked && !packetaName && (
                                  <div
                                    className="alert mb-2 mt-2"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Prosím vyberte výdajné miesto
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                      ))}
                  </div>
                  {submitBtnClicked &&
                    !choosenDelivery.method &&
                    !displayDifferenDeliveryInput && (
                      <div
                        className="alert mb-2 mt-2"
                        style={{ fontSize: "15px" }}
                      >
                        Prosím vyberte spôsob dopravy
                      </div>
                    )}
                </div>
              )}
            </div>

            <div className="order-detail-title-wrapper">
              <div className="order-detail-title">
                <div className="mt-5 mb-2 " style={{ fontWeight: "bold" }}>
                  Spôsob platby
                </div>
                <div className="mb-1 mt-2 flex align-items-center justify-content-between">
                  <div className="flex">
                    <input
                      type="checkbox"
                      id="card-method"
                      name="Karta"
                      style={{ borderRadius: "10px" }}
                      checked={choosenPaymentType === "Karta"}
                      onChange={updatePaymentMethod}
                    ></input>
                    <label
                      htmlFor="card"
                      className="flex justify-content-between align-items-center mt-2"
                    >
                      <div style={{ fontSize: "15px" }}>Kartou</div>
                    </label>
                  </div>

                  <div className="flex justify-content-end align-items-center">
                    <img
                      src="https://help.gopay.com/cs/img.php?hash=6839a31109d2573ce58c6b2b52a099aae7d7c047a8fe0bdd54ebbc10b54b49bb.png"
                      alt="gopay"
                    ></img>
                  </div>
                </div>
                <div className="ml-5">
                  <div className="mb-1 mt-2 flex align-items-center">
                    <input
                      type="checkbox"
                      id="PAYMENT_CARD"
                      style={{ borderRadius: "10px" }}
                      checked={cardPaymentType === "PAYMENT_CARD"}
                      onChange={updatePaymentCardType}
                    ></input>
                    <label
                      htmlFor="PAYMENT_CARD"
                      className="flex align-items-center mt-2"
                    >
                      <img
                        key="card-img"
                        src={icons.visa}
                        alt="card-img"
                        style={{ height: "20px" }}
                      />
                      <div
                        className="flex justify-content-center ml-2"
                        style={{ fontSize: "15px" }}
                      >
                        Platobná Karta
                      </div>
                    </label>
                  </div>

                  <div className="mb-1 mt-2 flex">
                    <input
                      type="checkbox"
                      id="APPLE_PAY"
                      style={{ borderRadius: "10px" }}
                      checked={cardPaymentType === "APPLE_PAY"}
                      onChange={updatePaymentCardType}
                    ></input>
                    <label
                      htmlFor="APPLE_PAY"
                      className="flex align-items-center mt-2"
                    >
                      <img
                        key="applepay-img"
                        src={icons.applepay}
                        alt="applepay-img"
                        style={{ height: "20px" }}
                      />
                    </label>
                  </div>
                </div>
                <div className="mb-1 mt-2 order-detail-title flex">
                  <input
                    type="checkbox"
                    name="Prevodom na účet"
                    id="payment-cash"
                    style={{ borderRadius: "10px" }}
                    checked={choosenPaymentType === "Prevodom na účet"}
                    onChange={updatePaymentMethod}
                  ></input>
                  <label
                    htmlFor="payment-cash"
                    className="flex align-items-center mt-2"
                  >
                    <div
                      className="flex justify-content-center"
                      style={{ fontSize: "15px" }}
                    >
                      Prevodom na účet
                    </div>
                  </label>
                </div>
                {submitBtnClicked && !choosenPaymentType && (
                  <div className="alert mb-2 mt-2" style={{ fontSize: "15px" }}>
                    Prosím vyberte spôsob platby
                  </div>
                )}
              </div>
            </div>
          </form>
          <div className="order-sumup mb-4" style={{ height: "fit-content" }}>
            <div id="order-overview">
              <div className="mb-2" style={{ fontWeight: "bold" }}>
                Zhrnutie objednávky
              </div>
              <div className="order-sumup-content">
                {JSON.parse(localStorage.getItem("shoppingCart")).map(
                  (item, index) => (
                    <div
                      className="mb-3 flex justify-content-between"
                      key={`${item.name}-${index}`}
                    >
                      <div>
                        <div
                          className="flex  mb-1"
                          style={{ fontSize: "15px" }}
                        >
                          <div>
                            {`${truncateText(item.name, 30)} `}-{" "}
                            {`${item.amount} `} ks
                          </div>
                        </div>
                        <div style={{ opacity: "0.5", fontSize: "14px" }}>
                          <div className="mb-1">
                            {item.shape && item.shape !== "undefined" && (
                              <div>Tvar: {item.shape}</div>
                            )}
                          </div>
                          <div className="mb-1">
                            {item.color && item.color !== "undefined" && (
                              <div>Farba: {item.color}</div>
                            )}
                          </div>
                          <div className="mb-1">
                            {item.variant && item.variant !== "undefined" && (
                              <div>Variant: {item.variant}</div>
                            )}
                          </div>
                          <div className="mb-1">
                            {item.size && item.size !== "undefined" && (
                              <div>Veľkosť: {item.size}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex-column">
                        <div
                          className="flex justify-content-end"
                          style={{
                            textDecoration:
                              percentDiscountExists() &&
                              !item.name.startsWith("Poukážka")
                                ? "line-through"
                                : "none",
                            opacity:
                              percentDiscountExists() &&
                              !item.name.startsWith("Poukážka")
                                ? "0.7"
                                : "1",
                            fontSize:
                              percentDiscountExists() &&
                              !item.name.startsWith("Poukážka")
                                ? "15px"
                                : "16px",
                          }}
                        >
                          {(item.price * item.amount)
                            .toFixed(2)
                            .replace(".", ",")}
                          €
                        </div>
                        {percentDiscountExists() &&
                          !item.name.startsWith("Poukážka") && (
                            <div
                              className="flex justify-content-end"
                              style={{ fontSize: "16px" }}
                            >
                              {getPriceAfterDiscount(item.price * item.amount)
                                .toFixed(2)
                                .replace(".", ",")}
                              €
                            </div>
                          )}
                      </div>
                    </div>
                  )
                )}
              </div>

              <div
                className="flex justify-content-between mb-2"
                style={{ fontSize: "15px" }}
              >
                <div>Doprava:</div>
                <div>
                  {choosenDelivery.price
                    ? typeof choosenDelivery.price === "string"
                      ? choosenDelivery.price
                      : choosenDelivery.price.toFixed(2).replace(".", ",")
                    : `od ${getLowestPrice()}`}
                  €
                </div>
              </div>

              <div
                className="mb-2 flex justify-content-between"
                style={{ fontSize: "15px" }}
              >
                <div>
                  Cena
                  {multipleProductsInCard ? " za produkty:" : " produktu:"}
                </div>
                <div>
                  {parseFloat(productPrice).toFixed(2).replace(".", ",")}€
                </div>
              </div>
              <div className="flex justify-content-center mb-1">
                <div style={{ width: "40%" }}>Zľava:</div>
                <div
                  style={{ width: "60%" }}
                  className="flex justify-content-end"
                >
                  {discounts && discounts.length === 0 && "0,00€"}
                </div>
              </div>
              {discounts && discounts.length > 0 && (
                <div className=" mb-2" style={{ fontSize: "14px" }}>
                  {discounts.map((discount) => (
                    <div
                      className="flex justify-content-between"
                      key={discount.code}
                    >
                      <div style={{ width: "60%" }}>{discount.code}</div>
                      <div
                        style={{ width: "40%" }}
                        className="flex justify-content-end "
                      >
                        <div className="mr-2">
                          {" "}
                          -{discount.amount}
                          {discount.type}
                        </div>
                        <div className="flex align-items-center">
                          <i
                            className="pi pi-times pointer"
                            style={{ fontSize: "15px" }}
                            onClick={() => removeDiscount(discount.code)}
                          ></i>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div
                className="mb-3 flex justify-content-between"
                style={{ fontWeight: "bold", fontSize: "16px" }}
              >
                <div>Celkom:</div>{" "}
                <div>
                  {parseFloat(totalPrice).toFixed(2).replace(".", ",")}€
                </div>
              </div>
              <div>
                <div className="signIn home-page">
                  <input
                    type="checkbox"
                    checked={conditionsAgreed}
                    name="conditionsAgreed"
                    id="conditionsAgreed"
                    onChange={() =>
                      setConditionsAgreed((prevState) => !prevState)
                    }
                    style={{
                      border:
                        submitBtnClicked && !conditionsAgreed
                          ? "solid 1px #df4930"
                          : "solid 1px #3a3432",
                    }}
                  />
                  <label
                    style={{ fontSize: "15px" }}
                    htmlFor="conditionsAgreed"
                  >
                    Súhlasím so{" "}
                    <NavLink
                      to="/purchase-conditions"
                      style={{
                        textDecoration: "underline",
                        color: "#3a3432",
                        fontWeight: "bold",
                      }}
                    >
                      <span className="switch-form">
                        všeobecnými obchodnými podmienkami
                      </span>
                    </NavLink>
                  </label>
                </div>

                {submitBtnClicked && !conditionsAgreed && (
                  <div
                    className="mt-1"
                    style={{
                      fontSize: "16px",
                      color: "#df4930",
                    }}
                  >
                    Pre dokončenie objednávky musíte súhlasiť so všeobecnými
                    obchodnými podmienkami
                  </div>
                )}
              </div>
              <button
                className="mt-3 btn dark col-12 p-2 pointer"
                style={{ color: "white", fontSize: "17px" }}
                onClick={makeOrder}
              >
                Objednať
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderDetail;
