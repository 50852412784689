import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import Loading from "../Loading";
import { UserInfoContext } from "../UserInfo";

function ReviewOverview() {
  const [reviewInfo, setReviewInfo] = useState({});
  const navigate = useNavigate();
  const [reviewExists, setReviewExists] = useState("");
  const { loading } = useContext(UserInfoContext);
  const params = useParams();

  const deleteReview = () => {
    setReviewExists("");
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        id: reviewInfo.id,
        token: params.token,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch("/api/reviews/deleteReviewByUser", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setReviewExists(false);
          setReviewInfo(null);
          localStorage.setItem(
            "reviewChanged",
            JSON.stringify({ changed: true })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStorageChange = (event) => {
    if (event.key === "reviewChanged") {
      const reviewChanged = localStorage.getItem("reviewChanged")
        ? JSON.parse(localStorage.getItem("reviewChanged"))
        : null;
      if (reviewChanged) {
        localStorage.removeItem("reviewChanged");

        window.location.reload();
      }
    } else if (event.key === "reviewAddedFromProfile") {
      if (localStorage.getItem("reviewAddedFromProfile")) {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        token: params.token,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch("/api/reviews/getUsersReview", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setReviewInfo(data.reviewInfo);
        }
        setReviewExists(data.alreadyExists);
      })
      .catch((error) => console.log(error));

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  return (
    <div className="flex justify-content-center">
      {reviewExists === "" || loading ? (
        <Loading />
      ) : reviewExists ? (
        <div className="review-overview">
          <div className="flex justify-content-between align-items-center flex-wrap">
            <h3 className="mb-2">Recenzia</h3>
            <div className="flex justify-content-end flex-wrap">
              <div>
                <i
                  className="pi pi-trash pointer p-2"
                  style={{
                    backgroundColor: "white",
                    border: "2px solid #3a3432",
                  }}
                  onClick={deleteReview}
                ></i>
              </div>
              <div>
                <i
                  className="pi pi-pencil ml-2 pointer p-2"
                  style={{
                    backgroundColor: "white",
                    border: "2px solid #3a3432",
                  }}
                  onClick={() =>
                    navigate(`/review-overview/edit-review/${params.token}`)
                  }
                ></i>
              </div>
            </div>
          </div>
          <div>
            {reviewInfo.stars && (
              <ReactStars
                count={5}
                size={27}
                value={parseFloat(reviewInfo.stars)}
                edit={false}
                isHalf={true}
                color="#9e918c"
                activeColor="#3a3432"
                classNames="mb-1"
              />
            )}
          </div>
          <div style={{ fontSize: "15px" }} className="mb-2">
            {reviewInfo.user_name}
          </div>
          <div style={{ fontSize: "15px" }} className="mb-2">
            {reviewInfo.comment}
          </div>
        </div>
      ) : (
        <div style={{ height: "80vh" }} className="flex align-items-center">
          <div className="text-center">
            <div style={{ fontSize: "18px", fontWeight: "bold" }}>
              Recenzia bola odstránená
            </div>
            <div className="mt-2">
              V prípade, ak chcete napísať novú, môžete tak urobiť pomocou
              rovnakého odkazu, ako pri predchádzajúcej recenzii
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReviewOverview;
