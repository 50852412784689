import React, { useState, useEffect, useContext } from "react";
import Dropdown from "../../Dropdown";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import { UserInfoContext } from "../../UserInfo";

function ProductDetailSelection({
  productKind,
  availableVariants,
  multiplePrices,
  productName,
  productImage,
  productId,
  allSizes,
}) {
  const navigate = useNavigate();
  const [allVariants, setAllVariants] = useState(null);
  const [displayAmountAlert, setDisplayAmountAlert] = useState("");
  const [idVariant, setIdVariant] = useState(0);
  const [displayedShapes, setDisplayedShapes] = useState(null);
  const [displayedColors, setDisplayedColors] = useState(null);
  const [displayedSizes, setDisplayedSizes] = useState(null);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [allAttributesSet, setAllAttributesSet] = useState(false);
  const [displayedVariants, setDisplayedVariants] = useState(null);
  const [availableAmount, setAvailableAmount] = useState(-1);
  const [choosenAttributes, setChoosenAttributes] = useState({
    amount: 0,
    shape: "",
    color: "",
    variant: "",
    size: "",
  });
  const [minPrice, setMinPrice] = useState(null);
  const [variantPrice, setVariantPrice] = useState("");
  const [itemAddedVisible, setItemAddedVisible] = useState(false);
  const { updateItemsCount, isLogedIn } = useContext(UserInfoContext);
  const [submitBtnClicked, setSubmitBtnClicked] = useState(false);

  useEffect(() => {
    if (displayedColors && displayedColors.length === 1) {
      setChoosenAttributes((prevState) => ({
        ...prevState,
        color: displayedColors[0],
      }));
    }
  }, [displayedColors]);

  useEffect(() => {
    if (displayedSizes && displayedSizes.length === 1) {
      setChoosenAttributes((prevState) => ({
        ...prevState,
        size: displayedSizes[0],
      }));
    }
  }, [displayedSizes]);

  useEffect(() => {
    if (displayedShapes && displayedShapes.length === 1) {
      setChoosenAttributes((prevState) => ({
        ...prevState,
        shape: displayedShapes[0],
      }));
    }
  }, [displayedShapes]);

  useEffect(() => {
    if (displayedVariants && displayedVariants.length === 1) {
      setChoosenAttributes((prevState) => ({
        ...prevState,
        variant: displayedVariants[0],
      }));
    }
  }, [displayedVariants]);

  useEffect(() => {
    if (!multiplePrices && availableVariants && availableVariants.length > 0) {
      setVariantPrice(availableVariants[0].price);
    }
    let minPrice = availableVariants[0].price;
    availableVariants.map((variant) => {
      if (variant.price < minPrice) {
        minPrice = variant.price;
      }
    });
    setMinPrice(minPrice);
    let existingValues = { amount: 0 };
    let shapes = [];
    availableVariants.map((availableVariant) => {
      if (availableVariant.shape) {
        shapes.push(availableVariant.shape);
      }
    });

    if (shapes.length > 0) {
      const uniqueShapes = new Set(shapes);
      const shapeArray = Array.from(uniqueShapes);
      setDisplayedShapes(shapeArray);
      existingValues.shape = "";
    }

    let colors = [];
    availableVariants.map((availableVariant) => {
      if (availableVariant.color) {
        colors.push(availableVariant.color);
      }
    });

    if (colors.length > 0) {
      const uniqueColors = new Set(colors);
      const colorArray = Array.from(uniqueColors);
      setDisplayedColors(colorArray);
      existingValues.color = "";
    }

    if (allSizes && allSizes.length > 0 && allSizes[0]) {
      const uniqueSizes = new Set(allSizes);
      const arraySizes = Array.from(uniqueSizes);
      setDisplayedSizes(arraySizes);
      setAvailableSizes(arraySizes);
      existingValues.size = "";
    }

    let variants = [];
    availableVariants.map((availableVariant) => {
      if (availableVariant.variant) {
        variants.push(availableVariant.variant);
      }
    });

    if (variants.length > 0) {
      const uniqueVariants = new Set(variants);
      const arrayVariant = Array.from(uniqueVariants);
      setDisplayedVariants(arrayVariant);
      setAllVariants(arrayVariant);
      existingValues.variant = "";
    }
    //set just attributes that exists in product_variants
    setChoosenAttributes(existingValues);
  }, []);

  function findIndexOfMatchingAttributes(otherAttributes) {
    return availableVariants.findIndex((variant) => {
      // Check only chosen attributes with non-null values
      for (const attribute in otherAttributes) {
        if (otherAttributes[attribute] !== null) {
          if (variant[attribute] !== otherAttributes[attribute]) {
            return false; // Stop iterating if any chosen attribute doesn't match
          }
        }
      }
      return true; // Return true if all chosen attributes with non-null values match
    });
  }

  useEffect(() => {
    const { amount, ...otherAttributes } = choosenAttributes;

    const areAllAttributesSet = Object.values(otherAttributes).every(
      (attr) => attr !== ""
    );

    if (areAllAttributesSet) {
      const index = findIndexOfMatchingAttributes(otherAttributes);
      if (index !== -1) {
        setAvailableAmount(availableVariants[index].amount);
        setIdVariant(availableVariants[index].id);
        setVariantPrice(availableVariants[index].price);
      } else {
        setAvailableAmount(-1);
      }
    } else {
      if (multiplePrices) {
        setVariantPrice(null);
      }
    }
    setAllAttributesSet(areAllAttributesSet);
  }, [choosenAttributes]);

  //if something was already choosen and doesnt exists with combination of shape that was selected
  const checkIfChoosenColor = (newColors) => {
    if (
      choosenAttributes.color !== "" &&
      !newColors.includes(choosenAttributes.color)
    ) {
      setChoosenAttributes((prevState) => ({
        ...prevState,
        color: "",
      }));
    }
  };

  const checkIfChoosenVariant = (newVariants) => {
    if (
      choosenAttributes.variant !== "" &&
      !newVariants.includes(choosenAttributes.variant)
    ) {
      setChoosenAttributes((prevState) => ({
        ...prevState,
        variant: "",
      }));
    }
  };

  const checkIfChoosenSize = (newSize) => {
    if (
      choosenAttributes.size !== "" &&
      !newSize.includes(choosenAttributes.size)
    ) {
      setChoosenAttributes((prevState) => ({
        ...prevState,
        size: "",
      }));
    }
  };

  useEffect(() => {
    if (choosenAttributes.shape && choosenAttributes.shape !== "") {
      setDisplayAmountAlert("");
      const newColors = [];
      const newSizes = [];
      const newVariants = [];

      availableVariants.forEach((availableVariant) => {
        if (availableVariant.shape === choosenAttributes.shape) {
          availableVariant.color && newColors.push(availableVariant.color);
          availableVariant.size && newSizes.push(availableVariant.size);
          availableVariant.variant &&
            newVariants.push(availableVariant.variant);
        }
      });

      if (newColors.length > 0) {
        const uniqueColors = new Set(newColors);
        setDisplayedColors(Array.from(uniqueColors));
        if (choosenAttributes.color !== "") {
          checkIfChoosenColor(Array.from(uniqueColors));
        }
      }
      if (newSizes.length > 0) {
        const uniqueSizes = new Set(newSizes);
        setAvailableSizes(Array.from(uniqueSizes));
        if (choosenAttributes.size !== "") {
          checkIfChoosenSize(Array.from(uniqueSizes));
        }
      }

      if (newVariants.length > 0) {
        const uniqueVariants = new Set(newVariants);
        setDisplayedVariants(Array.from(uniqueVariants));
        if (choosenAttributes.variant !== "") {
          checkIfChoosenVariant(Array.from(uniqueVariants));
        }
      }
    }
  }, [choosenAttributes.shape]);

  useEffect(() => {
    if (choosenAttributes.color && choosenAttributes.color !== "") {
      setDisplayAmountAlert("");
      const newSizes = [];
      const newVariants = [];

      availableVariants.forEach((availableVariant) => {
        if (
          availableVariant.color === choosenAttributes.color &&
          (!choosenAttributes.shape ||
            availableVariant.shape === choosenAttributes.shape)
        ) {
          availableVariant.size && newSizes.push(availableVariant.size);
          availableVariant.variant &&
            newVariants.push(availableVariant.variant);
        }
      });

      if (newSizes.length > 0) {
        const uniqueSizes = new Set(newSizes);
        setAvailableSizes(Array.from(uniqueSizes));
        if (choosenAttributes.size !== "") {
          checkIfChoosenSize(Array.from(uniqueSizes));
        }
      }

      if (newVariants.length > 0) {
        const uniqueVariants = new Set(newVariants);
        setDisplayedVariants(Array.from(uniqueVariants));
        if (choosenAttributes.variant !== "") {
          checkIfChoosenVariant(Array.from(uniqueVariants));
        }
      }
    }
  }, [choosenAttributes.color]);

  useEffect(() => {
    if (choosenAttributes.variant && choosenAttributes.variant !== "") {
      setDisplayAmountAlert("");
      if (allSizes && allSizes.length > 0) {
        const newSizes = [];

        availableVariants.forEach((availableVariant) => {
          if (
            availableVariant.variant === choosenAttributes.variant &&
            (!choosenAttributes.color ||
              availableVariant.color === choosenAttributes.color) &&
            (!choosenAttributes.shape ||
              availableVariant.shape === choosenAttributes.shape)
          ) {
            availableVariant && newSizes.push(availableVariant.size);
          }
        });

        if (newSizes.length > 0) {
          const uniqueSizes = new Set(newSizes);
          setAvailableSizes(Array.from(uniqueSizes));
          checkIfChoosenSize(Array.from(uniqueSizes));
        }
      }
    }
  }, [choosenAttributes.variant]);

  const checkIfAlreadyInCart = () => {
    const shoppingCart = JSON.parse(localStorage.getItem("shoppingCart")) || [];

    const foundIndex = shoppingCart.findIndex(
      (product) => product.id_variant === idVariant
    );

    return foundIndex !== -1 ? foundIndex : -1;
  };

  const addToShoppingCart = () => {
    setSubmitBtnClicked(true);
    let changeInCart = false;

    if (
      choosenAttributes.amount > 0 &&
      ((choosenAttributes.color && choosenAttributes.color != "") ||
        !choosenAttributes.color) &&
      ((choosenAttributes.shape && choosenAttributes.shape != "") ||
        !choosenAttributes.shape) &&
      ((choosenAttributes.variant && choosenAttributes.variant != "") ||
        !choosenAttributes.variant) &&
      ((choosenAttributes.size && choosenAttributes.size != "") ||
        !choosenAttributes.size)
    ) {
      if (availableAmount < choosenAttributes.amount) {
        if (availableAmount !== -1) {
          setDisplayAmountAlert("Zadané množstvo nie je dostupné skladom");
        }
      } else {
        setSubmitBtnClicked(false);
        setDisplayAmountAlert("");
        if (!localStorage.getItem("shoppingCart")) {
          const shoppingCart = [];
          localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
        }
        const storedCart = JSON.parse(localStorage.getItem("shoppingCart"));
        const index = checkIfAlreadyInCart();

        if (index !== -1) {
          storedCart[index].amount += parseInt(choosenAttributes.amount);
          if (storedCart[index].amount > availableAmount) {
            setDisplayAmountAlert(
              `Nie je možné pridať do košíka viac ako ${availableAmount}ks`
            );
          } else {
            localStorage.setItem("shoppingCart", JSON.stringify(storedCart));
            changeInCart = true;
            setItemAddedVisible(true);
          }
        } else {
          changeInCart = true;

          const shoppingCartItem = {
            id: productId,
            id_variant: idVariant,
            name: productName,
            size: choosenAttributes.size ? choosenAttributes.size : null,
            shape: choosenAttributes.shape ? choosenAttributes.shape : null,
            color: choosenAttributes.color ? choosenAttributes.color : null,
            variant: choosenAttributes.variant
              ? choosenAttributes.variant
              : null,
            amount: parseInt(choosenAttributes.amount, 10),
            maxAmount: parseInt(availableAmount),
            image: productImage.image,
            price: parseFloat(variantPrice),
          };

          const updatedCart = [...storedCart, shoppingCartItem];
          updateItemsCount(updatedCart.length);
          localStorage.setItem("shoppingCart", JSON.stringify(updatedCart));
          setItemAddedVisible(true);
        }
      }
      if (changeInCart && isLogedIn) {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id_variant: idVariant,
            amount: parseInt(choosenAttributes.amount, 10),
            product_name: `${productName} - ${choosenAttributes.size}, ${choosenAttributes.shape}, ${choosenAttributes.color}, ${choosenAttributes.variant}`,
            price: parseFloat(variantPrice),
          }),
        };

        fetch("/api/products/addToCart", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (!data.success) {
              if (data.message) {
                setDisplayAmountAlert(data.message);
              }
            } else {
              setDisplayAmountAlert("");
            }
          });
      }
    }
  };

  const handleDisplayedOptionSelect = (attribute, value, index) => {
    setChoosenAttributes((prevData) => ({
      ...prevData,
      [attribute]: value,
    }));
  };

  const increaseAmount = () => {
    const value = parseInt(choosenAttributes.amount) + 1;
    setChoosenAttributes((prevData) => ({
      ...prevData,
      ["amount"]: value,
    }));
  };

  useEffect(() => {
    if (parseFloat(choosenAttributes.amount) === 0) {
      setDisplayAmountAlert("");
    }
  }, [choosenAttributes.amount]);

  const decreaseAmount = () => {
    const value =
      choosenAttributes.amount - 1 >= 0
        ? parseInt(choosenAttributes.amount) - 1
        : 0;

    setChoosenAttributes((prevData) => ({
      ...prevData,
      ["amount"]: value,
    }));
  };

  const handleInput = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setChoosenAttributes((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const dialogHeader = () => {
    return (
      <div className="flex align-items-center">
        <i
          style={{ color: "green", fontSize: "40px" }}
          className="pi pi-check-circle"
        ></i>
        <div className="ml-3" style={{ fontSize: "20px" }}>
          Produkt bol pridaný do košíka
        </div>
      </div>
    );
  };

  const dialogContent = () => {
    return (
      <div className="flex">
        <img
          style={{ objectFit: "cover", height: "120px", width: "100px" }}
          src={productImage.image}
          alt="miniature"
        />
        <div>
          <div
            style={{ fontSize: "15px", fontWeight: "bold", marginLeft: "10px" }}
          >
            {productName}
          </div>
          <div
            className="item-added-overview"
            style={{ opacity: "0.7", fontSize: "15px" }}
          >
            {displayedShapes && <p>Tvar: {choosenAttributes.shape}</p>}
            {displayedVariants && <p>Variant: {choosenAttributes.variant}</p>}
            {displayedColors && <p>Farba: {choosenAttributes.color}</p>}
            {displayedSizes && <p>Veľkosť: {choosenAttributes.size}</p>}
            <p>Počet: {choosenAttributes.amount}</p>
          </div>
          <div
            style={{ color: "#3a3432", fontSize: "15px", marginLeft: "10px" }}
          >
            {(parseFloat(choosenAttributes.amount) * variantPrice)
              .toFixed(2)
              .replace(".", ",")}
            €
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        className="dialog-added-to-cart"
        header={dialogHeader}
        visible={itemAddedVisible}
        onHide={() => setItemAddedVisible(false)}
        dismissableMask
        draggable={false}
      >
        {dialogContent()}
        <div className="flex justify-content-between">
          <button
            className="dialog-add-to-cart-btn pointer"
            style={{
              width: "50%",
              color: "#f0e5d4",
              border: "none",
              backgroundColor: "#3a3432",
              fontSize: "15px",
            }}
            onClick={() => setItemAddedVisible(false)}
          >
            Pokračovať v nákupe
          </button>
          <button
            className="dialog-add-to-cart-btn pointer"
            style={{
              backgroundColor: "#f0e5d4",
              border: "2px solid #3a3432",
              color: "#3a3432",
              fontSize: "15px",
            }}
            onClick={() => navigate("/shopping-cart")}
          >
            Prejsť do košíka
          </button>
        </div>
      </Dialog>
      <ul>
        {displayedShapes && (
          <div id="shape">
            <li key="shape" className="product-detail">
              <div className="product-attribute-title">Tvar:</div>
              <Dropdown
                label={
                  choosenAttributes.shape && choosenAttributes.shape !== ""
                    ? choosenAttributes.shape
                    : "Vyberte tvar"
                }
                options={displayedShapes}
                setAttribute={handleInput}
                updateField="shape"
              ></Dropdown>
            </li>
            {submitBtnClicked && choosenAttributes.shape === "" && (
              <div
                className="alert"
                style={{ fontSize: "15px", position: "absolute" }}
              >
                Prosím, zadajte tvar
              </div>
            )}
          </div>
        )}

        {displayedColors && (
          <div>
            <li key="colors" className="product-detail">
              <div className="product-attribute-title mt-5">Farby:</div>
              <Dropdown
                id="color"
                label={
                  choosenAttributes.color && choosenAttributes.color !== ""
                    ? choosenAttributes.color
                    : "Vyberte farbu"
                }
                options={displayedColors}
                setAttribute={handleInput}
                updateField="color"
              ></Dropdown>
            </li>
            {submitBtnClicked && choosenAttributes.color === "" && (
              <div style={{ position: "relative" }}>
                <div
                  className="alert"
                  style={{
                    fontSize: "15px",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                  }}
                >
                  Prosím, zadajte farbu
                </div>
              </div>
            )}
          </div>
        )}
        <div>
          {displayedVariants && (
            <div>
              <li
                key="variant"
                className="product-choose-option mt-5"
                id="variant"
              >
                <div className="product-attribute-title pb-2">Variant:</div>
                <div className="multiple-options align-items-center">
                  {allVariants.map((variant, index) => {
                    return (
                      <div
                        key={`${variant}_${index}`}
                        className="option"
                        onClick={
                          displayedVariants.includes(variant)
                            ? () =>
                                handleDisplayedOptionSelect(
                                  "variant",
                                  variant,
                                  index
                                )
                            : null
                        }
                        style={{
                          borderColor: `${
                            variant === choosenAttributes.variant
                              ? "#3a3432"
                              : "transparent"
                          }`,
                          opacity: displayedVariants.includes(variant)
                            ? "1"
                            : "0.5",
                          cursor:
                            displayedVariants.includes(variant) && "pointer",
                        }}
                      >
                        {variant}
                      </div>
                    );
                  })}
                </div>
              </li>
              {submitBtnClicked && choosenAttributes.variant === "" && (
                <div
                  className="alert"
                  style={{ fontSize: "15px", position: "absolute" }}
                >
                  Prosím, zadajte variant
                </div>
              )}
            </div>
          )}
        </div>
        {displayedSizes && displayedSizes.length > 0 && (
          <div>
            <li key="size" className="product-choose-option mt-5" id="size">
              <div className="product-attribute-title pb-2">Veľkosť:</div>
              <div className="multiple-options align-items-center">
                {displayedSizes.map((size, index) => {
                  return (
                    <div
                      key={`${size}_${index}`}
                      className="option"
                      name="size"
                      value={size}
                      onClick={
                        availableSizes.includes(size)
                          ? () =>
                              handleDisplayedOptionSelect("size", size, index)
                          : null
                      }
                      style={{
                        borderColor: `${
                          size === choosenAttributes.size ? "#3a3432" : ""
                        }`,
                        opacity: availableSizes.includes(size) ? "1" : "0.5",
                        cursor: availableSizes.includes(size) && "pointer",
                      }}
                    >
                      {size}
                    </div>
                  );
                })}
              </div>
            </li>
            {submitBtnClicked && choosenAttributes.size === "" && (
              <div className="alert" style={{ fontSize: "15px" }}>
                Prosím, zadajte veľkosť
              </div>
            )}
          </div>
        )}

        <li key="amount" className="product-choose-option mt-5">
          <div className="product-attribute-title" style={{ width: "20%" }}>
            Počet:
          </div>
          <div
            className="flex flex-wrap align-items-end justify-content-end flex-column"
            style={{ width: "80%" }}
          >
            <div className="flex">
              <button
                onClick={decreaseAmount}
                className="light btn center-btn"
                style={{ borderRadius: "0px" }}
              >
                -
              </button>
              <div className="border-input">
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  value={choosenAttributes.amount}
                  onChange={handleInput}
                  style={{
                    width: "40px",
                    borderRadius: "0px",
                    display: "flex",
                    padding: "0px",
                    textAlign: "center",
                    color: `${
                      choosenAttributes.amount === 0 ? "#6c757d" : "black"
                    }`,
                  }}
                />
              </div>

              <button
                onClick={increaseAmount}
                className="light btn center-btn "
                style={{ borderRadius: "0px" }}
              >
                +
              </button>
            </div>
            {allAttributesSet && productKind !== "digi" && (
              <div style={{ position: "relative", width: "100%" }}>
                <div
                  className="available-amount"
                  style={{
                    fontSize: "15px",
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                  }}
                >
                  Skladom {availableAmount}ks
                </div>
              </div>
            )}
          </div>
        </li>

        {displayAmountAlert != "" && (
          <div
            style={{ fontSize: "15px", color: "#df4930" }}
            className="mt-3 flex justify-content-end"
          >
            {displayAmountAlert}
          </div>
        )}
        {submitBtnClicked && parseFloat(choosenAttributes.amount) === 0 && (
          <div className="alert alert-max-width" style={{ fontSize: "15px" }}>
            Prosím, zadajte množstvo
          </div>
        )}
        <div className="mb-5 mt-5">
          <li
            key="amount"
            className="product-choose-option flex align-items-center justify-content-between"
            style={{
              minHeight: "31px",
            }}
          >
            <div
              className="flex align-items-center"
              style={{
                width: "100px",
                fontWeight: "bold",
              }}
            >
              Cena za kus:
            </div>

            <div className="flex align-items-end ml-3 price-size">
              {variantPrice
                ? `${parseFloat(variantPrice).toFixed(2).replace(".", ",")}€`
                : minPrice && `od ${minPrice.toFixed(2).replace(".", ",")}€`}
            </div>
          </li>
        </div>
      </ul>
      <button
        className="btn round light pointer"
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          fontWeight: "bold",
        }}
        onClick={addToShoppingCart}
      >
        PRIDAŤ DO KOŠÍKA
      </button>
    </div>
  );
}

export default ProductDetailSelection;
