import React, { useContext, useEffect, useState } from "react";
import { UserInfoContext } from "../../UserInfo";

function ProductDetailBasicInfo({ name, id, description }) {
  const [isWished, setIsWished] = useState(false);
  const { addToWishlist, wishList, removeFromWishlist } =
    useContext(UserInfoContext);

  const handleStorageChange = (event) => {
    if (event.key === "wishList" || event.key === "logedIn") {
      if (localStorage.getItem("wishList")) {
        const wishList = JSON.parse(localStorage.getItem("wishList"));
        setIsWished(wishList.includes(id));
      } else {
        setIsWished(false);
      }
    }
  };
  useEffect(() => {
    setIsWished(wishList.includes(id));
  }, [wishList]);
  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    // Clean up the event listener when the component unmounts

    setIsWished(wishList.includes(id));
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  const icons = require("../../../icons").icons;

  const removeFullHearth = () => {
    setIsWished((prevState) => !prevState);
    removeFromWishlist(id);
  };

  const addFullHearth = () => {
    setIsWished((prevState) => !prevState);
    addToWishlist(id);
  };

  return (
    <div>
      <div className="flex justify-content-between align-items-center">
        <div className="productTitle flex align-items-center">
          {name.toUpperCase()}
        </div>
        <div className="flex flex-column justify-content-center align-items-center">
          <img
            className="pointer hearth-wishlist"
            src={isWished ? icons.hearthfilledBig : icons.hearthEmptyBig}
            onClick={isWished ? removeFullHearth : addFullHearth}
          ></img>
        </div>
      </div>

      <div className="mt-3 mb-5 p-4" style={{ backgroundColor: "#F0E5D4" }}>
        {description && (
          <div
            dangerouslySetInnerHTML={{
              __html: description.replace(/\n/g, "<br>"),
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ProductDetailBasicInfo;
