import React, { useState, useRef, useEffect, useContext } from "react";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { UserInfoContext } from "./UserInfo";
import Loading from "./Loading";
function ManageDiscounts() {
  const toast = useRef(null);
  const [submitBtnClicked, setSubmitBtnClicked] = useState(false);
  const [discoutns, setDiscounts] = useState([]);
  const { loading, updateLoadingState } = useContext(UserInfoContext);
  const [newDiscount, setNewDiscount] = useState({
    code: "",
    amount: "",
    type: "%",
    expiration_date: "",
  });

  const handleInput = (event) => {
    const { value, name } = event.target;
    setNewDiscount((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    updateLoadingState(true);
    fetch("/api/orders/getAllDiscounts")
      .then((response) => response.json())
      .then((data) => {
        setDiscounts(data.discounts);
        updateLoadingState(false);
      });
  }, []);

  const handleTypeInput = (type) => {
    setNewDiscount((prevState) => ({
      ...prevState,
      type: type,
    }));
  };

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Chyba pri spracovaní zľavy",
      detail: message,
      life: 5000,
    });
  };

  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      detail: message,
      life: 3000,
    });
  };

  const addNewDiscount = (event) => {
    event.preventDefault();
    setSubmitBtnClicked(true);
    if (
      newDiscount.code === "" ||
      newDiscount.amount === "" ||
      newDiscount.type === "" ||
      newDiscount.expiration_date == ""
    ) {
      showError("Vyplňte prosím všetky polia");
    } else {
      let mm = newDiscount.expiration_date.getMonth() + 1;
      let dd = newDiscount.expiration_date.getDate();
      mm = mm.toString().length === 1 ? `0${mm}` : mm;
      dd = dd.toString().length === 1 ? `0${dd}` : dd;
      const yyyy = newDiscount.expiration_date.getFullYear();
      const formattedDateDB = `${yyyy}-${mm}-${dd}`;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: newDiscount.code,
          amount: newDiscount.amount,
          type: newDiscount.type,
          expiration_date: formattedDateDB,
        }),
      };
      fetch("/api/orders/addNewDiscount", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            showSuccess("Zľava bola úspešne pridaná");
            setSubmitBtnClicked(false);
            setDiscounts((prevState) => [...prevState, data.newDiscount]);
            setNewDiscount({
              code: "",
              amount: "",
              type: "%",
              expiration_date: "",
            });
          } else {
            showError(data.message);
          }
        });
    }
  };

  const deleteDiscount = (event) => {
    const id = event.target.id;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        discountId: id,
      }),
    };
    fetch("/api/orders/deleteDiscount", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setDiscounts((prevState) => {
            const updatedDiscounts = prevState.filter(
              (discount) => discount.id !== parseInt(id)
            );
            return updatedDiscounts;
          });
          showSuccess("Zľava bola úspešne odstránená");
        } else {
          showError(
            "Pri odstránení zľavy došlo ku chybe, skúste to znova neskôr."
          );
        }
      });
  };

  return (
    <div
      className="flex justify-content-center mt-5 discount-manager"
      style={{ minHeight: "80vh" }}
    >
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Toast ref={toast} />
          <form
            style={{
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
              width: "90vw",
            }}
            onSubmit={addNewDiscount}
          >
            <div
              className="p-4"
              style={{
                color: "#3a3432",
                backgroundColor: "#f0e5d4",
                fontWeight: "bold",
              }}
            >
              Pridať zľavu
            </div>
            <div className="p-4">
              <div className="flex align-items-center">
                <div className="mr-2">Typ poukážky:</div>
                <div
                  className="order-details mb-2"
                  style={{
                    borderBottom:
                      submitBtnClicked && !newDiscount.type
                        ? "2px solid #df4930"
                        : "",
                  }}
                >
                  <input
                    type="checkbox"
                    name="type"
                    id="type"
                    style={{ borderRadius: "10px" }}
                    checked={newDiscount.type === "%"}
                    onChange={() => handleTypeInput("%")}
                  ></input>
                  <label htmlFor="type" className="mr-2">
                    %
                  </label>
                  <input
                    type="checkbox"
                    name="type"
                    style={{ borderRadius: "10px" }}
                    id="type"
                    checked={newDiscount.type === "€"}
                    onChange={() => handleTypeInput("€")}
                  ></input>
                  <label htmlFor="type">€</label>
                </div>
              </div>
              <div className="flex align-items-center">
                <label htmlFor="code" className="mr-2">
                  Kód kupónu:
                </label>
                <input
                  className="mb-2"
                  style={{
                    borderBottom:
                      submitBtnClicked && !newDiscount.code
                        ? "2px solid #df4930"
                        : "",
                  }}
                  type="text"
                  onChange={handleInput}
                  value={newDiscount.code}
                  name="code"
                  id="code"
                />
              </div>
              <div className="flex align-items-center">
                <label htmlFor="code" className="mr-2">
                  Hodnota:
                </label>
                <input
                  type="number"
                  style={{
                    borderBottom:
                      submitBtnClicked && !newDiscount.amount
                        ? "2px solid #df4930"
                        : "",
                    width: "50px",
                  }}
                  onChange={handleInput}
                  value={newDiscount.amount}
                  name="amount"
                  id="amount"
                />
              </div>

              <div className="flex align-items-end" style={{ width: "100%" }}>
                <div className="mr-2">Využiť do:</div>
                <Calendar
                  value={newDiscount.expiration_date}
                  onChange={(e) =>
                    setNewDiscount((prevState) => ({
                      ...prevState,
                      expiration_date: e.target.value,
                    }))
                  }
                  showIcon
                />
              </div>
            </div>
            <button
              className="btn light p-2"
              style={{ fontWeight: "bold", borderRadius: "0px" }}
            >
              Vytvoriť
            </button>
          </form>
          {discoutns && discoutns.length > 0 && (
            <div
              className="mt-5 p-4 discount-font"
              style={{
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                width: "90vw",
              }}
            >
              <div
                className="flex pb-1"
                style={{ borderBottom: "1px solid gray" }}
              >
                <div style={{ width: "65%" }}></div>
                <div style={{ width: "35%" }}>Využiť do</div>
              </div>
              {discoutns.map((discount, index) => {
                return (
                  <div
                    className="flex justify-content-between mb-2 pb-1 pt-1"
                    key={discount.id}
                    style={{
                      borderBottom:
                        index + 1 !== discount.length && "1px solid gray",
                    }}
                  >
                    <div
                      style={{
                        width: "65%",
                      }}
                    >
                      {discount.code} - {discount.amount}
                      {discount.type}
                    </div>
                    <div style={{ width: "35%" }}>
                      <div className="flex justify-content-betweem align-items-center">
                        <div
                          className="flex justify-content-start align-items-center"
                          style={{ width: "80%" }}
                        >
                          {discount.expiration_date}
                        </div>
                        <div
                          className="flex justify-content-end align-items-center"
                          style={{ width: "20%" }}
                        >
                          <i
                            className="pi pi-trash pointer"
                            id={discount.id}
                            onClick={deleteDiscount}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ManageDiscounts;
