import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./style.css";

// Import PrimeReact styles in the correct order:
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

/* Import PrimeReact styles */

import { UserInfoProvider } from "./Components/UserInfo";
import HomePage from "./Components/HomePage";
import NotFound from "./Components/NotFound";
import ProfileMenu from "./Components/Profile/ProfileMenu";
import UserInfo from "./Components/Profile/UserInfo";
import UserOrders from "./Components/Profile/UserOrders";
import UserReviews from "./Components/Profile/UserReviews";
import Products from "./Components/Products/Products";
import ProductDetail from "./Components/Products/ProductDetail";
import AddNewProductForm from "./Components/Products/AddNewProduct/AddNewProductForm";
import ResetPasswordForm from "./Components/Auth/ResetPasswordForm";
import NewsletterConfirmation from "./Components/NewsletterConfirmation";
import ReviewOverview from "./Components/Reviews/ReviewOverview";
import ShoppingCart from "./Components/ShoppingCart/ShoppingCart";
import OrderDetail from "./Components/ShoppingCart/OrderDetail";
import Favourites from "./Components/Favourites";
import Navbar from "./Components/NavigateOptions/Navbar";
import EditReview from "./Components/Reviews/EditReview";
import AddNewReview from "./Components/Reviews/AddNewReview";
import OrderPaidSuccess from "./Components/ShoppingCart/OrderPaidSuccess";
import OrdersOverview from "./Components/ShoppingCart/OrdersOverview";
import ManageDiscounts from "./Components/ManageDiscounts";
import GiftCartCreator from "./Components/Products/GiftCartCreator";
import UpdateProductSpecification from "./Components/Products/AddNewProduct/UpdateProductSpecification";
import Layout from "./Components/Layout";
import OrderPaidCanceled from "./Components/ShoppingCart/OrderPaidCanceled";
import NewsletterSubscribers from "./Components/NewsletterSubscribers";
import ProductsKind from "./Components/Products/ProductsKind";
import AllProducts from "./Components/Products/AllProducts";
import SearchProducts from "./Components/Products/SearchProducts";
import GopayRedirect from "./Components/ShoppingCart/GopayRedirect";
import PurchaseConditions from "./Components/PurchaseConditions";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import AddNewDigiProduct from "./Components/Products/AddNewProduct/AddNewDigiProduct";
import Footer from "./Components/Footer";

const isUserAdmin = async () => {
  const response = await fetch("/api/auth/isUserAdmin");
  if (!response.ok) {
    throw new Error("HTTP error " + response.status);
  }

  const data = await response.json();
  return data;
};

const checkCancelPaymentToken = async (token) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token: token,
    }),
  };

  const response = await fetch(
    "/api/orders/checkCancelPaymentToken",
    requestOptions
  );
  if (!response.ok) {
    throw new Error("HTTP error " + response.status);
  }

  const data = await response.json();
  return data;
};

const getReviewDetails = async (params, creatingNew) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: params.token,
      }),
    };
    const response = await fetch(
      "/api/reviews/getInformationAboutReview",
      requestOptions
    );
    if (!response.ok) {
      throw new Error("HTTP error " + response.status);
    }

    const data = await response.json();

    if (data.success) {
      return {
        review: {
          name: data.reviewInfo.name,
          comment: data.reviewInfo.comment,
          stars: data.reviewInfo.stars,
          orderId: creatingNew ? data.reviewInfo.orderId : null, //if i am creating new review not from profile i dont care about order id because i dont use it in redirect
          reviewId: data.reviewInfo.reviewId,
        },
      };
    }
  } catch (error) {
    console.error("Error loading user orders:", error);
    throw error;
  }
};

const getPermissionToChangePassword = async (params) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      token: params.token,
      email: decodeURIComponent(params.email),
    }),
  };

  const response = await fetch(
    "/api/auth/getPermissionToChangePassword",
    requestOptions
  );
  if (!response.ok) {
    throw new Error("HTTP error " + response.status);
  }
  const data = await response.json();
  return data;
};

const getUserOrders = async (params) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      orderId: params.orderId,
    }),
  };

  try {
    const response = await fetch("/api/user/getUserOrders", requestOptions);
    if (!response.ok) {
      throw new Error("HTTP error " + response.status);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error loading user orders:", error);
    throw error;
  }
};

const getProductDetail = async (productId) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      productId: productId,
    }),
  };

  const response = await fetch("/api/products/getDetail", requestOptions);
  if (!response.ok) {
    throw new Error("HTTP error " + response.status);
  }
  const data = await response.json();
  return data;
};

const router = createBrowserRouter([
  {
    path: "/",

    element: (
      <Layout>
        <Navbar />
      </Layout>
    ),
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },

      {
        path: "/create-giftcart",
        element: <GiftCartCreator />,
      },
      {
        path: "/newsletter-subscribers",
        element: <NewsletterSubscribers />,
        loader: async () => {
          const data = await isUserAdmin();

          if (data.success) {
            return null;
          }
        },
      },
      {
        path: "/products-all",
        element: <AllProducts />,
      },

      {
        path: "/products/:productKind",
        element: <ProductsKind />,
        loader: async ({ params }) => {
          const correctKinds = [
            "tampons",
            "cosmetic-bags",
            "limited-offers",
            "bathroom-accessories",
            "digi",
          ];
          if (correctKinds.includes(params.productKind)) {
            return null;
          }
        },
      },
      {
        path: "/search/:productName",
        element: <SearchProducts />,
      },
      {
        path: "/product/detail/:productId",
        element: <ProductDetail />,
        loader: async ({ params }) => {
          if (params.productId !== "null") {
            return null;
          }
        },
      },
      {
        path: "/add-new-digi-product",
        element: <AddNewDigiProduct />,
        loader: async () => {
          const data = await isUserAdmin();

          if (data.success) {
            return null;
          }
        },
      },
      {
        path: "/add-new-product/:productKind",
        element: <AddNewProductForm />,
        loader: async ({ params }) => {
          const correctKinds = [
            "tampons",
            "cosmetic-bags",
            "limited-offers",
            "bathroom-accessories",
            "digi",
          ];

          const data = await isUserAdmin();

          if (data.success && correctKinds.includes(params.productKind)) {
            return null;
          }
        },
      },
      {
        path: "/update-digi-product/:productId",
        element: <AddNewDigiProduct update={true} />,
        loader: async ({ params }) => {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              productId: params.productId,
            }),
          };
          const response = await fetch(
            "/api/products/getDigiDetail",
            requestOptions
          );
          if (!response.ok) {
            throw new Error("HTTP error " + response.status);
          }

          const data = await response.json();
          if (data.success) {
            return {
              product: data.product,
            };
          } else {
            throw new Error("Not successful data");
          }
        },
      },
      {
        path: "/update-product/:productId",
        element: <UpdateProductSpecification />,
        loader: async ({ params }) => {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              productId: params.productId,
            }),
          };
          const response = await fetch(
            "/api/products/getProductSpecification",
            requestOptions
          );
          if (!response.ok) {
            throw new Error("HTTP error " + response.status);
          }

          const data = await response.json();
          if (data.success) {
            return {
              product: data.product,
              variants: data.variants,
            };
          }
        },
      },
      {
        path: "/reset-password/:email/:token",
        element: <ResetPasswordForm />,
        loader: async ({ params }) => {
          try {
            const data = await getPermissionToChangePassword(params);
            if (data.success) {
              if (data.message) {
                return {
                  message: data.message,
                };
              }
              return null;
            }
          } catch (error) {
            console.error("Error loading user orders:", error);
            throw error;
          }
        },
      },
      {
        path: "/newsletter-confirmation/:token",
        element: <NewsletterConfirmation />,
      },
      {
        path: "/review-overview/edit-review/:token",
        element: <EditReview />,
        loader: async ({ params }) => {
          const data = await getReviewDetails(params, false);
          return {
            review: data.review,
            redirectUrl: `/review-overview/${params.token}`,
          };
        },
      },
      {
        path: "/add-review/:token",
        element: <AddNewReview />,
        loader: async ({ params }) => {
          const data = await getReviewDetails(params, false);
          return data;
        },
      },
      {
        path: "/review-overview/:token",
        element: <ReviewOverview />,

        loader: async ({ params }) => {
          const data = await getReviewDetails(params, false);
          return data;
        },
      },

      {
        path: "/shopping-cart",
        element: <ShoppingCart />,
      },

      {
        path: "/payment-success/:token",
        element: <OrderPaidSuccess />,
        loader: async ({ params }) => {
          try {
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: params.token,
              }),
            };

            const response = await fetch(
              "/api/orders/checkSuccessPaymentToken",
              requestOptions
            );
            if (!response.ok) {
              throw new Error("HTTP error " + response.status);
            }

            const data = await response.json();
            if (data.success) {
              return {
                email: data.email,
              };
            }
          } catch (error) {
            console.error("Error loading user orders:", error);
            throw error;
          }
        },
      },
      {
        path: "/order-detail",
        element: <OrderDetail />,
        loader: async () => {
          if (
            localStorage.getItem("shoppingCart") &&
            JSON.parse(localStorage.getItem("shoppingCart")).length > 0
          ) {
            return {
              shoppingCarExists: true,
            };
          } else {
            return {
              shoppingCarExists: false,
            };
          }
        },
      },

      {
        path: "/payment-redirect/:token",
        element: <GopayRedirect />,
        loader: async ({ params }) => {
          try {
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: params.token,
              }),
            };
            //check if token for redirecting after payment order is still good
            const response = await fetch(
              "/api/orders/checkPaymentCreatedToken",
              requestOptions
            );
            if (!response.ok) {
              throw new Error("HTTP error " + response.status);
            }

            const data = await response.json();
            if (data.success) {
              return null;
            }
          } catch (error) {
            console.error("Error loading user orders:", error);
            throw error;
          }
        },
      },

      {
        path: "/payment-error/:token",
        element: <OrderPaidCanceled />,
        loader: async ({ params }) => {
          try {
            const data = await checkCancelPaymentToken(params.token);
            if (data.success) {
              return null;
            }
          } catch (error) {
            console.error("Error loading user orders:", error);
            throw error;
          }
        },
      },

      {
        path: "/paywall-redirect-error/:token",
        element: <OrderPaidCanceled />,
        loader: async ({ params }) => {
          try {
            const data = await checkCancelPaymentToken(params.token);
            if (data.success) {
              return null;
            }
          } catch (error) {
            console.error("Error loading user orders:", error);
            throw error;
          }
        },
      },
      {
        path: "/favourites",
        element: <Favourites />,
      },
      {
        path: "/manage-discounts",
        element: <ManageDiscounts />,
        loader: async () => {
          const data = await isUserAdmin();

          if (data.success) {
            return null;
          }
        },
      },
      {
        path: "/profile",
        element: <ProfileMenu />,
        loader: async ({ params }) => {
          if (localStorage.getItem("logedIn")) {
            const logedIn = localStorage.getItem("logedIn");
            const logedInExpiration = new Date(logedIn);
            const currentDate = new Date();
            if (logedInExpiration > currentDate) {
              return null;
            } else {
              localStorage.removeItem("logedIn");
            }
          }
        },
        children: [
          {
            path: "/profile",
            loader: () => {
              throw new Error("Invalid profil path");
            },
          },
          {
            path: "/profile/user-details",
            element: <UserInfo />,
          },
          {
            path: "/profile/user-orders/:orderId",
            element: <UserOrders />,
            loader: async ({ params }) => {
              try {
                const data = await getUserOrders(params);
                const ids = data.orders.map((order) => order.orderId);
                if (!data.orders || !ids.includes(parseFloat(params.orderId))) {
                  throw new Error("Not found", { status: 404 });
                } else {
                  return {
                    props: {
                      orders: data.orders,
                    },
                  };
                }
              } catch (error) {
                console.error("Error loading user orders:", error);
                throw error;
              }
            },
          },
          {
            path: "/profile/user-orders",
            element: <UserOrders />,
            loader: async ({ params }) => {
              const data = await getUserOrders(params);

              return {
                props: {
                  orders: data.orders,
                },
              };
            },
          },
          {
            path: "/profile/user-reviews",
            element: <UserReviews />,
          },
          {
            path: "/profile/add-review/:token",
            element: <AddNewReview />,
            loader: async ({ params }) => {
              const data = await getReviewDetails(params, true);
              return data;
            },
          },
          {
            path: "/profile/edit-review/:reviewId",
            element: <EditReview />,
            loader: async ({ params }) => {
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  reviewId: params.reviewId,
                }),
              };
              const response = await fetch(
                "/api/reviews/updateReviewFromProfile",
                requestOptions
              );
              if (!response.ok) {
                throw new Error("HTTP error " + response.status);
              }

              const data = await response.json();

              if (data.success) {
                return {
                  review: data.reviewInfo,
                  redirectUrl: "/profile/user-reviews",
                };
              }
            },
          },
          {
            path: "/profile/reset-password/:email/:token",
            element: <ResetPasswordForm />,
            loader: async ({ params }) => {
              const data = await getPermissionToChangePassword(params);
              try {
                if (data.success) {
                  if (data.message) {
                    return {
                      message: data.message,
                    };
                  }
                  return null;
                }
              } catch (error) {
                console.error("Error loading user orders:", error);
                throw error;
              }
            },
          },
        ],
      },
      {
        path: "/delivery-overview",
        element: <OrdersOverview />,
        loader: async () => {
          const data = await isUserAdmin();
          if (data.success) {
            return null;
          }
        },
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/purchase-conditions",
        element: <PurchaseConditions />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserInfoProvider>
      <RouterProvider router={router} />
    </UserInfoProvider>
  </React.StrictMode>
);
