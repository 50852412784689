import React from "react";

function Loading() {
  return (
    <div>
      <div
        className="flex justify-content-center align-items-center  card"
        style={{ height: "calc(90vh - 80px)" }}
      >
        <div className="custom-spinner"></div>
      </div>
    </div>
  );
}

export default Loading;
