import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
function ProductDetailClickInformation({
  allSizes,
  material,
  weight,
  type,
  maintenance,
  certificate,
}) {
  const [sizeTableClicked, setSizeTableClicked] = useState(false);
  const [careClicked, setCareClicked] = useState(false);
  const [certificateClicked, setCertificateClicked] = useState(false);
  const [detailClicked, setDetailClicked] = useState(false);
  const [uniqueSizes, setUniqueSizes] = useState([]);

  useEffect(() => {
    if (allSizes && allSizes.length > 0 && allSizes[0]) {
      const uniqueNamesArray = allSizes.reduce((acc, current) => {
        // Check if the name already exists in the accumulator
        const x = acc.find((item) => item.name === current.name);
        if (!x) {
          // If name does not exist, add the current object to the accumulator
          acc.push(current);
        }
        return acc;
      }, []);
      setUniqueSizes(uniqueNamesArray);
    }
  }, [allSizes]);
  const toggleSizeTable = () => {
    setSizeTableClicked((prevData) => !prevData);
  };

  const toggleCareInfo = () => {
    setCareClicked((prevData) => !prevData);
  };

  const toggleCertificatesInfo = () => {
    setCertificateClicked((prevData) => !prevData);
  };

  const toggleDetailInfo = () => {
    setDetailClicked((prevData) => !prevData);
  };

  const sizeTable = () => {
    return (
      <DataTable value={uniqueSizes} stripedRows>
        <Column field="name" header="Veľkosť"></Column>
        <Column field="radius" header="Rozmer"></Column>
      </DataTable>
    );
  };

  const detailInfo = () => {
    return (
      <div style={{ fontSize: "15px" }}>
        <ul>
          <li key="material">
            <div className="product-attribute-title">Materiál:</div>
            <div>{material && material}</div>
          </li>
          <li key="weight">
            <div className="product-attribute-title">Gramáž:</div>
            <div>{weight && weight}</div>
          </li>
          <li key="type">
            <div className="product-attribute-title">Typ:</div>
            <div>{type && type}</div>
          </li>
          <li key="sewingType">
            <div className="product-attribute-title">Typ šitia:</div>
            <div>Strojové a ručné</div>
          </li>
        </ul>
      </div>
    );
  };
  return (
    <div>
      {allSizes &&
        allSizes.length > 0 &&
        allSizes[0] &&
        uniqueSizes.length > 0 && (
          <div>
            <button
              className="specification-btn pointer mt-5"
              onClick={toggleSizeTable}
            >
              <div>Tabuľka veľkostí</div>
              <div className="specification-btn-plus">
                {sizeTableClicked ? "-" : "+"}
              </div>
            </button>
            <div
              className={`table-container ${sizeTableClicked ? "open" : ""}`}
            >
              {sizeTable()}
            </div>
          </div>
        )}
      {maintenance && (
        <div>
          <button
            className="specification-btn mt-3 pointer"
            onClick={toggleCareInfo}
          >
            <div>Starostlivosť</div>
            <div className="specification-btn-plus">
              {careClicked ? "-" : "+"}
            </div>
          </button>
          <div className={`table-container ${careClicked ? "open" : ""}`}>
            <img
              style={{ width: "200px" }}
              src={maintenance}
              alt="maintenance"
            />
          </div>
        </div>
      )}
      {certificate && (
        <div>
          <button
            className="specification-btn mt-3 pointer"
            onClick={toggleCertificatesInfo}
          >
            <div>Certifikáty</div>
            <div className="specification-btn-plus">
              {certificateClicked ? "-" : "+"}
            </div>
          </button>
          <div
            className={`table-container ${certificateClicked ? "open" : ""}`}
          >
            <img
              style={{ width: "200px" }}
              src={certificate}
              alt="certificate"
            />
          </div>
        </div>
      )}

      {material && weight && (
        <div>
          <button
            className="specification-btn mt-3 pointer"
            onClick={toggleDetailInfo}
          >
            <div>Detail</div>
            <div className="specification-btn-plus">
              {detailClicked ? "-" : "+"}
            </div>
          </button>
          <div className={`table-container ${detailClicked ? "open" : ""}`}>
            {detailInfo()}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductDetailClickInformation;
