import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { UserInfoContext } from "../../UserInfo";
import Loading from "../../Loading";
import NewProductSpecification from "./NewProductSpecification";

function AddNewProductForm() {
  const params = useParams();
  const [redirect, setRedirect] = useState(true);
  const navigate = useNavigate();

  const [submitBtnClicked, setSubmitBtnClicked] = useState(false);
  const [newProductData, setNewProductData] = useState({
    images: [],
    coverImage: null,
    hoverImage: null,
  });
  const [warningMessage, setWarningMessage] = useState("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const { loading, isLogedIn, updateLoadingState } =
    useContext(UserInfoContext);

  const updateSubmitBtnState = (state) => {
    setSubmitBtnClicked(state);
  };

  useEffect(() => {
    if (params.productKind === "digi") {
      navigate("/add-new-digi-product");
    } else {
      setRedirect(false);
    }
  }, []);

  useEffect(() => {
    if (!isLogedIn && !localStorage.getItem("logedIn")) {
      updateLoadingState(true);

      navigate(`/products/${params.productKind}`);
    }
  }, [isLogedIn]);

  const handleImageInput = (e) => {
    const { name, type, files } = e.target;
    if (type === "file") {
      const selectedImages = Array.from(files);
      if (name === "coverImage" || name === "hoverImage") {
        setNewProductData((prevData) => ({
          ...prevData,
          [name]: selectedImages[0],
        }));
      } else {
        setNewProductData((prevData) => ({
          ...prevData,
          images: Array.isArray(prevData.images)
            ? [...prevData.images, ...selectedImages]
            : [...selectedImages],
        }));
      }
    }
  };

  const addProductToServer = (formData) => {
    if (!newProductData.coverImage && !newProductData.hoverImage) {
      setDisplayDialog(true);
      setWarningMessage("Prosím zadajte fotky");
    } else {
      formData.append("images", newProductData.coverImage);
      formData.append("images", newProductData.hoverImage);
      newProductData.images.forEach((file) => {
        formData.append("images", file);
      });
      const requestOptions = {
        method: "POST",
        body: formData,
      };
      fetch("/api/products/addProduct", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            setDisplayDialog(true);
            setWarningMessage(data.message);
          } else {
            navigate(`/products/${params.productKind}`);
          }
        });
    }
  };

  return (
    <div>
      {loading || redirect ? (
        <Loading />
      ) : (
        <div>
          <Dialog
            visible={displayDialog}
            header={<div style={{ color: "#df4930" }}>Error</div>}
            className="dialog-added-to-cart"
            onHide={() => setDisplayDialog(false)}
            dismissableMask
          >
            {warningMessage}
          </Dialog>
          <div className="add-product-form">
            <div className="add-product-card" style={{ maxWidth: "600px" }}>
              <div className="input-row">
                <div>Hlavná fotka: </div>
                <label className="custom-file-input">
                  <input
                    type="file"
                    name="coverImage"
                    onChange={handleImageInput}
                    accept="image/*"
                    style={{ marginLeft: "10px" }}
                  />
                </label>
              </div>
              {submitBtnClicked && !newProductData.coverImage && (
                <div className="mt-2 alert">Zadajte prosím fotku</div>
              )}
              <div className="input-row mt-3">
                <div>Prekryv fotka: </div>
                <label className="custom-file-input  ">
                  <input
                    type="file"
                    name="hoverImage"
                    onChange={handleImageInput}
                    accept="image/*"
                    style={{ marginLeft: "10px" }}
                  />
                </label>
              </div>
              {submitBtnClicked && !newProductData.hoverImage && (
                <div className="mt-2 alert">Zadajte prosím fotku</div>
              )}
              <div className="input-row mt-3">
                <div>Ostatné fotky: </div>
                <label className="custom-file-input  ">
                  <input
                    type="file"
                    name="image"
                    onChange={handleImageInput}
                    accept="image/*"
                    multiple
                    style={{ marginLeft: "10px" }}
                  />
                </label>
              </div>
              <NewProductSpecification
                serverRequest={addProductToServer}
                submitBtnClicked={submitBtnClicked}
                updateSubmitBtnState={updateSubmitBtnState}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddNewProductForm;
