import React, { useState, useContext, useRef, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import { UserInfoContext } from "../UserInfo";

function GmailExistsForm(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [displayLoading, setDisplayLoading] = useState(false);
  const { wishList, setLogedInUser, updateLoadingState } =
    useContext(UserInfoContext);
  const [formData, setFormData] = useState({
    formEmail: props.formEmail,
    formPassword: "",
  });

  const handleLogin = (event) => {
    event.preventDefault();
    if (formData.formPassword === "") {
      setDisplayLoading(false);
      setAlertText("Zadajte prosím heslo");
    } else if (formData.formPassword.length < 8) {
      setDisplayLoading(false);
      setAlertText("Heslo musí obsahovať aspoň 8 znakov");
    } else {
      let cartItems = [];
      if (localStorage.getItem("shoppingCart")) {
        cartItems = JSON.parse(localStorage.getItem("shoppingCart"));
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          formData,
          wishlist: wishList,
          shoppingCart: JSON.parse(localStorage.getItem("shoppingCart")),
        }),
      };

      fetch("/api/auth/login", requestOptions)
        .then((response) => response.json())
        .then(async (data) => {
          setDisplayLoading(false);

          if (data.success) {
            updateLoadingState(true);
            if (props.closeForms) {
              props.closeForms();
            }

            await setLogedInUser();
          } else {
            if (data.passwordMsg) {
              setAlertText(data.passwordMsg);
            } else if (data.message) {
              setAlertText(data.message);
            } else {
              setAlertText("");
            }
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevLoginInfo) => ({
      ...prevLoginInfo,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = (event) => {
    event.stopPropagation();
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div
      className={props.closeForms && "overlay"}
      style={{
        boxShadow: !props.closeForms && "0 2px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div
        className="form registered-differently"
        style={{ height: props.closeForms && "100vh" }}
      >
        <div className="form-wrapper p-5">
          {props.closeForms && (
            <i
              className="pi pi-times closing-tab"
              onClick={props.closeForms}
            ></i>
          )}
          <form onSubmit={handleLogin} className="flex flex-column">
            <div style={{ marginBottom: "10px", marginTop: "20px" }}>
              K emailu{" "}
              <span style={{ fontWeight: "bold" }}>{formData.formEmail}</span>{" "}
              je už vytvorený účet. Zadajte prosím heslo k tomuto účtu.
            </div>

            {alertText === "Nesprávne zadané prihlasovacie údaje" && (
              <div
                className="alert flex justify-content-start align-items-start mb-2 mt-1"
                style={{ fontSize: "16px" }}
              >
                {alertText}
              </div>
            )}
            <div className="password-container">
              <input
                className="inputText"
                type={showPassword ? "text" : "password"}
                placeholder="Heslo"
                id="formPassword"
                name="formPassword"
                value={formData.formPassword}
                style={{
                  width: "100%",
                  borderBottom: alertText !== "" ? "solid 2px #df4930" : "",
                }}
                onChange={handleChange}
              />

              <button
                type="button"
                className="password-toggle"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>

            {alertText !== "" &&
              alertText !== "Nesprávne zadané prihlasovacie údaje" && (
                <div
                  className="alert flex justify-content-start align-items-start"
                  style={{ fontSize: "15px" }}
                >
                  {alertText}
                </div>
              )}

            <div className="flex justify-content-center">
              <button
                className="submit btn dark mt-2"
                onClick={() => setDisplayLoading((prevState) => !prevState)}
              >
                {displayLoading && (
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "20px", marginRight: "10px" }}
                  ></i>
                )}
                Prihlásiť sa
              </button>
            </div>
          </form>
          <div>
            <p className="switch-form" onClick={props.toggleForgotPassForm}>
              Zabudli ste heslo?
            </p>
          </div>
          <div>
            Nie je to váš účet?
            <p className="switch-form" onClick={props.disablePasswordForm}>
              Prihlásiť sa
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GmailExistsForm;
