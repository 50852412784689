import React, { useState, useEffect, useContext } from "react";
import ProductOverview from "./ProductOverview";
import { UserInfoContext } from "../UserInfo";
import { Dialog } from "primereact/dialog";

function ProductsGrid({
  maxProductWidth,
  productsToDisplay,
  updateWidthOfProductOrder,
  removeFromWishlist,
  updateProductsAfterDelete,
}) {
  const [cardWidth, setCardWidth] = useState(null);
  const [numItemsPerRow, setNumItemsPerRow] = useState(null);
  const [maxProductDisplayedWidth, setMaxProductDisplayedWidth] =
    useState(null);
  const { wishList, isLogedIn, isAdmin } = useContext(UserInfoContext);
  const [deleteProductMsg, setDeleteProductMsg] = useState("");
  const [prevWindowWidth, setPrevWindowWidth] = useState(maxProductWidth);

  function calculateItemWidth(containerWidthToUse) {
    let desiredItemsPerRow =
      containerWidthToUse > 1115 ? 4 : containerWidthToUse > 600 ? 3 : 2;
    let itemsPerRow = desiredItemsPerRow;
    const minAvailableSpaceNeeded =
      containerWidthToUse - (itemsPerRow - 1) * 10;
    let minWidth =
      window.innerWidth <= 905
        ? window.innerWidth < 720
          ? 180
          : window.innerWidth < 450
          ? 160
          : 220
        : 240;

    if (minAvailableSpaceNeeded < minWidth * itemsPerRow) {
      const remainingSpace = minWidth * itemsPerRow - minAvailableSpaceNeeded;
      const widthToReduce = remainingSpace / itemsPerRow;
      minWidth -= widthToReduce;
    }
    const neededSpace = minWidth * itemsPerRow + 10 * (itemsPerRow - 1);

    setNumItemsPerRow(itemsPerRow);

    setMaxProductDisplayedWidth(neededSpace);

    if (updateWidthOfProductOrder) {
      updateWidthOfProductOrder(neededSpace);
    }
    return minWidth;
  }

  useEffect(() => {
    let reduce = 100;
    let minDifference = 50;
    if (window.innerWidth <= 720) {
      reduce = 40;
      minDifference = 20;
    }
    const containerWidthToUse = maxProductWidth - reduce;
    const divDifference = Math.abs(prevWindowWidth - containerWidthToUse);
    if (divDifference >= minDifference) {
      setCardWidth(calculateItemWidth(containerWidthToUse));
      setPrevWindowWidth(containerWidthToUse);
    }
  }, [maxProductWidth, productsToDisplay]);

  const deleteProduct = (event) => {
    const productIdToDelete = event.target.id;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: productIdToDelete,
      }),
    };

    fetch("/api/products/deleteProduct", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDeleteProductMsg(data.message);
        if (data.success) {
          const id = parseInt(event.target.id, 10);
          if (wishList.includes(id)) {
            removeFromWishlist(id);
          }
          updateProductsAfterDelete(productIdToDelete);
        }
      });
  };

  return (
    <div className="flex justify-content-center align-items-center">
      <Dialog
        header="Nebolo možné odstrániť produkt"
        visible={deleteProductMsg != ""}
        onHide={() => setDeleteProductMsg("")}
        dismissableMask
      >
        <div className="alert mt-2" style={{ fontSize: "15px", width: "100%" }}>
          {deleteProductMsg}
        </div>
      </Dialog>
      {numItemsPerRow && maxProductDisplayedWidth && (
        <div
          className="card-grid"
          style={{
            gridTemplateColumns: `repeat(${numItemsPerRow}, 1fr)`,
            width: maxProductDisplayedWidth,
          }}
        >
          {productsToDisplay &&
            productsToDisplay.map((product) => (
              <div
                style={{
                  width: `${cardWidth} px`,
                  height: `calc(${cardWidth} px + 120px)`,
                }}
                key={product.id}
              >
                <div style={{ position: "relative" }} key={product.id}>
                  <ProductOverview
                    overviewData={product}
                    maxWidth={cardWidth}
                    removeFromWishlist={removeFromWishlist}
                  />
                  {isAdmin && isLogedIn && (
                    <i
                      className="pi pi-trash delete pointer"
                      id={product.id}
                      onClick={deleteProduct}
                    ></i>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default ProductsGrid;
