import React, { useState, useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { UserInfoContext } from "../UserInfo";

import { Dialog } from "primereact/dialog";

function PopularProducts({ popularProducts }) {
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [oldPopularProductId, setOldPopularProductId] = useState(-1);
  const [alerMsg, setAlertMsg] = useState("");

  const [link, setLink] = useState("");
  const icons = require("../../icons.json").icons;

  function truncateText(text) {
    const maxLength =
      window.innerWidth < 471
        ? 17
        : window.innerWidth < 551
        ? 25
        : window.innerWidth <= 630
        ? 30
        : window.innerWidth <= 830
        ? 32
        : window.innerWidth <= 920
        ? 38
        : window.innerWidth <= 1220
        ? 45
        : window.innerWidth <= 1450
        ? 57
        : 68;
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  }

  const navigate = useNavigate();
  const { isAdmin, isLogedIn } = useContext(UserInfoContext);
  const handleChange = (event) => {
    const { value } = event.target;
    setLink(value);
  };

  const displayDialogForProductChange = (id) => {
    setVisibleDialog(true);
    setOldPopularProductId(id);
  };
  const handleNewPopularProduct = (event) => {
    event.preventDefault();
    if (!link) {
      setAlertMsg("Pole je povinné");
    } else {
      setAlertMsg("");
      const parts = link.split("/");
      const newPopularProductId = parseInt(parts[parts.length - 1]);
      const currentPopularIds = popularProducts.map((product) => product.id);
      if (currentPopularIds.includes(newPopularProductId)) {
        setAlertMsg("Zadaný produkt už je zobrazený v úvode");
      } else if (isNaN(newPopularProductId)) {
        setAlertMsg("Nesprávne zadaný link");
      } else {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            oldPopularProductId: oldPopularProductId,
            newPopularProductId: newPopularProductId,
          }),
        };
        fetch("/api/products/changePopularProduct", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (!data.success) {
              setAlertMsg(data.message);
            } else {
              window.location.reload();
            }
          });
      }
    }
  };
  const favouriteProductLeft = (url, urlInfo, name, price, id) => {
    return (
      <div className="img-hp-container ">
        {isAdmin && isLogedIn && (
          <div style={{ position: "relative" }}>
            <i
              className="pi pi-pencil p-2 pointer"
              style={{
                border: "2px solid #3a3432",
                position: "absolute",
                left: "5px",
                top: "5px",
                backgroundColor: "white",
              }}
              onClick={() => displayDialogForProductChange(id)}
            ></i>
          </div>
        )}
        <div className="flex text-end align-items-end justify-content-end">
          <NavLink
            to={`/product/detail/${id}`}
            style={{ textDecoration: "none" }}
          >
            <img
              src={url}
              alt={urlInfo}
              className="img-product-hp pointer"
            ></img>
          </NavLink>
        </div>

        <div className="flex justify-content-between ">
          <div className="flex align-items-center"></div>
          <div className="flex justify-content-end">
            <div>
              <div
                style={{ fontWeight: "bold" }}
                className="pointer flex justify-content-end"
              >
                <NavLink
                  to={`/product/detail/${id}`}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {truncateText(name)}
                </NavLink>
              </div>

              <div className="flex justify-content-end align-items-end">
                <div className="mr-1">{price && `${price}€`}</div>
                <NavLink
                  to={`/product/detail/${id}`}
                  className="flex align-items-end"
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={icons.shoppingCart}
                    alt="shopping-cart"
                    className="horizontal-flip pointer"
                  ></img>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const favouriteProductRight = (url, urlInfo, name, price, id) => {
    return (
      <div className="img-hp-container ">
        {isAdmin && isLogedIn && (
          <div style={{ position: "relative" }}>
            <i
              className="pi pi-pencil p-2 pointer"
              style={{
                border: "2px solid #3a3432",
                position: "absolute",
                right: "5px",
                top: "5px",
                backgroundColor: "white",
              }}
              onClick={() => displayDialogForProductChange(id)}
            ></i>
          </div>
        )}
        <NavLink
          to={`/product/detail/${id}`}
          style={{ textDecoration: "none" }}
        >
          <img src={url} alt={urlInfo} className="img-product-hp pointer"></img>
        </NavLink>

        <div className="flex justify-content-between">
          <div>
            <div
              style={{ fontWeight: "bold" }}
              className="pointer flex justify-content-start"
            >
              <NavLink
                to={`/product/detail/${id}`}
                style={{ textDecoration: "none", color: "black" }}
              >
                {truncateText(name, 17)}
              </NavLink>
            </div>
            <div className="flex justify-content-start align-items-end">
              <NavLink
                to={`/product/detail/${id}`}
                style={{ textDecoration: "none" }}
                className="flex align-items-end"
              >
                <img
                  src={icons.shoppingCart}
                  alt="shopping-cart"
                  className="pointer"
                ></img>
              </NavLink>

              <div className="ml-1">{price && `${price}€`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Dialog
        header="Zadajte link produktu, ktorý chcete zobraziť v úvode"
        visible={visibleDialog}
        className="dialog-min-width"
        onHide={() => setVisibleDialog(false)}
        dismissableMask
        resizable={false}
      >
        <form onSubmit={handleNewPopularProduct}>
          <input
            type="text"
            placeholder="Link produktu"
            style={{ width: "100%" }}
            name="productLink"
            value={link}
            onChange={handleChange}
          ></input>
          {alerMsg !== "" && (
            <div
              className="alert mt-2"
              style={{ fontSize: "15px", width: "100%" }}
            >
              {alerMsg}
            </div>
          )}
          <button className="btn dark p-2 mt-3" style={{ width: "100%" }}>
            Zadať
          </button>
        </form>
      </Dialog>
      {popularProducts && popularProducts.length && (
        <div className="flex flex-wrap justify-content-center">
          <div className="hp-img-row">
            {favouriteProductLeft(
              popularProducts[0].images[0],
              "product1",
              popularProducts[0].name,
              popularProducts[0].prices.length > 1
                ? `od ${popularProducts[0].prices[0]
                    .toFixed(2)
                    .replace(".", ",")}`
                : popularProducts[0].prices.toFixed(2).replace(".", ","),
              popularProducts[0].id
            )}
            {favouriteProductRight(
              popularProducts[1].images[0],
              "product2",
              popularProducts[1].name,
              popularProducts[1].prices.length > 1
                ? `od ${popularProducts[1].prices[0]
                    .toFixed(2)
                    .replace(".", ",")}`
                : popularProducts[1].prices.toFixed(2).replace(".", ","),
              popularProducts[1].id
            )}
          </div>

          <div className="hp-img-row">
            {favouriteProductLeft(
              popularProducts[2].images[0],
              "product3",
              popularProducts[2].name,
              popularProducts[2].prices.length > 1
                ? `od ${popularProducts[2].prices[0]
                    .toFixed(2)
                    .replace(".", ",")}`
                : popularProducts[2].prices.toFixed(2).replace(".", ","),
              popularProducts[2].id
            )}
            {favouriteProductRight(
              popularProducts[3].images[0],
              "product4",
              popularProducts[3].name,
              popularProducts[3].prices.length > 1
                ? popularProducts[3].prices[0] &&
                    `od ${popularProducts[3].prices[0]
                      .toFixed(2)
                      .replace(".", ",")}`
                : popularProducts[3].prices.toFixed(2).replace(".", ","),
              popularProducts[3].id
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PopularProducts;
