import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../Loading";
function GopayRedirect() {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = params.token;

    // Use URLSearchParams to parse the query string
    const urlParams = new URLSearchParams(window.location.search);

    // Get the value of 'orderId' parameter
    const id = urlParams.get("id");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id, token: token }),
    };
    fetch("/api/orders/getPaymentState", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.orderPaid) {
          navigate(`/payment-success/${data.tokenSuccess}`);
        } else {
          navigate(`/payment-error/${data.tokenError}`);
        }
      });
  }, []);
  return (
    <div>
      <Loading />
    </div>
  );
}

export default GopayRedirect;
