import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { useParams, useNavigate, useLoaderData } from "react-router-dom";

import { InputTextarea } from "primereact/inputtextarea";
import ImageOptions from "./ImageOptions";
import NewVariant from "./NewVariant";

function NewProductSpecification({
  serverRequest,
  submitBtnClicked,
  updateSubmitBtnState,
}) {
  const [displayDialog, setDisplayDialog] = useState(false);
  const [displayLoading, setDisplayLoading] = useState(false);
  const [maintenanceImages, setMaintenanceImages] = useState(null);
  const [certificateImages, setCertificateImages] = useState(null);
  const [variants, setVariants] = useState(null);
  const [newProductData, setNewProductData] = useState({
    name: "",
    material: "",
    weight: "",
    type: "",
    maintenance: null,
    certificate: "",
    description: "",
  });
  const [warningMessage, setWarningMessage] = useState("");
  const params = useParams();
  const loaderData = useLoaderData();

  useEffect(() => {
    fetch("/api/images/getImagesForProductUpload")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setMaintenanceImages(data.maintenanceImages);
          setCertificateImages(data.certificateImages);
        }
      })
      .catch(() => {});

    if (loaderData && loaderData.product) {
      setNewProductData(loaderData.product);
    }
    if (loaderData && loaderData.variants) {
      setVariants(loaderData.variants);
    } else {
      setVariants([]);
    }
  }, []);

  const uploadNewImage = (e) => {
    const { files, name } = e.target;

    const formData = new FormData();
    formData.append("image", files[0]);
    formData.append("name", name);
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch("/api/images/addNewImageForProductUpload", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          window.location.reload();
        } else {
          setDisplayDialog(true);
          setWarningMessage(
            "Nebolo možné nahrať obrázok, skúste to neskôr prosím"
          );
        }
      });
  };

  const checkIfAllVariantsHasSameAttributesSet = () => {
    const notNullVariants = variants.filter((variant) => {
      // Check specific attributes: shape, size, sizeMeasurement, variant
      const hasValue =
        (variant.shape !== undefined &&
          variant.shape !== null &&
          variant.shape !== "") ||
        (variant.size !== undefined &&
          variant.size !== null &&
          variant.size !== "") ||
        (variant.sizeMeasurement !== undefined &&
          variant.sizeMeasurement !== null &&
          variant.sizeMeasurement !== "") ||
        (variant.variant !== undefined &&
          variant.variant !== null &&
          variant.variant !== "") ||
        variant.colors.length !== 0;
      return hasValue;
    });
    for (let i = 0; i < notNullVariants.length; i++) {
      const firstVariant = notNullVariants[i];

      for (let j = i + 1; j < notNullVariants.length; j++) {
        const secondVariant = notNullVariants[j];

        if (
          (!firstVariant.size && secondVariant.size) ||
          (firstVariant.size && !secondVariant.size)
        ) {
          return false;
        }
        if (
          (!firstVariant.sizeMeasurement && secondVariant.sizeMeasurement) ||
          (firstVariant.sizeMeasurement && !secondVariant.sizeMeasurement)
        ) {
          return false;
        }
        if (
          (!firstVariant.variant && secondVariant.variant) ||
          (firstVariant.variant && !secondVariant.variant)
        ) {
          return false;
        }
        if (
          (!firstVariant.shape && secondVariant.shape) ||
          (firstVariant.shape && !secondVariant.shape)
        ) {
          return false;
        }
      }
    }

    return true;
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    setNewProductData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const chooseCertificate = (event) => {
    const { id } = event.target;
    setNewProductData((prevData) => ({
      ...prevData,
      certificate: parseInt(id),
    }));
  };

  const chooseMaintenance = (event) => {
    const { id } = event.target;
    setNewProductData((prevData) => ({
      ...prevData,
      maintenance: parseInt(id),
    }));
  };

  const increaseNumOfVariants = () => {
    setVariants((prevData) => [
      ...prevData,
      {
        shape: "",
        size: "",
        sizeMeasurement: "",
        variant: "",
        colors: [],
        availableAmount: [],
        price: 0,
      },
    ]);
  };

  function capitalizeWord(inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  const isEveryFieldFilled = () => {
    for (const key in newProductData) {
      if (!newProductData[key]) {
        return false;
      }
    }
    if (variants && variants.length === 0) {
      setDisplayDialog(true);
      setWarningMessage("Zadajte prosím aspoň jeden variant");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateSubmitBtnState(true);
    setDisplayLoading(true);
    if (isEveryFieldFilled()) {
      const formData = new FormData();
      if (loaderData && loaderData.product) {
        formData.append("kind", loaderData.product.productKind);
        formData.append("id", loaderData.product.id);
      } else {
        formData.append("kind", params.productKind);
      }

      formData.append("description", newProductData.description.trim());

      formData.append(
        "material",
        capitalizeWord(newProductData.material).trim()
      );
      formData.append("weight", capitalizeWord(newProductData.weight).trim());
      formData.append("type", capitalizeWord(newProductData.type).trim());

      formData.append("maintenance", newProductData.maintenance);
      formData.append("certificate", newProductData.certificate);
      formData.append("name", capitalizeWord(newProductData.name).trim());

      if (checkIfAllVariantsHasSameAttributesSet()) {
        let variantsAdded = false;
        let priceMissing = false;
        let amountMissing = false;
        let measurementMissing = false;
        let sizeMissing = false;
        variants.forEach((variant, variantIndex) => {
          const hasAttributes =
            variant.shape ||
            variant.variant ||
            variant.size ||
            variant.sizeMeasurement ||
            variant.price;
          if (hasAttributes) {
            variantsAdded = true;

            formData.append(
              `variants[${variantIndex}][shape]`,
              capitalizeWord(variant.shape).trim()
            );
            formData.append(
              `variants[${variantIndex}][size]`,
              capitalizeWord(variant.size).trim()
            );
            formData.append(
              `variants[${variantIndex}][sizeMeasurement]`,
              variant.sizeMeasurement
            );
            formData.append(
              `variants[${variantIndex}][variant]`,
              variant.variant
            );
            formData.append(`variants[${variantIndex}][price]`, variant.price);
            if (variant.size && !variant.sizeMeasurement) {
              measurementMissing = true;
              return;
            }
            if (!variant.size && variant.sizeMeasurement) {
              sizeMissing = true;
              return;
            }
            if (!variant.price) {
              priceMissing = true;
              return;
            }

            if (variant.colors.length === 0) {
              amountMissing = true;
              return;
            }
            variant.colors.forEach((color, colorIndex) => {
              if (color !== "") {
                if (variant.idVariants && variant.idVariants[colorIndex]) {
                  formData.append(
                    `variants[${variantIndex}][idVariants][${colorIndex}]`,
                    variant.idVariants[colorIndex]
                  );
                }
                formData.append(
                  `variants[${variantIndex}][colors][${colorIndex}]`,
                  capitalizeWord(variant.colors[colorIndex]).trim()
                );
                formData.append(
                  `variants[${variantIndex}][availableAmount][${colorIndex}]`,
                  variant.availableAmount[colorIndex]
                );
                if (
                  !variant.availableAmount[colorIndex] ||
                  variant.availableAmount[colorIndex] === ""
                ) {
                  amountMissing = true;
                  return;
                }
              }
            });
          }
        });
        if (variantsAdded) {
          if (priceMissing) {
            setDisplayLoading(false);
            setDisplayDialog(true);
            setWarningMessage("Pri všetkých produktoch prosím zadajte cenu");
          } else if (amountMissing) {
            setDisplayLoading(false);

            setDisplayDialog(true);
            setWarningMessage(
              "Pri všetkých produktoch prosím zadajte množstvo"
            );
          } else if (measurementMissing) {
            setDisplayLoading(false);

            setDisplayDialog(true);
            setWarningMessage(
              "Pri všetkých veľkostiach produktov prosím zadajte ich rozmer"
            );
          } else if (sizeMissing) {
            setDisplayLoading(false);

            setDisplayDialog(true);
            setWarningMessage(
              "Pri všetkých rozmeroch produktov prosím zadajte ich veľkosť"
            );
          } else {
            serverRequest(formData);
          }
        } else {
          setDisplayLoading(false);
          setDisplayDialog(true);
          setWarningMessage("Nebol zadaný žiaden variant produktu");
        }
      } else {
        setDisplayLoading(false);
        setDisplayDialog(true);
        setWarningMessage("Všetky varianty musia mať vyplnené rovnaké polia");
      }
    } else {
      setDisplayLoading(false);
      setDisplayDialog(true);
      setWarningMessage("Nevyplnili ste všetky polia");
    }
  };

  const removeVariant = (event) => {
    const { id } = event.target;
    const index = parseFloat(id);
    if (variants && variants[index] && variants[index].idVariants) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idVariants: variants[index].idVariants,
        }),
      };

      fetch("/api/products/deleteProductVariant", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            deleteVariant = false;
            setWarningMessage(data.message);
          }
        });
    }

    const newVariants = variants.filter((_, i) => i !== index); // Create a new array
    setVariants(newVariants); // Update state with the new array
  };

  const updateVariants = (variants) => {
    setVariants(variants);
  };

  const warningDialog = () => {
    return (
      <Dialog
        visible={displayDialog}
        header={<div style={{ color: "#df4930" }}>Error</div>}
        className="dialog-added-to-cart"
        onHide={() => setDisplayDialog(false)}
        dismissableMask
      >
        {warningMessage}
      </Dialog>
    );
  };
  return (
    <div>
      {warningDialog()}
      <div className="input-row mt-1 flex justify-content-between">
        <div className="add-form-label">
          <label htmlFor="name ">Názov: </label>
        </div>
        <input
          type="text"
          name="name"
          value={newProductData.name}
          onChange={handleInput}
          style={{
            width: "100%",
            borderBottom:
              submitBtnClicked && !newProductData.name && "2px solid #df4930",
          }}
        />
      </div>
      {submitBtnClicked && !newProductData.name && (
        <div className="mt-2 alert">Zadajte prosím názov</div>
      )}

      <div className="add-form-label">
        <label htmlFor="description">Popis: </label>
      </div>
      <InputTextarea
        autoResize
        type="text"
        name="description"
        value={newProductData.description}
        onChange={handleInput}
        rows={3}
        style={{
          border:
            submitBtnClicked &&
            !newProductData.description &&
            "2px solid #df4930",
          width: "100%",
        }}
      />
      {submitBtnClicked && !newProductData.description && (
        <div className="mt-1 alert">Zadajte prosím popis</div>
      )}

      <div className="add-form-label">
        <label htmlFor="material">Materiál: </label>
      </div>
      <InputTextarea
        autoResize
        type="text"
        name="material"
        value={newProductData.material}
        onChange={handleInput}
        rows={3}
        style={{
          border:
            submitBtnClicked && !newProductData.material && "2px solid #df4930",
          width: "100%",
        }}
      />
      {submitBtnClicked && !newProductData.material && (
        <div className="mt-1 alert">Zadajte prosím materiál</div>
      )}

      <div className="add-form-label">
        <label htmlFor="weight">Gramáž: </label>
      </div>

      <InputTextarea
        autoResize
        type="text"
        name="weight"
        value={newProductData.weight}
        onChange={handleInput}
        rows={3}
        style={{
          border:
            submitBtnClicked && !newProductData.weight && "2px solid #df4930",
          width: "100%",
        }}
      />
      {submitBtnClicked && !newProductData.weight && (
        <div className="mt-1 alert">Zadajte prosím gramáž</div>
      )}

      <div className="add-form-label">
        <label htmlFor="type">Typ: </label>
      </div>
      <InputTextarea
        autoResize
        type="text"
        name="type"
        value={newProductData.type}
        onChange={handleInput}
        rows={3}
        style={{
          border:
            submitBtnClicked && !newProductData.type && "2px solid #df4930",
          width: "100%",
        }}
      />
      {submitBtnClicked && !newProductData.type && (
        <div className="mt-1 alert">Zadajte prosím gramáž</div>
      )}
      <div className="add-form-label">
        <label htmlFor="maintenance">Starostlivosť: </label>
      </div>
      <div
        style={{
          padding: "10px",
          border:
            submitBtnClicked &&
            !newProductData.maintenance &&
            "2px solid #df4930",
        }}
      >
        <ImageOptions
          images={maintenanceImages}
          chooseOption={chooseMaintenance}
          handleInput={uploadNewImage}
          choosenImage={newProductData.maintenance}
          name="maintenance"
        />
      </div>

      {submitBtnClicked && !newProductData.maintenance && (
        <div className="mt-2 alert">Zadajte prosím starostlivosť</div>
      )}
      <div className="add-form-label">
        <label htmlFor="certificate">Certifikáty: </label>
      </div>
      <div
        style={{
          padding: "10px",
          border:
            submitBtnClicked &&
            !newProductData.certificate &&
            "2px solid #df4930",
        }}
      >
        <ImageOptions
          images={certificateImages}
          chooseOption={chooseCertificate}
          handleInput={uploadNewImage}
          choosenImage={newProductData.certificate}
          name="certificate"
        />
      </div>

      {submitBtnClicked && !newProductData.certificate && (
        <div className="mt-2 alert">Zadajte prosím certifikáty</div>
      )}

      {variants &&
        variants.length > 0 &&
        variants.map((variant, index) => (
          <div
            className="flex align-items-center"
            style={{ position: "relative" }}
            key={`variant_${index}`}
          >
            <NewVariant
              allVariants={variants}
              index={index}
              updateAllVariants={updateVariants}
              submitBtnClicked={submitBtnClicked}
            />

            <i
              className="pi pi-times pointer"
              id={index}
              style={{ position: "absolute", right: "5px", top: "0px" }}
              onClick={removeVariant}
            ></i>
          </div>
        ))}
      <div
        className="pb-2 pl-2 pr-2"
        style={{
          border:
            warningMessage !== "" &&
            variants.length === 0 &&
            "2px solid #df4930",
        }}
      >
        <button
          className="light btn flex justify-content-center mt-2"
          onClick={increaseNumOfVariants}
        >
          <div style={{ fontWeight: "bold" }}>+ Variant</div>
        </button>
      </div>
      {warningMessage !== "" && variants && variants.length === 0 && (
        <div className="alert mt-2">
          Zadajte prosím aspoň jeden variant produktu
        </div>
      )}

      <button
        style={{ marginTop: "10px", width: "100%" }}
        type="submit"
        className="btn dark flex justify-content-center align-items-center"
        onClick={handleSubmit}
      >
        {displayLoading && (
          <i
            className="pi pi-spin pi-spinner mr-2"
            style={{ fontSize: "20px" }}
          ></i>
        )}
        Pridať
      </button>
    </div>
  );
}

export default NewProductSpecification;
