import React, { useState, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { Dialog } from "primereact/dialog";

function NewVariant({
  allVariants,
  updateAllVariants,
  index,
  submitBtnClicked,
}) {
  const [newVariant, setNewVariant] = useState(null);
  const loaderData = useLoaderData();
  const [warningMessage, setWarningMessage] = useState("");
  useEffect(() => {
    if (loaderData && loaderData.variants && allVariants[index]) {
      setNewVariant({
        idVariants: allVariants[index].idVariants,
        shape: allVariants[index].shape,
        size: allVariants[index].size,
        sizeMeasurement: allVariants[index].sizeMeasurement,
        variant: allVariants[index].variant,
        colors: allVariants[index].colors,
        availableAmount: allVariants[index].availableAmount,
        price: allVariants[index].price,
      });
    } else {
      setNewVariant({
        shape: "",
        size: "",
        sizeMeasurement: "",
        variant: "",
        colors: [],
        availableAmount: [],
        price: 0,
      });
    }
  }, []);

  useEffect(() => {
    if (newVariant && allVariants[index]) {
      setNewVariant({
        idVariants: allVariants[index].idVariants,
        shape: allVariants[index].shape,
        size: allVariants[index].size,
        sizeMeasurement: allVariants[index].sizeMeasurement,
        variant: allVariants[index].variant,
        colors: allVariants[index].colors,
        availableAmount: allVariants[index].availableAmount,
        price: allVariants[index].price,
      });
    }
  }, [allVariants]);

  const handleVariantsInput = (event, variantIndex, colorIndex = -1) => {
    const { name, value } = event.target;

    let updatedVariants = allVariants;
    if (colorIndex !== -1) {
      setNewVariant((prevState) => {
        const updatedVariant = { ...prevState };
        updatedVariant[name][colorIndex] = value;
        return updatedVariant;
      });
      updatedVariants[variantIndex][name][colorIndex] = value;
    } else {
      setNewVariant((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      updatedVariants[variantIndex] = {
        ...updatedVariants[variantIndex],
        [name]: value,
      };
    }

    updateAllVariants(updatedVariants);
  };

  const deleteProductVariant = (event) => {
    const variantIndex = parseInt(event.target.id);
    let deleteVariant = true;
    if (newVariant.idVariants && newVariant.idVariants[variantIndex]) {
      const id = newVariant.idVariants[variantIndex];
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idVariants: id,
        }),
      };

      fetch("/api/products/deleteProductVariant", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            deleteVariant = false;
            setWarningMessage(data.message);
          }
        });
    }
    if (deleteVariant) {
      let newColors = newVariant.colors.filter((_, i) => i !== variantIndex);
      let newAvailableAmount = newVariant.availableAmount.filter(
        (_, i) => i !== variantIndex
      );

      let updatedVariant = {
        ...newVariant,
        colors: newColors,
        availableAmount: newAvailableAmount,
      };
      if (newVariant.idVariants && newVariant.idVariants[variantIndex]) {
        let newIdVariants = newVariant.idVariants.filter(
          (_, i) => i !== variantIndex
        );

        updatedVariant = {
          ...updatedVariant,
          idVariants: newIdVariants,
        };
      }

      let newAllVariants = allVariants;
      newAllVariants[index] = updatedVariant;
      updateAllVariants(newAllVariants);
      setNewVariant(updatedVariant);
    }
  };

  const increaseNumberOfColors = (index) => {
    const updatedVariants = allVariants;
    updatedVariants[index] = {
      ...updatedVariants[index],
      colors: [...allVariants[index].colors, ""],
      availableAmount: [...allVariants[index].availableAmount, ""],
    };

    setNewVariant((prevState) => {
      const { colors, availableAmount, ...rest } = prevState;

      return {
        ...rest,
        colors: [...colors, ""],
        availableAmount: [...availableAmount, ""],
      };
    });
    updateAllVariants(updatedVariants);
  };
  const warningDialog = () => {
    return (
      <Dialog
        visible={warningMessage !== ""}
        header={<div style={{ color: "#df4930" }}>Error</div>}
        className="dialog-added-to-cart"
        onHide={() => setWarningMessage("")}
        dismissableMask
      >
        {warningMessage}
      </Dialog>
    );
  };
  return (
    <div className="variant-form justify-content-center">
      {newVariant && (
        <div>
          {warningDialog()}

          <div className="flex justify-content-center flex-wrap">
            <div className="flex align-items-end justify-content-between">
              <div className="add-form-label">
                <label htmlFor="shape">Tvar:</label>
              </div>
              <input
                type="text"
                id="shape-input"
                name="shape"
                value={newVariant.shape}
                onChange={(event) => handleVariantsInput(event, index)}
                style={{ margin: "10px" }}
              />
            </div>
            <div className="flex align-items-end">
              <div className="add-form-label">
                <label htmlFor="variant">Variant:</label>
              </div>
              <input
                type="text"
                id="variant-input"
                name="variant"
                value={newVariant.variant}
                onChange={(event) => handleVariantsInput(event, index)}
                style={{ margin: "10px" }}
              />
            </div>
          </div>
          <div className="flex justify-content-center flex-wrap">
            <div className="flex align-items-end">
              <div className="add-form-label">
                <label htmlFor="size">Veľkosť:</label>
              </div>
              <input
                type="text"
                id="size-input"
                name="size"
                value={newVariant.size}
                onChange={(event) => handleVariantsInput(event, index)}
                style={{
                  margin: "10px",
                  borderBottom:
                    submitBtnClicked &&
                    newVariant.sizeMeasurement &&
                    !newVariant.size &&
                    "2px solid #df4930",
                }}
              />
            </div>
            <div className="flex align-items-end">
              <div className="add-form-label">
                <label htmlFor="size">Rozmer:</label>
              </div>
              <input
                type="text"
                id="size-input"
                name="sizeMeasurement"
                value={newVariant.sizeMeasurement}
                onChange={(event) => handleVariantsInput(event, index)}
                style={{
                  margin: "10px",
                  borderBottom:
                    submitBtnClicked &&
                    !newVariant.sizeMeasurement &&
                    newVariant.size &&
                    "2px solid #df4930",
                }}
              />
            </div>
          </div>
          <div className="flex flex-wrap align-add-product">
            <div className="flex  align-items-end">
              <div className="add-form-label">
                <label htmlFor="price">Cena:</label>
              </div>
              <input
                type="number"
                id="price-input"
                name="price"
                value={newVariant.price}
                onChange={(event) => handleVariantsInput(event, index)}
                style={{ margin: "10px" }}
              />
            </div>
          </div>
          <button
            className="btn flex justify-content-between align-items-center pl-3 pr-3"
            style={{
              width: "100%",
              height: "40px",
              backgroundColor: "#eae6e3",
              border: "none",
            }}
            onClick={() => increaseNumberOfColors(index)}
          >
            <div>Farba</div>
            <div style={{ fontSize: "20px" }}>+</div>
          </button>
          {newVariant && newVariant.colors.length > 0 && (
            <div id="color">
              {newVariant.colors.map((_, colorIndex) => (
                <div
                  className="flex justify-content-center flex-wrap mt-1"
                  key={`variant_${index}_color_${colorIndex}`}
                >
                  <div className="flex align-items-end">
                    <div className="add-form-label">
                      <label htmlFor="colors">Farba:</label>
                    </div>
                    <input
                      type="text"
                      id="colors-input"
                      name="colors"
                      value={newVariant.colors[colorIndex]}
                      onChange={(e) =>
                        handleVariantsInput(e, index, colorIndex)
                      }
                      style={{ margin: "10px" }}
                    />
                  </div>
                  <div className="flex align-items-end">
                    <div className="add-form-label">
                      <label htmlFor="alailableAmount">Množstvo:</label>
                    </div>
                    <input
                      type="number"
                      id="availableAmount-input"
                      name="availableAmount"
                      value={newVariant.availableAmount[colorIndex]}
                      onChange={(event) =>
                        handleVariantsInput(event, index, colorIndex)
                      }
                      placeholder="0"
                      style={{ margin: "10px" }}
                    />
                  </div>
                  <div
                    className="flex align-items-center"
                    style={{ position: "relative" }}
                  >
                    <i
                      className="pi pi-times pointer"
                      id={colorIndex}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "5px",
                      }}
                      onClick={deleteProductVariant}
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default NewVariant;
