import React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import "../../styles/navbar.css";
import AuthContainter from "../Auth/AuthContainter";
import { Outlet, useNavigate, NavLink } from "react-router-dom";
import Menu from "./Menu";
import ForgotPasswordForm from "../Auth/ForgotPasswordForm";
import { UserInfoContext } from "../UserInfo";
import { Sidebar } from "primereact/sidebar";
import SearchOptions from "./SearchOptions";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Footer from "../Footer";

function Navbar() {
  const [displayDropdownLogin, setDisplayDropdownLogin] = useState(false);
  const [displayDropdownShoppingCart, setDisplayDropdownShoppingCart] =
    useState(false);
  const [displayLoginForm, setDisplayLoginForm] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [displayForgotPassForm, setDisplayForgotPassForm] = useState(false);
  //search option display
  const [displaySearch, setDisplaySearch] = useState(false);
  const dropdownRef = useRef(null);
  const [giftCardAmount, setGiftCardAmount] = useState(null);
  const [isImgValid, setIsImgValid] = useState([]);
  const menuRef = useRef(null);
  const scrollbarRef = useRef(null);
  const navigate = useNavigate();
  const {
    itemsCount,
    updateItemsCount,
    setWishListContent,
    isLogedIn,
    isAdmin,
    updateUserDataAfterLogout,
    setLogedInUser,
    setLogInExpiration,
    updateLoadingState,
    loading,
  } = useContext(UserInfoContext);
  const [facebookId, setFacebookId] = useState("");
  const [googleId, setGoogleId] = useState("");
  const icons = require("../../icons").icons;

  const handleDisplayDropdownLogin = () => {
    setDisplayDropdownLogin(true);
  };

  const handleHideDropdownLogin = () => {
    setDisplayDropdownLogin(false);
  };
  const checkImage = (imageUrl, index) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setIsImgValid((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = true;
        return newArray;
      });
    };
    img.onerror = () => {
      setIsImgValid((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = false;
        return newArray;
      });
    };
  };

  const handleDisplayDropdownShoppingCart = () => {
    setIsImgValid([]);
    const discounts = localStorage.getItem("discounts")
      ? JSON.parse(localStorage.getItem("discounts"))
      : [];
    if (localStorage.getItem("shoppingCart")) {
      JSON.parse(localStorage.getItem("shoppingCart")).forEach(
        (item, index) => {
          checkImage(item.image, index);
        }
      );
    }

    const eurDiscount = discounts.filter((discount) => discount.type === "€");
    if (eurDiscount && eurDiscount.length > 0) {
      setGiftCardAmount(eurDiscount[0].amount);
    } else {
      setGiftCardAmount(null);
    }

    setDisplayDropdownShoppingCart(true);
  };
  const handleHideDropdownShoppingCart = () => {
    setDisplayDropdownShoppingCart(false);
  };

  const toggleForgotPassForm = (event) => {
    event.stopPropagation();
    setDisplayForgotPassForm((prevState) => !prevState);
  };

  const logout = () => {
    updateLoadingState(true);
    handleHideDropdownLogin();
    setIsMenuOpen(false);
    if (window.FB) {
      window.FB.logout((response) => {});
    }

    fetch("/api/auth/logout")
      .then((response) => response.json())
      .then((data) => {
        updateUserDataAfterLogout();
        const currentUrl = window.location.href;

        if (
          currentUrl.includes("profile") ||
          currentUrl.includes("delivery-overview") ||
          currentUrl.includes("manage-discounts")
        ) {
          navigate("/");
        } else {
          //window.location.reload();
        }
      })
      .catch((error) => {
        updateLoadingState(false);
        console.log(error);
      });
  };

  const closeForms = () => {
    if (localStorage.getItem("googleLogin")) {
      localStorage.removeItem("googleLogin");
    }
    setDisplayLoginForm(false);
    setDisplayForgotPassForm(false);
  };

  const handleOpenLoginForm = (event) => {
    event.stopPropagation();
    setIsMenuOpen(false);
    setDisplayLoginForm(true);
    handleHideDropdownLogin();
  };

  const handleMenuBtnClick = (event) => {
    // Check if the clicked element is inside the menu
    if (
      menuRef.current &&
      (menuRef.current.contains(event.target) ||
        event.target.classList.contains("pi-bars"))
    ) {
      handleHideDropdownLogin();
      return;
    }
  };

  const getTotalPrice = () => {
    let priceToPay = 0;
    const items = localStorage.getItem("shoppingCart")
      ? JSON.parse(localStorage.getItem("shoppingCart"))
      : [];
    const discounts = localStorage.getItem("discounts")
      ? JSON.parse(localStorage.getItem("discounts"))
      : [];
    const percentDiscount = discounts.filter(
      (discount) => discount.type === "%"
    );
    items.map((item) => {
      const proportionOfPrice =
        item.name.startsWith("Poukážka") || percentDiscount.length === 0
          ? 1
          : 1 - parseFloat(percentDiscount[0].amount) / 100;
      if (item.maxAmount > 0 || !item.id_variant) {
        priceToPay += item.price * item.amount * proportionOfPrice;
      }
    });
    const eurDiscount = discounts.filter((discount) => discount.type === "€");
    if (eurDiscount && eurDiscount.length > 0) {
      priceToPay -= eurDiscount[0].amount;
    }

    return priceToPay < 0 ? 0 : priceToPay;
  };
  const handleStorageChange = (event) => {
    if (event.key === "logedIn") {
      window.location.reload();
    }
  };

  function isBeforeToday(date1) {
    const today = new Date(); // Get today's date

    // Convert date1 to a Date object if it's not already one
    date1 = date1 instanceof Date ? date1 : new Date(date1);

    // Compare the dates (considering year, month, and day)
    return (
      date1.getFullYear() < today.getFullYear() ||
      (date1.getFullYear() === today.getFullYear() &&
        date1.getMonth() < today.getMonth()) ||
      (date1.getFullYear() === today.getFullYear() &&
        date1.getMonth() === today.getMonth() &&
        date1.getDate() <= today.getDate())
    );
  }

  useEffect(() => {
    updateLoadingState(true);
    fetch("/api/homePage/getAuthIds")
      .then((response) => response.json())
      .then((data) => {
        setGoogleId(data.googleId);
        setFacebookId(data.facebookId);
        if (localStorage.getItem("googleLogin")) {
          const localStorageData = new Date(
            JSON.parse(localStorage.getItem("googleLogin")).untill
          );
          const now = new Date();
          if (now < localStorageData) {
            setDisplayLoginForm(true);
          } else {
            localStorage.removeItem("googleLogin");
          }
        }
      });

    if (localStorage.getItem("logedIn") && !isLogedIn) {
      localStorage.setItem("initialLoading", true);
      //user wasnt logoud last time he left the page, if refresh token is still valid he doesnt need to loge in using password and username
      const logedIn = localStorage.getItem("logedIn");
      const logedInExpiration = new Date(logedIn);
      const currentDate = new Date();
      const isBefore = isBeforeToday(logedInExpiration);
      const timeDifferenceInMilliseconds =
        currentDate.getTime() - logedInExpiration.getTime();
      const oneWeekInMilliseconds = 1000 * 60 * 60 * 24 * 7;

      if (!isBefore && oneWeekInMilliseconds >= timeDifferenceInMilliseconds) {
        fetch("/api/auth/setNewRefreshToken")
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              updateUserDataAfterLogout();
              window.location.reload();
            } else {
              if (data.success) {
                //refresh token was successfuly set
                setLogedInUser();
                setLogInExpiration();
              } else {
                //refresh token wasnt set, he needs to again log in
                updateUserDataAfterLogout();
              }
            }
          })
          .catch(() => updateUserDataAfterLogout());
      } else if (oneWeekInMilliseconds < timeDifferenceInMilliseconds) {
        //refresh token is ok
        setLogedInUser();
      } else {
        updateUserDataAfterLogout();
      }
    } else {
      if (localStorage.getItem("initialLoading")) {
        localStorage.removeItem("initialLoading");
      }
      if (localStorage.getItem("shoppingCart")) {
        updateItemsCount(
          JSON.parse(localStorage.getItem("shoppingCart")).length
        );
      }
      //prepare wishlist for user
      if (!localStorage.getItem("wishList")) {
        //if isnt loged in, prepare new one
        const wishList = [];
        localStorage.setItem("wishList", JSON.stringify(wishList));
      }
      localStorage.removeItem("discounts");
      if (!localStorage.getItem("discounts")) {
        const discounts = [];
        localStorage.setItem("discounts", JSON.stringify(discounts));
      }

      //if wishlist exists in localStorage, just load it from there
      try {
        if (
          localStorage.getItem("wishList") &&
          (JSON.parse(localStorage.getItem("wishList")) !== undefined ||
            JSON.parse(localStorage.getItem("wishList")) !== "undefined")
        ) {
          setWishListContent(JSON.parse(localStorage.getItem("wishList")));
        } else {
          setWishListContent([]);
          localStorage.setItem("wishList", JSON.stringify([]));
        }
      } catch {
        setWishListContent([]);
        localStorage.setItem("wishList", JSON.stringify([]));
      }
    }

    const setScrollbarHeightAndTop = () => {
      if (scrollbarRef.current) {
        const navbarHeight = document.getElementById("navbar").offsetHeight; // Adjust 'navbar' with your actual navbar ID or class
        const contentHeight = window.innerHeight - navbarHeight;
        scrollbarRef.current.style.height = `${contentHeight}px`;
        scrollbarRef.current.style.top = `${navbarHeight}px`;
      }
    };

    // Initial setup
    setScrollbarHeightAndTop();

    // Update on window resize
    window.addEventListener("resize", setScrollbarHeightAndTop);
    document.addEventListener("click", handleMenuBtnClick);
    window.addEventListener("storage", handleStorageChange);
    updateLoadingState(false);
    return () => {
      document.removeEventListener("click", handleMenuBtnClick);
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("resize", setScrollbarHeightAndTop);
    };
  }, []);

  const toggleMenu = (event) => {
    event.stopPropagation();
    setIsMenuOpen((prevState) => !prevState);
  };

  const percentDiscountExists = () => {
    if (localStorage.getItem("discounts")) {
      const discounts = JSON.parse(localStorage.getItem("discounts"));
      if (discounts) {
        return discounts.some((discount) => discount.type === "%");
      }
    }
    return false;
  };

  const getPriceAfterDiscount = (itemPrice) => {
    if (localStorage.getItem("discounts")) {
      const discounts = JSON.parse(localStorage.getItem("discounts"));
      if (discounts) {
        const discountAmount = discounts.filter(
          (discount) => discount.type === "%"
        );

        if (discountAmount && discountAmount.length > 0) {
          return (1 - discountAmount[0].amount / 100) * itemPrice;
        }
      }
    }
  };

  const getUserProfile = () => {
    setIsMenuOpen(false);
    setDisplayDropdownLogin(false);
  };

  const displayShoppingCart = () => {
    setIsMenuOpen(false);
    setDisplayDropdownShoppingCart(false);
  };

  const displayHomePage = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
    navigate("/");
  };

  const getSearch = () => {
    setIsMenuOpen(false);
    setDisplaySearch((prevState) => !prevState);
  };

  return (
    <div>
      <Sidebar
        position="top"
        className="serach-menu"
        visible={displaySearch}
        onHide={() => setDisplaySearch((prevState) => !prevState)}
      >
        <SearchOptions
          hideSearch={() => setDisplaySearch((prevState) => !prevState)}
        />
      </Sidebar>
      <div className="navbar" id="navbar">
        <ul className="list">
          <div className="left pointer" onClick={toggleMenu} ref={menuRef}>
            <div className="custom-vector flex align-items-center">
              <i className=" pi pi-bars menu-bars-size"></i>
            </div>

            <div className="navbar-menu ml-2">MENU</div>
            {isMenuOpen && <Menu isAdmin={isAdmin} />}
          </div>

          <div className="middle">
            <li>
              <NavLink
                to={"/"}
                style={{ textDecoration: "none" }}
                onClick={() => setIsMenuOpen(false)}
              >
                <img
                  src={icons.logo}
                  alt="logo"
                  className="logoImge middle"
                ></img>
              </NavLink>
            </li>
          </div>
          <div className="right flex">
            <div className="flex align-items-center">
              <img
                src={icons.search}
                alt="search"
                onClick={getSearch}
                className="pointer right-icons navbar-items"
              />
            </div>
            <div className="flex align-items-center">
              <NavLink
                to={`/favourites`}
                className="pointer right-icons navbar-items"
                style={{ textDecoration: "none" }}
                onClick={() => setIsMenuOpen(false)}
              >
                <img src={icons.hearthFilled} alt="hearth"></img>
              </NavLink>
            </div>

            <div
              className="dropdown"
              onMouseEnter={handleDisplayDropdownLogin}
              onMouseLeave={handleHideDropdownLogin}
              onClick={() => setIsMenuOpen(false)}
            >
              <div className="flex align-items-end">
                <img
                  src={icons.avatar}
                  alt="avatar"
                  onClick={handleDisplayDropdownLogin}
                  className="pointer right-icons navbar-items"
                ></img>
              </div>

              {displayDropdownLogin && (
                <div className="dropdown-menu login-dropdown" ref={dropdownRef}>
                  {isLogedIn && localStorage.getItem("logedIn") ? (
                    <>
                      <NavLink
                        to={`/profile/user-details`}
                        onClick={getUserProfile}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="dropdown-menu-text pointer mt-2 mb-2">
                          Profil
                        </div>
                      </NavLink>
                      <div
                        className="dropdown-menu-text pointer mb-2"
                        onClick={logout}
                      >
                        Odhlásiť sa
                      </div>
                    </>
                  ) : (
                    <>
                      <p className="dropdown-menu-text">Nie ste prihlásený</p>
                      <div
                        className="btn dark dropdown-btn"
                        onClick={handleOpenLoginForm}
                      >
                        Prihlásiť sa
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            <div
              className="dropdown"
              onMouseEnter={handleDisplayDropdownShoppingCart}
              onMouseLeave={handleHideDropdownShoppingCart}
            >
              <div style={{ position: "relative" }}>
                <div className="flex align-items-end">
                  <img
                    src={icons.shoppingCart}
                    alt="shopping-cart"
                    onClick={handleDisplayDropdownShoppingCart}
                    className="pointer right-icons navbar-items"
                  ></img>
                </div>
                {itemsCount > 0 && (
                  <div className="shopping-cart-items-count">
                    <div className="flex justify-content-center align-items-center">
                      {itemsCount}
                    </div>
                  </div>
                )}
              </div>

              {displayDropdownShoppingCart && (
                <div
                  className="dropdown-menu"
                  style={{ width: itemsCount > 0 ? "400px" : "250px" }}
                  ref={dropdownRef}
                >
                  {itemsCount > 0 && localStorage.getItem("shoppingCart") ? (
                    <div className="choosen-items">
                      {JSON.parse(localStorage.getItem("shoppingCart")).map(
                        (product, index) => {
                          return (
                            <div key={`${product.name}_${index}`}>
                              <div className="flex">
                                <img
                                  style={{
                                    objectFit: "cover",
                                    height: "120px",
                                    width: "90px",
                                  }}
                                  src={product.image}
                                  alt="miniature"
                                />
                                <div>
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      marginLeft: "10px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    {product.name}
                                  </div>
                                  <div
                                    className="item-added-overview"
                                    style={{
                                      opacity: "0.7",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {product.shape !== "undefined" &&
                                      product.shape && (
                                        <p>Tvar: {product.shape}</p>
                                      )}
                                    {product.color !== "undefined" &&
                                      product.color && (
                                        <p>Farba: {product.color}</p>
                                      )}
                                    {product.size !== "undefined" &&
                                      product.size && (
                                        <p>Veľkosť: {product.size}</p>
                                      )}
                                    {product.variant !== "undefined" &&
                                      product.variant && (
                                        <p>Variant: {product.variant}</p>
                                      )}
                                    <p>Množstvo: {product.amount}</p>
                                  </div>
                                  {product.maxAmount === 0 ? (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        border: "2px solid #df4930",
                                        borderRadius: "10px",
                                        fontWeight: "bold",
                                        color: "#df4930",
                                        backgroundColor: "white",
                                        width: "130px",
                                        fontSize: "15px",
                                        marginLeft: "10px",
                                      }}
                                      className=" p-1 pl-2 pr-2 mb-2 flex justify-content-center"
                                    >
                                      Nedostupné
                                    </div>
                                  ) : (
                                    <div>
                                      <div
                                        style={{
                                          color: "#3a3432",
                                          fontSize: "15px",
                                          marginLeft: "10px",
                                          textDecoration:
                                            percentDiscountExists() &&
                                            !product.name.startsWith("Poukážka")
                                              ? "line-through"
                                              : "none",
                                          opacity:
                                            percentDiscountExists() &&
                                            !product.name.startsWith("Poukážka")
                                              ? "0.7"
                                              : "1",
                                        }}
                                      >
                                        {(product.amount * product.price)
                                          .toFixed(2)
                                          .replace(".", ",")}
                                        €
                                      </div>
                                      {percentDiscountExists() &&
                                        !product.name.startsWith(
                                          "Poukážka"
                                        ) && (
                                          <div
                                            className="flex mt-1 align-start"
                                            style={{
                                              color: "#3a3432",
                                              fontSize: "15px",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {getPriceAfterDiscount(
                                              product.price * product.amount
                                            )
                                              .toFixed(2)
                                              .replace(".", ",")}
                                            €
                                          </div>
                                        )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {index !==
                                JSON.parse(localStorage.getItem("shoppingCart"))
                                  .length -
                                  1 && <hr style={{ opacity: "0.4" }} />}
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <div
                      className="mt-2 mb-2"
                      style={{ fontWeight: "normal", fontSize: "17px" }}
                    >
                      Váš košík je prázdny
                    </div>
                  )}
                  {giftCardAmount && (
                    <div>
                      <div
                        className="flex justify-content-between mt-2"
                        style={{ fontSize: "14px" }}
                      >
                        <div>Poukážka:</div>
                        <div>-{giftCardAmount}€</div>
                      </div>
                    </div>
                  )}
                  <div
                    className="flex justify-content-between mt-2 mb-3"
                    style={{ fontSize: "15px" }}
                  >
                    <div>Celkom za produkty:</div>
                    <div>{getTotalPrice().toFixed(2).replace(".", ",")}€</div>
                  </div>
                  {itemsCount > 0 && (
                    <NavLink
                      to={`/shopping-cart`}
                      onClick={displayShoppingCart}
                      style={{ textDecoration: "none" }}
                    >
                      <button
                        style={{ width: "100%" }}
                        className="btn dark dropdown-btn"
                      >
                        Prejsť do košíka
                      </button>
                    </NavLink>
                  )}
                </div>
              )}
            </div>
          </div>
        </ul>
      </div>

      {displayForgotPassForm ? (
        <ForgotPasswordForm closeForms={closeForms} />
      ) : (
        displayLoginForm &&
        googleId && (
          <GoogleOAuthProvider clientId={googleId}>
            <AuthContainter
              facebookId={facebookId}
              closeForms={closeForms}
              toggleForgotPassForm={toggleForgotPassForm}
            />
          </GoogleOAuthProvider>
        )
      )}
      <div className="content-padding custom-scrollbar" ref={scrollbarRef}>
        <div className="scroll-content">
          <Outlet style={{ height: "100%" }} />
        </div>
        <div className="flex align-items-end">
          {<Footer loading={loading} />}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
