import React from "react";

import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import { useState } from "react";
import { NavLink } from "react-router-dom";

function SignInForm(props) {
  const [isVerified, setIsVerified] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [isEmpyConfCode, setIsEmptyConfCode] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    formEmail: "",
    formPassword: "",
    conditionsAgreed: false,
    formNewsletter: false,
  });
  const [displayLoading, setDisplayLoading] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertMessage, setAlertMessage] = useState({
    formEmail: "",
    formPassword: "",
    conditionsAgreed: "",
  });
  const displayLoadingIcon = () => {
    if (props.isFormFilled(formData, setAlertMessage)) {
      setDisplayLoading(true);
    }
  };

  const togglePasswordVisibility = (event) => {
    event.stopPropagation();
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    if (name !== "formNewsletter" && alertMessage[name] !== "") {
      setAlertMessage((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
    setFormData((prevLoginInfo) => ({
      ...prevLoginInfo,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleRegister = (event) => {
    event.preventDefault();
    if (!formData.conditionsAgreed) {
      setDisplayLoading(false);
      setAlertMessage((prevState) => ({
        ...prevState,
        conditionsAgreed: "Pre vytvorenie účtu musíš súhlasiť s pravidlami",
      }));
    } else {
      if (props.isFormFilled(formData, setAlertMessage)) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            formData,
          }),
        };

        fetch("/api/auth/register", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            setDisplayLoading(false);
            if (data.success) {
              setAlertText("");
              setAlertMessage({
                formEmail: "",
                formPassword: "",
                conditionsAgreed: "",
              });
              props.switchDisplayAlterLogin();
              setCodeSent((prevState) => !prevState);
            } else {
              setAlertMessage({
                formEmail: data.emailMsg,
                formPassword: data.passwordMsg,
                conditionsAgreed: data.conditionMsg,
              });
              if (data.emailMsg === "" && data.passwordMsg === "") {
                setAlertText(data.message);
              }
            }
          })
          .catch((error) => console.log(error));
      } else {
        setDisplayLoading(false);
        setAlertText("");
      }
    }
  };

  const sendNewVerificationMail = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: formData.formEmail,
      }),
    };
    fetch("/api/auth/sendNewVerificationEmail", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setAlertText(`Nový kód odoslaný`);
        }
      });
  };

  const handleCodeSubmit = (event) => {
    event.preventDefault();
    setDisplayLoading(false);
    if (confirmationCode === "") {
      setIsEmptyConfCode(true);
      setAlertText("");
    } else {
      setIsEmptyConfCode(false);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: formData.formEmail,
          code: confirmationCode,
        }),
      };

      fetch("/api/auth/verifyEmail", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            setConfirmationCode("");
            setAlertText(data.message);
          } else {
            setIsVerified(true);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const handleCodeInput = (event) => {
    setConfirmationCode(event.target.value);
  };

  const login = (event) => {
    props.switchForm(event);
    props.switchDisplayAlterLogin();
  };

  return (
    <div>
      {codeSent ? (
        !isVerified ? (
          <form className="p-3">
            <div
              className="flex justify-content-center align-items-center mb-2 mt-2"
              style={{ fontSize: "16px" }}
            >
              <div>
                Potvrdzovací kód bol odoslaný na email{" "}
                <span style={{ fontWeight: "bold" }}>{formData.formEmail}</span>
              </div>
            </div>
            {alertText !== "" && (
              <div className="alert flex justify-content-center align-items-center mb-1">
                {alertText}
              </div>
            )}
            <input
              className="input-login"
              type="number"
              placeholder="Kód"
              value={confirmationCode}
              style={{
                borderBottom: isEmpyConfCode ? "2px solid #df4930" : "",
                width: "100%",
              }}
              onChange={handleCodeInput}
            />
            {isEmpyConfCode && (
              <div
                style={{ fontSize: "15px" }}
                className="flex justify-content-start alert"
              >
                Je potrebné zadať kód
              </div>
            )}
            <button className="submit btn dark mt-2" onClick={handleCodeSubmit}>
              {displayLoading && (
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "20px", marginRight: "10px" }}
                ></i>
              )}
              Potvrdiť
            </button>
            <p
              style={{ fontSize: "16px" }}
              className="switch-form"
              onClick={sendNewVerificationMail}
            >
              Zaslať nový potvrdzovací kód
            </p>
          </form>
        ) : (
          <div>
            Registrácia prebehla úspešne, môžete sa prihlásiť.
            <div className="switch-form mt-2 mb-0" onClick={login}>
              Prihlásiť sa
            </div>
          </div>
        )
      ) : (
        <div>
          <h2 className="loginTitle">Registrácia</h2>
          <form onSubmit={handleRegister} className="register-form">
            <div className="alert">{alertText}</div>
            <input
              className="input-login"
              type="text"
              placeholder="Email"
              name="formEmail"
              id="formEmail"
              style={{
                borderBottom:
                  alertMessage.formEmail !== "" ? "solid 2px #df4930" : "",
              }}
              value={formData.formEmail}
              onChange={handleChange}
            />
            {alertMessage.formEmail !== "" && (
              <div className="missing-input">{alertMessage.formEmail}</div>
            )}
            <div className="password-container">
              <input
                className="input-login"
                type={showPassword ? "text" : "password"}
                placeholder="Heslo"
                id="formPassword"
                name="formPassword"
                style={{
                  borderBottom:
                    alertMessage.formPassword !== "" ? "solid 2px #df4930" : "",
                }}
                value={formData.formPassword}
                onChange={handleChange}
              />
              <button
                type="button"
                className="password-toggle"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {alertMessage.formPassword !== "" && (
              <div className="missing-input">{alertMessage.formPassword}</div>
            )}
            <div style={{ width: "92%" }}>
              <div className="flex justify-content-start flex-column align-items-start text-start">
                <div>
                  <div className="signIn mt-2">
                    <input
                      type="checkbox"
                      checked={formData.formNewsletter}
                      name="formNewsletter"
                      id="formNewsletter"
                      onChange={handleChange}
                    />
                    <label
                      style={{ fontSize: "15px" }}
                      htmlFor="formNewsletter"
                    >
                      Chcem odoberať newsletter
                    </label>
                  </div>

                  <div className="signIn mt-2 home-page">
                    <input
                      type="checkbox"
                      checked={formData.conditionsAgreed}
                      name="conditionsAgreed"
                      id="conditionsAgreed"
                      onChange={handleChange}
                      style={{
                        border:
                          alertMessage.conditionsAgreed !== "" &&
                          !formData.conditionsAgreed
                            ? "solid 1px #df4930"
                            : "solid 1px #3a3432",
                      }}
                    />
                    <label
                      style={{ fontSize: "15px" }}
                      htmlFor="conditionsAgreed"
                    >
                      Súhlasím s{" "}
                      <NavLink
                        to="/privacy-policy"
                        onClick={props.closeForms}
                        style={{
                          textDecoration: "underline",
                          color: "#3a3432",
                        }}
                      >
                        <span className="switch-form">
                          {" "}
                          pravidlami ochrany osobných údajov
                        </span>
                      </NavLink>
                    </label>
                  </div>
                  {alertMessage.conditionsAgreed !== "" && (
                    <div
                      className="mt-2"
                      style={{
                        color: " #df4930",
                        fontSize: "15px",
                        textAlign: "center",
                        width: "220px",
                      }}
                    >
                      {alertMessage.conditionsAgreed}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <button
              className="submit pointer btn dark mt-3 flex justify-content-center align-items-center"
              onClick={displayLoadingIcon}
            >
              {displayLoading && (
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "20px", marginRight: "10px" }}
                ></i>
              )}
              <div>Registrovať sa</div>
            </button>
            <div className="mt-3">
              Už máte účet?
              <p className="switch-form" onClick={props.switchForm}>
                Prihlásiť sa
              </p>
            </div>
          </form>
          <div className="line mt-4 mb-3">
            <div className="circle" />
          </div>
        </div>
      )}
    </div>
  );
}

export default SignInForm;
