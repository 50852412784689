import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function SearchOptions(props) {
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const navigate = useNavigate();
  const icons = require("../../icons").icons;

  useEffect(() => {
    fetch("/api/products/getAllDistinctNames")
      .then((response) => response.json())
      .then((data) => {
        setOptions(data.names);
      });
  }, []);

  function removeDiacritics(text) {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const changeSearchValue = (event) => {
    const { value } = event.target;
    setSearchValue(removeDiacritics(value));
    const filtered = options.filter((option) =>
      removeDiacritics(option).toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const search = (event) => {
    props.hideSearch();
    navigate(`/search/${removeDiacritics(event.target.id).toLowerCase()}`);
  };

  const searchUserInput = (event) => {
    if (searchValue) {
      event.preventDefault();
      props.hideSearch();
      navigate(`/search/${removeDiacritics(searchValue).toLowerCase()}`);
    }
  };

  return (
    <div className="flex flex-column justify-content-center align-items-center serach">
      <form
        onSubmit={searchUserInput}
        className="flex justify-content-center"
        style={{ width: "100%" }}
      >
        <input
          type="text"
          id="search"
          placeholder="Hľadať"
          value={searchValue}
          onChange={changeSearchValue}
        />
        <button
          className="p-2 mr-3 "
          style={{
            border: "none",
            width: "50px",
            height: "50px",
            backgroundColor: "transparent",
          }}
        >
          <img
            src={icons.search}
            alt="search"
            style={{ width: "30px", height: "30px" }}
          />
        </button>
      </form>

      <div className="search-options-wrapper flex justify-content-start flex-column mb-3">
        {filteredOptions.map((option, index) => (
          <button
            className="search-options"
            key={index}
            id={option}
            onClick={search}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
}

export default SearchOptions;
