import React, { useState, useEffect, useRef } from "react";

function Dropdown({ label, options, setAttribute, updateField }) {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [optionsWidth, setOptionsWidth] = useState(null);

  const handleDocumentClick = (event) => {
    // Check if the clicked element is inside the dropdown
    if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
      return;
    }
    setIsOpen(false);
  };

  const selectOption = (event) => {
    setAttribute(event);
    setIsOpen(false);
  };

  const handleResize = () => {
    if (isOpen) {
      setOptionsWidth(dropdownRef.current.offsetWidth);
    }
  };

  const openDropdown = () => {
    setIsOpen((prevState) => !prevState);
    setOptionsWidth(dropdownRef.current.offsetWidth);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    window.addEventListener("resize", handleResize);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="dropdown-product-detail" ref={dropdownRef}>
      <div
        className="flex justify-content-between block pointer"
        onClick={openDropdown}
        style={{ width: "100%", border: "none" }}
      >
        <div className="mr-2">{label}</div>
        <i className="pi pi-angle-down mt-1"></i>
      </div>

      <div
        className="product-detail-dropdown-menu flex flex-column"
        style={{ width: `${optionsWidth}px` }}
      >
        {isOpen && (
          <div className="mt-2 pointer" style={{ opacity: "0.7" }}>
            {options.map((option, index) => (
              <button
                key={`option_${index}`}
                className="mb-2 order-button pl-3 "
                name={updateField}
                value={option}
                onClick={selectOption}
              >
                {option}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Dropdown;
