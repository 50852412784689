import React, { useState, useContext, useEffect, useRef } from "react";

function ForgotPasswordForm({ closeForms = null, switchToForgotPassword }) {
  const [alertText, setAlertText] = useState("");
  const [displayLoading, setDisplayLoading] = useState(false);
  const [isInputMissing, setIsInputMissing] = useState(false);
  const [email, setEmail] = useState("");
  const authRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    setDisplayLoading(false);
    if (email === "") {
      setIsInputMissing(true);
      setDisplayLoading(false);
    } else {
      setIsInputMissing(false);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
        }),
      };
      fetch("/api/auth/forgotPassword", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setIsInputMissing(data.isInputMissing);

          setAlertText(data.message);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  return (
    <div
      className={closeForms && "overlay"}
      style={{
        boxShadow: !closeForms && "0 2px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div className="form" style={{ height: closeForms && "100vh" }}>
        <div className="form-wrapper p-5" ref={authRef}>
          {closeForms && (
            <i
              className="pi pi-times closing-tab"
              style={{ zIndex: "1000" }}
              onClick={closeForms}
            ></i>
          )}
          <h2 className="loginTitle">Zabudli ste heslo?</h2>
          {alertText !== "" && <div>{alertText}</div>}
          <form onSubmit={handleSubmit}>
            <input
              className="mt-3"
              type="text"
              placeholder="Email"
              name="email"
              id="email"
              style={{
                borderBottom: isInputMissing ? "solid 2px #df4930" : "",
                width: "100%",
                minWidth: "230px",
                marginTop: "0px",
                marginBottom: "10px",
              }}
              value={email}
              onChange={handleChange}
            />
            {isInputMissing && (
              <div
                className="missing-input"
                style={{
                  marginBottom: "10px",
                }}
              >
                Je potrebné zadať email
              </div>
            )}
            <div>
              <button
                className="btn pointer dark p-2 mt-2"
                onClick={() => setDisplayLoading((prevState) => !prevState)}
                style={{ width: "100%" }}
              >
                {displayLoading && (
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "20px", marginRight: "10px" }}
                  ></i>
                )}
                Odoslať
              </button>
            </div>
          </form>
          <div
            className="switch-form mt-3"
            onClick={() => switchToForgotPassword()}
          >
            Späť k prihlasovaniu
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordForm;
