import React from "react";

function BouncingDots({ message }) {
  return (
    <div>
      <div
        className="flex justify-content-center align-items-center text-center"
        style={{ height: "90vh", fontSize: "18px" }}
      >
        <div className="text-center">
          <div className="mb-4">{message}</div>
          <div className="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BouncingDots;
