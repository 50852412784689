import React, { useEffect, useState, useContext } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import { UserInfoContext } from "../UserInfo";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

function LogInForm(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    formEmail: "",
    formPassword: "",
  });
  const [displayLoading, setDisplayLoading] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertMessage, setAlerMesage] = useState({
    formEmail: "",
    formPassword: "",
  });
  const [saveCredentials, setSaveCredentials] = useState(false);
  const [autoFill, setAutoFill] = useState(false);
  const { wishList, setLogedInUser, updateLoadingState } =
    useContext(UserInfoContext);

  useEffect(() => {
    if (secureLocalStorage.getItem("credentials")) {
      setAutoFill(true);
      const credentials = JSON.parse(secureLocalStorage.getItem("credentials"));
      setFormData({
        formEmail: credentials[0].username,
        formPassword: credentials[0].password,
      });
    }
  }, []);

  useEffect(() => {
    const retrievedCredentials = secureLocalStorage.getItem("credentials");

    if (retrievedCredentials) {
      const credentials = JSON.parse(retrievedCredentials);

      const matchingCredential = credentials.find(
        (credential) => credential.username === formData.formEmail
      );

      if (matchingCredential) {
        setFormData((prevData) => ({
          ...prevData,
          formPassword: matchingCredential.password,
        }));
        setAutoFill(true);
      }
    }
  }, [formData.formEmail]);

  const displayLoadingIcon = () => {
    if (props.isFormFilled(formData, setAlerMesage)) {
      setDisplayLoading(true);
    }
  };

  const togglePasswordVisibility = (event) => {
    event.stopPropagation();
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleChange = (event) => {
    setAutoFill(false);
    const { name, value } = event.target;
    if (alertMessage[name] !== "") {
      setAlerMesage((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }

    setFormData((prevLoginInfo) => ({
      ...prevLoginInfo,
      [name]: value,
    }));
  };

  const handleLogin = (event) => {
    event.preventDefault();
    if (props.isFormFilled(formData, setAlerMesage)) {
      let cartItems = [];
      if (localStorage.getItem("shoppingCart")) {
        cartItems = JSON.parse(localStorage.getItem("shoppingCart"));
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          formData,
          wishlist: wishList,
          shoppingCart: cartItems,
        }),
      };

      fetch("/api/auth/login", requestOptions)
        .then((response) => response.json())
        .then(async (data) => {
          setDisplayLoading(false);
          if (data.success) {
            if (saveCredentials) {
              if (secureLocalStorage.getItem("credentials")) {
                const savedCredentials = JSON.parse(
                  secureLocalStorage.getItem("credentials")
                );
                const updatedCredentials = [
                  ...savedCredentials,
                  {
                    username: formData.formEmail,
                    password: formData.formPassword,
                  },
                ];
                secureLocalStorage.setItem(
                  "credentials",
                  JSON.stringify(updatedCredentials)
                );
              } else {
                const credentials = [
                  {
                    username: formData.formEmail,
                    password: formData.formPassword,
                  },
                ];
                secureLocalStorage.setItem(
                  "credentials",
                  JSON.stringify(credentials)
                );
              }
            }
            updateLoadingState(true);

            if (props.closeForms) {
              props.closeForms();
              await setLogedInUser();
            } else {
              await setLogedInUser();
              navigate("/shopping-cart");
            }
          } else {
            setAlerMesage({
              formEmail: data.emailMsg,
              formPassword: data.passwordMsg,
            });
            if (data.emailMsg === "" && data.passwordMsg === "") {
              setAlertText(data.message);
            }
          }
          updateLoadingState(false);
        })
        .catch((error) => {
          setDisplayLoading(false);
          updateLoadingState(false);
        });
    } else {
      setDisplayLoading(false);
      updateLoadingState(false);
      setAlertText("");
    }
  };
  return (
    <div>
      <h2 className="loginTitle">Prihlásenie</h2>
      <form onSubmit={handleLogin} className="register-form">
        <div className="alert">{alertText}</div>
        <input
          className="input-login"
          type="text"
          placeholder="Email"
          name="formEmail"
          id="formEmail"
          style={{
            backgroundColor: autoFill ? "#e8f0fe" : "transparent",
            borderBottom:
              alertMessage.formEmail !== "" ? "solid 2px #df4930" : "",
          }}
          value={formData.formEmail}
          onChange={handleChange}
        />
        {alertMessage.formEmail !== "" && (
          <div className="missing-input">{alertMessage.formEmail}</div>
        )}

        <div className="password-container">
          <input
            className="input-login"
            type={showPassword ? "text" : "password"}
            placeholder="Heslo"
            id="formPassword"
            name="formPassword"
            style={{
              backgroundColor: autoFill ? "#e8f0fe" : "transparent",
              borderBottom:
                alertMessage.formPassword !== "" ? "solid 2px #df4930" : "",
            }}
            value={formData.formPassword}
            onChange={handleChange}
          />
          {!secureLocalStorage.getItem("password") && (
            <button
              type="button"
              className="password-toggle"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          )}
        </div>
        {alertMessage.formPassword !== "" && (
          <div className="missing-input">{alertMessage.formPassword}</div>
        )}
        <div className="signIn mt-2">
          <input
            type="checkbox"
            checked={saveCredentials}
            name="saveCredential"
            id="saveCredential"
            onChange={() => setSaveCredentials((prevState) => !prevState)}
          />
          <label style={{ fontSize: "15px" }} htmlFor="saveCredential">
            Zapamätať prihlasovacie údaje
          </label>
        </div>

        <button
          className="mt-3 btn p-2 pointer btn dark flex justify-content-center align-items-center"
          onClick={displayLoadingIcon}
          style={{ width: "92%" }}
        >
          {displayLoading && formData.formPassword !== "" && (
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "20px", marginRight: "10px" }}
            ></i>
          )}
          <div>Prihlásiť sa</div>
        </button>
        <div
          className="switch-form mt-3"
          onClick={() => props.switchToForgotPassword()}
        >
          Zabudli ste heslo?
        </div>
        <div>
          Ešte nemáte účet?
          <p className="switch-form" onClick={props.switchForm}>
            Registrovať sa
          </p>
        </div>
      </form>
      <div className="line mt-4 mb-3">
        <div className="circle" />
      </div>
    </div>
  );
}

export default LogInForm;
