import React, { useEffect, useState, useRef, useContext } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import { UserInfoContext } from "../UserInfo";
import { Dropdown } from "primereact/dropdown";

function UserInfo() {
  const states = ["Slovensko", "Česko"];
  const toast = useRef(null);
  const navigate = useNavigate();
  const [displayLoading, setDisplayLoading] = useState(false);
  const { updateUserDataAfterLogout, isLogedIn, loading, updateLoadingState } =
    useContext(UserInfoContext);
  const [password, setPassword] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [displayLoadingUserInfo, setDisplayLoadingUserInfo] = useState(false);
  const [displayLoadingAdress, setDisplayLoadingAdress] = useState(false);
  const [missingAdress, setMissingAdress] = useState({
    missingStreet: false,
    missingPsc: false,
    missingCity: false,
    missingState: false,
  });
  const [userInfo, setUserInfo] = useState({
    name: "",
    surname: "",
    email: "",
    phoneNumber: "",
    street: "",
    city: "",
    psc: "",
    state: "",
    password: "",
  });
  const [newsletter, setNewsletter] = useState(false);
  const [displayCancelAccount, setDisplayCancelAccount] = useState(false);
  const [displayChangePassword, setDisplayChangePassword] = useState(false);

  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      detail: message,
      life: 3000,
    });
  };

  const showError = () => {
    toast.current.show({
      severity: "error",
      detail: "Nebolo možné zmeniť informácie",
      life: 3000,
    });
  };

  const updateNewsletter = (event) => {
    setNewsletter(event.value);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newsletter: event.value,
      }),
    };
    fetch("/api/user/changeNewsletter", requestOptions);
  };

  useEffect(() => {
    updateLoadingState(true);
    if (localStorage.getItem("logedIn")) {
      fetch("/api/user/getUsersProfileInfo")
        .then((response) => response.json())
        .then((data) => {
          updateLoadingState(false);
          if (data.success) {
            if (localStorage.getItem("passwordChanged")) {
              showSuccess("Heslo bolo úspešne zmenené");
              localStorage.removeItem("passwordChanged");
            }
            setUserInfo(data.user);
            setNewsletter(data.newsletter);
          }
        });
    } else {
      updateLoadingState(false);
    }
  }, []);

  const handleChange = (event) => {
    const { value, name } = event.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const cancelAccount = (event) => {
    event.preventDefault();
    setAlertMsg("");
    setDisplayLoading(true);
    if (!password && userInfo.password) {
      setAlertMsg("Prosím zadajte heslo");
      setDisplayLoading(false);
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: password,
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      };

      fetch("/api/auth/cancelAccount", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setDisplayLoading(false);
          if (!data.success) {
            setAlertMsg(data.message);
          } else {
            fetch("/api/auth/logout")
              .then((response) => response.json())
              .then((data) => {
                updateUserDataAfterLogout();
                navigate("/");
              })
              .catch((error) => {
                console.log(error);
                setDisplayLoading(false);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          setDisplayLoading(false);
        });
    }
  };

  const comparePasswords = (event) => {
    event.preventDefault();
    setAlertMsg("");
    setDisplayLoading(true);
    if (!password) {
      setAlertMsg("Prosím zadajte heslo");
      setDisplayLoading(false);
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: password,
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      };

      fetch("/api/auth/comparePasswords", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setDisplayLoading(false);

          if (!data.success) {
            setAlertMsg(data.message);
          } else {
            navigate(`/profile/reset-password/${data.email}/${data.token}`);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const handleSubmitUserAdress = (event) => {
    event.preventDefault();
    const fieldsToCheck = ["street", "city", "psc", "state"];
    let hasEmptyField = false;
    const updatedMissingAdress = {};

    fieldsToCheck.forEach((field) => {
      if (userInfo[field] === "") {
        updatedMissingAdress[
          `missing${field.charAt(0).toUpperCase() + field.slice(1)}`
        ] = true;
        hasEmptyField = true;
      } else {
        updatedMissingAdress[
          `missing${field.charAt(0).toUpperCase() + field.slice(1)}`
        ] = false;
      }
    });

    setMissingAdress((prevState) => ({
      ...prevState,
      ...updatedMissingAdress,
    }));

    if (!hasEmptyField) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          street: userInfo.street,
          psc: userInfo.psc,
          city: userInfo.city,
          state: userInfo.state,
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      };
      fetch("/api/user/changeUserAdress", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setDisplayLoadingAdress(false);
          if (data.missingData) {
            setMissingAdress(data.missingData);
          }
          if (data.success) {
            showSuccess("Informácie boli aktualizované");
          } else {
            showError();
          }
        });
    } else {
      setDisplayLoadingAdress(false);
    }
  };

  const handleSubmitUserDetails = (event) => {
    event.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: userInfo.name,
        surname: userInfo.surname,
        phoneNumber: userInfo.phoneNumber,
      }),
    };

    fetch("/api/user/changeUserInfo", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDisplayLoadingUserInfo(false);
        if (data.success) {
          showSuccess("Informácie boli aktualizované");
        } else {
          showError();
        }
      })
      .catch((error) => console.log(error));
  };

  const profile = () => {
    return (
      <div className="profile-form flex justify-content-center flex-column ">
        <div className="mb-3" style={{ fontWeight: "bold", color: "#3a3432" }}>
          Upraviť osobné údaje
        </div>
        <form onSubmit={handleSubmitUserDetails}>
          <div className="profile-input-row profile-two-inputs border-input">
            <div>
              <input
                type="text"
                name="name"
                placeholder="Meno"
                value={userInfo.name}
                onChange={handleChange}
                className="two-inputs"
              />
            </div>
            <div className="flex flex-column">
              <input
                type="text"
                name="surname"
                placeholder="Priezvisko"
                value={userInfo.surname}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="profile-input-row border-input">
            <input
              type="text"
              name="phoneNumber"
              placeholder="Tel. číslo"
              value={userInfo.phoneNumber}
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </div>
          <div className="profile-input-row">
            <button
              className="btn dark p-2 profile-btn flex justify-content-center align-items-center"
              onClick={() => setDisplayLoadingUserInfo(true)}
            >
              {displayLoadingUserInfo && (
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "20px", marginRight: "10px" }}
                ></i>
              )}
              Potvrdiť
            </button>
          </div>
        </form>
        <form onSubmit={handleSubmitUserAdress}>
          <div
            className="mb-3 mt-5"
            style={{ fontWeight: "bold", color: "#3a3432" }}
          >
            Upraviť adresu
          </div>
          <div className="profile-input-row border-input">
            <input
              type="text"
              name="street"
              className={`${missingAdress.missingStreet && "mb-0"}`}
              placeholder="Ulica"
              value={userInfo.street}
              onChange={handleChange}
              style={{
                border: missingAdress.missingStreet ? "2px solid #df4930" : "",
                width: "100%",
              }}
            />
            {missingAdress.missingStreet && (
              <div
                className="alert mb-2"
                style={{ fontSize: "15px", width: "100%" }}
              >
                Prosím zadajte ulicu
              </div>
            )}
          </div>
          <div className="profile-input-row border-input justify-content-between">
            <input
              type="text"
              name="psc"
              placeholder="PSČ"
              className={`${
                (missingAdress.missingPsc || missingAdress.missingCity) &&
                "mb-0"
              } psc`}
              value={userInfo.psc}
              onChange={handleChange}
              style={{
                width: "30%",
                marginRight: "0px",
                border: missingAdress.missingPsc ? "2px solid #df4930" : "",
              }}
            />

            <input
              type="text"
              name="city"
              className={`${
                (missingAdress.missingPsc || missingAdress.missingCity) &&
                "mb-0"
              } city`}
              placeholder="Mesto"
              value={userInfo.city}
              onChange={handleChange}
              style={{
                width: "65%",
                border: missingAdress.missingCity ? "2px solid #df4930" : "",
              }}
            />
          </div>
          <div className="flex justify-content-between">
            {missingAdress.missingPsc && (
              <div
                className="alert mb-2"
                style={{ fontSize: "15px", width: "35%" }}
              >
                Prosím zadajte PSČ
              </div>
            )}
            {missingAdress.missingCity && !missingAdress.missingPsc ? (
              <div style={{ width: "100%" }}>
                <div className="flex justify-content-between">
                  <div style={{ width: "30%" }}></div>
                  <div
                    className="alert mb-2"
                    style={{ fontSize: "15px", width: "65%" }}
                  >
                    Prosím zadajte mesto
                  </div>
                </div>
              </div>
            ) : (
              missingAdress.missingCity && (
                <div
                  className="alert mb-2"
                  style={{ fontSize: "15px", width: "65%" }}
                >
                  Prosím zadajte mesto
                </div>
              )
            )}
          </div>

          <div className="profile-input-row border-input">
            <Dropdown
              value={userInfo.state}
              onChange={(event) =>
                setUserInfo((prevState) => ({
                  ...prevState,
                  state: event.target.value,
                }))
              }
              options={states}
              placeholder="Krajina"
              className={`${missingAdress.missingState && "mb-0"}`}
              style={{
                marginBottom: "20px",
                width: "100%",
                border: missingAdress.missingState
                  ? "2px solid #df4930"
                  : "1px solid #b9b9b9",
              }}
            />

            {missingAdress.missingState && (
              <div
                className="alert mb-2"
                style={{ fontSize: "15px", width: "100%" }}
              >
                Prosím zadajte štát
              </div>
            )}
          </div>
          <div className="profile-input-row">
            <button
              className="btn dark p-2 profile-btn flex justify-content-center align-items-center"
              onClick={() => setDisplayLoadingAdress(true)}
            >
              {displayLoadingAdress && (
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "20px", marginRight: "10px" }}
                ></i>
              )}
              Potvrdiť
            </button>
          </div>
        </form>
      </div>
    );
  };

  const newsletterApply = () => {
    return (
      <div>
        <div
          className="mt-5 flex"
          style={{ fontWeight: "bold", color: "#3a3432" }}
        >
          Newsletter
        </div>
        <div
          className="newsletter-text mt-2 align-items-center"
          style={{ height: "50px" }}
        >
          {newsletter ? (
            <div className="flex justify-content-start">
              Ďakujeme za prejavenie záujmu o náš newslettr.
            </div>
          ) : (
            <div>
              Prihlás sa na odber našich noviniek, aby ti neušla žiadna zľava
              alebo akcia, ktorú si pre teba prichystáme.
            </div>
          )}
        </div>
        <div className="flex justify-content-center align-items-center mt-2">
          <InputSwitch
            checked={newsletter}
            className="mr-2"
            onChange={updateNewsletter}
          />
          <div>
            {newsletter ? (
              <div>Odhlásiť sa z odberu newslettra</div>
            ) : (
              <div>Prihlásiť sa na odber newslettra</div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const hideChangePassword = () => {
    setDisplayChangePassword(false);
    setAlertMsg("");
  };

  const changePassword = () => {
    return (
      <div>
        <Dialog
          header="Zmena hesla"
          visible={displayChangePassword}
          onHide={hideChangePassword}
          dismissableMask
          resizable={false}
          draggable={false}
        >
          <div className="mb-2" style={{ fontSize: "16px" }}>
            Pre zmenu hesla zadajte prosím aktuálne heslo.
          </div>
          <form onSubmit={comparePasswords}>
            <input
              type="password"
              placeholder="Heslo"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              style={{
                borderBottom: alertMsg !== "" ? "2px solid #df4930" : "",
                width: "100%",
              }}
            ></input>
            {alertMsg !== "" && (
              <div
                className="alert mt-2"
                style={{ fontSize: "15px", width: "100%" }}
              >
                {alertMsg}
              </div>
            )}
            <button className="btn dark mt-3">
              {displayLoading && (
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "20px", marginRight: "10px" }}
                ></i>
              )}
              Odoslať
            </button>
          </form>
        </Dialog>
        <div
          className="mt-5 flex"
          style={{ fontWeight: "bold", color: "#3a3432" }}
        >
          Zmena hesla
        </div>
        <div className="mt-2 mb-5">
          Pre zmenu hesla kliknite{" "}
          <a
            className="pointer"
            style={{ textDecoration: "underline" }}
            onClick={() => setDisplayChangePassword((prevState) => !prevState)}
          >
            sem
          </a>
        </div>
      </div>
    );
  };

  const hideCancelAccount = () => {
    setDisplayCancelAccount(false);
    setAlertMsg("");
  };

  const removeAccount = () => {
    return (
      <div>
        <Dialog
          header="Zrušenie účtu"
          visible={displayCancelAccount}
          onHide={hideCancelAccount}
          dismissableMask
          draggable={false}
          resizable={false}
        >
          {userInfo.password ? (
            <div>
              <div className="mb-2" style={{ fontSize: "16px" }}>
                Pre zrušenie účtu prosím zadajte heslo k Vášmu účtu.
              </div>
              <form onSubmit={cancelAccount}>
                <input
                  type="password"
                  placeholder="Heslo"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  style={{
                    borderBottom: alertMsg !== "" ? "2px solid #df4930" : "",
                    width: "100%",
                  }}
                ></input>
                {alertMsg !== "" && (
                  <div
                    className="alert mt-2"
                    style={{ fontSize: "15px", width: "100%" }}
                  >
                    {alertMsg}
                  </div>
                )}
                <button className="btn dark mt-3">
                  {displayLoading && (
                    <i
                      className="pi pi-spin pi-spinner"
                      style={{ fontSize: "20px", marginRight: "10px" }}
                    ></i>
                  )}
                  Odoslať
                </button>
              </form>
            </div>
          ) : (
            <div className="confirmation-width">
              <div>Naozaj chcete odstrániť účet?</div>
              <div className="flex mt-4 justify-content-end">
                <button
                  className="btn dark p-2 pointer mr-2"
                  style={{
                    width: "50px",
                  }}
                  onClick={cancelAccount}
                >
                  Áno
                </button>
                <button
                  className="btn light p-2 pointer"
                  style={{
                    width: "50px",
                  }}
                  onClick={() =>
                    setDisplayCancelAccount((prevState) => !prevState)
                  }
                >
                  Nie
                </button>
              </div>
            </div>
          )}
        </Dialog>
        {/* <div
          className="mt-5 flex"
          style={{ fontWeight: "bold", color: "#3a3432" }}
        >
          Zrušenie účtu
        </div>
        <div className="mt-2 mb-5" style={{ fontSize: "16px" }}>
          Pre zrušenie účtu kliknite{" "}
          <a
            className="pointer"
            style={{ textDecoration: "underline" }}
            onClick={() => setDisplayCancelAccount((prevState) => !prevState)}
          >
            sem
          </a>
        </div> */}
      </div>
    );
  };
  return (
    <div>
      <Toast ref={toast} />
      {loading ? (
        <Loading />
      ) : (
        <div>
          {profile()}
          {newsletterApply()}
          {userInfo.password && changePassword()}
          {removeAccount()}
        </div>
      )}
    </div>
  );
}

export default UserInfo;
