import React from "react";

function PurchaseConditions() {
  return (
    <div className="flex justify-content-center">
      <div className="document terms">
        <div className="flex justify-content-center">
          <h2 className="text-center">
            Všeobecné obchodné a reklamačné podmienky
          </h2>
        </div>
        <p>
          Prevádzkovateľom internetového obchodu umiestneného pod doménou{" "}
          <a
            href="http://www.broleit.com"
            style={{ textDecoration: "underline" }}
          >
            www.broleit.com
          </a>
        </p>
        <section className="flex flex-column gap-2">
          <div>
            <strong>Obchodné meno:</strong> Bronislava Leitman
          </div>
          <div>
            <strong>Sídlo:</strong> Poruba 405, 972 11 Poruba (SK)
          </div>
          <div>
            <strong>IČO:</strong> 55 970 834
          </div>
          <div>
            <strong>E-mail:</strong>{" "}
            <a href="mailto:leitman.bronislava@gmail.com">
              leitman.bronislava@gmail.com
            </a>
          </div>
          <div>
            <strong>Tel. číslo:</strong>{" "}
            <a href="tel:+421903985818">+421 903 985 818</a>
          </div>
          <strong>Orgán dozoru je:</strong>
          <div>
            <strong>Slovenská obchodná inšpekcia</strong>
          </div>
          <div>
            Hurbanova 59,
            <br />
            911 01 Trenčín
          </div>
          <div>
            <strong>E-mail:</strong> <a href="mailto:tn@soi.sk">tn@soi.sk</a>
          </div>
          <div>
            <strong>Tel. č.:</strong> 032/640 01 09
          </div>
        </section>
        <section>
          <h4>Článok I Definície </h4>
          <p>
            <strong>Predávajúci</strong> SZČO Bronislava Leitman, ktorá pri
            uzatváraní a plnení kúpnej zmluvy koná v rámci predmetu svojej
            podnikateľskej činnosti vo svojom mene, na svoj účet a ktorá
            prostredníctvom E-shopu predáva Tovar.{" "}
          </p>
          <p>
            <strong>Spotrebiteľ</strong> fyzická osoba, ktorá nakupuje Tovar
            prostredníctvom E-shopu na doméne{" "}
            <a
              href="http://www.broleit.com"
              style={{ textDecoration: "underline" }}
            >
              www.broleit.com
            </a>{" "}
            a ktorej tento Tovar neslúži na výkon zamestnania, povolania alebo
            podnikania
          </p>
          <p>
            <strong>E-shop </strong>
            internetový obchod Predávajúceho umiestnený na doméne{" "}
            <a
              href="http://www.broleit.com"
              style={{ textDecoration: "underline" }}
            >
              www.broleit.com
            </a>
          </p>
          <p>
            <strong>Tovar </strong>
            Tovar ponúkaný prostredníctvom E-shopu
          </p>
          <p>
            <strong>Objednávka </strong>
            úkon Spotrebiteľa prostredníctvom E-shopu, ktorý vyjadruje vôľu
            Spotrebiteľa nakúpiť Tovar v E-shope, náležitosti ktorého sú uvedené
            v čl. IV ods. 7 týchto VOP
          </p>
          <p>
            <strong>Cena </strong>
            celková cena uvedená v Objednávke, a to cena za všetok Tovar, ktorý
            si Spotrebiteľ objednal a je uvedený v Objednávke, cena Dopravného a
            všetky dane a poplatky, účtované v súlade s príslušnými daňovými
            predpismi
          </p>
          <p>
            <strong>Dopravné </strong>
            cena za dopravu Tovaru k Spotrebiteľovi a prípadne manipuláciu s
            Tovarom (napr. vynáška Tovaru a pod.)
          </p>
          <p>
            <strong>Kúpna zmluva </strong>
            Zmluva o kúpe Tovaru uzatvorená medzi Predávajúcim a Spotrebiteľom v
            súlade s odsekom 10 a 11 článku IV týchto VOP
          </p>
          <p>
            <strong>VOP </strong>
            tieto všeobecné obchodné podmienky
          </p>
          <p>
            <strong>Zmluvné strany </strong>
            Predávajúci a Spotrebiteľ
          </p>
        </section>
        <section>
          <h4>Článok II</h4>
          <strong>Všeobecné ustanovenia</strong>
          <p>1. Tieto VOP upravujú:</p>
          <ol>
            <li>
              proces nákupu Tovaru Spotrebiteľom prostredníctvom E-shopu;
              podmienky predaja a nákupu Tovaru prostredníctvom E-shopu;
            </li>
            <li>
              práva a povinnosti zmluvných strán, vyplývajúce z kúpnej zmluvy
              uzatvorenej medzi týmito stranami, ktorej predmetom je kúpa a
              predaj Tovaru prostredníctvom E-shopu Predávajúceho.
            </li>
          </ol>
          <p>
            2. Tieto VOP sú platné pre všetky dodávky Tovaru Spotrebiteľovi
            realizované Predávajúcim prostredníctvom E-shopu Predávajúceho na
            základe Objednávky a sú neoddeliteľnou súčasťou kúpnej zmluvy,
            uzatvorenej medzi Predávajúcim a Spotrebiteľom.
          </p>
        </section>
        <section>
          <h4>Článok III</h4>
          <strong>Tovar</strong>
          <ol>
            <li>Sortiment Tovaru je uvedený v ponuke E-shopu.</li>
            <li>
              Súčasťou ponuky každého Tovaru je názov Tovaru, popis vlastností
              daného Tovaru, vrátane veľkosti balenia Tovaru, údaj o Cene v EUR,
              údaj o dostupnosti Tovaru na sklade a dĺžka dodacej doby.
            </li>
            <li>
              Ceny uvedené pri Tovaroch v E-shope platia výhradne pre nákup
              prostredníctvom E-shopu.
            </li>
          </ol>
        </section>
        <section>
          <h4>Článok IV</h4>
          <strong>Objednávka a uzavretie zmluvy</strong>
          <ol>
            <li>
              Spotrebiteľ si objednáva Tovar u Predávajúceho cez E-shop
              umiestnený pod doménou{" "}
              <a href="http://www.broleit.com">www.broleit.com</a> a to podľa
              pokynov uvedených v E-shope.
            </li>
            <li>
              Nákup Tovaru v E-shope je podmienený registráciou v E-shope.
            </li>
            <li>
              V prípade, ak sa Spotrebiteľ rozhodne registrovať v E-shope, po
              úspešnej registrácii bude mať Spotrebiteľ prístup k svojmu kontu,
              prostredníctvom ktorého sa bude pri nákupoch prihlasovať v
              E-shope. Pri každom ďalšom nákupe sa Spotrebiteľ už neregistruje,
              stačí, aby sa pomocou svojich prihlasovacích údajov prihlásil k
              svojmu kontu v E-shope.
            </li>
            <li>
              Po stlačení tlačidla „DO KOŠÍKA“ pri danom Tovare sa objednaný
              Tovar automaticky pridá do nákupného košíka daného Spotrebiteľa.
              Nákupný košík daného Spotrebiteľa je tomuto Spotrebiteľovi
              kedykoľvek počas vytvárania Objednávky k dispozícii na
              nahliadnutie.
            </li>
            <li>
              V náhľade nákupného košíka si Spotrebiteľ zároveň zvolí spôsob
              dopravy Tovaru podľa možností, ktoré ponúka Predávajúci.
            </li>
            <li>
              K cenám za jednotlivé Tovary sa pripočíta aj cena za Dopravné
              podľa zvoleného druhu dopravy, ak Predávajúci umožňuje dopraviť
              Tovar viacerými spôsobmi.
            </li>
            <li>
              Formulár Objednávky obsahuje:
              <ol type="a">
                <li>
                  Identifikačné údaje Spotrebiteľa: meno a priezvisko, dodaciu a
                  fakturačnú adresu, email, telefónne číslo, náležitosti
                  súvisiace so spracovaním osobných údajov a oboznámenie sa s
                  VOP
                </li>
                <li>
                  Identifikačné údaje o objednávanom Tovare: názov, počet
                  balení;
                </li>
                <li>Spôsob platby a dopravy;</li>
                <li>
                  Cenu tovaru (vrátane nákladov na dopravu, dodanie, poštovné)
                </li>
              </ol>
            </li>
            <li>
              Objednávku Spotrebiteľ dokončí stlačením tlačidla „Objednať
              záväzne“. Predtým je Spotrebiteľ povinný sa oboznámiť s týmito VOP
              a toto oboznámenie potvrdiť zakliknutím tlačidla „Potvrdzujem, že
              som sa oboznámil/a so Všeobecnými obchodnými podmienkami“.
            </li>
            <li>
              Odoslaním Objednávky, t.j. stlačením tlačidla „Objednať záväzne“
              Spotrebiteľ zároveň potvrdzuje, že sa oboznámil/bol Predávajúcim
              informovaný aj o:
              <ol>
                <li>vlastnostiach Tovaru,</li>
                <li>
                  celkovej Cene, ktorú je Spotrebiteľ povinný Predávajúcemu
                  uhradiť.
                </li>
              </ol>
            </li>
            <li>
              Po vytvorení Objednávky bude táto Objednávka zaregistrovaná v
              systéme E-shopu. Predávajúci zašle na e-mailovú adresu
              Spotrebiteľa, ktorú tento uviedol pri registrácii alebo v
              Objednávke, nasledovné:
              <ol type="a">
                <li>
                  potvrdenie Objednávky s informáciami o Objednávke podľa odseku
                  7 tohto článku,
                </li>
                <li>
                  znenie VOP, ktoré je platné a účinné v čase vytvorenia
                  Objednávky Spotrebiteľom, vrátane reklamačného poriadku
                  Predávajúceho, ktorý je súčasťou týchto VOP.
                </li>
              </ol>
            </li>
            <li>
              Doručením potvrdenia o prijatí Objednávky podľa bodu 10 tohto
              článku VOP spolu s ostatnými dokumentmi Spotrebiteľovi sa zmluva o
              kúpe Tovaru považuje za uzavretú, pričom jej predmetom je dodanie
              v Objednávke špecifikovaného Tovaru za tam uvedenú cenu, a to
              všetko za podmienok, ktoré sú obsiahnuté v dokumentoch podľa bodu
              10 tohto článku VOP.
            </li>
          </ol>
        </section>
        <section>
          <h4>Článok V</h4>
          <strong>Platobné a dodacie podmienky </strong>
          <ol>
            <li>
              Všetky ceny Tovarov v Objednávke aj v E-shope sú uvedené s DPH,
              Cenu podľa Objednávky môže Spotrebiteľ uhradiť:
              <ol>
                <li>platobnou bránou GoPay,</li>
                <li>bankovým prevodom, alebo</li>
                <li>priamym vkladom v banke na účet Predávajúceho,</li>
                <li>
                  dobierkou, čiže odovzdaním hotovosti doručovateľovi pri
                  preberaní Tovaru.
                </li>
              </ol>
            </li>
            <li>
              Za úhradu Ceny si Predávajúci neúčtuje žiaden poplatok.
              Predávajúci zároveň upozornil Spotrebiteľa, že daný subjekt, u
              ktorého je platba Ceny realizovaná, si môže účtovať poplatok za
              úhradu (napr. daná banka pri vklade na účet).
            </li>
            <li>
              Úhradou sa rozumie moment pripísania Ceny na účet Predávajúceho.
            </li>
            <li>
              Predávajúci sa zaväzuje dodať objednaný Tovar Spotrebiteľovi v
              lehote do 30 dní od uhradenia Ceny Objednávky alebo do 30 dní od
              uzavretia Kúpnej zmluvy, ak je zvolená úhrada Ceny dobierkou.
            </li>
            <li>
              Predávajúci dodá Spotrebiteľovi objednaný Tovar na adresu, ktorú
              Spotrebiteľ uviedol ako dodaciu adresu v Objednávke.
            </li>
          </ol>
        </section>
        <section>
          <h4>Článok VI</h4>
          <strong>Poučenie o práve Spotrebiteľa odstúpiť od zmluvy</strong>
          <ol>
            <li>
              Ak nie je v odseku 9 tohto článku VOP uvedené inak, Spotrebiteľ má
              právo odstúpiť od Kúpnej zmluvy bez uvedenia dôvodu do 14
              kalendárnych dní odo dňa prevzatia Tovaru, pričom táto lehota sa
              považuje za zachovanú, ak oznámenie o odstúpení od zmluvy bolo
              odoslané Predávajúcemu najneskôr v posledný deň tejto lehoty.
              Tovar sa považuje za prevzatý Spotrebiteľom momentom, keď
              Spotrebiteľ alebo ním určená tretia osoba, s výnimkou dopravcu,
              prevezme všetky časti objednaného Tovaru alebo ak sa:
              <ol>
                <li>
                  viaceré Tovary objednané Spotrebiteľom v jednej Objednávke
                  dodávajú oddelene, okamihom prevzatia Tovaru, ktorý bol dodaný
                  ako posledný,
                </li>
                <li>
                  dodáva Tovar pozostávajúci z viacerých dielov alebo kusov,
                  okamihom prevzatia posledného dielu alebo posledného kusu,
                </li>
                <li>
                  Tovar dodáva opakovane počas vymedzeného obdobia, okamihom
                  prevzatia prvého dodaného Tovaru.
                </li>
              </ol>
            </li>
            <li>
              Ak nie je v odseku 9 tohto článku VOP uvedené inak, Spotrebiteľ
              môže odstúpiť od zmluvy aj pred začatím plynutia lehoty na
              odstúpenie podľa odseku 1 tohto článku VOP.
            </li>
            <li>
              Spotrebiteľ svoje právo odstúpiť od zmluvy podľa odseku 1 tohto
              článku VOP uplatňuje nasledovne:
              <ol>
                <li>
                  písomne poštovou zásielkou adresovanou na adresu sídla
                  Predávajúceho uvedeného vyššie alebo
                </li>
                <li>
                  písomne e-mailom na adrese{" "}
                  <a
                    href="mailto:info@broleit.com"
                    style={{ textDecoration: "underline" }}
                  >
                    info@broleit.com
                  </a>{" "}
                </li>
              </ol>
            </li>
            <li>
              Spotrebiteľ je na odstúpenie od zmluvy podľa odseku 1 alebo 2
              tohto článku VOP oprávnený použiť formulár, ktorý tvorí{" "}
              <a
                href="#contract-cancel"
                style={{ textDecoration: "underline" }}
              >
                prílohu č. 2 týchto VOP
              </a>{" "}
              . V prípade, ak Spotrebiteľ svoje právo odstúpiť od zmluvy podľa
              odseku 1 alebo 2 tohto článku VOP uplatní, vráti Tovar
              Predávajúcemu, a to zaslaním na adresu sídla Predávajúceho uvedenú
              v týchto VOP najneskôr do 14 dní odo dňa, keď svoje právo odstúpiť
              od zmluvy uplatnil alebo osobne odovzdaním Predávajúcemu na adrese
              sídla Predávajúceho uvedenej v týchto VOP v rovnakej lehote.
            </li>
            <li>
              Pri odstúpení od zmluvy podľa odseku 1 alebo 2 tohto článku VOP
              znáša náklady na vrátenie Tovaru Spotrebiteľ, a to vrátane
              nákladov na vrátenie Tovaru, ktorý nie je možné vzhľadom na jeho
              povahu vrátiť prostredníctvom pošty.
            </li>
            <li>
              Vrátený Tovar musí byť úplný, nesmie byť poškodený a musí byť
              zaslaný Predávajúcemu s dokladom o kúpe, s kompletným
              príslušenstvom, dokumentáciou, obalom atď. Za zníženie hodnoty
              Tovaru, ktoré nevzniklo bežným opotrebením počas používania v
              lehote na odstúpenie podľa odseku 1 tohto článku VOP, zodpovedá
              Spotrebiteľ. Za zníženie hodnoty Tovaru sa považuje okrem iného aj
              čiastočné spotrebovanie Tovaru.
            </li>
            <li>
              Predávajúci v lehote do 14 dní odo dňa doručenia odstúpenia
              Spotrebiteľa podľa odseku 1 tohto článku VOP vráti Spotrebiteľovi
              platby, ktoré s odstúpením od zmluvy súvisia a ktoré od neho
              prijal na základe zmluvy alebo v súvislosti s ňou, vrátane
              nákladov na dopravu, dodanie a poštovné a iných nákladov a
              poplatkov, pričom však Predávajúci nie je povinný vrátiť
              Spotrebiteľovi tieto platby skôr, ako mu je doručený Tovar alebo
              ako mu Spotrebiteľ preukáže, že mu zaslal Tovar späť. Predávajúci
              vráti Spotrebiteľovi platbu podľa predchádzajúcej vety rovnakým
              spôsobom, aký bol použitý Spotrebiteľom pri platbe za Tovar,
              pokiaľ sa Spotrebiteľ s Predávajúcim nedohodnú inak. Náklady na
              dopravu, dodanie a poštovné však Predávajúci zákazníkovi hradí len
              v rozsahu najlacnejšieho bežného spôsobu doručenia, ktorý je
              ponúkaný Predávajúcim, bez ohľadu na to, aký spôsob doručenia si
              zvolil Spotrebiteľ.
            </li>
            <li>
              Spotrebiteľ nemôže odstúpiť od zmluvy, ktorej predmetom je:
              <ol>
                <li>
                  predaj tovaru, ktorý podlieha rýchlemu zníženiu akosti alebo
                  skaze;
                </li>
                <li>
                  predaj tovaru uzavretého v ochrannom obale, ktorý nie je
                  vhodné vrátiť z dôvodu ochrany zdravia alebo z hygienických
                  dôvodov a ktorého ochranný obal bol po dodaní porušený;
                </li>
                <li>
                  predaj tovaru, ktorý môže byť vzhľadom na svoju povahu po
                  dodaní neoddeliteľne zmiešaný s iným tovarom.
                </li>
              </ol>
            </li>
            <li>
              Poučenie o uplatnení práva Spotrebiteľa na odstúpenie od zmluvy je
              uvedené v{" "}
              <a
                href="#contract-cancel"
                style={{ textDecoration: "underline" }}
              >
                Prílohe č. 2 týchto VOP
              </a>
              .
            </li>
          </ol>
        </section>
        <section>
          <h4>Článok VII</h4>
          <strong>
            Nadobudnutie vlastníctva a prechod nebezpečenstva škody na Tovare
          </strong>
          <p>
            1. Spotrebiteľ nadobúda vlastnícke právo k Tovaru až úplným
            zaplatením celej Ceny.
          </p>
          <p>
            2. Nebezpečenstvo škody na Tovare prechádza na Spotrebiteľa v čase,
            keď prevezme Tovar od Predávajúceho alebo keď tak neurobí včas, tak
            v čase, keď mu Predávajúci umožní nakladať s Tovarom a Spotrebiteľ
            Tovar neprevezme.
          </p>
        </section>
        <section>
          <h4>Článok VIII</h4>
          <strong>
            Reklamačný poriadok (zodpovednosť za vady, záruka, reklamácie)
          </strong>
          <ol>
            <li>
              Pri preberaní Tovaru je Spotrebiteľ povinný skontrolovať:
              <ul>
                <li>či mu bol dodaný Tovar v súlade s Objednávkou,</li>
                <li>
                  či mu bol Tovar dodaný v množstve v súlade s Objednávkou,
                </li>
                <li>či je Tovar alebo jeho obal poškodený.</li>
              </ul>
            </li>
            <li>
              V prípade, že bol Spotrebiteľovi dodaný Tovar, ktorý si
              neobjednal, je Spotrebiteľ povinný ihneď, najneskôr do 24 hodín
              e-mailom kontaktovať Predávajúceho. V takom prípade je Spotrebiteľ
              oprávnený Tovar neprevziať a s osobou, ktorá Tovar dodala, spísať
              Zápis o neprijatí zásielky.
            </li>
            <li>
              V prípade, ak bol Spotrebiteľovi dodaný Tovar, ktorý javí známky
              poškodenia, zjavných vád, prípadne v menšom množstve, než si
              Spotrebiteľ objednal, je Spotrebiteľ povinný Tovar prevziať, avšak
              uviesť tieto skutočnosti v dodacom liste. Spotrebiteľ je povinný
              takýto dodací list ihneď zaslať Predávajúcemu a uplatňovať si
              nároky z vád. Neskoršie reklamácie týkajúce sa poškodenosti,
              zničenosti alebo množstva Tovaru pri jeho dodaní nebudú
              akceptované. V prípade, ak Spotrebiteľ odmietne prevziať Tovar
              podľa tohto odseku týchto VOP, je Predávajúci oprávnený od Kúpnej
              zmluvy odstúpiť.
            </li>
            <li>
              Spotrebiteľ môže Tovar reklamovať e-mailom na adrese:{" "}
              <a
                href="mailto:info@broleit.com"
                style={{ textDecoration: "underline" }}
              >
                info@broleit.com
              </a>{" "}
              alebo poštou na adrese aktuálneho sídla Predávajúceho, a to spolu
              s dokladmi ako dodací list, faktúra.
            </li>
            V reklamácii je Spotrebiteľ povinný uviesť:
            <ul>
              <li>svoje identifikačné údaje,</li>
              <li>údaje Predávajúceho,</li>
              <li>opis Tovaru, ktorý Spotrebiteľ reklamuje,</li>
              <li>popis vady Tovaru</li>
              <li>číslo Objednávky, ktorej sa reklamácia týka.</li>
            </ul>
            <li>
              Ak je reklamácia uplatnená prostredníctvom prostriedkov diaľkovej
              komunikácie, Predávajúci je povinný potvrdenie o uplatnení
              reklamácie doručiť Spotrebiteľovi ihneď, ak nie je možné
              potvrdenie doručiť ihneď, musí sa doručiť bez zbytočného odkladu,
              najneskôr však spolu s dokladom o vybavení reklamácie.
            </li>
            <li>
              Záručná doba začne plynúť dňom, kedy Spotrebiteľ Tovar prevzal.
              Záručná doba je 24 mesiacov, pokiaľ nie je v dokladoch viažucich
              sa k Tovaru uvedené inak. Ak je na Tovare, jeho obale alebo návode
              k nemu pripojenom vyznačená dlhšia doba, neskončí sa záručná doba
              pred uplynutím tejto doby. Práva zo zodpovednosti za vady sa
              uplatňujú u Predávajúceho spôsobom ďalej uvedeným.
            </li>
            <li>
              Záruka sa nevzťahuje na poškodenie Tovaru vzniknuté, okrem iného,
              najmä:
              <ul>
                <li>prirodzeným alebo nadmerným mechanickým opotrebením,</li>
                <li>
                  znečistením Tovaru alebo jeho častí v dôsledku zanedbania
                  údržby, zanedbaním starostlivosti o Tovar,
                </li>
                <li>
                  používaním Tovaru v podmienkach, ktoré nezodpovedajú svojou
                  teplotou, prašnosťou, vlhkosťou, chemickými a mechanickými
                  vplyvmi prostredia, v ktorom sa Tovar bežne používa,
                </li>
                <li>
                  vonkajšími vplyvmi, napr. pádom alebo nárazom, vniknutím vody,
                  ohňom,
                </li>
                <li>
                  zásahom do Tovaru neoprávnenou osobou (neodborné opravy,
                  montáž alebo úpravy),
                </li>
                <li>
                  pri používaní Tovaru v rozpore s návodom, technickými normami,
                  inou dokumentáciou k Tovaru či s účelom, na ktorý je Tovar
                  určený,
                </li>
                <li>
                  mechanickým poškodením, najmä roztrhnutý, prerezaný, tepelne
                  poškodený Tovar, Tovar poškodený neopatrným neúmerným fyzickým
                  zaobchádzaním, zámerným poškriabaním vrstiev Tovaru a pod.,
                </li>
                <li>neoznámením zjavných vád pri prevzatí Tovaru,</li>
                <li>po uplynutí záručnej doby.</li>
              </ul>
            </li>
            <li>
              Záruka sa tiež nevzťahuje na škody vzniknuté v dôsledku živelnej
              udalosti, živelnej katastrofy, násilného poškodenia,
              poveternostných podmienok alebo prevádzkou v extrémnych a
              neobvyklých podmienkach.
            </li>
            <li>
              Predávajúci zodpovedá za vady, ktoré má predaná vec pri prevzatí
              Spotrebiteľom. Pri použitých veciach nezodpovedá za vady vzniknuté
              ich použitím alebo opotrebením. Pri veciach predávaných za nižšiu
              cenu nezodpovedá za vadu, pre ktorú bola dojednaná nižšia cena.
            </li>
            <li>
              Ak nejde o veci, ktoré sa rýchlo kazia, alebo o použité veci,
              zodpovedá Predávajúci za vady, ktoré sa vyskytnú po prevzatí veci
              v záručnej dobe (záruka).
            </li>
            <li>
              Predávajúci je povinný určiť spôsob vybavenia reklamácie ihneď, v
              zložitejších prípadoch do 3 dní od začiatku reklamačného konania,
              v odôvodnených prípadoch, najmä ak sa vyžaduje zložité technické
              zhodnotenie stavu Tovaru, najneskôr do 30 dní odo dňa začiatku
              reklamačného konania. Oznámenie určenia spôsobu vybavenia
              reklamácie je možné zo strany Predávajúceho vykonať aj formou
              e-mailového oznámenia na e-mail, ktorý Spotrebiteľ uviedol pri
              registrácii. Po určení spôsobu vybavenia reklamácie Predávajúci
              reklamáciu vybaví ihneď, v odôvodnených prípadoch možno reklamáciu
              vybaviť aj neskôr. Vybavenie reklamácie však nesmie trvať dlhšie
              ako 30 dní odo dňa uplatnenia reklamácie. Po márnom uplynutí
              lehoty na vybavenie reklamácie má Spotrebiteľ právo od Kúpnej
              zmluvy odstúpiť.
            </li>
            <li>
              O ukončení reklamačného konania a výsledku reklamácie informuje
              Predávajúci Spotrebiteľa e-mailom alebo doporučeným listom. Ak
              Spotrebiteľ reklamáciu Tovaru uplatnil počas prvých 12 mesiacov od
              uzavretia Kúpnej zmluvy, môže Predávajúci vybaviť reklamáciu
              zamietnutím len na základe vyjadrenia znalca alebo stanoviska
              vydaného autorizovanou, notifikovanou alebo akreditovanou osobou,
              alebo stanoviska určenej osoby (ďalej len „odborné posúdenie
              Tovaru“). Bez ohľadu na výsledok odborného posúdenia nemôže
              Predávajúci od Spotrebiteľa vyžadovať úhradu nákladov na odborné
              posúdenie Tovaru ani iné náklady súvisiace s odborným posúdením
              Tovaru.
            </li>
            <li>
              Odborné posúdenie Tovaru musí obsahovať:
              <ol type="a">
                <li>identifikáciu osoby, ktorá vykonáva odborné posúdenie,</li>
                <li>presnú identifikáciu posudzovaného výrobku, </li>
                <li>popis stavu výrobku,</li>
                <li>výsledok posúdenia,</li>
                <li>dátum vyhotovenia odborného posúdenia.</li>
              </ol>
            </li>
            <li>
              Predávajúci je povinný poskytnúť Spotrebiteľovi kópiu odborného
              posúdenia odôvodňujúceho zamietnutie reklamácie najneskôr do 14
              dní odo dňa vybavenia reklamácie.{" "}
            </li>
            <li>
              <p>
                Ak Spotrebiteľ reklamáciu výrobku uplatnil po 12 mesiacoch od
                uzavretia zmluvy a Predávajúci ju zamietol, je povinný v doklade
                o vybavení reklamácie uviesť, komu môže Spotrebiteľ zaslať Tovar
                na odborné posúdenie. Ak Spotrebiteľ Tovar zašle na odborné
                posúdenie určenej osobe uvedenej v doklade o vybavení
                reklamácie, náklady odborného posúdenia Tovaru, ako aj všetky
                ostatné s tým súvisiace účelne vynaložené náklady znáša
                Predávajúci bez ohľadu na výsledok odborného posúdenia.{" "}
              </p>
              <p>
                Ak Spotrebiteľ odborným posúdením od Predávajúcim určenej osoby
                preukáže zodpovednosť Predávajúceho za reklamovanú vadu Tovaru,
                môže reklamáciu uplatniť znova; počas vykonávania odborného
                posúdenia Tovaru záručná doba neplynie. Predávajúci je povinný
                Spotrebiteľovi uhradiť do 14 dní odo dňa znova uplatnenej
                reklamácie všetky náklady vynaložené na odborné posúdenie
                Tovaru, ako aj všetky s tým súvisiace účelne vynaložené náklady.
                Znova uplatnenú reklamáciu nemožno zamietnuť.
              </p>
            </li>
            <li>
              <p>
                Ak ide o vadu, ktorú možno odstrániť, má Spotrebiteľ právo, aby
                bola bezplatne, včas a riadne odstránená. Predávajúci môže vždy
                namiesto odstránenia vady vymeniť vadný Tovar za bezvadný. Ak
                ide o vadu, ktorú nemožno odstrániť a ktorá bráni tomu, aby sa
                Tovar mohol riadne užívať ako bez vady, má Spotrebiteľ právo na
                výmenu Tovaru alebo má právo od zmluvy odstúpiť. Tie isté práva
                prislúchajú Spotrebiteľovi, ak ide síce o odstrániteľné vady, ak
                však{" "}
              </p>
              <p>
                Spotrebiteľ nemôže pre opätovné vyskytnutie sa vady po oprave
                alebo pre väčší počet vád Tovar riadne užívať. Za väčší počet
                vád sa považujú aspoň tri vady a za opätovný výskyt vady
                vyskytnutie tej istej vady po jej aspoň dvoch predchádzajúcich
                opravách.{" "}
              </p>
            </li>
            <li>
              Vybavením oprávnenej reklamácie sa záručná doba predlžuje o dobu
              trvania reklamácie. Ak bola reklamácia v zákonnej záručnej lehote
              vybavená výmenou Tovaru za nový, potom záručná doba začne plynúť
              znova od dátumu vybavenia reklamácie.
            </li>
            <li>
              Predávajúci nevydal žiadne ďalšie kódexy správania, ktoré by mal
              dodržiavať, okrem týchto VOP.
            </li>
          </ol>
        </section>
        <section>
          <h4>Článok IX</h4>
          <strong>Alternatívne riešenie spotrebiteľských sporov </strong>
          <p>
            Alternatívne riešenie spotrebiteľských sporov (ARS) je postup
            zameraný na dosiahnutie zmierlivého vyriešenia sporu medzi
            spotrebiteľom apodnikateľom (obchodníkom) ana predchádzanie vzniku
            súdnych sporov medzi týmito dvoma subjektmi. V prípade, ak je
            Spotrebiteľ nespokojný s výsledkom svojej žiadosti o nápravu a pokus
            o vyriešenie sporu s Predávajúcim bol bezvýsledný, má právo podať
            návrh príslušnému subjektu alternatívneho riešenia sporov, ktorým je
            v tomto prípade napr. Slovenská obchodná inšpekcia. Slovenská
            obchodná inšpekcia má k dispozícii všetky potrebné informácie o
            alternatívnom postupe, ktorým môže Zákazník prípadný spor riešiť.
            Viac informácií oalternatívnom riešení spotrebiteľských sporov
            nájdete na internetovej stránke Slovenskej obchodnej inšpekcie:
            <a
              href="http://www.soi.sk/sk/Alternativne-riesenie-spotrebitelskych-sporov.soi"
              style={{ textDecoration: "underline" }}
            >
              soi - alternatívne riešenie sporov
            </a>
          </p>
        </section>
        <section>
          <h4>Článok X</h4>
          <strong>Záverečné ustanovenia</strong>
          <ol>
            <li>
              Na vzťahy neupravené Objednávkou a týmito VOP sa vzťahujú
              príslušné ustanovenia zákona č. 102/2014 Z. z. o ochrane
              spotrebiteľa pri predaji tovaru alebo poskytovaní služieb na
              základe zmluvy uzavretej na diaľku alebo zmluvy uzavretej mimo
              prevádzkových priestorov predávajúceho a o zmene a doplnení
              niektorých zákonov v platnom znení, zákona č. 250/2007 Z. z. o
              ochrane spotrebiteľa a o zmene zákona Slovenskej národnej rady č.
              372/1990 Zb. o priestupkoch v znení neskorších predpisov v platnom
              znení, zákona č. 22/2004 Z. z. o elektronickom obchode a o zmene a
              doplnení zákona č. 128/2002 Z. z. o štátnej kontrole vnútorného
              trhu vo veciach ochrany spotrebiteľa a o zmene a doplnení
              niektorých zákonov v platnom znení a zákona č. 40/1964 Zb.
              Občiansky zákonník.
            </li>
            <li>
              Spotrebiteľ vyhlasuje, že sa oboznámil s týmito VOP a zaväzuje sa
              ich dodržiavať.{" "}
            </li>
          </ol>
          <p>Tieto VOP nadobúdajú platnosť a účinnosť dňa 08.01.2024. </p>
        </section>
        <section>
          <h4>
            Poučenie o uplatnení práva spotrebiteľa na odstúpenie od zmluvy
          </h4>
          <ol>
            <li>
              <strong>Právo na odstúpenie od zmluvy</strong>
            </li>
            <ol>
              <li>
                Máte právo odstúpiť od tejto zmluvy bez uvedenia dôvodu v lehote
                14 dní.
              </li>
              <li>
                Lehota na odstúpenie od zmluvy uplynie po 14 dňoch odo dňa:
                <ol type="a">
                  <li>
                    keď Vy alebo Vami určená tretia osoba s výnimkou dopravcu
                    prevezmete tovar;
                  </li>
                  <li>
                    ak sa tovary objednané spotrebiteľom v jednej objednávke
                    dodávajú oddelene, keď Vy alebo Vami určená tretia osoba s
                    výnimkou dopravcu prevezmete tovar, ktorý bol dodaný ako
                    posledný;
                  </li>
                  <li>
                    ak sa dodáva tovar pozostávajúci z viacerých dielov alebo
                    kusov, keď Vy alebo Vami určená tretia osoba s výnimkou
                    dopravcu prevezmete posledný diel alebo kus;
                  </li>
                  <li>
                    ak sa tovar dodáva opakovane počas vymedzeného obdobia, keď
                    Vy alebo Vami určená tretia osoba s výnimkou dopravcu
                    prevezmete prvý dodaný tovar.
                  </li>
                </ol>
              </li>
              <li>
                Pri uplatnení práva na odstúpenie od zmluvy nás informujte o
                svojom rozhodnutí odstúpiť od tejto zmluvy jednoznačným
                vyhlásením (listom zaslaným poštou alebo e-mailom) na adrese:
                <ol type="a">
                  <li>
                    v prípade e-mailu na adrese{" "}
                    <a
                      href="mailto:info@broleit.com"
                      style={{ textDecoration: "underline" }}
                    >
                      info@broleit.com
                    </a>
                  </li>
                  <li>
                    v prípade listu na adrese sídla Predávajúceho, t. j. na
                    adrese Poruba 405, 972 11 Poruba
                  </li>
                </ol>
                Na tento účel môžete použiť vzorový formulár na odstúpenie od
                zmluvy, ktorý je uvedený v{" "}
                <a
                  href="#contract-cancel"
                  style={{ textDecoration: "underline" }}
                >
                  prílohe č. 2 týchto VOP
                </a>
                .
              </li>
              <li>
                Lehota na odstúpenie od zmluvy je zachovaná, ak zašlete
                oznámenie o uplatnení práva na odstúpenie od zmluvy pred
                uplynutím lehoty na odstúpenie od zmluvy.
              </li>
            </ol>

            <li>
              <strong>Dôsledky odstúpenia od zmluvy</strong>{" "}
            </li>
            <ol>
              <li>
                Po odstúpení od zmluvy vám vrátime všetky platby, ktoré ste
                uhradili v súvislosti s uzavretím zmluvy, najmä kúpnu cenu
                vrátane nákladov na doručenie tovaru k Vám. To sa nevzťahuje na
                dodatočné náklady, ak ste si zvolili iný druh doručenia, ako je
                najlacnejší bežný spôsob doručenia, ktorý ponúkame. Platby Vám
                budú vrátené bez zbytočného odkladu, najneskôr do 14 dní odo
                dňa, keď nám bude doručené Vaše oznámenie o odstúpení od tejto
                zmluvy. Ich úhrada bude uskutočnená rovnakým spôsobom, aký ste
                použili pri Vašej platbe, ak ste výslovne nesúhlasili s iným
                spôsobom platby, a to bez účtovania akýchkoľvek ďalších
                poplatkov.
              </li>
              <li>
                <p>
                  Platba za zakúpený tovar Vám bude uhradená až po doručení
                  vráteného tovaru späť na našu adresu alebo po predložení
                  dokladu preukazujúceho zaslanie tovaru späť.
                </p>
                <p>
                  Najneskôr do 14 dní odo dňa uplatnenia práva na odstúpenie od
                  zmluvy. Lehota sa považuje za zachovanú, ak tovar odošlete
                  späť pred uplynutím 14- dňovej lehoty.
                </p>
              </li>
              <li>Zašlite nám tovar späť alebo ho prineste na našu adresu.</li>
              <li>Priame náklady na vrátenie tovaru znášate Vy.</li>
              <li>
                Zodpovedáte za akékoľvek zníženie hodnoty tovaru v dôsledku
                zaobchádzania s ním iným spôsobom, než je potrebný na zistenie
                povahy, vlastností a funkčnosti tovaru.
              </li>
            </ol>
          </ol>
        </section>
        <section>
          <div>
            <div id="contract-cancel" style={{ fontWeight: "bold" }}>
              <a
                style={{
                  color: "#3a3432",
                  textDecoration: "underline",
                }}
                href="https://dl.dropbox.com/scl/fi/63c2cwi6j60epudma5fft/PR-LOHA-.-1-FORMUL-R-PRE-REKLAM-CIU.pdf?rlkey=blcise0lsdzlohkw6dkcjr9fh&st=ijtwpl5r&dl=1"
                download
              >
                Príloha číslo 1 - FORMULÁR PRE REKLAMÁCIU
              </a>
            </div>
            <div style={{ fontWeight: "bold" }}>
              <a
                style={{
                  color: "#3a3432",
                  textDecoration: "underline",
                }}
                href="https://dl.dropbox.com/scl/fi/ura0vid25zaxgos0s4g7y/PR-LOHA-.-2-FORMUL-R-PRR-ODST-PENIE-OD-ZMLUVY.pdf?rlkey=rfmepbjz4ks49cd14ai7fy39s&st=qs45fh2q&dl=1"
                download
              >
                Príloha číslo 2 - FORMULÁR PRE ODSTÚPENIE OD ZMLUVY
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default PurchaseConditions;
