import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";

function ReviewForm({
  reviewInfo,
  setStarsOfReview,
  setRestOfReviewInfo,
  manageReview,
  alertStarsFromServer,
  alertNameFromServer,
  alertCommentFromServer,
}) {
  const [alertStars, setAlretStarts] = useState(false);
  const [alertName, setAlretName] = useState(false);
  const [alertComment, setAlertComment] = useState(false);

  const checkFilledForm = () => {
    let isFormFilled = true;
    if (reviewInfo.stars === 0) {
      setAlretStarts(true);
      isFormFilled = false;
    } else {
      setAlretStarts(false);
    }
    if (reviewInfo.name === "") {
      setAlretName(true);
      isFormFilled = false;
    } else {
      setAlretName(false);
    }
    if (reviewInfo.comment === "") {
      setAlertComment(true);
      isFormFilled = false;
    } else {
      setAlertComment(false);
    }
    return isFormFilled;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (checkFilledForm()) {
      manageReview();
      localStorage.setItem("reviewChanged", JSON.stringify({ changed: true }));
    }
  };

  const ratingChanged = (newRating) => {
    setStarsOfReview(newRating);
  };

  const handleChange = (event) => {
    setRestOfReviewInfo(event);
  };

  return (
    <form onSubmit={handleSubmit} className="mt-5 review-form">
      <div className="flex justify-content-between">
        <div>
          <h3 className="mb-2">Napísať recenziu</h3>
        </div>
      </div>

      <ReactStars
        count={5}
        size={27}
        value={parseFloat(reviewInfo.stars)}
        edit={true}
        isHalf={true}
        onChange={ratingChanged}
        color="#9e918c"
        activeColor="#3a3432"
        classNames="mb-1"
      />

      {(alertStars || alertStarsFromServer) && (
        <div className="alert mb-2" style={{ fontSize: "15px", width: "100%" }}>
          Je potrebné zadať počet hviezd
        </div>
      )}

      <div className="border-input">
        <input
          type="text"
          name="name"
          className={alertName ? "mb-2" : "mb-3"}
          value={reviewInfo.name}
          onChange={handleChange}
          placeholder="Meno"
        />
      </div>
      {(alertName || alertNameFromServer) && (
        <div className="alert mb-2" style={{ fontSize: "15px", width: "100%" }}>
          Je potrebné zadať meno
        </div>
      )}

      <div>
        <textarea
          type="text"
          name="comment"
          value={reviewInfo.comment}
          onChange={handleChange}
        />
      </div>
      {(alertComment || alertCommentFromServer) && (
        <div className="alert mt-1" style={{ fontSize: "15px", width: "100%" }}>
          Je potrebné zadať popis k recenzii
        </div>
      )}
      <button className="btn dark p-2 mt-3" style={{ width: "100%" }}>
        Odoslať
      </button>
    </form>
  );
}

export default ReviewForm;
