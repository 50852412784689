import React, { useState, useEffect, useContext, useRef } from "react";
import { UserInfoContext } from "../UserInfo";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import AuthContainter from "../Auth/AuthContainter";
import { GoogleOAuthProvider } from "@react-oauth/google";
const icons = require("../../icons.json").icons;

function ShoppingCart() {
  const toast = useRef(null);
  const [initial, setInitial] = useState(true);
  const [items, setItems] = useState(null);
  const [giftCart, setGifCart] = useState("");
  const {
    updateItemsCount,
    isLogedIn,
    updateLoadingState,
    loading,
    setLogInExpiration,
  } = useContext(UserInfoContext);
  const [amounAlert, setAmountAlert] = useState([]);
  const [loadDiscount, setLoadDiscount] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [deletedProductInShoppingCart, setDeletedProductInShoppingCart] =
    useState(false);
  const navigate = useNavigate();
  const [displayLogin, setDisplayLogin] = useState(false);

  const checkItemsAmount = (itemsToCheck) => {
    itemsToCheck.map((item, index) => {
      if (item.amount > item.maxAmount) {
        setAmountAlert((prevState) => {
          prevState[index] = "Zadané množstvo nie je dostupné skladom";

          return prevState;
        });
      } else if (item.amount === 0) {
        setAmountAlert((prevState) => {
          prevState[index] = "Prosím zadajte množstvo";

          return prevState;
        });
      } else if (item.maxAmount === 0) {
        setAmountAlert((prevState) => {
          prevState[index] = "Produkt už nie je dostupný";
          return prevState;
        });
      } else {
        setAmountAlert((prevState) => {
          prevState[index] = "";
          return prevState;
        });
      }
    });
  };

  const handleStorageChange = (event) => {
    if (event.key === "shoppingCart") {
      if (localStorage.getItem("shoppingCart")) {
        checkItemsAmount(JSON.parse(localStorage.getItem("shoppingCart")));
        const newCart = JSON.parse(event.newValue);
        updateItemsCount(newCart.length);
        setItems(newCart);
      } else {
        setItems([]);
        updateItemsCount(0);
      }
    } else if (event.key === "discounts") {
      setDiscounts(JSON.parse(localStorage.getItem("discounts")));
    }
  };

  useEffect(() => {
    const storedShoppingCart = localStorage.getItem("shoppingCart");
    if (isLogedIn) {
      if (storedShoppingCart && JSON.parse(storedShoppingCart).length > 0) {
        setItems(JSON.parse(storedShoppingCart));
      }
    } else {
      setItems([]);
    }

    updateLoadingState(false);
  }, [isLogedIn]);

  const checkIfEmptyImgExists = (shoppingCart) => {
    let emptyImg = false;
    for (const item of shoppingCart) {
      // Use for...of loop to iterate over array elements directly
      if (
        !item.image ||
        item.image.includes("empty-img") ||
        item.maxAmount === 0
      ) {
        emptyImg = true;
        break; // Exit the loop once an empty image is found
      }
    }
    setDeletedProductInShoppingCart(emptyImg);
  };

  useEffect(() => {
    if (
      localStorage.getItem("logedIn") &&
      localStorage.getItem("initialLoading")
    ) {
      localStorage.removeItem("initialLoading");
    }
    updateLoadingState(true);
    const fetchData = async () => {
      const promises = [];
      if (localStorage.getItem("shoppingCart")) {
        const storedShoppingCart = JSON.parse(
          localStorage.getItem("shoppingCart")
        );

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            shoppingCart: storedShoppingCart,
          }),
        };
        const shoppingCartPromise = fetch(
          "/api/products/getExistingProductVariant",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              updateLoadingState(false);
            }
            if (data.success) {
              const length = data.shoppingCart.length;
              setAmountAlert(Array.from({ length }, () => ""));
              setItems(data.shoppingCart);
              checkIfEmptyImgExists(data.shoppingCart);
              data.shoppingCart.map((item, index) => {
                if (item.maxAmount < item.amount) {
                  const message =
                    item.maxAmount === 0
                      ? "Produkt už nie je dostupný"
                      : "Zadané množstvo nie je dostupné skladom";
                  setAmountAlert((prevState) => {
                    prevState[index] = message;
                    return prevState;
                  });
                }
              });
            }
          })
          .catch((error) => updateLoadingState(false));
        promises.push(shoppingCartPromise);
      } else {
        setItems([]);
      }

      if (
        localStorage.getItem("discounts") &&
        JSON.parse(localStorage.getItem("discounts")).length > 0 &&
        localStorage.getItem("shoppingCart")
      ) {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            discounts: JSON.parse(localStorage.getItem("discounts")),
          }),
        };
        const discountsPromise = fetch(
          "/api/orders/checkValidDiscounts",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            setDiscounts(data.discounts);
            localStorage.setItem("discounts", JSON.stringify(data.discounts));
          });
        promises.push(discountsPromise);
      }

      await Promise.all(promises);

      updateLoadingState(false);
    };

    fetchData().then(() => setInitial(false));
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (items && !initial) {
      localStorage.setItem("shoppingCart", JSON.stringify(items));
      getTotalPrice();
    }
  }, [items]);

  useEffect(() => {
    if (discounts) {
      localStorage.setItem("discounts", JSON.stringify(discounts));
    }
  }, [discounts]);

  const removeDiscount = (discount) => {
    const newDiscounts = discounts.filter(
      (newDiscount) => discount.code !== newDiscount.code
    );
    setDiscounts(newDiscounts);
  };

  const percentDiscountExists = () => {
    return discounts && discounts.some((discount) => discount.type === "%");
  };

  const getPriceAfterDiscount = (itemPrice) => {
    const discountAmount = discounts.filter(
      (discount) => discount.type === "%"
    );
    if (discountAmount && discountAmount.length > 0) {
      return (1 - discountAmount[0].amount / 100) * itemPrice;
    }
  };

  const checkDiscount = (event) => {
    event.preventDefault();
    const alreadyUsedCode = discounts.filter(
      (discount) => discount.code === giftCart
    );
    if (alreadyUsedCode && alreadyUsedCode.length !== 0) {
      setAlertMessage("Nie je možné použiť rovnaký kupón viackrát");
    } else if (giftCart !== "") {
      setAlertMessage("");
      setLoadDiscount(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: giftCart,
          existingDiscounts: discounts,
        }),
      };
      fetch("/api/orders/checkIfDiscountExists", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            if (data.discount.type === "%") {
              let giftCartPresented = false;
              for (const item of items) {
                if (item.name.startsWith("Poukážka")) {
                  giftCartPresented = true;
                  break;
                }
              }
              if (giftCartPresented) {
                setAlertMessage(
                  "Nie je možné aplikovať zľavu na všetky produkty v košíku"
                );
              }
            }
            setDiscounts((prevState) => [...prevState, data.discount]);
          }
          if (data.message) {
            setAlertMessage(data.message);
          }
          setLoadDiscount(false);
        });
    } else {
      setAlertMessage("Pre získanie zľavy prosím vyplňte toto pole");
    }
  };

  const handleInput = (event) => {
    const { value } = event.target;
    setGifCart(value);
    if (setAlertMessage !== "") {
      setAlertMessage("");
    }
  };

  const removeItem = (index) => {
    if (isLogedIn) {
      const item = JSON.parse(localStorage.getItem("shoppingCart"))[index];
      let productName = item.name;
      if (item.size || item.color || item.variant || item.shape) {
        productName += " - ";
        productName += item.size ? `${item.size}` : "undefined";
        productName += item.shape ? `, ${item.shape}` : ", undefined";
        productName += item.color ? `, ${item.color}` : ", undefined";
        productName += item.variant ? `, ${item.variant}` : ", undefined";
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          productName: productName,
          price: item.price,
          id_variant: item.id_variant,
        }),
      };

      fetch("/api/products/removeFromCart", requestOptions);
    }
    if (items.length === 1) {
      setDiscounts([]);
    }
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
    setAmountAlert((prevMessages) => {
      // Filter out the text at the specified index
      const newMessages = prevMessages.filter(
        (message, msgIndex) => index !== msgIndex
      );
      return newMessages;
    });
    updateItemsCount((prevState) => prevState - 1);
  };

  const increaseAmount = (index) => {
    if (items[index].maxAmount < items[index].amount + 1) {
      setAmountAlert((prevState) => {
        prevState[index] = "Zadané množstvo nie je dostupné skladom";
        return prevState;
      });
    } else {
      setAmountAlert((prevState) => {
        prevState[index] = "";
        return prevState;
      });
    }
    setItems((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        amount: updatedData[index].amount + 1,
      };
      return updatedData;
    });
  };

  const decreaseAmount = (index) => {
    if (items[index].amount - 1 >= 0) {
      if (items[index].maxAmount >= items[index].amount - 1) {
        setAmountAlert((prevState) => {
          prevState[index] = "";
          return prevState;
        });
      }

      if (items[index].amount - 1 === 0) {
        setAmountAlert((prevState) => {
          prevState[index] = "Prosím zadajte množstvo";
          return prevState;
        });
      }

      setItems((prevData) => {
        const updatedData = [...prevData]; // Shallow copy of the array
        updatedData[index] = {
          ...updatedData[index], // Shallow copy of the object at the specified index
          amount: updatedData[index].amount - 1, // Update the amount property
        };
        return updatedData;
      });
    }
  };

  const showError = (summary, message) => {
    toast.current.show({
      severity: "error",
      summary: summary,
      detail: message,
      life: 3000,
    });
  };

  function isBeforeToday(date1) {
    const today = new Date(); // Get today's date

    // Convert date1 to a Date object if it's not already one
    date1 = date1 instanceof Date ? date1 : new Date(date1);

    // Compare the dates (considering year, month, and day)
    return (
      date1.getFullYear() < today.getFullYear() ||
      (date1.getFullYear() === today.getFullYear() &&
        date1.getMonth() < today.getMonth()) ||
      (date1.getFullYear() === today.getFullYear() &&
        date1.getMonth() === today.getMonth() &&
        date1.getDate() <= today.getDate())
    );
  }

  const findOutIfUserLogedIn = () => {
    let logedIn = false;
    if (localStorage.getItem("logedIn")) {
      const logedInDate = localStorage.getItem("logedIn");
      const logedInExpiration = new Date(logedInDate);
      const currentDate = new Date();
      const isBefore = isBeforeToday(logedInExpiration);
      if (!isBefore) {
        logedInExpiration.setDate(logedInExpiration.getDate() + 1);
        let refreshTokenOk = true;
        const timeDifferenceInMilliseconds =
          currentDate.getTime() - logedInExpiration.getTime();
        const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
        if (timeDifferenceInMilliseconds < oneDayInMilliseconds) {
          fetch("/api/auth/setNewRefreshToken")
            .then((response) => response.json())
            .then((data) => {
              if (data.error) {
                updateUserDataAfterLogout();
                refreshTokenOk = false;
                updateLoadingState(false);
              } else {
                if (data.success) {
                  setLogInExpiration();
                } else {
                  refreshTokenOk = false;
                }
              }
            })
            .catch(() => {
              refreshTokenOk = false;
            });
        }
        if (refreshTokenOk) {
          logedIn = true;
        } else {
          updateUserDataAfterLogout();
        }
      } else {
        logedIn = false;
        updateUserDataAfterLogout();
      }
    } else {
      logedIn = false;
    }
    return logedIn;
  };

  const getOrderDetail = () => {
    let alertExists = false;
    checkItemsAmount(items);
    JSON.parse(localStorage.getItem("shoppingCart")).map((item) => {
      if (!item.image || item.image.includes("empty-img")) {
        alertExists = true;
      }
    });
    for (const alertItem of amounAlert) {
      if (alertItem !== "") {
        alertExists = true;
        break;
      }
    }

    if (!alertExists) {
      if (findOutIfUserLogedIn()) {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            shoppingCart: JSON.parse(localStorage.getItem("shoppingCart")),
          }),
        };
        fetch("/api/orders/updateOrderItemsAmount", requestOptions);
        navigate("/order-detail");
      } else {
        setDisplayLogin(true);
      }
    } else {
      showError(
        "Nebolo možné prejsť k objednávke",
        "Prosím aktualizujte košík"
      );
    }
  };

  const setAmount = (e, index) => {
    e.preventDefault();
    const { value } = e.target;
    if (value <= 0) {
      setAmountAlert((prevState) => {
        prevState[index] =
          "Pre objednanie produktu musí byť jeho množstvo minimálne 1";
        return prevState;
      });
    } else {
      if (value > items[index].maxAmount) {
        setAmountAlert((prevState) => {
          prevState[index] = "Zadané množstvo nie je dostupné skladom";
          return prevState;
        });
      } else {
        setAmountAlert((prevState) => {
          prevState[index] = "";
          return prevState;
        });
      }
    }
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index].amount = parseInt(value);
      return updatedItems;
    });
  };
  const getDetail = (id, name) => {
    if (id) {
      navigate(`/product/detail/${id}`);
    } else if (name.startsWith("Poukážka")) {
      navigate(`/create-giftcart`);
    }
  };

  const getTotalPrice = () => {
    let priceToPay = 0;
    const percentDiscount = discounts.filter(
      (discount) => discount.type === "%"
    );

    items.map((item) => {
      const proportionOfPrice =
        item.name.startsWith("Poukážka") || percentDiscount.length === 0
          ? 1
          : 1 - parseFloat(percentDiscount[0].amount) / 100;
      if (item.maxAmount > 0 || !item.id_variant) {
        priceToPay +=
          parseFloat(item.price) * parseInt(item.amount) * proportionOfPrice;
      }
    });
    if (discounts && discounts.length > 0) {
      discounts.map((discount) => {
        if (discount.type === "€") {
          priceToPay -= discount.amount;
        }
      });
    }
    return priceToPay < 0 ? 0 : priceToPay;
  };

  const deletedProductDialog = () => {
    return (
      <Dialog
        header="Nie všetky produkty z košíka sú stále dostupné"
        visible={deletedProductInShoppingCart}
        onHide={() => setDeletedProductInShoppingCart(false)}
        dismissableMask
        resizable={false}
        draggable={false}
      >
        <div>
          Ľutujeme, ale 1 alebo viac produktov, ktoré ste pridali do košíka už
          nie sú dostupné.
        </div>
      </Dialog>
    );
  };
  const shoppingCartItems = () => {
    return (
      <div>
        {displayLogin && (
          <GoogleOAuthProvider clientId="100743819708-8esngl0kae8mq1sf8o7ajtcs1cs55ash.apps.googleusercontent.com">
            <AuthContainter
              closeForms={() => setDisplayLogin(false)}
              title=" Pre prístup k objednávke sa prosím prihláste"
            />
          </GoogleOAuthProvider>
        )}
        {items &&
          items.map((item, index) => (
            <div
              key={`shopping-cart-item-${index}`}
              style={{ borderBottom: "1px solid #d1d1d1" }}
              className="pb-3 mb-3"
            >
              <div style={{ position: "relative" }}>
                <i
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                  }}
                  className="pi pi-times pointer"
                  onClick={() => removeItem(index)}
                ></i>
              </div>
              <div className="flex ">
                <img
                  style={{ objectFit: "cover", height: "110px", width: "85px" }}
                  onClick={() => getDetail(item.id, item.name)}
                  className="pointer"
                  src={item.image ? item.image : icons.emptyImg}
                  alt="miniature"
                />
                <div style={{ width: "100%" }} className="ml-3">
                  <div className="flex">
                    <div
                      className="mb-2"
                      onClick={() => getDetail(item.id, item.name)}
                    >
                      {item.name}
                    </div>
                    <div style={{ width: "20px" }}></div>
                  </div>
                  <div className="flex justify-content-between flex-wrap">
                    <div>
                      <div
                        style={{ opacity: "0.7", fontSize: "15px" }}
                        className="order-items-specification-width"
                      >
                        <div className="mb-2">
                          {item.shape && item.shape !== "undefined" && (
                            <div className="flex justify-content-between">
                              <div
                                className="mb-1 mr-1"
                                style={{ width: "35px" }}
                              >
                                Tvar:
                              </div>
                              <div
                                style={{ width: "calc(70% - 35px)" }}
                                className="flex justify-content-start"
                              >
                                {item.shape}
                              </div>
                            </div>
                          )}
                          {item.color && item.color !== "undefined" && (
                            <div className="flex justify-content-between">
                              <div
                                className="mb-1 mr-1"
                                style={{ width: "35px" }}
                              >
                                Farba:
                              </div>
                              <div style={{ width: "calc(70% - 35px)" }}>
                                {item.color}
                              </div>
                            </div>
                          )}
                          {item.size && item.size !== "undefined" && (
                            <div className="flex justify-content-between">
                              <div
                                className="mb-1 mr-1"
                                style={{ width: "35px" }}
                              >
                                Veľkosť:
                              </div>
                              <div style={{ width: "calc(70% - 35px)" }}>
                                {item.size}
                              </div>
                            </div>
                          )}
                          {item.variant && item.variant !== "undefined" && (
                            <div className="flex justify-content-between">
                              <div
                                className="mb-1 mr-1"
                                style={{ width: "35px" }}
                              >
                                Variant:
                              </div>
                              <div style={{ width: "calc(70% - 35px)" }}>
                                {item.variant}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {(!item.image ||
                        item.maxAmount === 0 ||
                        item.image.includes("empty-img")) && (
                        <div
                          style={{
                            marginTop: "5px",
                            border: "2px solid #df4930",
                            borderRadius: "10px",
                            fontWeight: "bold",
                            color: "#df4930",
                            backgroundColor: "white",
                            width: "130px",
                            fontSize: "15px",
                          }}
                          className=" p-1 pl-2 pr-2 mb-2 flex justify-content-center"
                        >
                          Nedostupné
                        </div>
                      )}
                    </div>

                    <div className="align-amount flex amount-width">
                      {((item &&
                        !item.image.includes("empty-img") &&
                        item.image &&
                        item.maxAmount > 0) ||
                        item.name.includes("Poukážka")) && (
                        <div>
                          <div key="amount">
                            <div className="flex flex-wrap align-amount">
                              <div style={{ display: "flex", width: "100px" }}>
                                <button
                                  onClick={() => decreaseAmount(index)}
                                  className="light btn center-btn"
                                  style={{ borderRadius: "0px" }}
                                >
                                  -
                                </button>
                                <div className="border-input">
                                  <input
                                    type="number"
                                    name="amount"
                                    value={item.amount}
                                    onChange={(e) => setAmount(e, index)}
                                    style={{
                                      width: "40px",
                                      display: "flex",
                                      padding: "0px",
                                      borderRadius: "0px",
                                      textAlign: "center",
                                    }}
                                  />
                                </div>
                                <button
                                  onClick={() => increaseAmount(index)}
                                  className="btn light center-btn"
                                  style={{ borderRadius: "0px" }}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>

                          <div
                            style={{ fontSize: "15px", color: "#df4930" }}
                            className="flex align-amount"
                          >
                            {amounAlert[index]}
                          </div>

                          <div
                            className="flex mt-2 align-amount"
                            style={{
                              width: "100%",
                              textDecoration:
                                percentDiscountExists() &&
                                !item.name.startsWith("Poukážka")
                                  ? "line-through"
                                  : "none",
                              opacity:
                                percentDiscountExists() &&
                                !item.name.startsWith("Poukážka")
                                  ? "0.7"
                                  : "1",
                            }}
                          >
                            {(item.price * item.amount)
                              .toFixed(2)
                              .replace(".", ",")}
                            €
                          </div>
                          {percentDiscountExists() &&
                            !item.name.startsWith("Poukážka") && (
                              <div className="flex mt-2 align-amount">
                                {getPriceAfterDiscount(item.price * item.amount)
                                  .toFixed(2)
                                  .replace(".", ",")}
                                €
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };
  return (
    <div style={{ minHeight: "80vh" }}>
      <div style={{ backgroundColor: "#3a3432 ", color: "white" }}>
        <div
          className="pt-3 pb-3 flex justify-content-center"
          style={{ fontSize: "25px" }}
        >
          Košík
        </div>
      </div>

      {loading || localStorage.getItem("initialLoading") || !items ? (
        <Loading />
      ) : (
        <div>
          <Toast ref={toast} />
          {deletedProductInShoppingCart && deletedProductDialog()}
          {items && items.length === 0 ? (
            <div
              className="flex align-items-center justify-content-center"
              style={{ height: "calc(100vh - 200px)" }}
            >
              <div className="empty-cart-title">Váš košík je prázdny</div>
            </div>
          ) : (
            <div className="p-3 flex justify-content-center flex-wrap">
              <div className="shopping-cart-item">{shoppingCartItems()}</div>
              {items && (
                <div
                  className="shopping-cart-summary p-4"
                  style={{ backgroundColor: "#F0E5D4", color: "#3a3432" }}
                >
                  <div
                    className="mb-2 flex justify-content-between flex-column"
                    style={{ fontSize: "20px" }}
                  >
                    <form
                      className="flex justify-content-between align-items-center"
                      onSubmit={checkDiscount}
                    >
                      <input
                        type="text"
                        value={giftCart}
                        onChange={handleInput}
                        placeholder="Zľavový kód"
                        style={{
                          width: "100%",
                          marginRight: "10px",
                          backgroundColor: "#f0e5d4",
                          padding: "0px 5px",

                          borderBottom:
                            alertMessage ===
                            "Pre získanie zľavy prosím vyplňte toto pole"
                              ? "2px solid #df4930"
                              : "",
                        }}
                      />
                      <button
                        className="mt-2 p-2 pointer"
                        style={{
                          backgroundColor: "#3a3432",
                          color: "#f0e5d4",
                          borderRadius: "5px",
                          border: "none",
                        }}
                      >
                        Prepočítať
                      </button>
                    </form>
                    {alertMessage !== "" && (
                      <div
                        className="alert"
                        style={{ fontSize: "14px", fontWeight: "normal" }}
                      >
                        {alertMessage}
                      </div>
                    )}
                    <div style={{ fontSize: "16px" }} className="mt-2">
                      {loadDiscount ? (
                        <div
                          className="flex justify-content-center align-items-center"
                          style={{ height: "48px" }}
                        >
                          <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "40px", marginRight: "10px" }}
                          ></i>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-content-center mb-1">
                            <div style={{ width: "40%" }}>Zľava:</div>
                            <div
                              style={{ width: "60%" }}
                              className="flex justify-content-end"
                            >
                              {((discounts && discounts.length === 0) ||
                                !discounts) &&
                                "0,00€"}
                            </div>
                          </div>
                          {discounts && discounts.length > 0 && (
                            <div className=" mb-2" style={{ fontSize: "14px" }}>
                              {discounts &&
                                discounts.map((discount) => (
                                  <div
                                    className="flex justify-content-between"
                                    key={discount.code}
                                  >
                                    <div style={{ width: "60%" }}>
                                      {discount.code}
                                    </div>
                                    <div
                                      style={{ width: "40%" }}
                                      className="flex justify-content-end "
                                    >
                                      <div className="mr-2">
                                        {" "}
                                        -
                                        {discount.amount
                                          .toFixed(2)
                                          .replace(".", ",")}
                                        {discount.type}
                                      </div>
                                      <div className="flex align-items-center">
                                        <i
                                          className="pi pi-times pointer"
                                          style={{ fontSize: "15px" }}
                                          onClick={() =>
                                            removeDiscount(discount)
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}

                          <div
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                            className="flex justify-content-center mb-1"
                          >
                            <div style={{ width: "40%" }}>Celkom: </div>
                            <div
                              style={{
                                width: "60%",
                              }}
                              className="flex justify-content-end"
                            >
                              {getTotalPrice().toFixed(2).replace(".", ",")}€{" "}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    className="btn dark col-12 p-2 pointer"
                    style={{ fontWeight: "normal" }}
                    onClick={getOrderDetail}
                  >
                    Prejsť k objednávke
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ShoppingCart;
