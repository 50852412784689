import React, { useEffect, useState } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
function AddNewDigiProduct({ update }) {
  const loaderData = useLoaderData();
  const navigate = useNavigate();
  const [newProductData, setNewProductData] = useState({
    images: [],
    coverImage: null,
    hoverImage: null,
    name: "",
    description: "",
    price: "",
  });
  const [warningMessage, setWarningMessage] = useState("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [submitBtnClicked, setSubmitBtnClicked] = useState(false);

  useEffect(() => {
    if (loaderData) {
      setNewProductData({
        name: loaderData.product.name,
        description: loaderData.product.description,
        price: loaderData.product.price,
      });
    }
  }, []);

  const handleImageInput = (e) => {
    const { name, type, files } = e.target;
    if (type === "file") {
      const selectedImages = Array.from(files);
      if (name === "coverImage" || name === "hoverImage") {
        setNewProductData((prevData) => ({
          ...prevData,
          [name]: selectedImages[0],
        }));
      } else {
        setNewProductData((prevData) => ({
          ...prevData,
          images: Array.isArray(prevData.images)
            ? [...prevData.images, ...selectedImages]
            : [...selectedImages],
        }));
      }
    }
  };
  const handleInput = (event) => {
    const { name, value } = event.target;
    setNewProductData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isEveryFieldFilled = () => {
    for (const key in newProductData) {
      if (!newProductData[key]) {
        return false;
      }
    }
    return true;
  };
  const addDigiProduct = () => {
    setSubmitBtnClicked(true);

    if (isEveryFieldFilled()) {
      if (loaderData && loaderData.product) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: newProductData.name,
            price: newProductData.price,
            description: newProductData.description,
            id: loaderData.product.id,
          }),
        };
        fetch("/api/products/updateDigiProduct", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              navigate(`/product/detail/${loaderData.product.id}`);
            } else {
              setWarningMessage(data.message);
            }
          })
          .catch(() =>
            setWarningMessage(
              "Nebolo možné nahrať produkt, vyskúšajte to neskôr prosím"
            )
          );
      } else {
        const formData = new FormData();

        formData.append("name", newProductData.name);
        formData.append("price", newProductData.price);
        formData.append("description", newProductData.description);
        formData.append("images", newProductData.coverImage);
        formData.append("images", newProductData.hoverImage);
        newProductData.images.forEach((file) => {
          formData.append("images", file);
        });
        const requestOptions = {
          method: "POST",
          body: formData,
        };
        fetch("/api/products/addNewDigiProduct", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              navigate("/products/digi");
            } else {
              setWarningMessage(data.message);
            }
          })
          .catch(() =>
            setWarningMessage(
              "Nebolo možné nahrať produkt, vyskúšajte to neskôr prosím"
            )
          );
      }
    } else {
      setWarningMessage("Vyplňte prosím všetky polia");
    }
  };

  return (
    <div>
      <Dialog
        visible={displayDialog}
        header={<div style={{ color: "#df4930" }}>Error</div>}
        className="dialog-added-to-cart"
        onHide={() => setDisplayDialog(false)}
        dismissableMask
      >
        {warningMessage}
      </Dialog>

      <div className="p-6" style={{ height: "80vh" }}>
        {!loaderData && loaderData.product && (
          <div className="p-5" style={{ boxShadow: "0 0px 5px #3a3432" }}>
            <div className="input-row">
              <div>Hlavná fotka:</div>
              <label className="custom-file-input">
                <input
                  type="file"
                  name="coverImage"
                  onChange={handleImageInput}
                  accept="image/*"
                  style={{ marginLeft: "10px" }}
                />
              </label>
            </div>
            {submitBtnClicked && !newProductData.coverImage && (
              <div className="mt-2 alert">Zadajte prosím fotku</div>
            )}
            <div className="input-row mt-3">
              <div>Prekryv fotka:</div>
              <label className="custom-file-input">
                <input
                  type="file"
                  name="hoverImage"
                  onChange={handleImageInput}
                  accept="image/*"
                  style={{ marginLeft: "10px" }}
                />
              </label>
            </div>
            {submitBtnClicked && !newProductData.hoverImage && (
              <div className="mt-2 alert">Zadajte prosím fotku</div>
            )}
            <div className="input-row mt-3">
              <div>Ostatné fotky:</div>
              <label className="custom-file-input">
                <input
                  type="file"
                  name="image"
                  onChange={handleImageInput}
                  accept="image/*"
                  multiple
                  style={{ marginLeft: "10px" }}
                />
              </label>
            </div>
          </div>
        )}

        <div className="input-row mt-1 flex justify-content-between">
          <div className="add-form-label">
            <label htmlFor="name ">Názov: </label>
          </div>
          <input
            type="text"
            name="name"
            value={newProductData.name}
            onChange={handleInput}
            style={{
              width: "100%",
              borderBottom:
                submitBtnClicked && !newProductData.name && "2px solid #df4930",
            }}
          />
        </div>

        {submitBtnClicked && !newProductData.name && (
          <div className="mt-2 alert">Zadajte prosím názov</div>
        )}
        <div className="add-form-label">
          <label htmlFor="description">Popis: </label>
        </div>
        <InputTextarea
          autoResize
          type="text"
          name="description"
          value={newProductData.description}
          onChange={handleInput}
          rows={3}
          style={{
            border:
              submitBtnClicked &&
              !newProductData.description &&
              "2px solid #df4930",
            width: "100%",
          }}
        />
        {submitBtnClicked && !newProductData.description && (
          <div className="mt-1 alert">Zadajte prosím popis</div>
        )}

        <div className="input-row mt-1 flex justify-content-between">
          <div className="add-form-label">
            <label htmlFor="price ">Cena: </label>
          </div>
          <input
            type="number"
            name="price"
            value={newProductData.price}
            onChange={handleInput}
            style={{
              width: "100%",
              borderBottom:
                submitBtnClicked &&
                !newProductData.price &&
                "2px solid #df4930",
            }}
          />
        </div>
        {submitBtnClicked && !newProductData.price && (
          <div className="mt-2 alert">Zadajte prosím cenu</div>
        )}
        <button
          style={{ marginTop: "10px", width: "100%" }}
          type="submit"
          className="btn dark flex justify-content-center align-items-center"
          onClick={addDigiProduct}
        >
          {submitBtnClicked && (
            <i
              className="pi pi-spin pi-spinner mr-2"
              style={{ fontSize: "20px" }}
            ></i>
          )}
          <div>{loaderData && loaderData.product ? "Upraviť" : "Pridať"}</div>
        </button>
      </div>
    </div>
  );
}

export default AddNewDigiProduct;
