import React, { useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";

function Menu({ isAdmin }) {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const allMenuOptions = [
    {
      name: "VŠETKY PRODUKTY",
      redirect: "/products-all",
    },
    {
      name: "TAMPÓNIKY",
      redirect: "/products/tampons",
    },
    {
      name: "VRECKÁ / KORZME. TAŠKY",
      redirect: "/products/cosmetic-bags",
    },
    {
      name: "LIMITKY",
      redirect: "/products/limited-offers",
    },
    {
      name: "DOPLNKY DO KÚPEĽNE",
      redirect: "/products/bathroom-accessories",
    },
    {
      name: "DIGI",
      redirect: "/products/digi",
    },
    {
      name: "DARČEKOVÉ POUKÁŽKY",
      redirect: "/create-giftcart",
    },

    {
      name: "OBCHODNÉ A REKLAMAČNÉ PODMIENKY",
      redirect: "/purchase-conditions",
    },
    {
      name: "OCHRANA OSOBNÝCH ÚDAJOV",
      redirect: "/privacy-policy",
    },
    {
      name: "PRIHLÁSIŤ SA NA ODBER NEWSLETTRA",
      redirect: "/#newsletter",
    },
  ];

  const allAdminOptions = [
    {
      name: "PREHĽAD OBJEDNÁVOK",
      redirect: "/delivery-overview",
    },
    {
      name: "SPRAVOVAŤ ZĽAVY",
      redirect: "/manage-discounts",
    },
    {
      name: "ODOBERATELIA NEWSLETTRA",
      redirect: "/newsletter-subscribers",
    },
  ];

  if (!isMenuOpen) {
    return null;
  }
  return (
    <div className={isMenuOpen ? "overlay-menu" : ""}>
      <div
        className={`zero-height menu ${
          isMenuOpen ? "table-container open" : ""
        }`}
      >
        <div className="menu-overflow" id="scrollable-menu">
          {allMenuOptions.map((option, index) => {
            return (
              <NavLink
                key={option.name}
                onClick={() => setIsMenuOpen(false)}
                to={option.redirect}
                style={{ textDecoration: "none", color: "black" }}
                className={`menu-item ${index % 2 === 0 ? "white" : "yellow"}`}
              >
                {option.name}
              </NavLink>
            );
          })}

          {isAdmin &&
            allAdminOptions.map((option, index) => {
              return (
                <NavLink
                  key={option.name}
                  to={option.redirect}
                  style={{ textDecoration: "none", color: "black" }}
                  className={`menu-item ${
                    index % 2 === 0 ? "white" : "yellow"
                  }`}
                >
                  {option.name}
                </NavLink>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Menu;
