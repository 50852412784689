import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useParams, useLoaderData } from "react-router-dom";
import { Toast } from "primereact/toast";
import { UserInfoContext } from "../UserInfo";
import Loading from "../Loading";

function UserOrders() {
  const toast = useRef(null);
  const [orders, setOrders] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const navigate = useNavigate();
  const loaderData = useLoaderData();
  const params = useParams();
  const { loading, updateLoadingState } = useContext(UserInfoContext);
  const icons = require("../../icons.json").icons;
  const changeSelectedOrder = (order) => {
    setSelectedOrder(order);
    navigate(`/profile/user-orders/${order.orderId}`);
  };

  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  }

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Chyba pri sťahovaní",
      detail: message,
      life: 3000,
    });
  };

  const ordersSummary = () => {
    return (
      <div className="order-summary-wrapper" id="scrollable-container">
        {orders.map((order) => {
          return (
            <div
              key={order.orderId}
              style={{
                width: "100%",
                backgroundColor: `${
                  selectedOrder.orderId === order.orderId ? "#f0e5d4" : "white"
                }`,
                borderRadius: `${
                  selectedOrder.orderId === order.orderId ? "10px" : "none"
                }`,
              }}
              className="pointer p-3 flex flex-column justify-content-center order-summary"
              onClick={() => changeSelectedOrder(order)}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Objednávka číslo {order.orderId}
              </div>
              <div
                style={{ fontSize: "13px", opacity: "0.7" }}
                className="mt-2"
              >
                {order.dateSend
                  ? `Odoslaná ${order.dateSend}`
                  : order.datePrepared
                  ? `Pripravená ${order.datePrepared}`
                  : order.datePaid
                  ? `Zaplatená ${order.datePaid}`
                  : `Vytvorená ${order.dateCreated}`}
              </div>
              <div
                style={{ fontSize: "13px", opacity: "0.7" }}
                className="mt-2"
              >
                Cena:{" "}
                {(order.orderPrice + order.deliveryPrice)
                  .toFixed(2)
                  .replace(".", ",")}
                €
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const checkIconInCircle = (date) => {
    return (
      <div
        style={{
          width: "25px",
          height: "25px",
          position: "absolute",
          left: "0",
          top: "35px",
          borderRadius: "15px",
          border: `2px solid ${date ? "green" : "gray"}`,
          backgroundColor: `${date ? "green" : "white"}`,
        }}
      >
        <i
          className="pi pi-check"
          style={{
            fontSize: "10px",
            position: "absolute",
            left: "25%",
            top: "30%",
            fontWeight: "bold",
            color: `${date ? "white" : "black"}`,
            opacity: `${date ? "1" : "0.5"}`,
          }}
        ></i>
      </div>
    );
  };

  const orderDeliveryOverview = () => {
    return (
      <div className="justify-content-center order-delivery-info">
        <div className="flex justify-content-between flex-column ">
          <div style={{ fontWeight: "bold" }}>
            Objednávka číslo {selectedOrder.orderId}
          </div>
          <div style={{ height: "150px" }} className="mt-3">
            <div
              className="flex "
              style={{ position: "relative", width: "100%" }}
            >
              <div style={{ width: "100%" }}>
                <img
                  src={icons.deliveryCreated}
                  alt="delivery_created"
                  style={{
                    width: "30px",
                    height: "30px",
                    position: "absolute",
                    top: "0",
                    left: "0%",
                  }}
                />
                <img
                  src={icons.deliveryPaid}
                  alt="delivery_paid"
                  style={{
                    width: "30px",
                    height: "30px",
                    position: "absolute",
                    top: "-0",
                    left: "calc(33% - 20px)",
                  }}
                />
                <img
                  src={icons.deliveryPrepared}
                  alt="delivery_prepared"
                  style={{
                    width: "30px",
                    height: "30px",
                    position: "absolute",
                    top: "-0",
                    left: "calc(66% - 20px)",
                  }}
                />
                <img
                  src={icons.deliverySent}
                  alt="delivery_send"
                  style={{
                    width: "40px",
                    height: "30px",
                    position: "absolute",
                    top: "-0",
                    left: "calc(100% - 30px)",
                  }}
                />
              </div>
            </div>

            <div
              style={{
                borderBottom: "1px solid black",
                position: "relative",
                height: "50px",
                width: "100%",
              }}
            >
              <div style={{ position: "absolute", left: "0" }}>
                {checkIconInCircle(selectedOrder.dateCreated)}
              </div>
              <div style={{ position: "absolute", left: "calc(33% - 20px)" }}>
                {checkIconInCircle(selectedOrder.datePaid)}
              </div>
              <div style={{ position: "absolute", left: "calc(66% - 20px)" }}>
                {checkIconInCircle(selectedOrder.datePrepared)}
              </div>
              <div style={{ position: "absolute", left: "calc(100% - 20px )" }}>
                {checkIconInCircle(selectedOrder.dateSend)}
              </div>
            </div>

            <div className="flex " style={{ fontSize: "13px" }}>
              <div
                className="flex justify-content-between order-delivery-info-width"
                style={{ position: "relative" }}
              >
                {selectedOrder.dateCreated && (
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                      }}
                      className="order-state-description order-delivery-state-info"
                    >
                      Objednávka vytvorená
                    </div>
                    <div
                      style={{
                        opacity: "0.7",
                        position: "absolute",
                      }}
                      className="order-state-date order-delivery-state-info"
                    >
                      {selectedOrder.dateCreated}
                    </div>
                  </div>
                )}

                {selectedOrder.datePaid && (
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                      }}
                      className="order-state-description left-offset-paid order-delivery-state-info"
                    >
                      Objednávka zaplatená
                    </div>
                    <div
                      style={{
                        opacity: "0.7",
                        position: "absolute",
                      }}
                      className="order-state-date left-offset-paid order-delivery-state-info"
                    >
                      {selectedOrder.datePaid}
                    </div>
                  </div>
                )}

                {selectedOrder.datePrepared && (
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                      }}
                      className="order-state-description left-offset-prepared order-delivery-state-info"
                    >
                      Objednávka pripravená
                    </div>
                    <div
                      style={{
                        opacity: "0.7",
                        position: "absolute",
                      }}
                      className="order-state-date left-offset-prepared order-delivery-state-info"
                    >
                      {selectedOrder.datePrepared}
                    </div>
                  </div>
                )}

                {selectedOrder.dateSend && (
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                      }}
                      className="order-state-description left-offset-send order-delivery-state-info"
                    >
                      Objednávka odoslaná
                    </div>
                    <div
                      style={{
                        opacity: "0.7",
                        position: "absolute",
                      }}
                      className="order-state-date left-offset-send order-delivery-state-info"
                    >
                      {selectedOrder.dateSend}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const orderContentOverview = () => {
    return (
      <div>
        {selectedOrder &&
          selectedOrder.orderDetail &&
          selectedOrder.orderDetail.map((orderItem, index) => {
            return (
              <div
                className="flex justify-content-between mb-2 pb-2"
                style={{
                  borderBottom: "1px solid gray",
                }}
                key={index}
              >
                <div className="flex flex-column align-center">
                  <div
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                    className="mb-1"
                  >
                    {truncateText(orderItem.productName, 20)} -{" "}
                    {orderItem.amount}ks
                  </div>
                  <div className="mt-1 mb-1 ">
                    {orderItem.productShape !== "undefined" &&
                      orderItem.productShape && (
                        <div className="mt-1" style={{ fontSize: "14px" }}>
                          Tvar: {orderItem.productShape}
                        </div>
                      )}
                    {orderItem.productColor !== "undefined" &&
                      orderItem.productColor && (
                        <div className="mt-1" style={{ fontSize: "14px" }}>
                          Farba: {orderItem.productColor}
                        </div>
                      )}
                    {orderItem.productSize !== "undefined" &&
                      orderItem.productSize && (
                        <div className="mt-1" style={{ fontSize: "14px" }}>
                          Veľkosť: {orderItem.productSize}
                        </div>
                      )}
                    {orderItem.productVariant !== "undefined" &&
                      orderItem.productVariant && (
                        <div className="mt-1" style={{ fontSize: "14px" }}>
                          Variant: {orderItem.productVariant}
                        </div>
                      )}
                  </div>

                  <div style={{ fontSize: "14px" }} className="mb-1">
                    {(
                      parseFloat(orderItem.price) * parseFloat(orderItem.amount)
                    )
                      .toFixed(2)
                      .replace(".", ",")}
                    €
                  </div>
                </div>
                <div>
                  <img
                    style={{
                      height: "120px",
                      width: "90px",
                      objectFit: "cover",
                    }}
                    className={orderItem.image && "pointer"}
                    src={orderItem.image ? orderItem.image : icons.emptyImg}
                    onClick={() =>
                      orderItem.productId
                        ? navigate(`/product/detail/${orderItem.productId}`)
                        : navigate(`/product/detail/null`)
                    }
                  />
                </div>
              </div>
            );
          })}
        <div className="flex justify-content-between mb-5 mt-1">
          <div style={{ width: "50%" }}></div>
          <div style={{ width: "50%" }}>
            <div
              className="pb-1 mb-1"
              style={{ borderBottom: "1px solid #3a3432" }}
            >
              <div className="flex justify-content-between">
                Cena za produkty:
                <div className="ml-2 flex align-items-center">
                  {selectedOrder.orderPrice.toFixed(2).replace(".", ",")}€
                </div>
              </div>
              <div className="flex justify-content-between mt-1">
                Cena za dopravu:
                <div className="ml-2 flex align-items-center">
                  {selectedOrder.deliveryPrice.toFixed(2).replace(".", ",")}€
                </div>
              </div>
            </div>
            <div>
              <div className="flex justify-content-between mt-1">
                Spolu:
                <div className="ml-2 flex align-items-center">
                  {(selectedOrder.deliveryPrice + selectedOrder.orderPrice)
                    .toFixed(2)
                    .replace(".", ",")}
                  €
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      detail: "Recenzia bola úspešne pridaná",
      life: 3000,
    });
  };

  useEffect(() => {
    updateLoadingState(true);
    if (localStorage.getItem("logedIn")) {
      if (
        loaderData &&
        loaderData.props.orders &&
        loaderData.props.orders.length > 0
      ) {
        const selectedOrderId = params.orderId
          ? params.orderId
          : loaderData.props.orders[0].orderId;
        setOrders(loaderData.props.orders);
        const selected = loaderData.props.orders.filter(
          (order) => order.orderId === parseFloat(selectedOrderId)
        );

        setSelectedOrder(selected[0]);

        if (localStorage.getItem("reviewAddedFromProfile")) {
          showSuccess();
          localStorage.removeItem("reviewAddedFromProfile");
        }
        updateLoadingState(false);
      } else {
        setOrders([]);
        updateLoadingState(false);
      }
    }
  }, []);

  const addReview = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: selectedOrder.orderId,
        refreshToken: localStorage.getItem("refreshToken"),
      }),
    };

    fetch("/api/reviews/createAddReviewToken", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          navigate(`/profile/add-review/${data.addReviewToken}`);
        }
      })
      .catch((error) => {
        console.log(`Internal server error ${error}`);
      });
  };

  return (
    <div>
      <Toast ref={toast} />

      {loading ? (
        <Loading />
      ) : orders && orders.length > 0 ? (
        <div className="flex justify-content-center flex-wrap ">
          <div className="mr-3">{ordersSummary()}</div>
          <div>
            {!selectedOrder.reviewId && selectedOrder.dateSend && (
              <div className="flex justify-content-end">
                <button
                  className="btn light"
                  style={{ width: "200px", fontWeight: "bold" }}
                  onClick={() => addReview()}
                >
                  + Pridať recenziu
                </button>
              </div>
            )}
            <div>{orderDeliveryOverview()}</div>
            <div>{orderContentOverview()}</div>
          </div>
        </div>
      ) : (
        <div
          className="flex  justify-content-center align-items-center center-text-profile"
          style={{ fontSize: "18px" }}
        >
          Zatiaľ nemáte žiadne objednávky
        </div>
      )}
    </div>
  );
}

export default UserOrders;
