import React, { useEffect, useState, useContext } from "react";
import { useLocation, useParams, NavLink, Outlet } from "react-router-dom";
import { UserInfoContext } from "../UserInfo";
function ProfileMenu() {
  const location = useLocation();
  const [latestOrder, setLatestOrder] = useState("");
  const { isLogedIn } = useContext(UserInfoContext);
  const handleStorageChange = (event) => {
    if (event.key === "reviewChanged") {
      const reviewChanged = localStorage.getItem("reviewChanged")
        ? JSON.parse(localStorage.getItem("reviewChanged"))
        : null;
      if (reviewChanged) {
        localStorage.removeItem("reviewChanged");
        window.location.reload();
      }
    }
  };
  useEffect(() => {
    if (isLogedIn) {
      fetch("/api/user/getUsersLatestOrder")
        .then((response) => response.json())
        .then((data) => {
          if (data.orderId) {
            setLatestOrder(data.orderId);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div>
      {location && (
        <div className="flex flex-wrap ">
          <div>
            <div className="profile-navigate flex-column pt-4">
              <div className="profile-navigate-options">
                <NavLink
                  to={"/profile/user-details"}
                  style={{ textDecoration: "none" }}
                >
                  <button
                    className={`${
                      location.pathname.endsWith("/user-details") && "active"
                    } pointer flex  pb-3 `}
                  >
                    Účet
                  </button>
                </NavLink>
                <NavLink
                  to={`/profile/user-orders${
                    latestOrder != "" ? `/${latestOrder}` : ""
                  }`}
                  style={{ textDecoration: "none" }}
                >
                  <button
                    className={`${
                      location.pathname.includes("/user-orders") && "active"
                    } pointer flex  pb-3 `}
                  >
                    Objednávky
                  </button>
                </NavLink>
                <NavLink
                  to={"/profile/user-reviews"}
                  style={{ textDecoration: "none" }}
                >
                  <button
                    className={`${
                      location.pathname.includes("/user-reviews") && "active"
                    } pointer flex  pb-3 `}
                  >
                    Recenzie
                  </button>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="mt-5 content-part ">
            <div className="flex justify-content-center">
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileMenu;
