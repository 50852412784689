import React, { useEffect, useState, useContext, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../styles/home.css";
import ReactStars from "react-rating-stars-component";
import PopularProducts from "./Products/PopularProducts";
import { Dialog } from "primereact/dialog";
import Loading from "./Loading";
import { UserInfoContext } from "./UserInfo";
import ImagesManager from "./ImagesManager";
import { Toast } from "primereact/toast";
import { Helmet } from "react-helmet";

function HomePage() {
  const navigate = useNavigate();
  const urlLocation = useLocation();
  const { isLogedIn, isAdmin, loading, updateLoadingState } =
    useContext(UserInfoContext);
  const toast = useRef(null);

  const [homePageLoading, setHomePageLoading] = useState(true);
  const [popularProducts, setPopularProducts] = useState([]);
  const [slideShowImages, setSlideShowImages] = useState([]);
  const [igPosts, setIgPosts] = useState(null);
  const [description, setDescription] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");
  const [descriptionImg, setDescriptionImg] = useState("");
  const [alertMsgDesImg, setAlertMsgDesImg] = useState("");
  const [changeDescriptionTitle, setChangeDescriptionTitle] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [chageDescriptionText, setChangeDescriptionText] = useState(false);
  const [newDescriptioText, setNewDescriptioText] = useState("");
  const [alerMsg, setAlertMsg] = useState("");
  const [location, setLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [changeContactInfo, setChangeContactInfo] = useState("");
  const [newContactInfo, setNewContactInfo] = useState({});
  const [changeSlideImages, setChangeSlideImages] = useState(false);
  const [settingsInstagram, setSettingsInstagram] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      window.innerWidth > 1220
        ? 4
        : window.innerWidth > 920
        ? 3
        : window.innerWidth > 470
        ? 2
        : 1,
    slidesToScroll:
      window.innerWidth > 1220
        ? 4
        : window.innerWidth > 920
        ? 3
        : window.innerWidth > 470
        ? 2
        : 1,
  });
  const [reviews, setReviews] = useState([]);
  const [settingsReviews, setSettingsReviews] = useState({});
  const [subscribingEmail, setSubscribingEmail] = useState("");
  const [unauthorizedDialog, setUnauthorizedDialog] = useState(false);
  const icons = require("../icons").icons;

  const redirectToUrl = (url) => {
    window.location.href = url;
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };

  const subscribeToNewsletter = () => {
    setAlertMsg("");
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (subscribingEmail === "") {
      setAlertMsg("Zadajte prosím email");
    } else if (!emailRegex.test(subscribingEmail)) {
      setAlertMsg("Nesprávne zadaný email");
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: subscribingEmail,
        }),
      };

      fetch("/api/homePage/verifyNewsletterEmail", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            setAlertMsg(data.message);
          }
          if (data.success) {
            setAlertMsg(
              "Na zadaný email boli zaslané pokyny k jeho potvrdeniu"
            );
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const setNewsletterSubscribingEmail = (event) => {
    setSubscribingEmail(event.target.value);
  };

  const handleResize = () => {
    setSettingsInstagram((prevState) => ({
      ...prevState,
      slidesToShow:
        window.innerWidth > 1220
          ? 4
          : window.innerWidth > 920
          ? 3
          : window.innerWidth > 470
          ? 2
          : 1,
      slidesToScroll:
        window.innerWidth > 1220
          ? 4
          : window.innerWidth > 920
          ? 3
          : window.innerWidth > 470
          ? 2
          : 1,
    }));
    setSettingsReviews((prevState) => ({
      ...prevState,
      slidesToShow: window.innerWidth > 920 && reviews.length > 1 ? 2 : 1,
    }));
  };

  const scrollToDiv = () => {
    const hash = urlLocation.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({
        top: 0, // Scroll to the top of the page
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (!loading && !homePageLoading && igPosts) {
      scrollToDiv();
    }
  }, [loading, homePageLoading, igPosts, urlLocation.hash]);

  useEffect(() => {
    updateLoadingState(true);

    Promise.all([
      fetch("/api/images/slideShowImages").then((response) => response.json()),
      fetch("/api/products/getPopularProducts").then((response) =>
        response.json()
      ),
    ]).then(([slideShowImages, popularProducts]) => {
      let notNullSlideShowImages = {
        images: [],
        ids: [],
        redirect_urls: [],
      };
      slideShowImages.images.map((image, index) => {
        if (image) {
          notNullSlideShowImages.images.push(image);
          notNullSlideShowImages.ids.push(slideShowImages.ids[index]);
          notNullSlideShowImages.redirect_urls.push(
            slideShowImages.redirect_urls[index]
          );
        }
      });
      if (notNullSlideShowImages.images.length === 0) {
        notNullSlideShowImages = {
          images: [icons.emptyImg],
          ids: [null],
          redirect_urls: [null],
        };
      }
      setSlideShowImages(notNullSlideShowImages);
      setPopularProducts(popularProducts.products);
      setHomePageLoading(false);

      updateLoadingState(false);
    });

    Promise.all([
      fetch("/api/homePage/homePageInfo").then((response) => response.json()),
      fetch("/api/reviews/all").then((response) => response.json()),
    ])
      .then(([homePageInfo, reviewsData]) => {
        const hash = urlLocation.hash;
        const titleDescription = homePageInfo.descriptions.find(
          (info) => info.page_type === "hp-text-title"
        );
        const textDescription = homePageInfo.descriptions.find(
          (info) => info.page_type === "hp-text"
        );
        const location = homePageInfo.descriptions.find(
          (info) => info.page_type === "location"
        );
        const email = homePageInfo.descriptions.find(
          (info) => info.page_type === "email"
        );
        const phone = homePageInfo.descriptions.find(
          (info) => info.page_type === "phone"
        );
        // setLocation(location.text);
        // setEmail(email.text);
        // setPhone(phone.text);
        setDescriptionTitle(titleDescription.text);
        setNewTitle(titleDescription.text);
        setDescription(textDescription.text);
        setNewDescriptioText(textDescription.text);
        setDescriptionImg(homePageInfo.img);
        setNewContactInfo({
          phone: phone.text,
          email: email.text,
          location: location.text,
        });
        setReviews(reviewsData.data);
        setSettingsReviews({
          dots: false,
          infinite: true,
          speed: window.innerWidth > 470 ? 1050 : 525,
          autoplay: true,
          arrows: false,
          autoplaySpeed: 6000,
          slidesToShow:
            window.innerWidth > 920 && reviewsData.data.length !== 1 ? 2 : 1,
          slidesToScroll: 1,
        });
      })
      .then(() => {
        setHomePageLoading(false);

        updateLoadingState(false);
      });

    fetch("/api/homePage/getInstagramToken")
      .then((response) => response.json())
      .then((data) => {
        if (data.token) {
          const apiUrl = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,username,permalink&limit=8&access_token=${data.token}`;
          fetch(apiUrl)
            .then((response) => response.json())
            .then((instagramData) => {
              if (!instagramData.error) {
                const truncatedPosts = instagramData.data.map((post) => ({
                  ...post,
                  caption: post.caption ? truncateText(post.caption, 20) : "",
                }));
                setIgPosts(truncatedPosts);
              } else {
                setIgPosts([]);
              }
            });
        }
      });

    // Add event listener for hashchange
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
  };

  const hideDialog = () => {
    setAlertMsg("");
    setChangeContactInfo("");
    setChangeDescriptionText(false);
    setChangeDescriptionTitle(false);
  };

  const hideDialogSliderChange = () => {
    setAlertMsg("");
    setChangeSlideImages(false);
  };

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Nebolo možné vykonať požadovanú akciu",
      detail: message,
      life: 3000,
    });
  };

  const hadnleImagesModification = (formData) => {
    const requestOptions = {
      method: "POST",
      body: formData,
    };

    fetch("/api/images/changeSlideImages", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setUnauthorizedDialog(true);
        }
        if (data.success) {
          window.location.reload();
        } else {
          showError("Dočasná chyba servera, vyskúšajte to opäť neskôr prosím");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showError("Dočasná chyba servera, vyskúšajte to opäť neskôr prosím");
      });
  };

  const handleContactInfoInputChange = (event) => {
    event.preventDefault();
    setNewContactInfo((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleContactInfoChange = (event) => {
    event.preventDefault();
    if (
      newContactInfo[changeContactInfo] === "" ||
      !newContactInfo[changeContactInfo]
    ) {
      setAlertMsg("Je potrebné vyplniť pole");
    } else {
      setAlertMsg("");
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          infoToChange: changeContactInfo,
          newInfo: newContactInfo[changeContactInfo],
        }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch("/api/homePage/setNewContactInfo", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setAlertMsg(data.message);
          if (data.success) {
            window.location.reload();
          }
          if (data.error) {
            setUnauthorizedDialog(true);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const handleChangeDescription = (event) => {
    event.preventDefault();
    let updatedText = newDescriptioText;
    let descriptionType = "hp-text";
    if (changeDescriptionTitle) {
      updatedText = newTitle;
      descriptionType = "hp-text-title";
    }

    if (updatedText === "" || !updatedText) {
      setAlertMsg("Je potrebné zadať popis");
    } else {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          descriptionType: descriptionType,
          newText: updatedText,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch("/api/homePage/setNewDescription", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setAlertMsg(data.message);
          if (data.error) {
            setUnauthorizedDialog(true);
          }
          if (data.success) {
            window.location.reload();
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "title") {
      setNewTitle(value);
    } else {
      setNewDescriptioText(value);
    }
  };
  const handleLoadDescriptionImg = (event) => {
    const { files } = event.target;
    const selectedImg = files[0];

    const formData = new FormData();
    formData.append("image", selectedImg);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    fetch("/api/homePage/setNewDescriptionImg", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setAlertMsgDesImg(data.message);
        if (data.error) {
          setUnauthorizedDialog(true);
        }
        if (data.success) {
          window.location.reload();
        }
      })
      .catch((error) => {
        // Handle upload error
        console.error("Error uploading image:", error);
      });
  };

  const displayChangeSlideImages = () => {
    setChangeSlideImages(true);
    setAlertMsg("");
  };

  const displayChangeDescriptionTitle = () => {
    setChangeDescriptionTitle(true);
    setAlertMsg("");
  };

  const displayChangeDescriptionText = () => {
    setChangeDescriptionText(true);
    setAlertMsg("");
  };

  const displayChangeContact = (contactInfo) => {
    setChangeContactInfo(contactInfo);
    setAlertMsg("");
  };

  const deleteReview = (event) => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        id: event.target.id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch("/api/reviews/deleteReview", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setUnauthorizedDialog(true);
        }
        if (data.success) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const instagramPost = (
    imageSrc,
    text,
    likesCount,
    commentsCount,
    link,
    id,
    media_type,
    index
  ) => {
    return (
      <div
        className="ig-post pointer"
        onClick={() => handleLinkClick(link)}
        key={`post_${id}`}
      >
        <div className="flex justify-content-center">
          {media_type === "VIDEO" ? (
            <video
              src={imageSrc}
              alt="post-video"
              className="post-image"
            ></video>
          ) : (
            <img src={imageSrc} alt="post-image" className="post-image" />
          )}

          <div className="overlay-hp"></div>
          <div className="post-text flex justify-content-center">
            <img
              src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/instagram-white-icon.png"
              alt="ig-post"
              className="mr-3 ig-icon"
            />
            <div>bronca.leitman</div>
          </div>
        </div>
        <div className="flex justify-content-center post-captions">{text}</div>
        {/* <div className="flex justify-content-center">
          <div className="flex align-items-center">
            <i className="pi pi-heart-fill"></i>
            <div className="ml-1">{likesCount}</div>
          </div>
          <div className="flex align-items-center ml-2">
            <i className="pi pi-comment"></i>
            <div className="ml-1">{commentsCount}</div>
          </div>
        </div> */}
      </div>
    );
  };

  const getReviews = () => {
    return (
      <div className="max-hp-width justify-content-between mb-5 " id="reviews">
        <Slider {...settingsReviews}>
          {reviews.map((review) => {
            return (
              <div className="flex justify-content-center " key={review.id}>
                <div
                  className="review-container max-hp-width-bottom"
                  key={`review_${review.id}`}
                >
                  {isLogedIn && isAdmin && (
                    <div style={{ position: "relative" }}>
                      <i
                        className="pi pi-trash pointer p-2 m-2"
                        id={review.id}
                        onClick={deleteReview}
                        style={{
                          backgroundColor: "white",
                          border: "2px solid #3a3432",
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                        }}
                      ></i>
                    </div>
                  )}
                  <div className="review-stars">
                    <ReactStars
                      count={5}
                      size={25}
                      value={parseFloat(review.stars)}
                      edit={false}
                      isHalf={true}
                      color="#9e918c"
                      activeColor="#3a3432"
                    />
                    <div style={{ fontSize: "15px" }} className="mt-1 mb-1 ">
                      {review.product_name}
                    </div>
                  </div>

                  <div className="flex flex-column align-items-start review-comment">
                    <div>{review.comment}</div>
                  </div>
                  <div className="flex align-items-end justify-content-end review-author">
                    -
                    {review.user_name
                      ? review.user_name
                      : `${review.User.name} ${review.User.surname[0]}.`}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  };

  const changeContactInfoDialog = () => {
    return (
      <Dialog
        header={`Zadajte ${
          changeContactInfo === "phone"
            ? "telefónne číslo"
            : changeContactInfo === "location"
            ? "adresu"
            : "email"
        }`}
        visible={changeContactInfo != ""}
        onHide={hideDialog}
        dismissableMask
        resizable={false}
        className="dialog-min-width"
      >
        <form onSubmit={handleContactInfoChange}>
          <input
            type="text"
            placeholder={changeContactInfo}
            style={{ width: "100%" }}
            name={changeContactInfo}
            value={newContactInfo[changeContactInfo]}
            onChange={handleContactInfoInputChange}
          ></input>

          {alerMsg !== "" && (
            <div
              className="alert mt-2"
              style={{ fontSize: "15px", width: "100%" }}
            >
              {alerMsg}
            </div>
          )}
          <button className="btn dark p-2 mt-3" style={{ width: "100%" }}>
            Zadať
          </button>
        </form>
      </Dialog>
    );
  };

  const changeDescriptionDialog = () => {
    return (
      <Dialog
        header={
          changeDescriptionTitle ? "Zadajte nový nadpis" : "Zadajte nový popis"
        }
        visible={changeDescriptionTitle || chageDescriptionText}
        onHide={hideDialog}
        dismissableMask
        resizable={false}
        className="dialog-min-width"
      >
        <form onSubmit={handleChangeDescription}>
          {changeDescriptionTitle ? (
            <input
              type="text"
              placeholder="Nadpis"
              style={{ width: "100%" }}
              name="title"
              value={newTitle}
              onChange={handleChange}
            ></input>
          ) : (
            chageDescriptionText && (
              <div className="flex justify-content-center">
                <div className="hp-description">
                  <textarea
                    style={{
                      width: "100%",
                      borderColor: "eae6e3",
                      borderRadius: "15px",
                    }}
                    placeholder="Popis"
                    name="description"
                    value={newDescriptioText}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            )
          )}

          {alerMsg !== "" && (
            <div
              className="alert mt-2"
              style={{ fontSize: "15px", width: "100%" }}
            >
              {alerMsg}
            </div>
          )}
          <button className="btn dark p-2 mt-3" style={{ width: "100%" }}>
            Zadať
          </button>
        </form>
      </Dialog>
    );
  };

  const dialogUnathorized = () => {
    return (
      <Dialog
        header="Nemáte oprávnenie"
        visible={unauthorizedDialog}
        className="dialog-min-width"
        onHide={() => setUnauthorizedDialog(false)}
        dismissableMask
        resizable={false}
      >
        <div>Na vyžiadanú akciu nemáte oprávnenie</div>
      </Dialog>
    );
  };

  const changeSlideImagesDialog = () => {
    return (
      <Dialog
        header={<div style={{ fontSize: "16px" }}>Upraviť baner</div>}
        visible={changeSlideImages}
        onHide={hideDialogSliderChange}
        resizable={false}
        className="dialog-min-width"
      >
        <div>
          <ImagesManager
            allImages={slideShowImages}
            hadnleImagesModification={hadnleImagesModification}
            withLink={true}
          />
        </div>
      </Dialog>
    );
  };

  const metaData = () => {
    return (
      <Helmet>
        <title>Domov</title>
        <meta name="home-page" content="Vitajte na domovskej obrazovke." />
        <meta property="og:title" content="Domov" />
      </Helmet>
    );
  };

  return (
    <div className="homePageContainer">
      {metaData}
      {loading || homePageLoading || !igPosts ? (
        <Loading />
      ) : (
        <div>
          <Toast ref={toast} />
          {(changeDescriptionTitle || chageDescriptionText) &&
            changeDescriptionDialog()}
          {changeContactInfo !== "" && changeContactInfoDialog()}
          {changeSlideImages && changeSlideImagesDialog()}
          {unauthorizedDialog && dialogUnathorized()}

          <div style={{ position: "relative", width: "100%" }}>
            {isLogedIn && isAdmin && (
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: "2",
                }}
              >
                <i
                  className="pi pi-pencil p-2 pointer"
                  style={{
                    border: "2px solid #3a3432",
                    backgroundColor: "white",
                  }}
                  onClick={displayChangeSlideImages}
                ></i>
              </div>
            )}
            <Slider {...settings} style={{ zIndex: "1" }}>
              {slideShowImages.images &&
                slideShowImages.images.map((image, index) => {
                  return (
                    <div
                      key={`slide_${index}`}
                      className={`${
                        slideShowImages.redirect_urls[index] && "pointer"
                      } slide`}
                      onClick={() =>
                        slideShowImages.redirect_urls[index] &&
                        redirectToUrl(slideShowImages.redirect_urls[index])
                      }
                    >
                      <img id={index} src={image} alt={`slide_${index}`} />
                    </div>
                  );
                })}
            </Slider>
          </div>
          <PopularProducts popularProducts={popularProducts} />

          <div
            style={{ backgroundColor: "#f0e5d4" }}
            className="flex flex-wrap justify-content-center align-items-center"
          >
            <div className="hp-text-container justify-content-center align-items-center max-hp-width">
              <div className="description-img ">
                <div className="flex justify-content-center flex-column">
                  {isLogedIn && isAdmin && (
                    <div className="mb-3 flex justify-content-center">
                      <input
                        type="file"
                        name="descriptionImg"
                        onChange={handleLoadDescriptionImg}
                        accept="image/*"
                      />
                    </div>
                  )}
                  <div className="flex justify-content-center">
                    <img
                      src={descriptionImg}
                      alt="img-about-text"
                      className="hp-text-img"
                    />
                  </div>

                  {alertMsgDesImg && (
                    <div
                      className="alert mt-2"
                      style={{ fontSize: "15px", width: "100%" }}
                    >
                      {alertMsgDesImg}
                    </div>
                  )}
                </div>
              </div>

              <div className="hp-text">
                <div style={{ fontWeight: "bold" }}>
                  <div style={{ width: "100%" }}>
                    {
                      <div
                        dangerouslySetInnerHTML={{
                          __html: descriptionTitle.replace(/\n/g, "<br>"),
                        }}
                      />
                    }

                    {isLogedIn && isAdmin && (
                      <div style={{ position: "relative" }}>
                        <div
                          style={{
                            position: "absolute",
                            top: "-30px",
                            right: "0px",
                          }}
                        >
                          <i
                            className="pi pi-pencil p-1 pointer"
                            onClick={displayChangeDescriptionTitle}
                            style={{
                              border: "2px solid #3a3432",
                              backgroundColor: "white",
                            }}
                          ></i>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <br />
                <div>
                  {isLogedIn && isAdmin && (
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "0px",
                        }}
                      >
                        <i
                          className="pi pi-pencil p-1 pointer"
                          onClick={displayChangeDescriptionText}
                          style={{
                            border: "2px solid #3a3432",
                            backgroundColor: "white",
                          }}
                        ></i>
                      </div>
                    </div>
                  )}

                  <div
                    dangerouslySetInnerHTML={{
                      __html: description.replace(/\n/g, "<br>"),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-5 pb-0 flex flex-column justify-content-center align-items-center">
            <div className="mb-5 hp-title flex justify-content-center align-items-center">
              SPOJME SA NA
            </div>
            <div className="flex justify-content-center icons-hp ">
              <a
                href="https://www.instagram.com/bronca.leitman/"
                target="_blank"
              >
                <img
                  src={icons.instagram}
                  alt="instagram-icon"
                  className="mr-5 pointer"
                  style={{ borderRadius: "8px" }}
                />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100083228244550"
                target="_blank"
              >
                <img
                  src={icons.facebook}
                  alt="facebook-icon"
                  style={{ borderRadius: "8px" }}
                  className="pointer"
                />
              </a>

              {/* <img
                src={icons.pinterest}
                alt="pinterest-icon"
                style={{ borderRadius: "8px" }}
                className="ml-5 pointer"
              /> */}
            </div>
            {igPosts && igPosts.length > 0 && (
              <div className="flex justify-content-center mt-5 mb-5">
                <div className="max-hp-width justify-content-between">
                  <Slider {...settingsInstagram}>
                    {igPosts.map((post, index) => {
                      return instagramPost(
                        post.media_url,
                        post.caption,
                        120,
                        10,
                        post.permalink,
                        post.id,
                        post.media_type,
                        index
                      );
                    })}
                  </Slider>
                </div>
              </div>
            )}
          </div>
          {reviews.length !== 0 && (
            <div className="flex justify-content-center">
              <div className="mt-5 max-hp-width">
                <div>
                  <div className="flex justify-content-center hp-title mb-5">
                    RECENZIE
                  </div>
                  {getReviews()}
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-content-center">
            <div className="mt-5 mb-5 max-hp-width flex justify-content-center flex-wrap">
              <div className="max-hp-width-bottom">
                <div className="flex justify-content-center ">
                  <div
                    className="bottom-text-container left-bottom-container"
                    id="newsletter"
                  >
                    <div
                      style={{ fontSize: "17px", fontWeight: "bold" }}
                      className="text-center mb-2 "
                    >
                      ODOBERAJTE NÁŠ NEWSLETTER
                    </div>
                    <div className="flex justify-content-center pl-2 text-center">
                      Prihlás sa na odber našich noviniek, aby ti neušla žiadna
                      zľava alebo akcia, ktorú si pre teba prichystáme.
                    </div>
                  </div>
                </div>
                <div className="flex justify-content-center ">
                  <div className="bottom-text-container-color  mb-5 mt-3 left-bottom-container">
                    <div
                      className={`flex justify-content-center ${
                        alerMsg === ""
                          ? "mb-3 mt-3"
                          : alerMsg === "Zadajte prosím email" ||
                            alerMsg === "Nesprávne zadaný email"
                          ? "mb-1 mt-3"
                          : "mb-1 mt-1"
                      } `}
                    >
                      <input
                        placeholder="Vložte váš e-mail"
                        type="text"
                        value={subscribingEmail}
                        onChange={setNewsletterSubscribingEmail}
                        name="email"
                      ></input>
                    </div>
                    {alerMsg !== "" && (
                      <div
                        className={`${
                          alerMsg ===
                          "Na zadaný email boli zaslané pokyny k jeho potvrdeniu"
                            ? ""
                            : "alert"
                        } flex mb-1`}
                        style={{ fontSize: "14px", width: "100%" }}
                      >
                        {alerMsg}
                      </div>
                    )}
                    <div className="flex justify-content-center ">
                      <button
                        className="btn-newsletter pointer"
                        onClick={subscribeToNewsletter}
                      >
                        PRIHLÁSTE SA NA ODBER
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* { <div className="max-hp-width-bottom">
                <div className="flex justify-content-center">
                  <div className="bottom-text-container right-bottom-container">
                    <div
                      style={{ fontSize: "17px", fontWeight: "bold" }}
                      className="text-center mb-2"
                      id="contact"
                    >
                      KONTAKTNÉ ÚDAJE
                    </div>
                    <div className="white-space mb-3"></div>
                    <div className="flex justify-content-center">
                      <div className="bottom-text-container-color">
                        <div className="flex justify-content-center">
                          <div style={{ fontSize: "14px" }}>
                            <div className="flex justify-content-center align-items-center contact-row">
                              <div className="contact-row-img">
                                <img src={icons.message} alt="message" />
                              </div>

                              <div className="contact-row-text">{email}</div>
                              {isLogedIn && isAdmin && (
                                <i
                                  className="pi pi-pencil p-1 pointer"
                                  onClick={() => displayChangeContact("email")}
                                  style={{
                                    border: "2px solid #3a3432",
                                    backgroundColor: "white",
                                  }}
                                ></i>
                              )}
                            </div>
                            <div className="flex justify-content-center align-items-center contact-row">
                              <div className="contact-row-img">
                                <img src={icons.phone} alt="phone" />
                              </div>
                              <div className="contact-row-text">{phone}</div>
                              {isLogedIn && isAdmin && (
                                <i
                                  className="pi pi-pencil p-1 pointer"
                                  onClick={() => displayChangeContact("phone")}
                                  style={{
                                    border: "2px solid #3a3432",
                                    backgroundColor: "white",
                                  }}
                                ></i>
                              )}
                            </div>
                            <div className="flex justify-content-center align-items-center contact-row">
                              <div className="contact-row-img">
                                <img src={icons.location} alt="location" />
                              </div>
                              <div className="contact-row-text">{location}</div>
                              {isLogedIn && isAdmin && (
                                <i
                                  className="pi pi-pencil p-1 pointer"
                                  onClick={() =>
                                    displayChangeContact("location")
                                  }
                                  style={{
                                    border: "2px solid #3a3432",
                                    backgroundColor: "white",
                                  }}
                                ></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>} */}
            </div>
          </div>
          <div className="pointer home-page">
            <div
              className="flex justify-content-center p-3 "
              style={{ backgroundColor: "#f0e5d4" }}
              id="bussines-terms"
            >
              <NavLink
                to="/purchase-conditions"
                className="text-center"
                style={{ textDecoration: "none" }}
              >
                OBCHODNÉ A REKLAMAČNÉ PODMIENKY
              </NavLink>
            </div>

            <div
              className="flex  justify-content-center p-3 text-center"
              style={{
                backgroundColor: "#f0e5d4",
                width: "100%",
                border: "none",
              }}
              id="safety-protection"
            >
              <NavLink
                to="/privacy-policy"
                className="text-center"
                style={{ textDecoration: "none" }}
              >
                OCHRANA OSOBNÝCH ÚDAJOV
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomePage;
