import React, { useState, useEffect, useContext } from "react";
import { UserInfoContext } from "../UserInfo";
import { useParams } from "react-router-dom";
import Products from "./Products";

function ProductsKind() {
  const [products, setProducts] = useState(null);
  const { updateLoadingState, loading } = useContext(UserInfoContext);
  const params = useParams();

  useEffect(() => {
    updateLoadingState(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productKind: params.productKind,
      }),
    };
    fetch("/api/products/getOverviewData", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.products);
      });
  }, []);

  return (
    <div>
      <Products
        allProducts={products}
        updateLoadingState={updateLoadingState}
        loading={loading}
      />
    </div>
  );
}

export default ProductsKind;
