import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="flex justify-content-center">
      <div className="document">
        <div className="flex justify-content-center ">
          <h2 className="text-center">Ochrana osobných údajov</h2>
        </div>

        <section>
          <h3>Prehlásenie</h3>
          <p>
            Prehlasujeme, že ako prevádzkovateľ vašich osobných údajov spĺňame
            všetky zákonné povinnosti vyžadované platnou legislatívou, najmä
            zákonom o ochrane osobných údajov a GDPR, a teda že:
          </p>
          <p>
            Budeme spracovávať vaše osobné údaje len na základe platného
            právneho dôvodu, a to predovšetkým oprávneného záujmu, plnenia
            zmluvy, zákonných povinností či udeleného súhlasu. Plníme podľa
            článku 13 GDPR informačnú povinnosť ešte pred začatím spracovania
            osobných údajov. Umožníme a budeme vás podporovať v uplatňovaní a
            plnení vašich práv podľa zákona o ochrane osobných údajov a GDPR.
          </p>
        </section>

        <section>
          <h3>Informácie o ochrane osobných údajov</h3>
          <p>
            V súvislosti so spracúvaním Vašich osobných údajov zo strany ZSČO
            Bronislava Leitman ako prevádzkovateľa, vám ako osobe, ktorej osobné
            údaje spracúvame (ďalej len „dotknutá osoba“) týmto poskytujeme
            informácie podľa článkov 13 a 14 GDPR, ktoré sme koncipovali formou
            otázok, a to tak, aby tieto informácie boli pre Vás zrozumiteľné,
            prehľadné a praktické:
          </p>
          <h3>Kto je prevádzkovateľom vašich osobných údajov?</h3>
          <p>
            Prevádzkovateľom osobných údajov na webových stránkach{" "}
            <a
              href="http://www.broleit.com"
              style={{ textDecoration: "underline" }}
            >
              www.broleit.com
            </a>{" "}
            podľa § 5 písm. o) zákona č. 18/2018 Z.z. o ochrane osobných údajov
            v znení neskorších predpisov (ďalej len „Zákon“) je spoločnosť:
          </p>
          <address>
            Bronisava Leitman
            <br />
            IČO: 55 970 834
          </address>
          <p>
            Vaše osobné údaje spracovávame ako prevádzkovateľ, tj. určujeme, ako
            budú osobné údaje spracovávané a za akým účelom, ako dlho a vyberáme
            prípadné ďalšie sprostredkovateľov, ktorí nám so spracovaním budú
            pomáhať. Ak sa na nás budete chcieť v priebehu spracovania obrátiť,
            môžete nás kontaktovať elektronicky na e-mail:{" "}
            <a
              href="mailto:info@broleit.com"
              style={{ textDecoration: "underline" }}
            >
              info@broleit.com
            </a>{" "}
            alebo písomne na vyššie uvedenú adresu.
          </p>
        </section>

        <section>
          <h3>V akom rozsahu a prečo spracovávame vaše údaje?</h3>
          <p>
            Spracovávame osobné údaje, ktoré ste nám sami poskytli, a to z
            nasledujúcich dôvodov (pre naplnenie týchto účelov):
          </p>
          <h3>Poskytovanie služieb, plnenie zmluvy</h3>
          <p>
            Vaše osobné údaje v rozsahu: e-mail, meno, priezvisko, adresa
            bydliska, v prípade nákupu ako podnikateľ alebo firma tiež IČO, DIČ
            prípadne IČDPH, číslo účtu, popr. telefón nevyhnutne potrebujeme k
            plneniu zmluvy (napr. zaslanie prístupov do aplikácie, dodanie
            tovaru a pod.) a vedeniu účtovníctva.
          </p>
          <p>
            Ak ste našimi zákazníkmi, vaše osobné údaje (fakturačné údaje)
            nevyhnutne potrebujeme, aby sme vyhoveli zákonnej povinnosti pre
            vystavovanie a evidenciu daňových dokladov.
          </p>
        </section>

        <section>
          <h3>Marketing – zasielanie newslettrov</h3>
          <p>
            Vaše osobné údaje (email a meno) v minimálnej miere využívame za
            účelom priameho marketingu – zasielanie obchodných oznámení. Ak ste
            naším zákazníkom, robíme tak z oprávneného záujmu, lebo dôvodne
            predpokladáme, že vás naše novinky zaujímajú, a to po dobu 3 rokov
            od poslednej objednávky/faktúry.
          </p>
          <p>
            Ak nie ste naším zákazníkom, zasielame vám newslettre len na základe
            vášho súhlasu, po dobu 3 rokov od udelenia. Súhlas nám vyjadríte
            tzv. Double opt-in potvrdením. Toto potvrdenie je jasným vyjadrením
            vášho súhlasu so spracovaním vašich osobných údajov.
          </p>
          <p>
            V oboch prípadoch môžete tento súhlas odvolať použitím
            odhlasovacieho odkazu v každom zaslanom e-maili, prípadne môžete
            svoj súhlas odvolať e-mailom na{" "}
            <a href="mailto:info@broleit.com">info@broleit.com</a>.
          </p>
        </section>

        <section>
          <h3>Fotografická dokumentácia, referencie</h3>
          <p>
            Iba na základe vášho súhlasu môžeme použiť vaše fotografie, videá
            alebo písomné referencie na svojich stránkach, a to do doby, než váš
            udelený súhlas odvoláte. Ten možno kedykoľvek odvolať
            prostredníctvom našich kontaktných údajov.
          </p>
          <p>
            Vaše osobné údaje si ponechávame po dobu premlčacích lehôt, pokiaľ
            zákon neustanovuje dlhšiu dobu k ich uchovanie alebo sme v
            konkrétnych prípadoch neuviedli inak.
          </p>
        </section>

        <section>
          <h3>Cookies</h3>
          <p>
            Pri prechádzaní našich webových stránok zaznamenávame vašu IP
            adresu, ako dlho sa na stránke zdržíte a z ktorej stránky
            prichádzate. Používanie cookies na meranie návštevnosti webu.
          </p>
          <p>
            Naše webové stránky možno prechádzať aj v režime, ktorý neumožňuje
            zbieranie osobných údajov. Používanie cookies môžete na svojom
            počítači zakázať priamo v prehliadači, ktorý používate
          </p>
          <ul>
            <li>Chrome</li>
            <li>Firefox</li>
            <li>Internet Explorer</li>
            <li>alebo iné</li>
          </ul>
          <p>
            Pokiaľ vás zaujímajú cookies viac, ďalšie informácie nájdete v
            sekcii{" "}
            <a href="#cookies-policy" style={{ textDecoration: "underline" }}>
              Cookies Policy
            </a>
            .
          </p>
        </section>

        <section>
          <h3>Zabezpečenie a ochrana osobných údajov</h3>
          <p>
            Chránime osobné údaje v maximálnej možnej miere pomocou moderných
            technológií, ktoré zodpovedajú stupňu technického rozvoja. Chránime
            ich, ako keby boli naše vlastné. Prijali sme a udržujeme všetky
            dostupné (aktuálne známe) technické a organizačné opatrenia, ktoré
            zamedzujú zneužitiu, poškodeniu alebo zničeniu vašich osobných
            údajov, najmä:
          </p>
          <ul>
            <li>
              Zabezpečeným prístupom do našich počítačov (zabezpečené heslom).
            </li>
            <li>
              Zabezpečeným prístupom do našich telefónov (zabezpečené heslom a
              čítačkou odtlačkov prstov).
            </li>
            <li>
              Zabezpečeným prístupom do našich e-mailových schránok (zabezpečené
              užívateľským menom a heslom).
            </li>
            <li>
              Zabezpečeným prístupom do aplikácií na e-mailovú rozosielanie
              (zabezpečené menom a heslom).
            </li>
            <li>
              Zabezpečeným prístupom do fakturačných systémov (zabezpečené menom
              a heslom).
            </li>
            <li>
              Šifrovanou komunikáciou na našom webe (platný certifikát https).
            </li>
            <li>
              Zabezpečeným prístupom do členských sekcií webu (zabezpečené
              užívateľským menom a heslom).
            </li>
            <li>Pravidelnou aktualizáciou softvéru.</li>
          </ul>
          <p>
            Osobné údaje budú spracovávané v elektronickej podobe
            automatizovaným spôsobom alebo v tlačenej podobe neautomatizovaným
            spôsobom.
          </p>
        </section>
        <section>
          <h3>Aké subjekty majú prístup k vašim osobným údajom?</h3>
          <p>
            Pre zaistenie riadneho fungovania našej spoločnosti majú prístup k
            osobným údajom aj tretie subjekty. Ide o poskytovateľa
            marketingových služieb, poskytovateľa IT služieb a poskytovateľa
            služieb spojených s odosielaním hromadného newslettera – MailChimp.
          </p>
        </section>
        <section>
          <h3>Uskutočňujeme prenos údajov do tretích krajín?</h3>
          <p>
            Vaše údaje nebudeme odovzdávať do tretích krajín. Dáta spracovávame
            výhradne v Európskej únii alebo v krajinách, ktoré zaisťujú stupeň
            ochrany na základe rozhodnutia Európskej komisie.
          </p>
        </section>
        <section>
          <h3>Aké máte práva v súvislosti so spracúvaním osobných údajov?</h3>
          <p>
            V súvislosti s ochranou osobných údajov máte množstvo práv. Ak
            budete chcieť niektoré z týchto práv využiť, prosím, kontaktujte nás
            prostredníctvom e-mailu:{" "}
            <a
              href="mailto:info@broleit.com"
              style={{ textDecoration: "underline" }}
            >
              info@broleit.com
            </a>
            .
          </p>
          <p>
            Máte právo na informácie, ktoré je plnené už touto informačnou
            stránkou so zásadami spracovania osobných údajov.
          </p>
          <p>
            Vďaka právu na prístup nás môžete kedykoľvek vyzvať a my vám
            doložíme v lehote 14 dní, aké vaše osobné údaje spracovávame a
            prečo.
          </p>
          <p>
            Ak sa u vás niečo zmení alebo akékoľvek svoje osobné údaje
            považujete za neaktuálne alebo neúplné, máte právo na doplnenie a
            zmenu osobných údajov.
          </p>
          <p>
            Právo na obmedzenie spracovania môžete využiť, ak sa domnievate, že
            spracovávame vaše nepresné údaje, ak si myslíte, že vykonávame
            spracovanie nezákonne, ale nechcete všetky údaje vymazať alebo ak
            ste vzniesli námietku proti spracovaniu.
          </p>
          <p>
            Obmedziť môžete rozsah osobných údajov alebo účelov spracovania.
            (Napr. Odhlásením z newslettera obmedzujete účel spracovania pre
            zasielanie obchodných oznámení.)
          </p>
          <p>
            Právo na prenosnosť: Ak by ste chceli svoje osobné údaje vziať a
            preniesť k niekomu inému, budeme postupovať rovnako ako pri využití
            práva na prístup – informácie vám pošleme v strojovo čitateľnej
            podobe. Lehota plnenia do 14 dní.
          </p>
          <p>
            Právo na výmaz (byť zabudnutý): Vaším ďalším právom je právo na
            vymazanie (byť zabudnutý). Nechceme na vás zabudnúť, ale ak si to
            budete priať, máte na to právo. V takom prípade vymažeme všetky vaše
            osobné údaje zo svojho systému aj zo systému všetkých čiastkových
            spracovateľov a záloh. Na zabezpečenie práva na výmaz si
            vyhradzujeme lehotu 14 dní.
          </p>
          <p>
            V niektorých prípadoch sme viazaný zákonnou povinnosťou, a napr.
            musíme evidovať vystavené daňové doklady po lehotu stanovenú
            zákonom. V tomto prípade teda zmažeme všetky také osobné údaje,
            ktoré nie sú viazané iným zákonom. O dokončení výmazu vás budeme
            informovať e-mailom.
          </p>
          <h4>Sťažnosť na Úrade na ochranu osobných údajov</h4>
          <p>
            Ak máte pocit, že s vašimi údajmi nezaobchádzame v súlade so
            zákonom, máte právo sa so svojou sťažnosťou kedykoľvek obrátiť na
            Úrad na ochranu osobných údajov. Budeme radi, ak najprv budete o
            tomto podozrení informovať nás, aby sme s tým mohli niečo urobiť a
            prípadné pochybenie napraviť.
          </p>
          <h4>Odhlásenie zo zasielania newsletterov a obchodných oznámení</h4>
          <p>
            Emaily s inšpiráciou, článkami či produktami a službami vám
            zasielame, ak ste náš zákazník na základe nášho oprávneného záujmu.
          </p>
          <p>
            Ak zákazníkom ešte nie ste, posielame vám ich len na základe vášho
            súhlasu. V oboch prípadoch môžete ukončiť odber našich e-mailov
            stlačením odhlasovacieho odkazu v každom zaslanom e-maili, alebo
            odhlásením sa z odberu na{" "}
            <a
              href="mailto:info@broleit.com"
              style={{ textDecoration: "underline" }}
            >
              info@broleit.com
            </a>
            .
          </p>
        </section>
        <section>
          <h4>Zachovávanie mlčanlivosti</h4>
          <p>
            Dovoľujeme si vás uistiť, že naši zamestnanci aj spolupracovníci,
            ktorí budú spracovávať Vaše osobné údaje, sú povinní zachovávať
            mlčanlivosť o osobných údajoch a o bezpečnostných opatreniach,
            ktorých zverejnenie by ohrozilo bezpečnosť vašich osobných údajov.
            Táto mlčanlivosť pritom trvá aj po skončení záväzkových vzťahov s
            nami. Bez vášho súhlasu nebudú vaše osobné údaje vydané žiadnej inej
            tretej strane.
          </p>
        </section>
        <section>
          <h4>
            Používate profilovanie a automatizované rozhodovanie pri spracúvaní
            osobných údajov?
          </h4>
          <p>
            <a
              href="http://www.broleit.com"
              style={{ textDecoration: "underline" }}
            >
              www.broleit.com
            </a>{" "}
            v rámci svojich aktivít nevyužíva automatizované rozhodovanie a
            profilovanie.
          </p>
          <p>Tieto zásady spracovania osobných údajov platia od 08.01.2024.</p>
        </section>
        <section id="cookies-policy">
          <h3>Cookies policy</h3>
          <p>
            Súbor cookie je malý textový súbor, ktorý je navštívenou webovou
            lokalitou uložený do vášho počítača. Pri budúcej návšteve rovnakej
            webovej lokality potom umožní použiť rovnaké predvoľby.
          </p>
          <h4>Nevyhnutné Cookies</h4>
          <p>
            Ide o cookies, ktoré potrebuje webová stránka, aby fungovala
            správne. Väčšinou sú dané použitým CMS systémom. Ukladanie týchto
            cookies je dané nastavením vášho prehliadača, takže ak si myslíte,
            že je to pre vás vhodné, zakážte ich ukladanie v prehliadači. Ide o
            základné cookies, ktoré sa používajú napr. pri navigácii, bez ich
            povolenia nemusí webová stránka fungovať správne.
          </p>
          <h4>Technické Cookies</h4>
          <p>
            Cookies, bez ktorých stránka funguje správne, ale ovplyvňujú vaše
            používanie stránky. Ukladajú sa napríklad informácie o prihlásení
            (zapamätať si ma) alebo o iných častiach stránky, ktoré môžete
            upraviť podľa seba.
          </p>
          <h4>Trakovacie Cookies</h4>
          <p>
            Ide o cookies, ktoré sa používajú na sledovanie správania sa
            návštevníkov stránky (monitorovanie stránky). Na tejto stránke sa
            používa len Google Analytics. Všetky tieto informácie sú agregované
            a z toho dôvodu anonymné. Napriek tomu môžete v spodnej lište
            odmietnuť cookies, potom sa kód GA do stránky nevloží.
          </p>
          <h4>Ostatné Cookies tretích strán</h4>
          <p>
            Ide o cookies, ktoré sa ukladajú pri návšteve našej stránky, ale
            nemáme na ne vplyv. Neukladajú žiadne osobné informácie, len
            informácie o zariadení, ktoré používate a webovom prehliadači.
            Najčastejšie ide o: Google, Facebook, Vimeo, Youtube.
          </p>
          <p>
            Cookies od Google. Tieto cookies vkladá prehliadač, pokiaľ používate
            služby od google (jeden účet pre všetko). Nemôžeme ovplyvniť
            (vymazať) cookies v klientovom browseri, ktoré tam vkladá iná
            doména, to nie je technicky možné. Môžete tomu zabrániť, ak sa
            odhlásite z prehliadača (občas nepomôže ani inkognito). Podstatné
            je, že tieto cookies si podľa GDPR ošetril google sám a ako ďalšiu
            cookie vkladá váš súhlas, ktorý ste mu dali, keď ste si vytvárali
            google konto. Vloženie analytického kódu ovplyvniť môžete (viď
            trakovacie cookies).
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
