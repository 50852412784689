import React, { useState, useEffect, useContext } from "react";
import ReactStars from "react-rating-stars-component";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import { UserInfoContext } from "../UserInfo";

function UserReviews() {
  const [selectedReviewIndex, setSelectedReviewIndex] = useState(null);
  const [passwordExists, setPasswordExists] = useState(null);
  const [reviews, setReviews] = useState(null);
  const { loading, updateLoadingState } = useContext(UserInfoContext);
  const [password, setPassword] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [displayLoading, setDisplayLoading] = useState(false);
  const [dialogPurpose, setDialogPurpose] = useState("");
  const navigate = useNavigate();

  const handleStorageChange = (event) => {
    if (event.key === "reviewAddedFromProfile") {
      if (localStorage.getItem("reviewAddedFromProfile")) {
        localStorage.removeItem("reviewAddedFromProfile");
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    updateLoadingState(true);
    if (localStorage.getItem("logedIn")) {
      fetch("/api/user/getUsersReviews")
        .then((response) => response.json())
        .then((data) => {
          setPasswordExists(data.passwordExists);
          setReviews(data.reviews);
          updateLoadingState(false);
        })
        .catch((error) => {
          console.log(error);
          updateLoadingState(false);
        });
    }
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const deleteReview = () => {
    updateLoadingState(true);
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        id: reviews[selectedReviewIndex].id,
        refreshToken: localStorage.getItem("refreshToken"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch("/api/reviews/deleteReviewByRegisteredUser", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          localStorage.setItem(
            "reviewChanged",
            JSON.stringify({ changed: true })
          );
          setReviews((prevState) => {
            const updatedReviews = prevState.filter(
              (review, index) => index !== selectedReviewIndex
            );
            return updatedReviews;
          });
        }
        updateLoadingState(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoadingState(false);
      });
  };

  const hideUpdateReview = () => {
    setDialogPurpose("");
  };

  const updateOrRemoveReview = (index, method) => {
    setSelectedReviewIndex(index);
    if (passwordExists) {
      setDialogPurpose(method);
    } else {
      if (method === "updateWithoutPassword") {
        navigate(`/profile/edit-review/${reviews[index].id}`);
      } else {
        setDialogPurpose(method);
      }
    }
  };

  const comparePasswords = (event) => {
    event.preventDefault();
    setAlertMsg("");
    setDisplayLoading(true);
    if (!password) {
      setAlertMsg("Prosím zadajte heslo");
      setDisplayLoading(false);
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: password,
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      };

      fetch("/api/auth/comparePasswords", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setDisplayLoading(false);
          setPassword("");
          if (!data.success) {
            setAlertMsg(data.message);
          } else {
            if (dialogPurpose === "delete") {
              deleteReview();
            } else if (dialogPurpose === "update") {
              navigate(
                `/profile/edit-review/${reviews[selectedReviewIndex].id}`
              );
            }
            setDialogPurpose("");
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const confirmationDialog = () => {
    return (
      <Dialog
        header="Odstránenie recenzie"
        visible={dialogPurpose == "deleteWithoutPassword"}
        onHide={hideUpdateReview}
        dismissableMask
        resizable={false}
        draggable={false}
      >
        <div className="confirmation-width">
          <div>Naozaj chcete odstrániť recenziu?</div>
          <div className="flex mt-4 justify-content-end">
            <button
              className="btn dark p-2 pointer mr-2"
              style={{
                width: "50px",
              }}
              onClick={deleteReview}
            >
              Áno
            </button>
            <button
              className="btn light p-2 pointer"
              style={{
                width: "50px",
              }}
              onClick={() => setDialogPurpose("")}
            >
              Nie
            </button>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : reviews && reviews.length > 0 ? (
        <div className="flex justify-content-center flex-column align-items-center">
          {confirmationDialog()}
          <Dialog
            header={`${
              dialogPurpose === "delete"
                ? "Odstránenie recenzie"
                : "Upravenie recenzie"
            }`}
            visible={dialogPurpose === "delete" || dialogPurpose === "update"}
            onHide={hideUpdateReview}
            dismissableMask
            resizable={false}
            draggable={false}
          >
            <div className="mb-2" style={{ fontSize: "16px" }}>
              {`Pre ${
                dialogPurpose === "delete" ? "odstránenie" : "úpravu"
              } recenzie prosím zadajte heslo`}
            </div>
            <form onSubmit={comparePasswords}>
              <input
                type="password"
                placeholder="Heslo"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                style={{
                  borderBottom: alertMsg !== "" ? "2px solid #df4930" : "",
                  width: "100%",
                }}
              ></input>
              {alertMsg !== "" && (
                <div
                  className="alert mt-2"
                  style={{ fontSize: "15px", width: "100%" }}
                >
                  {alertMsg}
                </div>
              )}
              <button className="btn dark mt-3">
                {displayLoading && (
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "20px", marginRight: "10px" }}
                  ></i>
                )}
                Odoslať
              </button>
            </form>
          </Dialog>
          {reviews &&
            reviews.map((review, index) => {
              return (
                <div
                  className="pb-2 mb-2 flex justify-content-between"
                  key={index}
                  style={{
                    borderBottom:
                      index + 1 !== reviews.length && "1px solid gray",
                    width: "70vw",
                  }}
                >
                  <div>
                    <ReactStars
                      count={5}
                      size={20}
                      value={parseFloat(review.stars)}
                      edit={false}
                      isHalf={true}
                      color="#9e918c"
                      activeColor="#3a3432"
                      classNames="mb-1"
                    />
                    <div style={{ fontSize: "15px" }} className="mb-2">
                      {review.comment}
                    </div>
                    <div style={{ fontSize: "14px" }}>-{review.user_name}</div>
                  </div>
                  <div className="flex justify-content-end flex-wrap">
                    <div>
                      <i
                        className="pi pi-trash pointer p-1"
                        style={{
                          border: "2px solid #3a3432",
                        }}
                        id={review.id}
                        onClick={
                          passwordExists
                            ? () => updateOrRemoveReview(index, "delete")
                            : () =>
                                updateOrRemoveReview(
                                  index,
                                  "deleteWithoutPassword"
                                )
                        }
                      ></i>
                    </div>
                    <div>
                      <i
                        className="pi pi-pencil ml-2 pointer p-1"
                        style={{
                          border: "2px solid #3a3432",
                        }}
                        id={review.id}
                        onClick={
                          passwordExists
                            ? () => updateOrRemoveReview(index, "update")
                            : () =>
                                updateOrRemoveReview(
                                  index,
                                  "updateWithoutPassword"
                                )
                        }
                      ></i>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div
          className="flex  justify-content-center align-items-center center-text-profile"
          style={{ fontSize: "18px" }}
        >
          Zatiaľ ste nepridali žiadne recenzie
        </div>
      )}
    </div>
  );
}

export default UserReviews;
