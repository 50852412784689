import React, { useState, useEffect, uceContext, useContext } from "react";
import { useParams, useNavigate, useLoaderData } from "react-router-dom";
import Loading from "../Loading";
import ReviewForm from "./ReviewForm";
import { UserInfoContext } from "../UserInfo";

function AddNewReview() {
  const loaderData = useLoaderData();
  const navigate = useNavigate();
  const params = useParams();
  const [reviewInfo, setReviewInfo] = useState(null);
  const [alertStarsFromServer, setAlertStarsFromServer] = useState(null);
  const [alertCommentFromServer, setAlertCommentFromServer] = useState(null);
  const [alertNameFromServer, setAlertNameFromServer] = useState(null);
  const { loading } = useContext(UserInfoContext);
  useEffect(() => {
    setReviewInfo(loaderData.review);

    const handleStorageChange = (event) => {
      if (event.key === "reviewChanged") {
        const reviewChanged = localStorage.getItem("reviewChanged")
          ? JSON.parse(localStorage.getItem("reviewChanged"))
          : null;
        if (reviewChanged) {
          localStorage.removeItem("reviewChanged");
          window.location.reload();
        }
      } else if (event.key === "reviewAddedFromProfile") {
        if (localStorage.getItem("reviewAddedFromProfile")) {
          window.location.reload();
        }
      }
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const setRestOfReviewInfo = (event) => {
    const { value, name } = event.target;
    setReviewInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setStars = (newRating) => {
    setReviewInfo((prevState) => ({
      ...prevState,
      stars: parseFloat(newRating),
    }));
  };

  const manageReview = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stars: reviewInfo.stars,
        name: reviewInfo.name,
        comment: reviewInfo.comment,
        token: params.token,
      }),
    };
    fetch("/api/reviews/addNewReview", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.emptyFields) {
          setAlertCommentFromServer(data.alertComment);
          setAlertNameFromServer(data.alertName);
          setAlertStarsFromServer(data.alertStars);
        } else if (!data.emptyFields && data.success) {
          if (reviewInfo.orderId) {
            localStorage.setItem("reviewAddedFromProfile", true);
            navigate(`/profile/user-orders/${reviewInfo.orderId}`);
          } else {
            window.location.reload();
          }
          localStorage.setItem("reviewChanged", true);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {!reviewInfo || loading ? (
        <Loading />
      ) : !reviewInfo.reviewId ? (
        <div className="flex justify-content-center">
          <ReviewForm
            reviewInfo={reviewInfo}
            setStarsOfReview={setStars}
            setRestOfReviewInfo={setRestOfReviewInfo}
            manageReview={manageReview}
            alertStarsFromServer={alertStarsFromServer}
            alertNameFromServer={alertNameFromServer}
            alertCommentFromServer={alertCommentFromServer}
          />
        </div>
      ) : (
        <div className="flex justify-content-center">
          <div className="review-send-wrapper flex align-items-center">
            <div style={{ fontSize: "18px" }}>
              <div className="title" style={{ fontWeight: "bold" }}>
                Ďakujeme za hodnotenie!
              </div>
              <div className="text">
                Tvoje hodnotenie bolo úspešne odoslané, k zobrazeniu je dostupné
                na{" "}
                <a
                  style={{
                    color: "#3a3432",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                  className="pointer"
                  onClick={() => navigate("/")}
                >
                  domovskej obrazovke
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddNewReview;
