import React from "react";
import { useLoaderData } from "react-router-dom";
function OrderPaidSuccess() {
  const loaderData = useLoaderData();

  return (
    <div
      className="flex align-items-center justify-content-center"
      style={{ height: "90vh" }}
    >
      <div
        style={{
          maxWidth: "600px",
          boxShadow: " 0 2px 10px rgba(0, 0, 0, 0.3)",
          padding: "20px 50px",
          borderRadius: "10px",
        }}
      >
        <div className="flex justify-content-center align-items-center">
          <i
            style={{ color: "green", fontSize: "40px" }}
            className="pi pi-check-circle"
          ></i>
          <div
            className="m-l-cancel"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            Objednávka bola zaplatená
          </div>
        </div>
        {loaderData.email && (
          <div className="text-center mt-2">
            Tvoja objednávka bola úspešne zaplatená, o jej priebehu ťa budeme
            priebežne informovať na {loaderData.email}
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderPaidSuccess;
