import React, { useRef, useState, useEffect, useContext } from "react";
import ProdutctDetailImage from "./ProductDetail/ProdutctDetailImage";

import { UserInfoContext } from "../UserInfo";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";

function GiftCartCreator() {
  const [itemAddedVisible, setItemAddedVisible] = useState(false);
  const [newGiftCart, setNewGiftCart] = useState({
    amount: "",
    expirationDate: "",
    deliveryMethod: "email",
  });
  const toast = useRef(null);
  const [submitBtnClicked, setSubmitBtnClicked] = useState(false);
  const { isLogedIn, updateItemsCount, loading } = useContext(UserInfoContext);
  const navigate = useNavigate();
  const icons = require("../../icons.json").icons;

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  useEffect(() => {
    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 3);
    currentDate.setHours(0, 0, 0, 0);
    const formattedDate = formatDate(currentDate);
    setNewGiftCart((prevState) => ({
      ...prevState,
      expirationDate: formattedDate,
    }));
  }, []);

  const handleInput = (event) => {
    const { value, name } = event.target;
    setNewGiftCart((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Nebolo možné vytvoriť poukážku",
      detail: message,
      life: 5000,
    });
  };

  const addToShoppingCart = (event) => {
    event.preventDefault();
    setSubmitBtnClicked(true);
    if (newGiftCart.amount === "") {
      showError("Vyplňte prosím všetky polia");
    } else {
      if (!localStorage.getItem("shoppingCart")) {
        localStorage.setItem("shoppingCart", JSON.stringify([]));
      }
      let storedCart = JSON.parse(localStorage.getItem("shoppingCart"));
      const productName = `Poukážka na ${
        newGiftCart.deliveryMethod === "email" ? "email" : "adresu"
      }`;
      let amount = 1;
      let sameGiftCart = false;
      for (const item of storedCart) {
        if (
          item.name === productName &&
          item.price === parseFloat(newGiftCart.amount)
        ) {
          item.amount += 1;
          amount = item.amount;
          sameGiftCart = true;
        }
      }
      if (!sameGiftCart) {
        const newitem = {
          name: productName,
          amount: 1,
          image: icons.voucherFront,
          price: parseFloat(newGiftCart.amount),
        };

        const updatedCart = [...storedCart, newitem];
        updateItemsCount(updatedCart.length);
        localStorage.setItem("shoppingCart", JSON.stringify(updatedCart));
      } else {
        localStorage.setItem("shoppingCart", JSON.stringify(storedCart));
      }
      setItemAddedVisible(true);
      if (isLogedIn) {
        //to do-add to cart if loged in
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: parseInt(amount, 10),
            product_name: productName,
            price: parseFloat(newGiftCart.amount),
          }),
        };
        fetch("/api/products/addToCart", requestOptions);
      }
    }
  };
  const dialogHeader = () => {
    return (
      <div className="flex align-items-center">
        <i
          style={{ color: "green", fontSize: "40px" }}
          className="pi pi-check-circle"
        ></i>
        <div className="ml-3" style={{ fontSize: "20px" }}>
          Produkt bol pridaný do košíka
        </div>
      </div>
    );
  };

  const dialogContent = () => {
    return (
      <div className="flex">
        <img
          style={{ objectFit: "cover", height: "120px" }}
          src={icons.voucherFront}
          alt="voucher"
        />
        <div>
          <div
            style={{ fontSize: "15px", fontWeight: "bold", marginLeft: "10px" }}
          >
            {newGiftCart.deliveryMethod === "email"
              ? "Poukážka na email"
              : "Poukážka na adresu"}
          </div>

          <div
            style={{ color: "#3a3432", fontSize: "15px", marginLeft: "10px" }}
          >
            {parseFloat(newGiftCart.amount).toFixed(2).replace(".", ",")}€
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Dialog
            header={dialogHeader}
            visible={itemAddedVisible}
            className="dialog-added-to-cart"
            onHide={() => setItemAddedVisible(false)}
            dismissableMask
            draggable={false}
          >
            {dialogContent()}
            <div className="flex justify-content-between">
              <button
                className="dialog-add-to-cart-btn pointer"
                style={{
                  width: "50%",
                  color: "#f0e5d4",
                  border: "none",
                  backgroundColor: "#3a3432",
                  fontSize: "15px",
                }}
                onClick={() => setItemAddedVisible(false)}
              >
                Pokračovať v nákupe
              </button>
              <button
                className="dialog-add-to-cart-btn pointer"
                style={{
                  backgroundColor: "#f0e5d4",
                  border: "2px solid #3a3432",
                  color: "#3a3432",
                  fontSize: "15px",
                }}
                onClick={() => navigate("/shopping-cart")}
              >
                Prejsť do košíka
              </button>
            </div>
          </Dialog>
          <div className="flex justify-content-center flex-wrap mt-5 create-giftcard-wrapper">
            <div>
              <ProdutctDetailImage
                images={[
                  { image: icons.voucherFront, id: null },
                  { image: icons.voucherBack, id: null },
                ]}
              />
            </div>

            <form
              className="product-detail-specification"
              onSubmit={addToShoppingCart}
            >
              <div className="flex align-items-end mb-3">
                <label htmlFor="code" className="mr-2 align-items-end">
                  Suma:
                </label>
                <input
                  type="number"
                  style={{
                    borderBottom:
                      submitBtnClicked && !newGiftCart.amount
                        ? "2px solid #df4930"
                        : "",
                    width: "100%",
                  }}
                  onChange={handleInput}
                  value={newGiftCart.amount}
                  name="amount"
                  id="amount"
                />
              </div>
              <div className="flex align-items-end mb-3 justify-content-between">
                <div htmlFor="code" className="mr-2 align-items-end">
                  Využiť do:
                </div>
                <div>{newGiftCart.expirationDate}</div>
              </div>
              <div className="flex align-items-end mb-3 justify-content-between">
                <div htmlFor="code" className="mr-2 align-items-end">
                  Doručiť na:
                </div>
                <div className="flex justify-content-end align-items-center order-details flex-wrap">
                  <div
                    className="flex justify-content-end align-items-center"
                    style={{ width: "120px" }}
                  >
                    <input
                      type="checkbox"
                      id={`${newGiftCart.deliveryMethod}`}
                      style={{ borderRadius: "10px" }}
                      checked={newGiftCart.deliveryMethod === "email"}
                      onChange={() =>
                        setNewGiftCart((prevState) => ({
                          ...prevState,
                          deliveryMethod: "email",
                        }))
                      }
                    ></input>
                    <label
                      htmlFor={`${newGiftCart.deliveryMethod}`}
                      className="flex align-items-center mt-2"
                    >
                      Email
                    </label>
                  </div>
                  <div
                    className="flex justify-content-end align-items-center"
                    style={{ width: "120px" }}
                  >
                    <input
                      type="checkbox"
                      id={`${newGiftCart.deliveryMethod}`}
                      style={{ borderRadius: "10px" }}
                      checked={newGiftCart.deliveryMethod === "post"}
                      onChange={() =>
                        setNewGiftCart((prevState) => ({
                          ...prevState,
                          deliveryMethod: "post",
                        }))
                      }
                    ></input>
                    <label
                      htmlFor={`${newGiftCart.deliveryMethod}`}
                      className="flex align-items-center mt-2"
                    >
                      Adresu
                    </label>
                  </div>
                </div>
              </div>

              <button
                className="btn round light pointer mt-1 mb-3"
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                PRIDAŤ DO KOŠÍKA
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default GiftCartCreator;
