import React from "react";

function Footer() {
  const icons = require("../icons").icons;

  return (
    <div
      style={{
        width: "100%",
        borderTop: "4px solid #3a3432",
        backgroundColor: "#f0e5d4 ",
      }}
      className="flex justify-content-center align-items-center pt-3 pb-3"
    >
      <div className="flex flex-column gap-2">
        <div
          style={{ fontWeight: "bold", fontSize: "16px" }}
          className="text-center"
        >
          Kontaktné údaje
        </div>
        <div
          className="flex flex-wrap justify-content-center gap-2"
          style={{ fontSize: "14px" }}
        >
          <div className="flex justify-content-start flex-wrap contact-small">
            <div className="flex contact-row">
              <div className="contact-row-img flex justify-content-start align-items-start mr-3">
                <img src={icons.location} alt="location" />
              </div>
              <div
                className="flex flex-column gap-1 contact-row-text"
                style={{ width: "150px" }}
              >
                <div>Poruba 405</div>
                <div>97211</div>
                <div>Slovensko</div>
              </div>
            </div>

            <div className="flex contact-row">
              <div className="contact-row-img mr-3">
                <img src={icons.message} alt="message" />
              </div>

              <div className="contact-row-text" style={{ width: "140px" }}>
                info@broleit.com
              </div>
            </div>
            <div className="flex  contact-row" style={{ width: "180px" }}>
              <div className="contact-row-img mr-3">
                <img src={icons.phone} alt="phone" />
              </div>
              <div className="contact-row-text" style={{ width: "140px" }}>
                +421903985818
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
