import React, { useEffect, useState, useContext } from "react";
import { UserInfoContext } from "../UserInfo";
import { NavLink } from "react-router-dom";
function TermsAndConditionsAgreement({ email, hideDialog }) {
  const [agreed, setAgreed] = useState(false);
  const { setLogedInUser } = useContext(UserInfoContext);
  const [submitBtnClicked, setSubmitBtnClicked] = useState(false);
  const [errorWhileRegister, setErrorWhileRegister] = useState(false);
  const [loadingIcon, setLoadingIcon] = useState(false);

  const handleAgreement = (event) => {
    event.preventDefault();
    setSubmitBtnClicked(true);
    if (agreed) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          wishlist: localStorage.getItem("wishList")
            ? JSON.parse(localStorage.getItem("wishList"))
            : [],
          shoppingCart: localStorage.getItem("shoppingCart")
            ? JSON.parse(localStorage.getItem("shoppingCart"))
            : [],
          agreed: agreed,
        }),
      };
      fetch("/api/auth/addNewlyRegisteredToDb", requestOptions)
        .then((response) => response.json())
        .then(async (data) => {
          setLoadingIcon(false);

          if (data.success) {
            hideDialog();
            await setLogedInUser();
          } else {
            if (!data.agreed) {
              setAgreed(false);
            } else {
              setErrorWhileRegister(true);
            }
          }
        })
        .catch(() => {
          setLoadingIcon(false);
          setErrorWhileRegister(true);
        });
    } else {
      setLoadingIcon(false);
    }
  };

  return (
    <div className="signIn mt-2 home-page">
      <div className="form-wrapper pt-5 pb-5">
        {errorWhileRegister && (
          <div className="missing-input mt-2 mb-2">
            Nebolo možné dokončiť registráciu, vyskúšajte to opäť neskôr
          </div>
        )}
        <div className="mb-2" style={{ fontWeight: "bold" }}>
          Pre dokončenie registrácie si prosím prečítajte pravidlá ochrany
          osobných údajov
        </div>
        <form onSubmit={handleAgreement} className="register-form">
          <div className="signIn mt-2 home-page">
            <input
              type="checkbox"
              checked={agreed}
              name="conditionsAgreed"
              id="conditionsAgreed"
              onChange={() => setAgreed((prevState) => !prevState)}
              style={{
                border:
                  submitBtnClicked && !agreed
                    ? "solid 1px #df4930"
                    : "solid 1px #3a3432",
              }}
            />
            <label style={{ fontSize: "15px" }} htmlFor="wannaJoin">
              Súhlasím s{" "}
              <NavLink
                to="/privacy-policy"
                style={{
                  textDecoration: "underline",
                  color: "#3a3432",
                  fontWeight: "bold",
                }}
              >
                <span className="switch-form">
                  {" "}
                  pravidlami ochrany osobných údajov
                </span>
              </NavLink>
            </label>
          </div>

          {submitBtnClicked && !agreed && (
            <div className="missing-input mt-2">
              Pre dokončenie registrácie musíte súhlasiť s pravidlami
            </div>
          )}
          <button
            className="submit pointer btn dark mt-3"
            onClick={() => setLoadingIcon(true)}
          >
            {loadingIcon && (
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "20px", marginRight: "10px" }}
              ></i>
            )}
            Potvrdiť
          </button>
        </form>
      </div>
    </div>
  );
}

export default TermsAndConditionsAgreement;
