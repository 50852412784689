import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import { useParams, useLoaderData, useNavigate } from "react-router-dom";

function ResetPasswordForm() {
  const { email, token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [displayLoading, setDisplayLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const loaderData = useLoaderData();
  const navigate = useNavigate();

  const [isInputMissing, setIsInputMissing] = useState({
    missingPass: false,
    missingConfirmPass: false,
  });
  const [formData, setFormData] = useState({
    formPassword: "",
    confirmFormPassword: "",
  });

  useEffect(() => {}, []);

  const isFormFilled = () => {
    let isFilled = true;
    if (formData.formPassword.length < 8) {
      setIsInputMissing((prevValues) => ({
        ...prevValues,
        missingPass: true,
      }));
      isFilled = false;
    } else {
      setIsInputMissing((prevValues) => ({
        ...prevValues,
        missingPass: false,
      }));
    }
    if (formData.confirmFormPassword.length < 8) {
      setIsInputMissing((prevValues) => ({
        ...prevValues,
        missingConfirmPass: true,
      }));
      isFilled = false;
    } else {
      setIsInputMissing((prevValues) => ({
        ...prevValues,
        missingConfirmPass: false,
      }));
    }
    return isFilled;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormFilled()) {
      if (formData.confirmFormPassword === formData.formPassword) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: email,
            token: token,
            password: formData.formPassword,
            confirmPassword: formData.confirmFormPassword,
          }),
        };
        fetch("/api/auth/resetPassword", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            setDisplayLoading(false);
            setIsSuccess(data.success);
            if (data.message) {
              setAlertText(data.message);
            } else {
              const currentUrl = window.location.href;
              if (currentUrl.includes("profile")) {
                navigate("/profile/user-details");
                localStorage.setItem("passwordChanged", true);
              }
              setAlertText("");
            }
          });
      } else {
        setDisplayLoading(false);
        setAlertText("Zadané heslá sa nezhodujú");
      }
    } else {
      setDisplayLoading(false);
      setAlertText("");
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setIsInputMissing((prevValues) => ({
      ...prevValues,
      missingPass: name === "formPassword" ? false : prevValues.missingPass,
      missingConfirmPass:
        name === "confirmFormPassword" ? false : prevValues.missingConfirmPass,
    }));
    setFormData((prevLoginInfo) => ({
      ...prevLoginInfo,
      [name]: value,
    }));
  };
  const togglePasswordVisibility = (event) => {
    event.stopPropagation();
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="center-container">
      {loaderData && loaderData.message ? (
        <div style={{ fontSize: "20px" }}>Čas na zmenu hesla vypršal</div>
      ) : isSuccess ? (
        <div
          className="flex  justify-content-center align-items-center center-text-profile"
          style={{ fontSize: "20px" }}
        >
          Zmena hesla prebehla úspešne
        </div>
      ) : alertText === "Čas na zmenu hesla vypršal" ? (
        <div style={{ fontSize: "20px" }}>{alertText}</div>
      ) : (
        <div className="reset-password-form">
          <form onSubmit={handleSubmit}>
            <div className="reset-password-form-text">
              <div>
                {alertText !== "" && (
                  <div style={{ marginBottom: "30px" }} className="alert">
                    {alertText}
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label htmlFor="formPassword">Nové heslo:</label>
              </div>
              <div className="password-container">
                <input
                  className="inputText"
                  type={showPassword ? "text" : "password"}
                  placeholder="Heslo"
                  id="formPassword"
                  name="formPassword"
                  value={formData.formPassword}
                  style={{
                    borderBottom: isInputMissing.missingPass
                      ? "solid 2px #df4930"
                      : "",
                    width: "100%",
                  }}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="password-toggle"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>

              {isInputMissing.missingPass &&
              formData.formPassword.length === 0 ? (
                <div className="missing-input">Je potrebné zadať heslo</div>
              ) : (
                isInputMissing.missingPass &&
                formData.formPassword.length < 8 && (
                  <div className="missing-input">
                    Heslo musí byť dlhé aspoň 8 znakov
                  </div>
                )
              )}
              <div className="mt-4 mb-2">
                <label htmlFor="confirmFormPassword">
                  Zopakujte nové heslo:
                </label>
              </div>

              <div className="password-container">
                <input
                  className="inputText"
                  type={showPassword ? "text" : "password"}
                  placeholder="Heslo"
                  id="confirmFormPassword"
                  name="confirmFormPassword"
                  value={formData.confirmFormPassword}
                  style={{
                    borderBottom: isInputMissing.missingPass
                      ? "solid 2px #df4930"
                      : "",
                    width: "100%",
                  }}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="password-toggle"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>

              {isInputMissing.missingConfirmPass &&
              formData.confirmFormPassword.length === 0 ? (
                <div className="missing-input">Je potrebné zopakovať heslo</div>
              ) : (
                isInputMissing.missingConfirmPass &&
                formData.confirmFormPassword.length < 8 && (
                  <div className="missing-input">
                    Heslo musí byť dlhé aspoň 8 znakov
                  </div>
                )
              )}

              <div>
                <button
                  className="pointer dark btn p-2 mt-3"
                  style={{ width: "100%" }}
                  onClick={() => setDisplayLoading((prevState) => !prevState)}
                >
                  {displayLoading && (
                    <i
                      className="pi pi-spin pi-spinner"
                      style={{ fontSize: "20px", marginRight: "10px" }}
                    ></i>
                  )}
                  Odoslať
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default ResetPasswordForm;
