import React, { useRef, useState, useEffect, useContext } from "react";
import { Galleria } from "primereact/galleria";
import { UserInfoContext } from "../../UserInfo";
import { Dialog } from "primereact/dialog";
import ImagesManager from "../../ImagesManager";
import { Toast } from "primereact/toast";

function ProductDetailImage({ images, idProduct }) {
  const toast = useRef(null);

  const galleria = useRef();
  const [idsExist, setIdsExist] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [imagesToGallery, setImagesToGallery] = useState([]);
  const lastActiveIndex = useRef(null);
  const { isLogedIn, isAdmin } = useContext(UserInfoContext);
  const [changeImages, setChangeImages] = useState(false);
  const [unauthorizedDialog, setUnauthorizedDialog] = useState(false);
  const itemTemplate = (item) => {
    return (
      <div className="product-detail-image">
        <img
          src={item}
          alt={item}
          className="pointer"
          onClick={() => {
            galleria.current.show();
          }}
        />
      </div>
    );
  };

  const itemTemplateFullSize = (item) => {
    return (
      <img
        src={item}
        alt={item}
        style={{ maxHeight: "90vh", maxWidth: "100%" }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <div className="overview-imgs-wrapper">
        <img src={item} alt={item} className="overview-img" />
      </div>
    );
  };

  useEffect(() => {
    // Update last activeIndex when activeIndex changes
    lastActiveIndex.current = activeIndex;
  }, [activeIndex]);

  useEffect(() => {
    let paths = [];
    let ids = [];
    images.map((image) => {
      paths.push(image.image);
      if (!image.id) {
        setIdsExist(false);
      }
      ids.push(image.id);
    });
    setImagesToGallery({ images: paths, ids: ids });
    // Set activeIndex to last activeIndex when full-size gallery is shown
    if (images.length > 0 && galleria.current) {
      galleria.current.onShow = () => {
        setActiveIndex(lastActiveIndex.current);
      };
    }
  }, []);

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Nebolo možné vykonať požadovanú akciu",
      detail: message,
      life: 3000,
    });
  };

  const hadnleImagesModification = (formData) => {
    formData.append(`idProduct`, idProduct);
    const requestOptions = {
      method: "POST",
      body: formData,
    };

    fetch("/api/images/changeProductImages", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setUnauthorizedDialog(true);
        }
        if (data.success) {
          window.location.reload();
        } else {
          showError("Dočasná chyba servera, vyskúšajte to opäť neskôr prosím");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showError("Dočasná chyba servera, vyskúšajte to opäť neskôr prosím");
      });
  };

  const changeProductImages = () => {
    return (
      <div>
        <Toast ref={toast} />
        <Dialog
          header={<div style={{ fontSize: "16px" }}>Upraviť baner</div>}
          visible={changeImages}
          onHide={() => setChangeImages(false)}
          resizable={false}
          className="dialog-min-width"
        >
          <div>
            <ImagesManager
              allImages={imagesToGallery}
              hadnleImagesModification={hadnleImagesModification}
              withLink={false}
            />
          </div>
        </Dialog>
      </div>
    );
  };

  const dialogUnathorized = () => {
    return (
      <Dialog
        header="Nemáte oprávnenie"
        visible={unauthorizedDialog}
        className="dialog-min-width"
        onHide={() => setUnauthorizedDialog(false)}
        dismissableMask
        resizable={false}
      >
        <div>Na vyžiadanú akciu nemáte oprávnenie</div>
      </Dialog>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      {changeProductImages()}
      {dialogUnathorized()}
      {isLogedIn && isAdmin && idsExist && (
        <div className="flex justify-content-end ">
          <div
            style={{
              borderBottom: "2px solid #3a3432",
              backgroundColor: "white",
              position: "absolute",
              left: 0,
              top: 0,

              zIndex: "5",
            }}
            className="p-2 m-2 pointer flex align-items-center"
            onClick={() => setChangeImages(true)}
          >
            <i className="pi pi-pencil pointer pr-2"></i>
            <div> Upraviť fotky</div>
          </div>
        </div>
      )}

      <div className="gallery">
        <Galleria
          value={imagesToGallery.images}
          style={{ maxWidth: "700px" }}
          numVisible={2}
          responsiveOptions={[
            {
              breakpoint: "992px", // Adjust breakpoint as needed for mobiles
              numVisible: 2, // Force 2 images on screens below 992px
            },
          ]}
          item={itemTemplate}
          thumbnail={thumbnailTemplate}
          showItemNavigators
          circular
          showItemNavigatorsOnHover
          activeIndex={activeIndex}
          onItemChange={(e) => setActiveIndex(e.index)}
        />

        <Galleria
          className="full-size"
          ref={galleria}
          value={imagesToGallery.images}
          numVisible={1}
          fullScreen
          circular
          showItemNavigators
          showThumbnails={false}
          item={itemTemplateFullSize}
          thumbnail={thumbnailTemplate}
          activeIndex={activeIndex}
          onItemChange={(e) => setActiveIndex(e.index)}
        />
      </div>
    </div>
  );
}

export default ProductDetailImage;
