import React, { useState } from "react";

function ImageOptions({
  images,
  chooseOption,
  handleInput,
  choosenImage,
  name,
}) {
  const [addNewMaintenanceImage, setAddNewMaintenanceImage] = useState(false);

  return (
    <div>
      {!addNewMaintenanceImage ? (
        <div>
          <div className="flex flex-wrap align-items-top justify-content-center ">
            {images &&
              images.map((image, index) => (
                <img
                  key={image.imageId}
                  style={{
                    marginRight: index % 2 === 0 ? "20px" : "0px",
                    width: "250px",
                    border:
                      choosenImage === image.imageId
                        ? "2px solid #3a3432"
                        : "2px solid transparent",
                    marginBottom: "20px",
                  }}
                  src={image.path}
                  alt={`${name}_${image.imageId}`}
                  id={image.imageId}
                  className="pointer"
                  onClick={chooseOption}
                />
              ))}
          </div>
          <button
            className="btn light"
            style={{
              width: "200px",
              height: "30px",
            }}
            onClick={() => setAddNewMaintenanceImage((prevState) => !prevState)}
          >
            +Pridať nový
          </button>
        </div>
      ) : (
        <div>
          <label className="custom-file-input">
            <input
              type="file"
              name={name}
              onChange={handleInput}
              accept="image/*"
            />
          </label>
          <div className="mt-2">
            <button
              className="btn light mt-2"
              style={{ width: "250px", height: "30px" }}
              onClick={() =>
                setAddNewMaintenanceImage((prevState) => !prevState)
              }
            >
              Vybrať z existujúcich
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageOptions;
