import React, { useEffect, useContext, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { UserInfoContext } from "../UserInfo";
import Loading from "../Loading";
function OrderPaidCanceled() {
  const [paymentRedirectError, setPaymentRedirectError] = useState(
    window.location.href.includes("paywall-redirect-error")
  );
  const params = useParams();
  const navigate = useNavigate();
  const { updateItemsCount, updateLoadingState, loading } =
    useContext(UserInfoContext);

  useEffect(() => {
    updateLoadingState(true);
    updateItemsCount(0);
    updateLoadingState(false);
  }, []);
  const payAgain = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: params.token,
      }),
    };

    fetch("/api/orders/createGoPayPaymentAfterFail", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          window.location.href = data.paymentUrl;
        } else {
          if (data.alreadyPaid) {
            navigate(`/payment-success/${data.tokenSuccess}`);
          } else {
            window.location.reload();
          }
        }
      })
      .catch(() => {
        window.location.reload();
      });
  };
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div
          className="flex align-items-center justify-content-center"
          style={{ height: "90vh" }}
        >
          <div
            style={{
              maxWidth: "600px",
              boxShadow: " 0 2px 10px rgba(0, 0, 0, 0.3)",
              padding: "20px 50px",
              borderRadius: "10px",
            }}
          >
            <div className="flex justify-content-center align-items-center">
              <i
                style={{ fontSize: "40px" }}
                className="pi pi-times-circle alert"
              ></i>
              <div
                className="ml-3 text-center"
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {paymentRedirectError
                  ? "Chyba pri presmerovaní na platobnú bránu"
                  : "Objednávka nebola zaplatená"}
              </div>
            </div>
            <div className="mt-2 text-center" style={{ fontSize: "17px" }}>
              {paymentRedirectError
                ? "Ľutujeme, ale pri presmerovaní na platobnú bránu došlu ku chybe."
                : "Ľutujeme, ale vaša objednávka nebola zaplatená"}
            </div>
            <div className="flex align-items-center justify-content-center">
              <button
                className="btn dark mt-3"
                onClick={payAgain}
                style={{ maxWidth: "250px", fontSize: "16px" }}
              >
                Zopakovať platbu
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderPaidCanceled;
