import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { InputSwitch } from "primereact/inputswitch";

const CookiesManager = () => {
  const necessaryCookies = [
    "ACCOUNT_CHOOSER",
    "JSESSIONID",
    "accessToken",
    "refreshToken",
    "csrftoken",
    "sessionid",
  ];
  const functional = ["AEC", "NID", "SEARCH_SAMESITE"];
  const [displayAdjustCookies, setDisplayAdjustCookies] = useState(false);
  const [acceptedCategories, setAcceptedCategories] = useState({
    necessary: true,
    functional: false,
    statistics: false,
    marketing: false,
    other: false,
  });
  const allCookies = [
    // Google-related cookies
    { name: "AEC", domain: ".google.com" },
    { name: "APISID", domain: ".google.com" },
    { name: "HSID", domain: ".google.com" },
    { name: "JSESSIONID", domain: "gw.sandbox.gopay.com" },
    { name: "LSID", domain: ".accounts.google.com" },
    { name: "NID", domain: ".google.com" },
    { name: "SAPISID", domain: ".google.com" },
    { name: "SEARCH_SAMESITE", domain: ".google.com" },
    { name: "SID", domain: ".google.com" },
    { name: "SIDCC", domain: ".google.com" },
    { name: "SSID", domain: ".google.com" },
    { name: "__Secure-1PAPISID", domain: ".google.com" },
    { name: "__Secure-1PSID", domain: ".google.com" },
    { name: "__Secure-1PSIDCC", domain: ".google.com" },
    { name: "__Secure-1PSIDTS", domain: ".google.com" },
    { name: "__Secure-3PAPISID", domain: ".google.com" },
    { name: "__Secure-3PSID", domain: ".google.com" },
    { name: "__Secure-3PSIDCC", domain: ".google.com" },
    { name: "__Secure-3PSIDTS", domain: ".google.com" },

    // Instagram-related cookies
    { name: "csrftoken", domain: ".instagram.com" },
    { name: "datr", domain: ".instagram.com" },
    { name: "ds_user_id", domain: ".instagram.com" },
    { name: "mid", domain: ".instagram.com" },
    { name: "ps_l", domain: ".instagram.com" },
    { name: "ps_n", domain: ".instagram.com" },
    { name: "rur", domain: ".instagram.com" },
    { name: "sessionid", domain: ".instagram.com" },
    { name: "shbid", domain: ".instagram.com" },
    { name: "shbts", domain: ".instagram.com" },
    { name: "wd", domain: ".instagram.com" },

    // Other cookies
    { name: "__stripe_mid", domain: ".railway.app" },
    { name: "_gt_ct", domain: "gw.sandbox.gopay.com" },
    { name: "_tckkies", domain: "gw.sandbox.gopay.com" },
    { name: "accessToken", domain: "broleit.netlify.app" },
    { name: "cf_clearance", domain: ".cloudinary.com" },
    { name: "fblo_769580365202502", domain: "broleit.netlify.app" },
    { name: "fbm_769580365202502", domain: "broleit.netlify.app" },
    { name: "gp_gsid", domain: ".gopay.com" },
    { name: "intercom-device-id-avmr5b6h", domain: ".cloudinary.com" },
    { name: "intercom-session-avmr5b6h", domain: ".cloudinary.com" },
    { name: "refreshToken", domain: "broleit.netlify.app" },
    // Add other cookies here...
  ];

  const categorizeCookies = (cookies) => {
    const categorizedCookies = {
      necessary: [],
      functional: [],
      analytical: [],
      marketing: [],
      others: [],
    };

    cookies.forEach((cookie) => {
      // Check if the cookie is necessary based on its name or domain
      if (
        necessaryCookies.includes(cookie.name) ||
        cookie.domain.includes(window.location.hostname) ||
        cookie.domain.includes("gopay")
      ) {
        categorizedCookies.necessary.push(cookie);
      }
      // Check if the cookie is functional based on its name or domain
      else if (
        cookie.name.includes("csrftoken") ||
        cookie.domain.includes("instagram.com") ||
        functional.includes(cookie.name)
      ) {
        categorizedCookies.functional.push(cookie);
      }
      // Check if the cookie is analytical based on its name or domain
      else if (
        cookie.name.includes("SID") ||
        cookie.domain.includes("cloudinary.com")
      ) {
        categorizedCookies.analytical.push(cookie);
      }
      // Check if the cookie is marketing based on its name or domain
      else if (
        cookie.name.startsWith("fb") ||
        cookie.domain.includes(".railway.app")
      ) {
        categorizedCookies.marketing.push(cookie);
      }
      // If it doesn't fit into any category, consider it as other
      else {
        categorizedCookies.others.push(cookie);
      }
    });
    return categorizedCookies;
  };

  const categorizedCookies = categorizeCookies(allCookies);

  const [preferencesSet, setPreferencesSet] = useState(null);

  useEffect(() => {
    const preferences = localStorage.getItem("cookiePreferences");
    if (preferences) {
      if (JSON.parse(preferences).askAgain) {
        setPreferencesSet(false);
      } else {
        const acceptedPreferences = JSON.parse(
          localStorage.getItem("cookiePreferences")
        );
        const allAcceptedPreferences = Object.keys(acceptedPreferences);

        allCookies.forEach((cookie) => {
          allAcceptedPreferences.forEach((category) => {
            if (!acceptedCategories[category] && category !== "necessary") {
              Cookies.remove(cookie.name, { domain: cookie.domain });
            }
          });
        });
        setPreferencesSet(true);
      }
    } else {
      setPreferencesSet(false);
    }
  }, []);

  const handleSavePreferences = () => {
    localStorage.setItem(
      "cookiePreferences",
      JSON.stringify(acceptedCategories)
    );

    allCookies.forEach((cookie) => {
      for (const category in acceptedCategories) {
        if (!acceptedCategories[category] && category !== "necessary") {
          Cookies.remove(cookie.name, { domain: cookie.domain });
        }
      }
    });

    setPreferencesSet(true);
  };

  function cookieExists(name) {
    // Check if the cookie is present in document.cookie
    return document.cookie.split(";").some((cookie) => {
      return cookie.trim().startsWith(name + "=");
    });
  }

  const hideOptions = () => {
    localStorage.setItem(
      "cookiePreferences",
      JSON.stringify({ necessary: true, askAgain: true })
    );
    //if user just hide dialog, it set neccessary cookies and delete rest, but next time when user get to the page he will be asked to confirm it again
    allCookies.forEach((cookie) => {
      if (!categorizedCookies.necessary.includes(cookie.name)) {
        Cookies.remove(cookie.name, { domain: cookie.domain });
      }
    });
    setPreferencesSet(true);
  };

  const selectAll = () => {
    // for (const category of Object.keys(categorizedCookies)) {
    //   console.log(`
    //  "${category}":[`);
    //   categorizedCookies[category].map((cookie) => {
    //     console.log(
    //       `{"name": "${cookie.name}", "domain": "${cookie.domain}"},`
    //     );
    //   });
    //   console.log("],");
    // }
    localStorage.setItem(
      "cookiePreferences",
      JSON.stringify({
        necessary: true,
        functional: true,
        statistics: true,
        marketing: true,
        other: true,
      })
    );

    setPreferencesSet(true);
  };

  const cookiesPreferencieSet = () => {
    return (
      <div
        className="p-2 manage-cookies-preferencies"
        id="scrollable-container"
      >
        <div
          className="mb-2"
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            color: "#3a3432",
          }}
        >
          Prispôsobte preferencie súhlasu
        </div>
        <div className="mb-3">
          <div className="flex justify-content-between mb-1">
            <div style={{ fontWeight: "bold" }}>Nevyhnutné</div>
            <div className="flex justify-content-end">
              <InputSwitch checked={true} className="mr-2" disabled />
            </div>
          </div>
          <div style={{ fontSize: "15px" }}>
            Slúžia na správne fungovanie webovej lokality a umožňujú vám
            pohodlne využívať naše služby.
          </div>
        </div>
        <div className="mb-3">
          <div className="flex justify-content-between mb-1">
            <div style={{ fontWeight: "bold" }}>Funkčné</div>
            <div className="flex justify-content-end">
              <InputSwitch
                checked={acceptedCategories.functional}
                onChange={(e) =>
                  setAcceptedCategories({
                    ...acceptedCategories,
                    functional: e.value,
                  })
                }
                className="mr-2"
              />
            </div>
          </div>
          <div style={{ fontSize: "15px" }}>
            Umožňujú webovej lokalite ukladať vaše nastavenia a prispôsobovať
            určité funkcie alebo obsah.
          </div>
        </div>
        <div className="mb-3">
          <div className="flex justify-content-between mb-1">
            <div style={{ fontWeight: "bold" }}>Analytické</div>
            <div className="flex justify-content-end">
              <InputSwitch
                checked={acceptedCategories.statistics}
                onChange={(e) =>
                  setAcceptedCategories({
                    ...acceptedCategories,
                    statistics: e.value,
                  })
                }
                className="mr-2"
              />
            </div>
          </div>
          <div style={{ fontSize: "15px" }}>
            Umožňujú získavať informácie o používaní našej webovej lokality,
            mieste a počte návštev v našom online obchode.
          </div>
        </div>
        <div className="mb-3">
          <div className="flex justify-content-between mb-1">
            <div style={{ fontWeight: "bold" }}>Reklamné</div>
            <div className="flex justify-content-end">
              <InputSwitch
                checked={acceptedCategories.marketing}
                onChange={(e) =>
                  setAcceptedCategories({
                    ...acceptedCategories,
                    marketing: e.value,
                  })
                }
                className="mr-2"
              />
            </div>
          </div>
          <div style={{ fontSize: "15px" }}>
            Tieto cookies používame, aby sme vám mohli ponúknuť optimálny výber
            našich produktov a služieb na webových lokalitách našich partnerov.
          </div>
        </div>
        <div className="flex justify-content-between">
          <button className="light pointer" onClick={handleSavePreferences}>
            Uložiť preferencie
          </button>
          <button className="light pointer" onClick={hideOptions}>
            Odmietnuť
          </button>
          <button className="dark pointer" onClick={selectAll}>
            Prijať všetko
          </button>
        </div>
      </div>
    );
  };

  if (preferencesSet) {
    return null; // Don't show the component if preferences are already set
  }
  return (
    <div>
      {preferencesSet !== null && (
        <div className="overlay ">
          <div className="cookies-manager-wrapper">
            {displayAdjustCookies ? (
              cookiesPreferencieSet()
            ) : (
              <div className="cookies-manager">
                <div>
                  <div
                    className="mb-3"
                    style={{
                      fontWeight: "bold",
                      color: "#3a3432",
                      fontSize: "18px",
                    }}
                  >
                    Spravujte súhlas so súbormi cookie
                  </div>
                  <div className="mb-3" style={{ fontSize: "16px" }}>
                    Na poskytovanie tých najlepších skúseností používame
                    technológie, ako sú súbory cookie na ukladanie a/alebo
                    prístup k informáciám o zariadení. Súhlas s týmito
                    technológiami nám umožní spracovávať údaje, ako je správanie
                    pri prehliadaní alebo jedinečné ID na tejto stránke.
                    Nesúhlas alebo odvolanie súhlasu môže nepriaznivo ovplyvniť
                    určité vlastnosti a funkcie.
                  </div>
                  <div className="flex justify-content-between">
                    <button
                      className="light pointer"
                      onClick={() => setDisplayAdjustCookies(true)}
                    >
                      Prispôsobiť
                    </button>
                    <button className="light pointer" onClick={hideOptions}>
                      Odmietnuť
                    </button>
                    <button className="dark pointer" onClick={selectAll}>
                      Prijať všetko
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* <h1>Cookie Preferences</h1>
          <form>
            <label>
              <input type="checkbox" name="necessary" checked disabled />
              Necessary Cookies (always active)
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                name="preferences"
                checked={acceptedCategories.preferences}
                onChange={handleCategoryChange}
              />
              Preference Cookies
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                name="statistics"
                checked={acceptedCategories.statistics}
                onChange={handleCategoryChange}
              />
              Statistics Cookies
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                name="marketing"
                checked={acceptedCategories.marketing}
                onChange={handleCategoryChange}
              />
              Marketing Cookies
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                name="other"
                checked={acceptedCategories.other}
                onChange={handleCategoryChange}
              />
              Other Cookies
            </label>
            <br />
            <button type="button" onClick={handleSavePreferences}>
              Save Preferences
            </button>
          </form> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default CookiesManager;
