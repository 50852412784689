import React, { useEffect, useState, useContext } from "react";
import ProductOverview from "./Products/ProductOverview";
import { UserInfoContext } from "./UserInfo";
import Loading from "./Loading";

function Favourites() {
  const {
    removeFromWishlist,
    wishList,
    setWishListContent,
    isLogedIn,
    loading,
    updateLoadingState,
  } = useContext(UserInfoContext);
  const [wishListData, setWishListData] = useState(null);
  const [cardWidth, setCardWidth] = useState(null);
  const [numItemsPerRow, setNumItemsPerRow] = useState(null);
  const [maxProductDisplayedWidth, setMaxProductDisplayedWidth] = useState(0);
  const [initial, setInitial] = useState(true);

  const getFavouriteData = () => {
    if (wishList.length > 0) {
      updateLoadingState(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ids: wishList,
        }),
      };

      fetch("/api/products/getOverviewDataBasedOnId", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.productExists) {
            setWishListData(data.products);
            if (data.products.length === 0) {
              setNumItemsPerRow(1);
            }
            //in case there is still something stored in localStorage but it was already deleted from DB

            if (
              data.products &&
              data.products.length !==
                JSON.parse(localStorage.getItem("wishList")).length
            ) {
              const ids = data.products.map((product) => product.id);
              setWishListContent(ids);
            }
          } else {
            //there is nothing from wishlist in DB- set it to empty array
            const newWishlist = [];
            setWishListContent(newWishlist);
            setWishListData(newWishlist);
          }
          updateLoadingState(false);
        })
        .catch(() => {
          setWishListData([]);
          updateLoadingState(false);
        });
    } else {
      const storedWishList = localStorage.getItem("wishList");
      if (storedWishList && JSON.parse(storedWishList).length === 0) {
        updateLoadingState(false);
      }
    }
  };

  useEffect(() => {
    if (initial) {
      getFavouriteData();
      setInitial(false);
    }
  }, [wishList]);

  useEffect(() => {
    if (localStorage.getItem("logedIn")) {
      getFavouriteData();
      setCardWidth(calculateItemWidth(wishList.length));
    } else if (!isLogedIn) {
      setWishListContent([]);
      setWishListData([]);
    }
  }, [isLogedIn]);

  function findMissingValue(arr1, arr2) {
    const set1 = new Set(arr1);
    for (const num of arr2) {
      if (!set1.has(num)) {
        return parseFloat(num);
      }
    }
    const set2 = new Set(arr2);
    // If loop completes, missing value is in the larger array (arr1)
    return arr1.find((num) => !set2.has(num)); // Assuming arr1 is larger (adjust if needed)
  }

  const removeItemFromWishlistData = (id) => {
    setWishListData((prevWishList) => {
      const indexToRemove = prevWishList.findIndex((list) => list.id === id);

      if (indexToRemove !== -1) {
        const updatedWishList = [...prevWishList];
        updatedWishList.splice(indexToRemove, 1);
        return updatedWishList;
      }
    });
    if (wishListData.length === 1) {
      setMaxProductDisplayedWidth(window.innerWidth);
      setNumItemsPerRow(1);
    } else {
      setCardWidth(calculateItemWidth(wishList.length));
    }
  };

  const wishlistChanged = () => {
    if (
      !initial &&
      wishListData &&
      Math.abs(wishList.length - wishListData.length) === 1
    ) {
      const wishlistDataIds = wishListData
        ? wishListData.map((data) => data.id)
        : [];

      if (wishList.length > wishListData.length) {
        const id = findMissingValue(wishList, wishlistDataIds);

        updateLoadingState(true);
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ids: [id],
          }),
        };
        fetch("/api/products/getOverviewDataBasedOnId", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.productExists) {
              setWishListData((prevValues) => [
                ...prevValues,
                data.products[0],
              ]);
              setCardWidth(calculateItemWidth(wishList.length));
              updateLoadingState(false);
            } else {
              updateLoadingState(false);
            }
          })
          .catch(updateLoadingState(false));
      } else {
        const missingValue = findMissingValue(wishlistDataIds, wishList);
        removeItemFromWishlistData(missingValue);
        setCardWidth(calculateItemWidth(wishList.length));
      }
    } else if (wishListData && wishList.length === wishListData.length) {
      setCardWidth(calculateItemWidth(wishList.length));
    } else {
      getFavouriteData();
      setCardWidth(calculateItemWidth(wishList.length));
    }
  };

  useEffect(() => {
    wishlistChanged();
  }, [wishList]);

  const removeFromWishlistData = (id) => {
    removeItemFromWishlistData(id);
    removeFromWishlist(id);
  };

  function calculateItemWidth(wishlistLength) {
    let reduce = 100;
    if (window.innerWidth <= 500) {
      reduce = 30;
    }
    const rowWidth = window.innerWidth - reduce;
    let itemsPerRow = Math.min(Math.floor(rowWidth / wishlistLength), 4);
    const minItemsInRow = 2;
    const minAvailableSpaceNeeded = rowWidth - (minItemsInRow - 1) * 10;
    let width = 240;
    //set width to minimum to be able to have minimum needed products
    if (minAvailableSpaceNeeded < width * minItemsInRow) {
      const remainingSpace = width * minItemsInRow - minAvailableSpaceNeeded;
      const widthToReduce = remainingSpace / minItemsInRow;
      width -= widthToReduce;
    }

    while (itemsPerRow * width > rowWidth) {
      itemsPerRow -= 1;
    }
    if (wishlistLength > 0) {
      itemsPerRow = Math.min(itemsPerRow, wishlistLength);
    }

    const neededSpace = width * itemsPerRow + 10 * (itemsPerRow - 1);
    setMaxProductDisplayedWidth(neededSpace);
    setNumItemsPerRow(itemsPerRow);
    return width;
  }

  const handleResize = () => {
    setCardWidth(
      calculateItemWidth(JSON.parse(localStorage.getItem("wishList")).length)
    );
  };

  useEffect(() => {
    getFavouriteData();

    const handleStorageChange = (event) => {
      if (event.key === "wishlist") {
        wishlistChanged();
      }
    };

    setCardWidth(calculateItemWidth(wishList.length));

    window.addEventListener("resize", handleResize);
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div className="flex justify-content-center flex-column">
      <div style={{ backgroundColor: "#3a3432", color: "white" }}>
        <div
          className="pt-3 pb-3 flex justify-content-center"
          style={{ fontSize: "25px" }}
        >
          Obľúbené
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div
          className="flex justify-content-center mt-5"
          style={{ minHeight: "80vh" }}
        >
          {!wishListData ||
          (wishListData.length === 0 && wishList.length === 0) ? (
            <div
              className="flex justify-content-center flex-wrap justify-content-center align-items-center"
              style={{ fontSize: "20px", height: "70vh", widows: "100vw" }}
            >
              Nemáte uložené žiadne produkty
            </div>
          ) : (
            wishList &&
            wishList.length > 0 && ( // Check if wishList exists and has items
              <div
                className="card-grid"
                style={{
                  gridTemplateColumns: `repeat(${numItemsPerRow}, 1fr)`,
                  width: maxProductDisplayedWidth,
                }}
              >
                {wishListData.map((product) => (
                  <div
                    key={product.id}
                    style={{
                      width: `${cardWidth}px`,
                      height: `calc(${cardWidth}px + 120px)`,
                      position: "relative",
                    }}
                  >
                    <ProductOverview
                      overviewData={product}
                      removeFromWishlist={removeFromWishlistData}
                      maxWidth={cardWidth}
                    />
                  </div>
                ))}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
}

export default Favourites;
