import React, { useState, useEffect } from "react";

function ImagesManager({ allImages, hadnleImagesModification, withLink }) {
  const [imagesToRemove, setImagesToRemove] = useState([]);
  const [numOfNewImages, setNumOfNewImages] = useState(0);
  const [imagesToAdd, setImagesToAdd] = useState([]);
  const [submitBtnClicked, setSubmitBtnClicked] = useState(false);

  useEffect(() => {
    setNumOfNewImages(0);
    setImagesToAdd([]);
    setImagesToAdd([]);
  }, [allImages]);

  const addImageIdToRemove = (event) => {
    const id = parseInt(event.target.id);
    setImagesToRemove((prevState) => [...prevState, id]);
  };

  const removeImageIdToRemove = (event) => {
    const id = parseInt(event.target.id);
    setImagesToRemove((prevState) => {
      const indexToRemove = prevState.findIndex((item) => item === id);
      if (indexToRemove !== -1) {
        const newState = [...prevState];
        newState.splice(indexToRemove, 1);
        return newState;
      } else {
        return prevState;
      }
    });
  };

  const expandSlideImagesInput = () => {
    setNumOfNewImages((prevState) => prevState + 1);
    setImagesToAdd((prevState) => [
      ...prevState,
      {
        newSlideImg: "",
        newSlideRedirectLink: "",
      },
    ]);
  };

  const updateImages = (event) => {
    event.preventDefault();
    const formData = new FormData();
    setSubmitBtnClicked(true);
    imagesToAdd.map((image, index) => {
      if (image.newSlideImg !== "") {
        formData.append(`images`, image.newSlideImg);
        if (withLink) {
          formData.append(`redirectUrl_${index}`, image.newSlideRedirectLink);
        }
      }
    });
    if (imagesToRemove.length > 0) {
      imagesToRemove.map((image, index) => {
        formData.append(`imageToRemove${index}`, parseInt(image));
      });
    }
    hadnleImagesModification(formData);
  };

  const handleChangeNewSliderImages = (event) => {
    event.preventDefault();
    const { name, value, type, files, id } = event.target;
    setImagesToAdd((prevState) => {
      return prevState.map((slide, index) => {
        if (index === parseInt(id)) {
          // Convert id to integer if necessary
          return {
            ...slide,
            [name]: type === "file" ? files[0] : value,
          };
        }
        return slide;
      });
    });
  };
  return (
    <div>
      <div className="mb-2" style={{ fontSize: "16px" }}>
        Vyberte obrázky, ktoré chcete odstrániť
      </div>

      <div className="flex justify-content-center flex-wrap">
        {allImages.images &&
          allImages.images.map((image, index) => {
            if (allImages.ids[index] && allImages.ids[index] !== -1) {
              return (
                <div
                  key={`slide_${index}`}
                  onClick={
                    imagesToRemove.includes(allImages.ids[index])
                      ? removeImageIdToRemove
                      : addImageIdToRemove
                  }
                >
                  <img
                    id={allImages.ids[index]}
                    src={image}
                    alt={`slide_${index}`}
                    className="pointer mr-2"
                    style={{
                      maxHeight: "150px",
                      width: "22vw",
                      border: imagesToRemove.includes(allImages.ids[index])
                        ? "4px solid #3a3432"
                        : "none",
                      objectFit: "cover",
                    }}
                  />
                </div>
              );
            }
          })}
        <div style={{ width: "100%" }}>
          <button
            className="btn light mt-3 mb-1"
            style={{ width: "100%" }}
            onClick={expandSlideImagesInput}
          >
            +Pridať nový obrázok
          </button>
          <form onSubmit={updateImages}>
            <div className="mb-3 flex-wrap">
              {[...Array(numOfNewImages).keys()].map((index) => (
                <div key={index} className="flex flex-column">
                  <div className="mb-2 mt-2">
                    <input
                      id={index}
                      type="file"
                      name={`newSlideImg`}
                      onChange={handleChangeNewSliderImages}
                      accept="image/*"
                    />
                  </div>
                  {withLink && (
                    <div style={{ width: "100%" }} className="mb-3">
                      <input
                        id={index}
                        type="text"
                        value={imagesToAdd[index].newSlideRedirectLink}
                        style={{ width: "100%" }}
                        placeholder="Zadajte link, na ktorý bude obrázok odkazovať"
                        name={`newSlideRedirectLink`}
                        onChange={handleChangeNewSliderImages}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <button
              className="btn dark p-2 flex justify-content-center align-items-center"
              style={{ width: "100%" }}
            >
              {submitBtnClicked && (
                <i
                  className="pi pi-spin pi-spinner mr-2"
                  style={{ fontSize: "20px" }}
                ></i>
              )}
              Zadať
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ImagesManager;
