import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../Loading";
import ProdutctDetailImage from "./ProductDetail/ProdutctDetailImage";
import ProductDetailBasicInfo from "./ProductDetail/ProductDetailBasicInfo";
import ProductDetailClickInformation from "./ProductDetail/ProductDetailClickInformation";
import ProductDetailSelection from "./ProductDetail/ProductDetailSelection";
import { UserInfoContext } from "../UserInfo";
function ProductDetail() {
  const navigate = useNavigate();
  const [productKind, setProductKind] = useState("");
  const [productDetail, setProductDetail] = useState(null);
  const [productExists, setProductExists] = useState(true);
  const [multiplePrices, setMultiplePrices] = useState(false);
  const [allSizes, setAllSizes] = useState([]);
  const params = useParams();
  const { loading, isAdmin, isLogedIn, updateLoadingState } =
    useContext(UserInfoContext);
  const customSizeOrder = (a, b) => {
    // Define the order of sizes
    const sizeOrder = {
      XXS: 0,
      XS: 1,
      S: 2,
      M: 3,
      L: 4,
      XL: 5,
      XXL: 6,
      XXXL: 7,
    }; // Add more sizes as needed

    // Get the index of each size in the order or default to a high index if not found
    const indexA =
      sizeOrder[a.name] !== undefined ? sizeOrder[a.name] : Infinity;
    const indexB =
      sizeOrder[b.name] !== undefined ? sizeOrder[b.name] : Infinity;

    // Compare the indexes
    return indexA - indexB;
  };
  useEffect(() => {
    updateLoadingState(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        productId: params.productId,
      }),
    };
    fetch("/api/products/getDetail", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setProductExists(data.product.productExists);

        if (data.product.productExists) {
          setProductDetail(data.product.product);
          setProductKind(data.productKind);

          const firstPrice =
            data.product.product.availableVariants &&
            data.product.product.availableVariants.length > 0
              ? data.product.product.availableVariants[0].price
              : null;
          let multiplePrices = false;
          if (firstPrice) {
            for (const variant of data.product.product.availableVariants) {
              if (firstPrice !== variant.price) {
                multiplePrices = true;
                break;
              }
            }
          }
          setMultiplePrices(multiplePrices);

          const allSizes = data.product.product.availableVariants
            ? data.product.product.availableVariants.map((availableVariant) => {
                if (availableVariant.size) {
                  return {
                    name: availableVariant.size,
                    radius: availableVariant.sizeRadius,
                  };
                }
              })
            : [];
          const uniqueSizes = new Set(allSizes);
          const sortedSizes = Array.from(uniqueSizes).sort(customSizeOrder);
          setAllSizes(sortedSizes);
        } else {
          navigate("/product/detail/null");
        }
        updateLoadingState(false);
      });
  }, []);
  return (
    <div>
      {(productExists && !productDetail) || loading ? (
        <Loading />
      ) : (
        <div>
          <div
            className="product-detail-container"
            style={{
              display: productExists ? "flex" : "none",
              height: "100%",
            }}
          >
            <div className="product-detail-content">
              {productDetail.images && (
                <ProdutctDetailImage
                  images={productDetail.images}
                  idProduct={productDetail.id}
                />
              )}

              <div className="product-detail-specification">
                {isLogedIn && isAdmin && (
                  <div className="flex justify-content-end ">
                    <div
                      style={{
                        borderBottom: "2px solid #3a3432",
                        backgroundColor: "white",
                      }}
                      className="pl-2 pr-2 pb-1 pointer mt-2"
                      onClick={() =>
                        productKind === "digi"
                          ? navigate(`/update-digi-product/${productDetail.id}`)
                          : navigate(`/update-product/${productDetail.id}`)
                      }
                    >
                      <i className="pi pi-pencil pointer pr-2"></i>
                      Upraviť
                    </div>
                  </div>
                )}
                {productDetail && (
                  <ProductDetailBasicInfo
                    name={productDetail.name}
                    id={productDetail.id}
                    description={productDetail.description}
                  />
                )}
                {productDetail &&
                productDetail.availableVariants &&
                productDetail.availableVariants.length > 0 ? (
                  <ProductDetailSelection
                    productKind={productKind}
                    availableVariants={productDetail.availableVariants}
                    multiplePrices={multiplePrices}
                    productName={productDetail.name}
                    productId={productDetail.id}
                    productImage={productDetail.images[0]}
                    allSizes={allSizes
                      .filter((size) => size && size.name !== null)
                      .map((size) => size.name)}
                  />
                ) : (
                  <div className="flex justify-content-center mb-5">
                    <div
                      style={{
                        padding: "5px 15px",
                        border: "2px solid #DF4930",
                        borderRadius: "10px",
                        color: "#DF4930",
                        fontSize: "18px",
                        marginLeft: "10px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Vypredané
                    </div>
                  </div>
                )}
                <ProductDetailClickInformation
                  allSizes={allSizes}
                  material={productDetail.material}
                  weight={productDetail.weight}
                  type={productDetail.type}
                  maintenance={productDetail.maintenance}
                  certificate={productDetail.certificate}
                />
              </div>
            </div>
          </div>

          <div
            className="flex-wrap justify-content-center align-items-center	"
            style={{
              fontSize: "20px",
              height: "70vh",
              display: productExists ? "none" : "flex",
            }}
          >
            Produkt nie je k dispozícii
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductDetail;
