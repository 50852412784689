import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

export const UserInfoContext = createContext();

export const UserInfoProvider = ({ children }) => {
  const [itemsCount, setItemsCount] = useState(0);
  const [wishList, setWishList] = useState([]);
  const [initial, setInitial] = useState(true);
  const [isLogedIn, setIsLogedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateItemsCount = (newCount) => {
    setItemsCount(newCount);
  };

  const setLogInExpiration = () => {
    const currentDate = new Date();
    const millisecondsInDay = 1000 * 60 * 60 * 24;
    const expirationTimeInMilliseconds = millisecondsInDay * 180;

    const expirationDate = new Date(
      currentDate.getTime() + expirationTimeInMilliseconds
    );
    expirationDate.setHours(0, 0, 0, 0);
    localStorage.setItem("logedIn", expirationDate.toISOString());
  };

  const updateUserDataAfterLogin = (wishlistFromDB = [], shoppingCart = []) => {
    setLogInExpiration();

    const whislistFromLocalStorga = localStorage.getItem("wishList")
      ? JSON.parse(localStorage.getItem("wishList"))
      : [];
    const mergedWishlist =
      wishlistFromDB.length > 0
        ? [
            ...wishlistFromDB,
            ...whislistFromLocalStorga.filter((itemFromLocal) => {
              // Check for existing item based on your unique identifier (e.g., 'id'):
              return !wishlistFromDB.some(
                (itemFromDB) => itemFromDB.id === itemFromLocal.id
              );
            }),
          ]
        : whislistFromLocalStorga;

    localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
    setWishListContent(mergedWishlist);
    updateItemsCount(shoppingCart.length);
    setIsLogedIn(true);
    setLoading(false);
  };

  const updateUserDataAfterLogout = () => {
    updateItemsCount(0);
    setWishListContent([]);
    setIsAdmin(false);
    localStorage.setItem("shoppingCart", JSON.stringify([]));
    localStorage.removeItem("logedIn");
    if (localStorage.getItem("discounts")) {
      localStorage.removeItem("discounts");
    }
    setLoading(false);
    setIsLogedIn(false);
  };

  const addToWishlist = (id) => {
    setWishList((prevWishList) => {
      const updatedWishList = [...prevWishList, id];
      return updatedWishList;
    });

    if (localStorage.getItem("logedIn")) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_product: id,
        }),
      };

      fetch("/api/wishList/addToWishlist", requestOptions).catch((error) =>
        console.log(error)
      );
    }
  };

  const updateLogedInState = (state) => {
    setIsLogedIn(state);
  };

  const removeFromWishlist = (id) => {
    setWishList((prevWishList) => {
      const indexToRemove = prevWishList.indexOf(id);
      if (indexToRemove !== -1) {
        const updatedWishList = [...prevWishList];
        updatedWishList.splice(indexToRemove, 1);
        return updatedWishList;
      }
    });
    if (localStorage.getItem("logedIn")) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_product: id,
        }),
      };

      fetch("/api/wishList/removeFromWishlist", requestOptions).catch((error) =>
        console.log(error)
      );
    }
  };

  const handleStorageChange = (event) => {
    if (event.key === "wishList") {
      setWishList(JSON.parse(event.newValue));
    } else if (event.key === "shoppingCart") {
      updateItemsCount(JSON.parse(localStorage.getItem("shoppingCart")).length);
    } else if (event.key === "unpaidOrder") {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (!initial) {
      localStorage.setItem("wishList", JSON.stringify(wishList));
    }
  }, [wishList]);

  const setWishListContent = (content) => {
    //first time its local storage load into wishlist, after it it is updating also local storage
    setWishList(content);
    if (initial) {
      setInitial(false);
    }
  };

  const setLogedInUser = async () => {
    try {
      const response = await fetch("/api/auth/getDataToLogedInUser");
      const data = await response.json();
      if (data.success) {
        updateUserDataAfterLogin(data.wishlist, data.shoppingCart);
        setIsAdmin(data.isAdmin);
      } else {
        updateUserDataAfterLogout();
      }
      localStorage.removeItem("initialLoading");
    } catch (error) {
      console.log("Error while setting user info after login", error);
    }
  };

  const updateLoadingState = (state) => {
    setLoading(state);
  };

  return (
    <UserInfoContext.Provider
      value={{
        itemsCount,
        isLogedIn,
        updateUserDataAfterLogin,
        updateItemsCount,
        addToWishlist,
        removeFromWishlist,
        setWishListContent,
        wishList,
        updateUserDataAfterLogout,
        isAdmin,
        setLogedInUser,
        loading,
        updateLoadingState,
        setLogInExpiration,
        updateLogedInState,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};
