import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function NewsletterConfirmation() {
  const [success, setSuccess] = useState("");
  const params = useParams();

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: params.token,
      }),
    };
    fetch("/api/homePage/newsletterConfirmation", requestOptions)
      .then((response) => response.json())
      .then((data) => setSuccess(data.success));
  }, []);

  return (
    <div
      className="flex justify-content-center align-items-center"
      style={{ height: "90vh" }}
    >
      {success !== "" && (
        <div
          style={{
            width: "300px",
            fontSize: "20px",
            fontWeight: "bold",
            color: "#3a3432",
          }}
          className="text-center"
        >
          {success
            ? "Prihlásenie na odber newslettra prebehlo úspešne"
            : "Je nám to ľúto, čas na potvrdenie emailu vypršal"}
        </div>
      )}
    </div>
  );
}

export default NewsletterConfirmation;
